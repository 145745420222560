import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { http } from "../../utils/httpCommon";
import { apiUrls } from "../../utils/apiUrls";

export const shipmentTagSchema = z.object({
  id: z.number(),
  name: z.string(),
  colorHex: z.string(),
  description: z.string(),
  position: z.number(),
});

const consigneeSchema = z.object({
  name: z
    .string()
    .nullish()
    .transform((x) => x ?? undefined),
  address: z
    .string()
    .nullish()
    .transform((x) => x ?? undefined),
  city: z
    .string()
    .nullish()
    .transform((x) => x ?? undefined),
  state: z
    .string()
    .nullish()
    .transform((x) => x ?? undefined),
  zipCode: z
    .string()
    .nullish()
    .transform((x) => x ?? undefined),
});

const driverSchema = z.object({
  id: z.number(),
  name: z.string(),
  refCode: z.number(),
});

const driverUcCcSchema = z.object({
  proNumber: z.string(),
  pickUpDate: z.date().nullable(),
  serviceDue: z.date().nullable(),
  routeOrTrapName: z.string(),
  pieces: z.number(),
  pallets: z.number(),
  weight: z.number(),
  startTime: z.date().nullable(),
  door: z.number(),
  driver: driverSchema.nullish().transform((x) => x ?? undefined),
  refTrailerNumber: z.string().nullish(),
  deliveryEta: z.date().nullable(),
  status: z.string(),
  tags: z.array(shipmentTagSchema),
  appointmentStartDate: z.date().nullable(),
  appointmentEndDate: z.date().nullable(),
  consignee: consigneeSchema.nullish().transform((x) => x ?? undefined),
});

const urgentAndCriticalCareSchema = z.array(driverUcCcSchema);

const keys = {
  urgentAndCriticalCare: (sicCode: number, planDate: number) => [
    "inbound",
    "urgentAndCriticalCare",
    sicCode,
    planDate,
  ],
};

export type UrgentAndCriticalCare = z.infer<typeof urgentAndCriticalCareSchema>;

export type InboundUrgentAndCriticalCareShipment = z.infer<
  typeof driverUcCcSchema
>;

export function useInboundUrgentAndCriticalCare(
  sicCode: number,
  planDate: number
) {
  return useQuery({
    queryKey: keys.urgentAndCriticalCare(sicCode, planDate),
    queryFn: async (): Promise<UrgentAndCriticalCare> => {
      const { data } = await http.get(
        apiUrls.getInboundUrgentAndCriticalCare(sicCode, planDate)
      );
      return urgentAndCriticalCareSchema.parse(data);
    },
  });
}
