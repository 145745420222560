import { BulkUpdateDeliveryRouteTrapStatusResponse } from "../../../hooks/react-query/useDeliveryRoutesTrapsStatus";

export const mockPlannedUpdateRouteTrapStatuses = {
  empty: {
    successfullyUpdatedIds: {
      deliveryRoute: [],
      deliveryTrap: [],
    },
    failedToUpdateIds: {
      deliveryRoute: [],
      deliveryTrap: [],
    },
  },
  default: {
    failedToUpdateIds: {
      deliveryRoute: [],
      deliveryTrap: [],
    },
    successfullyUpdatedIds: {
      deliveryRoute: [1234],
      deliveryTrap: [],
    },
  },
  partial: {
    failedToUpdateIds: {
      deliveryRoute: [4321],
      deliveryTrap: [],
    },
    successfullyUpdatedIds: {
      deliveryRoute: [1234],
      deliveryTrap: [],
    },
  },
} as const satisfies Record<string, BulkUpdateDeliveryRouteTrapStatusResponse>;
