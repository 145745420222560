import { TableBody as MuiTableBody } from "@mui/material";
import { Row, Table } from "@tanstack/react-table";
import { Fragment, useCallback, useRef } from "react";
import ContextMenuDropdown, { ContextMenuRef } from "../ContextMenuDropdown";
import type { ContextMenuAction } from "../../../types/contextMenuAction";
import TableRow, { type TableRowProps } from "./TableRow";
import { RowSelectionMode } from "./Selectable.types";
import { ErrorMap } from "./table.types";

type Props<TData> = {
  /** Table instance */
  table: Table<TData>;
  /** current mode of selection */
  rowSelectionMode?: RowSelectionMode;
  /** id of the droppable table */
  droppableTableId?: (row: Row<TData>) => string;
  /** id of the droppable row */
  droppableRowId?: (row: Row<TData>) => string;
  /** can table rows sort/reorder */
  isSortable: boolean;
  /** context menu actions */
  contextMenuActions?: (row?: TData) => ContextMenuAction[];
  /** Map of row id to row error. */
  rowErrors?: ErrorMap;
};

const TableBody = <TData,>({
  table,
  rowSelectionMode = "unselectable",
  droppableTableId,
  droppableRowId,
  isSortable,
  contextMenuActions,
}: Props<TData>) => {
  const contextMenuRef = useRef<ContextMenuRef<TData>>(null);

  const contextActions = useCallback(
    (row?: TData) => {
      if (contextMenuActions) {
        if (
          !table.getIsSomeRowsSelected() &&
          !table.getIsAllPageRowsSelected()
        ) {
          return contextMenuActions(row);
        } else {
          return contextMenuActions();
        }
      }
      return [];
    },
    [contextMenuActions, table]
  );

  const tableRowProps = (
    row: Row<TData>,
    isDraggableRow: boolean
  ): TableRowProps<TData> => ({
    table,
    row,
    selectionCount: row.getIsSelected()
      ? table.getSelectedRowModel().flatRows.length
      : 0,
    rowSelectionMode,
    isSelected: row.getIsSelected(),
    contextMenuRef,
    isDraggableRow,
    isSortableRow: isSortable,
    dragAndDropId: droppableTableId
      ? droppableTableId(row)
      : droppableRowId?.(row),
  });

  const rows = table.getRowModel().rows;

  const contextMenu = () =>
    contextMenuActions && (
      <ContextMenuDropdown actions={contextActions} ref={contextMenuRef} />
    );

  const tableRowDefinition = () => {
    if (droppableTableId) {
      return (
        <MuiTableBody>
          {rows.map((row) => (
            <Fragment key={row.id}>
              <TableRow {...tableRowProps(row, true)} />
            </Fragment>
          ))}
          {contextMenu()}
        </MuiTableBody>
      );
    } else if (droppableRowId) {
      return (
        <MuiTableBody>
          {rows.map((row) => (
            <Fragment key={row.id}>
              <TableRow {...tableRowProps(row, false)} />
            </Fragment>
          ))}
          {contextMenu()}
        </MuiTableBody>
      );
    } else {
      return (
        <MuiTableBody>
          {rows.map((row) => (
            <Fragment key={row.id}>
              <TableRow {...tableRowProps(row, false)} />
            </Fragment>
          ))}
          {contextMenu()}
        </MuiTableBody>
      );
    }
  };

  return tableRowDefinition();
};

export default TableBody;
