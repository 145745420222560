import { Box, Card, CardContent, Typography, useTheme } from "@mui/material";
import { MouseEvent } from "react";
import pluralize from "pluralize";
import {
  Consignee,
  Shipment,
  Shipper,
} from "../../../../services/prePlanningService.types";
import { Tag } from "../../../../types/freight/tag.type";
import { dashIfEmpty } from "../../../../constants/utils";
import { DASH_PLACEHOLDER } from "../../../../constants/dashPlaceholder";
import { useSelectionContext } from "../../../../context/SelectionContext";
import { OldDragMultipleShipments } from "../../../../components/shared/OldDragMultipleShipments";
import { PrePlanningSection } from "../../../../types/inbound/prePlanning/sections.type";
import PlannedShipmentStatusTag from "../../../../components/shared/StatusTag/PlannedShipmentStatusTag";
import { TagsGrid } from "./TagsGrid";
import ShipmentTypography from "./ShipmentTypography";

interface ShipmentItemProps {
  planDate: string;
  index: number;
  item: Shipment;
  section: PrePlanningSection;
  isSelected?: boolean;
  isDraggable: boolean;
  /** Assigned route's name */
  assignedRoute?: string;
  onSelected?: (shipmentIds: number[]) => void;
  onRightClick?: (event: MouseEvent<HTMLElement>, shipmentId: number[]) => void;
}

const TextLine = ({ label, content }: { label: string; content?: string }) => (
  <>
    <ShipmentTypography>{label}</ShipmentTypography>
    <ShipmentTypography fontWeight="bold" mb={1}>
      {dashIfEmpty(content)}
    </ShipmentTypography>
  </>
);

type LeftColumnProps = {
  proNumber: string;
  tags: Tag[];
  shipper?: Shipper;
  consignee?: Consignee;
};

const LeftColumn = ({
  proNumber,
  tags,
  shipper,
  consignee,
}: LeftColumnProps) => {
  const consigneeName = dashIfEmpty(consignee?.name);
  const consigneeCity = dashIfEmpty(consignee?.city);
  const consigneeZipCode = dashIfEmpty(consignee?.zipCode);

  return (
    <Box sx={{ width: 150 }}>
      <ShipmentTypography>
        <b style={{ fontSize: "16px" }}>{`Pro #${proNumber}`}</b>
      </ShipmentTypography>
      <TagsGrid tags={tags} />
      {tags.length === 0 && <Box sx={{ height: 30 }} />}
      <TextLine label="Shipper" content={shipper?.name} />
      <TextLine
        label="Consignee"
        content={
          consignee
            ? `${consigneeName} ${consigneeCity} ${consigneeZipCode}`
            : DASH_PLACEHOLDER
        }
      />
    </Box>
  );
};

type RightColumnProps = {
  selectedDate: string;
  appointmentDate: Date | null;
  location: string | null;
  sic: string | null;
  status: string;
  isPlanned: boolean;
  futurePlanDates?: number[];
};

const RightColumn = ({
  selectedDate,
  appointmentDate,
  location,
  sic,
  status,
  isPlanned,
  futurePlanDates,
}: RightColumnProps) => (
  <Box sx={{ width: 120 }}>
    <Box
      sx={{
        position: "absolute",
        top: 10,
        right: 10,
      }}
    >
      <PlannedShipmentStatusTag
        label={status}
        isPlannedForToday={isPlanned}
        futurePlanDates={futurePlanDates}
        selectedDate={selectedDate}
      />
    </Box>
    <Box sx={{ height: 55 }} />
    <TextLine
      label="APPT"
      content={appointmentDate?.toLocaleDateString() ?? ""}
    />
    <TextLine label="SIC" content={sic ?? ""} />
    <TextLine label="LOC" content={location ?? ""} />
  </Box>
);

/** Shipment item preview with basic information */
const ShipmentItem = ({
  planDate,
  index,
  section,
  item,
  isSelected = false,
  isDraggable = false,
  assignedRoute,
  onSelected = () => {},
  onRightClick = () => {},
}: ShipmentItemProps) => {
  const theme = useTheme();
  const { selected, draggingSource } = useSelectionContext();
  const selectedCount = selected.flatMap((s) => s.shipmentIds).length || 0;

  return (
    <Card
      sx={{
        borderRadius: 0,
        userSelect: "none",
        backgroundColor:
          draggingSource === "sidebar" && isSelected
            ? "lightgray"
            : isSelected
              ? theme.palette.action.selected
              : "white",
        boxShadow: "none",
        position: "relative",
        overflow: "unset",
        borderTop: "2px solid rgba(0, 0, 0, .3)",
      }}
      onClick={() => onSelected([item.id])}
      onContextMenu={(event) => onRightClick(event, [item.id])}
    >
      <CardContent
        sx={{
          padding: 1,
        }}
      >
        <Box display="flex" paddingBottom="10px">
          {isDraggable && (
            <OldDragMultipleShipments
              mainConsigneeName={item.consignee?.name}
              mainProNumber={item.proNumber}
              draggableId={`${item.id.toString()}-${section}`}
              index={index}
              selectedCount={selectedCount}
            />
          )}
          <LeftColumn
            consignee={item.consignee}
            proNumber={item.proNumber}
            tags={item.tags}
            shipper={item.shipper}
          />
          <RightColumn
            selectedDate={planDate}
            appointmentDate={item.appointmentDate}
            location={item.location}
            sic={item.sic}
            status={item.status}
            isPlanned={item.isPlanned}
            futurePlanDates={item.futurePlanDates}
          />
        </Box>

        {assignedRoute && (
          <Box sx={{ px: 3, pb: 1 }}>
            <Typography variant="caption">Current Assignment</Typography>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 700,
              }}
            >
              {assignedRoute}
            </Typography>
          </Box>
        )}

        <Box display="flex" justifyContent="start" gap={2} px={3}>
          <Box display="flex" alignItems="center">
            <ShipmentTypography sx={{ fontSize: "16px" }}>
              {pluralize("Pallet", item.pallets, true)}
            </ShipmentTypography>
          </Box>
          <Box display="flex" alignItems="center">
            <ShipmentTypography sx={{ fontSize: "16px" }}>
              {pluralize("Piece", item.pieces, true)}
            </ShipmentTypography>
          </Box>
          <Box display="flex" alignItems="center">
            <ShipmentTypography sx={{ fontSize: "16px" }}>
              {`${item.weight.toLocaleString()} lbs`}
            </ShipmentTypography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ShipmentItem;
