import { Table } from "@tanstack/react-table";
import { TableCell, TableHead, TableRow, useTheme } from "@mui/material";
import TableSortLabel, { getSortDirection } from "./TableSortLabel";
import { ACTION_COLUMN_MAX_SIZE } from "./Table";

type Props<TData> = {
  table: Table<TData>;
};

const TableHeader = <TData,>({ table }: Props<TData>) => {
  const theme = useTheme();
  return (
    <TableHead>
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <TableCell
              sx={{
                color: theme.palette.grey["900"],
                fontSize: 12,
                backgroundColor: theme.palette.background.default,
                fontWeight: 400,
                padding: "10px 12px",
                whiteSpace: "nowrap",
                width:
                  header.getSize() !== ACTION_COLUMN_MAX_SIZE
                    ? header.getSize()
                    : undefined,
              }}
              key={header.id}
              colSpan={header.colSpan}
              sortDirection={getSortDirection(header.column.getIsSorted())}
            >
              <TableSortLabel header={header} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableHead>
  );
};

export default TableHeader;
