import { z } from "zod";

import type { DragLocationHistory as Location } from "@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types";
import { SummaryContextType } from "../pages/inbound/shared/ActionsMenuContext";
import type { Droppable } from "../utils/dragging";

export const assignShipmentTypeSchema = z.enum([
  "trap",
  "section",
  "route",
  "bucket",
  "shipment",
]);

export type AssignShipmentType = z.infer<typeof assignShipmentTypeSchema>;

type AssignShipment = {
  destination: Droppable;
  origin: Droppable;
  location: Location;
  destinationIndex: number;
  shipmentIds: number[];
  prePlanningType?: SummaryContextType;
};

type OldAssignShipment = {
  destinationType: AssignShipmentType;
  destinationId: string;
  shipmentIds: Array<number>;
  sourceType: AssignShipmentType;
  sourceId: string;
  prePlanningType?: SummaryContextType;
  isDroppedOnHeader?: boolean;
};

type UnassignShipment = {
  shipmentIds: Array<number>;
  sourceType: AssignShipmentType;
  sourceId: string;
};

type ReorderShipment = {
  routeId: number;
  shipmentIds: number[];
  sourceStopSeq: number;
  destinationStopSeq: number;
};

export type ReorderShipmentsFn = (data: ReorderShipment) => void;
export type AssignShipmentFn = (data: AssignShipment) => void;
export type OldAssignShipmentFn = (data: OldAssignShipment) => void;
export type UnassignShipmentFn = (data: UnassignShipment) => void;
