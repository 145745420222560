import { Filter } from "../../../types/filter";
import {
  getNonEmptyUniqueSortedOptions,
  handlePrefix,
  isActiveFilter,
  stringOptionFactory,
  type Prefix,
} from "../utils";

export type TrailerNameQueryParam = "trailerName";
export type SomethingWithTrailerName = {
  trailer?: {
    name: string | null;
  } | null;
  straightTruck?: {
    name: string | null;
  } | null;
};

export const getTrailerNameFilter = (
  data: SomethingWithTrailerName[],
  prefix?: Prefix
): Filter => ({
  label: "Trailer / ST",
  queryParam: handlePrefix("trailerName", prefix),
  options: getNonEmptyUniqueSortedOptions(
    data.flatMap(({ trailer, straightTruck }) =>
      trailer && trailer.name
        ? stringOptionFactory(trailer.name)
        : straightTruck && straightTruck.name
          ? stringOptionFactory(straightTruck.name)
          : []
    )
  ),
});

export const filterTrailerName = <T extends SomethingWithTrailerName>(
  data: T[],
  filters?: string[]
): T[] =>
  isActiveFilter(filters)
    ? data.filter(
        ({ trailer, straightTruck }) =>
          filters.includes(trailer?.name || "") ||
          filters.includes(straightTruck?.name || "")
      )
    : data;
