import { UrgentAndCriticalCare } from "../../../hooks/react-query/useInboundUrgentAndCriticalCare";

export const mockInboundUrgentAndCriticalCare: UrgentAndCriticalCare = [
  {
    proNumber: "137844361",
    pickUpDate: new Date("2025-01-24T01:00:00-05:00"),
    serviceDue: new Date(),
    routeOrTrapName: "AZUSA-1",
    startTime: new Date(),
    consignee: {
      name: "FINAFLEX",
      address: "643 Main St",
      city: "New York",
      state: "NY",
      zipCode: "54907",
    },
    appointmentStartDate: new Date(),
    door: 5,
    driver: {
      id: 60,
      name: "Jane Smith",
      refCode: 8310,
    },
    refTrailerNumber: "ENGC42186",
    tags: [
      {
        id: 3,
        name: "CC",
        description: "Critical care shipment",
        colorHex: "#EA5E5A",
        position: 20,
      },
    ],
    deliveryEta: new Date(),
    status: "CDLK",
    appointmentEndDate: new Date(),
    weight: 1521,
    pallets: 10,
    pieces: 100,
  },
  {
    proNumber: "137844362",
    pickUpDate: new Date(),
    serviceDue: new Date(),
    routeOrTrapName: "AZUSA-2",
    startTime: new Date(),
    consignee: {
      name: "FOOD SUPPLY LLC",
      address: "789 MARKET ST",
      city: "LOS ANGELES",
      state: "CA",
      zipCode: "90001",
    },
    appointmentStartDate: new Date(),
    door: 1,
    driver: {
      id: 39,
      name: "John Doe",
      refCode: 1374,
    },
    refTrailerNumber: "ENGC42183",
    tags: [
      {
        id: 3,
        name: "CC",
        description: "Critical care shipment",
        colorHex: "#EA5E5A",
        position: 20,
      },
    ],
    deliveryEta: new Date(),
    status: "ARIV",
    appointmentEndDate: new Date(),
    weight: 1321,
    pallets: 13,
    pieces: 120,
  },
  {
    proNumber: "137844363",
    pickUpDate: new Date(),
    serviceDue: new Date(),
    routeOrTrapName: "AZUSA-3",
    startTime: new Date(),
    consignee: {
      name: "FINAFLEX",
      address: "740 Main St",
      city: "Chicago",
      state: "NY",
      zipCode: "25295",
    },
    appointmentStartDate: new Date(),
    door: 3,
    driver: {
      id: 86,
      name: "Jane Smith",
      refCode: 2425,
    },
    refTrailerNumber: "ENGC42182",
    tags: [
      {
        id: 2,
        name: "UC",
        colorHex: "#FBC02D",
        description: "Urgent care shipment",
        position: 10,
      },
    ],
    deliveryEta: new Date(),
    status: "CDLK",
    appointmentEndDate: new Date(),
    weight: 3211,
    pallets: 5,
    pieces: 142,
  },
  {
    proNumber: "137844364",
    pickUpDate: new Date(),
    serviceDue: new Date(),
    routeOrTrapName: "CERRITOS-1",
    startTime: new Date(),
    consignee: {
      name: "FINAFLEX",
      address: "989 Main St",
      city: "New York",
      state: "CA",
      zipCode: "50372",
    },
    appointmentStartDate: new Date(),
    door: 2,
    driver: {
      id: 8,
      name: "Alice Johnson",
      refCode: 3950,
    },
    refTrailerNumber: "ENGC42187",
    tags: [
      {
        id: 2,
        name: "UC",
        description: "Urgent care shipment",
        colorHex: "#FBC02D",
        position: 10,
      },
    ],
    deliveryEta: new Date(),
    status: "ARIV",
    appointmentEndDate: new Date(),
    weight: 4213,
    pallets: 15,
    pieces: 22,
  },
  {
    proNumber: "137844361",
    pickUpDate: new Date(),
    serviceDue: new Date(),
    routeOrTrapName: "CERRITOS-1",
    startTime: new Date(),
    consignee: {
      name: "FINAFLEX",
      address: "989 Main St",
      city: "New York",
      state: "CA",
      zipCode: "50372",
    },
    appointmentStartDate: new Date(),
    door: 2,
    driver: {
      id: 8,
      name: "Alice Johnson",
      refCode: 3950,
    },
    refTrailerNumber: "ENGC42187",
    tags: [
      {
        id: 2,
        name: "UC",
        description: "Urgent care shipment",
        colorHex: "#FBC02D",
        position: 10,
      },
    ],
    deliveryEta: new Date(),
    status: "ARIV",
    appointmentEndDate: new Date(),
    weight: 4213,
    pallets: 15,
    pieces: 22,
  },
];
