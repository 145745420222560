import { ErrorBoundary } from "react-error-boundary";
import { Box } from "@mui/material";
import { ErrorContainer } from "../AccordionWithContentAndActionBar";
import { AssignToDialog } from "../AssignToDialog";
import { useActionsMenuContext } from "../ActionsMenuContext";
import { InboundTab } from "../../InboundLayout";
import { useIsFeatureFlagEnabled } from "../../../../featureFlags/useIsFeatureFlagEnabled";
import { ClearSidebarSelection } from "../ClearSidebarSelection";
import { useSelectionContext } from "../../../../context/SelectionContext";
import { DeliveryRoutes } from "./DeliveryRoutes";
import { Traps } from "./Traps";
import { Appointments } from "./Appointments";
import { Sections } from "./Sections";
import { UnassignedShipments } from "./UnassignedShipments";
import { PlannedRoutesAndTraps } from "./PlannedRoutesAndTraps";

export interface SidebarRef {
  refresh: () => void;
  clearSelection: () => void;
}

type SidebarContentProps = {
  tab: InboundTab;
};

const ErrorFallback = () => <ErrorContainer title="Error when fetching" />;

export const SidebarContent = ({ tab }: SidebarContentProps) => {
  const isRoutesAndSectionsEnabled = useIsFeatureFlagEnabled(
    "inbound-routes-and-section-tabs"
  );
  const { activeDialog } = useActionsMenuContext();

  const { selected } = useSelectionContext();

  const isAnySelected =
    selected.reduce((total, item) => total + item.shipmentIds.length, 0) > 0;

  return (
    <Box
      sx={{
        position: "relative",
        paddingX: "1rem",
        marginBottom: isAnySelected ? "180px" : "unset",
      }}
    >
      {tab === "planning" && (
        <>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <UnassignedShipments />
          </ErrorBoundary>
          <PlannedRoutesAndTraps />
        </>
      )}
      {tab === "pre-planning" && (
        <>
          {isRoutesAndSectionsEnabled && (
            <>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <DeliveryRoutes />
              </ErrorBoundary>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Sections />
              </ErrorBoundary>
            </>
          )}
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Traps />
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Appointments />
          </ErrorBoundary>
          {isAnySelected && <ClearSidebarSelection />}
        </>
      )}
      {activeDialog === "assign-to" && <AssignToDialog />}
    </Box>
  );
};
