import { useSearchParams } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import { z } from "zod";
import {
  DESTINATION_FILTER_KEY,
  ORIGIN_FILTER_KEY,
  REGION_FILTER_KEY,
} from "../shared/filter-controls/ServiceCenterFilter";
import { CARRIER_FILTER_KEY } from "../shared/filter-controls/CarrierFilter";
import { DaylightDateFormat } from "../../../constants/DaylightDateFormat";
import { SortDirection } from "../../../types/sortDirection.type";
import { SEARCH_FILTER_KEY } from "./LoadboardFilter";

const SELECTION_KEY = "selected";
const DATE_KEY = "date";
export const MODE_KEY = "mode";
const EXPANDED_KEY = "expanded";
const SORT_BY_KEY = "sort";
const SORT_DIRECTION_KEY = "dir";

export const WEEK_OPTION = "view_week";

type useLoadboardDateReturn = {
  date: Dayjs;
  setDate: (date: Dayjs | null, isWeekSelected?: boolean) => void; // TODO - review this null type
  isWeekOptionSelected: boolean;
};

export function useLoadBoardDate(): useLoadboardDateReturn {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryDate = dayjs(searchParams.get(DATE_KEY));
  const date = queryDate.isValid() ? queryDate : dayjs().startOf("day");
  const isWeekOptionSelected = z
    .string()
    .catch("")
    .transform((value) => value === "true")
    .parse(searchParams.get(WEEK_OPTION));

  const setDate = (date: Dayjs | null, isWeekSelected?: boolean) => {
    if (!date) return;
    searchParams.set(DATE_KEY, date.format(DaylightDateFormat.ISO_DATE));
    searchParams.delete(SELECTION_KEY);
    searchParams.delete(EXPANDED_KEY);
    searchParams.delete(WEEK_OPTION);

    if (isWeekSelected) {
      searchParams.set(WEEK_OPTION, "true");
    }

    setSearchParams(searchParams);
  };

  return { date, setDate, isWeekOptionSelected };
}

type LoadBoardSelectionState =
  | {
      type: "none";
      origin?: never;
      destination?: never;
      scheduleIds?: never;
    }
  | {
      type: "sic";
      origin: string;
      destination?: never;
      scheduleIds?: never;
    }
  | {
      type: "lane";
      origin: string;
      destination: string;
      scheduleIds?: never;
    }
  | {
      type: "entry";
      origin?: never;
      destination?: never;
      scheduleIds: number[];
    };

export function useLoadBoardSelection() {
  const [searchParams, setSearchParams] = useSearchParams();

  const selected = searchParams.getAll(SELECTION_KEY).join(",");

  const [type, ...params] = selected.split("_");

  const getSelection = (): LoadBoardSelectionState => {
    switch (type) {
      case "none":
        return { type: "none" };
      case "sic":
        if (params.length >= 1) {
          return { type: "sic", origin: params[0] };
        }
        break;
      case "lane":
        if (params.length >= 2) {
          return {
            type: "lane",
            origin: params[0],
            destination: params[1],
          };
        }
        break;
      case "entry":
        if (params.length >= 1) {
          return {
            type: "entry",
            scheduleIds: selected
              .split(",")
              .map((s) => Number(s.split("_")[1])),
          };
        }
        break;
    }
    return { type: "none" };
  };

  const setSelection = (selection: LoadBoardSelectionState) => {
    switch (selection.type) {
      case "none":
        searchParams.delete(SELECTION_KEY);
        break;
      case "sic":
        searchParams.set(SELECTION_KEY, `sic_${selection.origin}`);
        break;
      case "lane":
        searchParams.set(
          SELECTION_KEY,
          `lane_${selection.origin}_${selection.destination}`
        );
        break;
      case "entry":
        searchParams.delete(SELECTION_KEY);
        selection.scheduleIds.forEach((id) =>
          searchParams.append(SELECTION_KEY, `entry_${id}`)
        );
        break;
    }
    searchParams.delete(MODE_KEY);
    setSearchParams(searchParams);
  };

  const removeSelection = (entryId: number) => {
    const all = [...new Set(searchParams.getAll(SELECTION_KEY))];
    searchParams.delete(SELECTION_KEY);
    const filtered = all.filter((s) => s !== `entry_${entryId}`);
    filtered.forEach((s) => searchParams.append(SELECTION_KEY, s));
    setSearchParams(searchParams);
  };

  const clearSelection = () => {
    searchParams.delete(SELECTION_KEY);
    searchParams.delete(MODE_KEY);
    setSearchParams(searchParams);
  };

  return {
    selection: getSelection(),
    setSelection,
    clearSelection,
    removeSelection,
  };
}

export type LoadBoardFilterState = {
  origins: string[];
  destinations: string[];
  carriers: string[];
  regions: string[];
  search: string;
};

export function useLoadBoardFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const origins = searchParams.getAll(ORIGIN_FILTER_KEY);
  const destinations = searchParams.getAll(DESTINATION_FILTER_KEY);
  const carriers = searchParams.getAll(CARRIER_FILTER_KEY);
  const regions = searchParams.getAll(REGION_FILTER_KEY);
  const search = searchParams.get(SEARCH_FILTER_KEY) ?? "";

  const filter: LoadBoardFilterState = {
    origins,
    destinations,
    carriers,
    regions,
    search,
  };

  const setRegionFilter = (regions: string[]) => {
    searchParams.delete(REGION_FILTER_KEY);
    regions.forEach((c) => searchParams.append(REGION_FILTER_KEY, c));
    setSearchParams(searchParams);
  };

  const clearRegionFilter = () => {
    searchParams.delete(REGION_FILTER_KEY);
    setSearchParams(searchParams);
  };

  const setCarrierFilter = (carriers: string[]) => {
    searchParams.delete(CARRIER_FILTER_KEY);
    carriers.forEach((c) => searchParams.append(CARRIER_FILTER_KEY, c));
    setSearchParams(searchParams);
  };

  const clearCarrierFilter = () => {
    searchParams.delete(CARRIER_FILTER_KEY);
    setSearchParams(searchParams);
  };

  const setOriginFilter = (origins: string[]) => {
    searchParams.delete(ORIGIN_FILTER_KEY);
    origins.forEach((c) => searchParams.append(ORIGIN_FILTER_KEY, c));
    setSearchParams(searchParams);
  };

  const clearOriginFilter = () => {
    searchParams.delete(ORIGIN_FILTER_KEY);
    setSearchParams(searchParams);
  };

  const setDestinationFilter = (destinations: string[]) => {
    searchParams.delete(DESTINATION_FILTER_KEY);
    destinations.forEach((c) => searchParams.append(DESTINATION_FILTER_KEY, c));
    setSearchParams(searchParams);
  };

  const clearDestinationFilter = () => {
    searchParams.delete(DESTINATION_FILTER_KEY);
    setSearchParams(searchParams);
  };

  const setSearchFilter = (search: string) => {
    searchParams.set(SEARCH_FILTER_KEY, search);
    setSearchParams(searchParams);
  };

  const clearSearchFilter = () => {
    searchParams.delete(SEARCH_FILTER_KEY);
    setSearchParams(searchParams);
  };

  return {
    filter,
    setCarrierFilter,
    clearCarrierFilter,
    setRegionFilter,
    clearRegionFilter,
    setOriginFilter,
    clearOriginFilter,
    setDestinationFilter,
    clearDestinationFilter,
    setSearchFilter,
    clearSearchFilter,
  };
}

export function useLoadBoardEditMode() {
  const [searchParams, setSearchParams] = useSearchParams();

  const isEditing = searchParams.get(MODE_KEY) === "edit";

  const setIsEditing = (isEditing: boolean) => {
    if (isEditing) searchParams.set(MODE_KEY, "edit");
    else searchParams.delete(MODE_KEY);
    setSearchParams(searchParams);
  };

  return { isEditing, setIsEditing };
}

export function useLoadBoardExpanded() {
  const [searchParams, setSearchParams] = useSearchParams();

  const expanded = searchParams.getAll(EXPANDED_KEY);

  const isExpanded = (origin: string, destination?: string) =>
    expanded.some(
      (e) => e === `${origin}${destination ? `_${destination}` : ""}`
    );

  const appendIsExpanded = (origin: string, destination?: string) => {
    if (
      expanded.find(
        (e) => e === `${origin}${destination ? `_${destination}` : ""}`
      )
    ) {
      return;
    }
    searchParams.append(
      EXPANDED_KEY,
      `${origin}${destination ? `_${destination}` : ""}`
    );
    setSearchParams(searchParams);
  };

  const removeIsExpanded = (origin: string, destination?: string) => {
    searchParams.delete(
      EXPANDED_KEY,
      `${origin}${destination ? `_${destination}` : ""}`
    );
    setSearchParams(searchParams);
  };

  const clearIsExpanded = () => {
    searchParams.delete(EXPANDED_KEY);
    setSearchParams(searchParams);
  };

  return { isExpanded, appendIsExpanded, removeIsExpanded, clearIsExpanded };
}

export const SORT_BY_SIC = "sic";
export const SORT_BY_REGION = "region";

export function useLoadBoardSort() {
  const [searchParams, setSearchParams] = useSearchParams();

  const sortBy = searchParams.get(SORT_BY_KEY) ?? SORT_BY_SIC;
  const sortDirection: SortDirection =
    searchParams.get(SORT_DIRECTION_KEY) === "desc" ? "desc" : "asc";

  const setSort = (sort: string, dir: SortDirection) => {
    searchParams.set(SORT_BY_KEY, sort);
    searchParams.set(SORT_DIRECTION_KEY, dir);
    setSearchParams(searchParams);
  };

  const clearSort = () => {
    searchParams.delete(SORT_BY_KEY);
    searchParams.delete(SORT_DIRECTION_KEY);
    setSearchParams(searchParams);
  };

  return { sort: sortBy, dir: sortDirection, setSort, clearSort } as const;
}
