import { Row } from "@tanstack/react-table";
import { IconButton, Tooltip } from "@mui/material";
import { Print } from "@mui/icons-material";
import { TableShipment } from "../../../../components/shared/old-table/ShipmentsTableBase";
import {
  PrintResult,
  usePrintBulkReport,
} from "../../../../hooks/react-query/usePrint";
import Loading from "../../../../components/shared/layout/Loading";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { useDateSearchParamOrFallbackToToday } from "../../../../hooks/useDateSearchParamOrFallbackToToday";
import { useIsFeatureFlagEnabled } from "../../../../featureFlags/useIsFeatureFlagEnabled";

type PrintShipmentIconProps = {
  row: Row<TableShipment>;
};

const PrintShipment = ({ row }: PrintShipmentIconProps) => {
  const [serviceCenter] = useSelectedServiceCenter();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const isPrintShipmentIconEnabled = useIsFeatureFlagEnabled(
    "inbound-planning-print-shipment-icon-visibility-client"
  );
  const onPrintSuccess = (result: PrintResult) => {
    if (result.state === "Processed") {
      window.open(result.url, "_blank");
    }
  };

  const { mutate: printBulkReport, isPending } =
    usePrintBulkReport(onPrintSuccess);

  if (!isPrintShipmentIconEnabled) {
    return;
  }

  return (
    <Tooltip title="Print Delivery Receipt">
      <IconButton
        onClick={() => {
          printBulkReport({
            sicId: serviceCenter.id,
            planDate: selectedDate,
            deliveryReceipt: {
              routes: [row.original.id],
            },
          });
        }}
      >
        {isPending ? <Loading containerWidth={32} size={32} /> : <Print />}
      </IconButton>
    </Tooltip>
  );
};

export default PrintShipment;
