import { DriverUsage } from "../../../hooks/react-query/useDrivers";
import { Driver as InitialDriver } from "../../../services/prePlanningService.types";
import {
  AssignmentDetails,
  getAssignment,
} from "../../../types/assignment.type";
import { Driver } from "../../../types/driver.type";

export const driverAvailability = {
  ASSIGNED: "Assigned Drivers",
  AVAILABLE: "Available Drivers",
  INACTIVE: "Inactive Drivers",
} as const;

export const getDriverOptionsOld = (drivers: Driver[]) =>
  drivers
    .map((driver) => ({
      name: `${driver.name} - ${driver.refCode}`,
      value: driver.id,
      group: driver.inService
        ? driverAvailability.AVAILABLE
        : driverAvailability.ASSIGNED,
      inUseBy: null,
    }))
    .sort((a, b) => b.group.localeCompare(a.group));

type DriverOption = {
  name: string;
  value: number;
  group: string;
  refCode: string;
  assignment: AssignmentDetails | null;
};

export const getDriverOptions = (
  drivers: DriverUsage[],
  isDriverRefEnabled: boolean,
  initialDriver: InitialDriver | null = null
): DriverOption[] => {
  const driverOptions: DriverOption[] = drivers.map((driver) => ({
    name: driver.name,
    value: driver.id,
    group: driver.inUseByInfo
      ? driverAvailability.ASSIGNED
      : driverAvailability.AVAILABLE,
    refCode: isDriverRefEnabled ? driver.refCode ?? "" : "",
    assignment: getAssignment(
      driver.planningAssignmentsInfo,
      driver.inUseByInfo
    ),
  }));

  if (
    initialDriver &&
    initialDriver.id !== 0 &&
    !driverOptions.some((option) => option.value === initialDriver.id)
  ) {
    driverOptions.push({
      name: initialDriver.name ?? "",
      value: initialDriver.id,
      group: driverAvailability.INACTIVE,
      refCode: isDriverRefEnabled ? initialDriver.refCode ?? "" : "",
      assignment: null,
    });
  }

  return driverOptions;
};
