import { Filter } from "../../../types/filter";
import { dateToFormat } from "../../../utils/dateTimeHelper";
import { DaylightDateFormat } from "../../DaylightDateFormat";
import {
  getNonEmptyUniqueSortedOptions,
  handlePrefix,
  isActiveFilter,
  sortByTime,
  stringOptionFactory,
  type Prefix,
} from "../utils";

export type StartTimeQueryParam = "startTime";
export type SomethingWithStartTime = {
  startTime: Date | null;
};

export const getStartTimeFilter = (
  data: SomethingWithStartTime[],
  timeZone: string,
  prefix?: Prefix
): Filter => ({
  label: "Start Time",
  queryParam: handlePrefix("startTime", prefix),
  options: getNonEmptyUniqueSortedOptions(
    data.map((d) =>
      stringOptionFactory(
        dateToFormat(d.startTime, DaylightDateFormat.TIME_12, timeZone)
      )
    ),
    sortByTime
  ),
});

export const filterStartTime = <T extends SomethingWithStartTime>(
  data: T[],
  timeZone: string,
  filters?: string[]
): T[] =>
  isActiveFilter(filters)
    ? data.filter(({ startTime }) => {
        const formattedStartTime = dateToFormat(
          startTime,
          DaylightDateFormat.TIME_12,
          timeZone
        );
        return filters.includes(formattedStartTime);
      })
    : data;
