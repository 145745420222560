import { isActiveFilter } from "../utils";

export type RouteEndQueryParam = "routeEnd";
type SomethingWithRouteEnd = {
  routeEnd?: string | null;
};

export const filterRouteEnd = <T extends SomethingWithRouteEnd>(
  data: T[],
  filters?: string[]
): T[] =>
  isActiveFilter(filters)
    ? data.filter(({ routeEnd }) => routeEnd && filters.includes(routeEnd))
    : data;
