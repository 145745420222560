import { Filter } from "../../../types/filter";
import {
  getNonEmptyUniqueSortedOptions,
  handlePrefix,
  isActiveFilter,
  stringOptionFactory,
  type Prefix,
} from "../utils";

export type DriverNameQueryParam = "driverName";
export type SomethingWithDriverName = {
  driver?: {
    name: string | null;
  } | null;
};

export const getDriverNameFilter = (
  data: SomethingWithDriverName[],
  prefix?: Prefix
): Filter => ({
  label: "Driver",
  queryParam: handlePrefix("driverName", prefix),
  options: getNonEmptyUniqueSortedOptions(
    data.map((d) => stringOptionFactory(d.driver?.name))
  ),
});

export const filterDriverName = <T extends SomethingWithDriverName>(
  data: T[],
  filters?: string[]
): T[] =>
  isActiveFilter(filters)
    ? data.filter(({ driver }) => filters.includes(driver?.name || ""))
    : data;
