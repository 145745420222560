import ClickAwayListener from "@mui/material/ClickAwayListener";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { ArrowDropDown, Cancel, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  InputBase,
  Popper,
  Typography,
  alpha,
  colors,
  styled,
  useTheme,
} from "@mui/material";
import {
  Fragment,
  useEffect,
  useRef,
  useState,
  type Dispatch,
  type RefObject,
  type SetStateAction,
} from "react";

// this component was copied from https://mui.com/material-ui/react-autocomplete/#system-GitHubLabel.tsx

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const StyledFilterAutocompletePopper = styled("div")(() => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    marginRight: -2,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: "auto",
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}));

function FilterPopperComponent({
  disablePortal: _ignore,
  anchorEl: _ignore2,
  open: _ignore3,
  ...rest
}: PopperComponentProps) {
  return <StyledFilterAutocompletePopper {...rest} />;
}

const StyledFilterPopper = styled(Popper)(({ theme }) => ({
  border: "1px solid #e1e4e8",
  boxShadow: "0 8px 24px rgba(149, 157, 165, 0.2)",
  borderRadius: 6,
  width: 400,
  zIndex: theme.zIndex.modal,
}));

const StyledFilterInput = styled(InputBase)(() => ({
  width: "100%",
  backgroundColor: "#fff",
  borderRadius: "4px",
  border:
    "1px solid var(--light-other-outlined-border-23-p, rgba(0, 0, 0, 0.23))",
  padding: "6px 8px",
}));

interface FilterItemProps<T> {
  anchorRef: RefObject<HTMLButtonElement>;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null | undefined>>;
  open: boolean;
  id: string;
  label: string;
  value: T[];
  filterId: string;
  getSelectedDisplayValue: (selected: T[]) => string;
  onRemove: (value: string) => void;
  direction?: "row" | "column";
}

export type FilterProps = {
  initialOpen?: boolean;
  onRemove: (value: string) => void;
};

type FilterMultiselectProps<T> = FilterProps & {
  id: string;
  label: string;
  options: T[];
  value: T[];
  getDisplayValue: (value: T) => string;
  getSelectedDisplayValue: (selected: T[]) => string;
  onChange: (value: readonly T[]) => void;
  direction?: "row" | "column";
};

type PropsWithKey = React.HTMLAttributes<HTMLLIElement> & { key?: React.Key };

export default function FilterMultiselect<T>({
  id,
  label,
  options,
  value,
  initialOpen = false,
  getDisplayValue,
  getSelectedDisplayValue,
  onChange,
  onRemove,
  direction = "row",
}: FilterMultiselectProps<T>) {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>();

  // this is necessary to make the popper open on first render
  useEffect(() => {
    if (initialOpen) setAnchorEl(anchorRef.current);
  }, [anchorRef, initialOpen]);

  const handleClose = () => {
    if (anchorEl) anchorEl.focus();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const filterId = `filter-${id}`;

  const itemProps: FilterItemProps<T> = {
    anchorRef,
    filterId,
    id,
    label,
    onRemove,
    open,
    setAnchorEl,
    value,
    getSelectedDisplayValue,
  };

  return (
    <Fragment>
      {direction === "row" ? (
        <ButtonFilterItem {...itemProps} />
      ) : (
        <ChipFilterItem {...itemProps} />
      )}
      <StyledFilterPopper
        id={filterId}
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Autocomplete
              open
              multiple
              onClose={(_, reason) => {
                if (reason === "escape") handleClose();
              }}
              value={value}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === "keydown" &&
                  "key" in event &&
                  event.key === "Backspace" &&
                  reason === "removeOption"
                ) {
                  return;
                }
                onChange(newValue);
              }}
              disableCloseOnSelect
              PopperComponent={FilterPopperComponent}
              renderTags={() => null}
              noOptionsText="No results found"
              renderOption={(
                { key, ...props }: PropsWithKey,
                option,
                { selected }
              ) => (
                <li key={key} {...props}>
                  <Checkbox
                    size="small"
                    sx={{ mr: 1 }}
                    checked={selected}
                    content={getDisplayValue(option)}
                  />
                  {getDisplayValue(option)}
                </li>
              )}
              options={options}
              getOptionLabel={(option) => getDisplayValue(option)}
              renderInput={(params) => (
                <StyledFilterInput
                  ref={params.InputProps.ref}
                  endAdornment={
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  }
                  inputProps={params.inputProps}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  placeholder="Search"
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </StyledFilterPopper>
    </Fragment>
  );
}

const ButtonFilterItem = <T,>({
  anchorRef,
  filterId,
  id,
  label,
  onRemove,
  open,
  setAnchorEl,
  value,
  getSelectedDisplayValue,
}: FilterItemProps<T>) => (
  <Box display="flex" position="relative">
    <Button
      ref={anchorRef}
      color="inherit"
      aria-label={`Filter by ${label}`}
      aria-describedby={filterId}
      onClick={(e) => setAnchorEl(e.currentTarget)}
      sx={{
        textTransform: "none",
        backgroundColor: open ? colors.blue[100] : undefined,
        ":hover": {
          backgroundColor: open ? colors.blue[100] : undefined,
        },
      }}
    >
      <Box display="flex" alignItems="center" gap={1}>
        <Box display="flex" gap="1px">
          <Typography fontFamily="Roboto" fontSize={13} fontWeight={500}>
            {label}
          </Typography>
          <Typography fontFamily="Roboto" fontSize={13} fontWeight={500}>
            :
          </Typography>
          <Typography fontFamily="Roboto" fontSize={13} fontWeight={700}>
            {getSelectedDisplayValue(value)}
          </Typography>
        </Box>
        <ArrowDropDown fontSize="small" />
        <Box width="1rem" />
      </Box>
    </Button>
    <IconButton
      sx={{
        p: 0,
        position: "absolute",
        alignSelf: "center",
        right: 10,
      }}
      size="small"
      aria-label={`Remove ${label} filter`}
      title={`Remove ${label} filter`}
      onClick={(e) => {
        e.stopPropagation();
        if (label !== "Type") {
          onRemove(id);
        }
      }}
    >
      <Cancel color="action" fontSize="small" />
    </IconButton>
  </Box>
);

const StyledChipButton = styled(Button)(() => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  gap: "0.25rem",
  borderRadius: "2rem",
  fontSize: "0.875rem",
  lineHeight: "unset",
  fontWeight: "600",
  textTransform: "none",
  wordBreak: "keep-all",
  whiteSpace: "nowrap",
  padding: "0 2rem 0 12px",
  minHeight: "40px",
}));

const SelectionCount = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: "-0.5rem",
  top: "0rem",
  width: "0.875rem",
  height: "0.875rem",
  backgroundColor: theme.palette.primary.main,
  borderRadius: "100%",
  padding: "2px",
  lineHeight: "0.875rem",
  color: "white",
  boxShadow: "0px 2px 4px #0000001F",
  fontSize: "12px",
}));

const ChipFilterItem = <T,>({
  anchorRef,
  filterId,
  id,
  label,
  onRemove,
  open,
  setAnchorEl,
  value,
  getSelectedDisplayValue,
}: FilterItemProps<T>) => {
  const theme = useTheme();

  const valueDisplay =
    value.length === 0
      ? "All"
      : value.length === 1
        ? getSelectedDisplayValue([value[0]])
        : getSelectedDisplayValue([value[0]]);

  return (
    <Box display="flex" position="relative">
      <StyledChipButton
        ref={anchorRef}
        color="inherit"
        aria-label={`Filter by ${label}`}
        aria-describedby={filterId}
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
        sx={{
          border:
            value.length > 1
              ? `2px solid ${alpha(theme.palette.primary.light, 0.5)}`
              : "unset",
          backgroundColor: open ? colors.blue[100] : theme.palette.grey[200],
          ":hover": {
            backgroundColor: open ? colors.blue[100] : theme.palette.grey[300],
          },
        }}
      >
        {value.length > 1 && (
          <SelectionCount>+{value.length - 1}</SelectionCount>
        )}
        {label}: {valueDisplay}
        <ArrowDropDown fontSize="small" />
      </StyledChipButton>
      <IconButton
        sx={{
          position: "absolute",
          right: 12,
          p: 0,
          alignSelf: "center",
        }}
        size="small"
        aria-label={`Remove ${label} filter`}
        title={`Remove ${label} filter`}
        onClick={(e) => {
          e.stopPropagation();
          if (label !== "Type") {
            onRemove(id);
          }
        }}
      >
        <Cancel color="action" fontSize="small" />
      </IconButton>
    </Box>
  );
};
