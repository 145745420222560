import { IconProps, iconFillColors } from "./Icon.types";

export default function PalletsIcon({
  fill = iconFillColors.darkGray,
  size = 16,
  style,
}: Readonly<IconProps>) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      role="figure"
      aria-label="Pallets Icon"
    >
      <path
        d="M3 20.4014V16.8005H21V20.4014H18.3V18.601H13.35V20.4014H10.65V18.601H5.7V20.4014H3Z"
        fill={fill}
      />
      <path
        d="M3 20.4014V16.8005H21V20.4014H18.3V18.601H13.35V20.4014H10.65V18.601H5.7V20.4014H3Z"
        fill={fill}
      />
      <path
        d="M3 13.8005V10.1996H21V13.8005H18.3V12H13.35V13.8005H10.65V12H5.7V13.8005H3Z"
        fill={fill}
      />
      <path
        d="M3 13.8005V10.1996H21V13.8005H18.3V12H13.35V13.8005H10.65V12H5.7V13.8005H3Z"
        fill={fill}
      />
      <path
        d="M3 7.19953V3.59863H21V7.19953H18.3V5.39908H13.35V7.19953H10.65V5.39908H5.7V7.19953H3Z"
        fill={fill}
      />
      <path
        d="M3 7.19953V3.59863H21V7.19953H18.3V5.39908H13.35V7.19953H10.65V5.39908H5.7V7.19953H3Z"
        fill={fill}
      />
    </svg>
  );
}
