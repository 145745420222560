import { type PropsWithChildren, type ReactNode } from "react";
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  styled,
  Typography,
  type SxProps,
} from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import { Accordion } from "../layout/Accordion";

const Summary = styled(AccordionSummary)(({ theme }) => ({
  position: "sticky",
  padding: 0,
  borderRadius: "0.5rem",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    order: -1,
  },
}));

const SummaryTitleIconWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "2px",
  borderRadius: "0.5rem",
  color: theme.palette.grey[700],
  marginRight: "0.5rem",
}));

const SummaryTitle = styled(Typography)(() => ({
  fontWeight: 700,
  lineHeight: 1.7,
}));

const SummaryCount = styled(Box)(({ theme }) => ({
  padding: "2px 4px",
  fontSize: "0.875rem",
  fontWeight: 700,
  lineHeight: 1.8,
  borderRadius: "0.25rem",
  backgroundColor: theme.palette.action.hover,
  color: theme.palette.grey[600],
  marginLeft: "0.25rem",
}));

interface Props {
  title: string;
  onExpand: (isExpanded: boolean) => void;
  style?: SxProps;
  /** number of items on the collapsible, can be string if need to pass custom values */
  displayCount?: number | string;
  accordionSummaryHeight?: string | number;
  isExpanded?: boolean;
  icon?: ReactNode;
}

export const CollapsibleItem = ({
  title,
  style,
  displayCount,
  icon,
  isExpanded = false,
  onExpand,
  children,
}: PropsWithChildren<Props>) => (
  <Accordion
    sx={{ border: 0, ...style }}
    onChange={(_, isExpanded) => onExpand(isExpanded)}
    expanded={isExpanded}
    slotProps={{ transition: { unmountOnExit: true } }}
  >
    <Summary expandIcon={<ArrowDropDown sx={{ padding: "0.25rem" }} />}>
      {icon && <SummaryTitleIconWrapper>{icon}</SummaryTitleIconWrapper>}
      <SummaryTitle>{title}</SummaryTitle>
      {displayCount !== undefined && (
        <SummaryCount>{displayCount}</SummaryCount>
      )}
    </Summary>
    <AccordionDetails sx={{ padding: 0 }}>{children}</AccordionDetails>
  </Accordion>
);
