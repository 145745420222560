import { Typography, Box } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const CollapsedNavBar = ({ title }: { title: string }) => (
  <Box
    bgcolor="white"
    sx={{
      padding: 0.5,
      border: 1,
      borderRadius: 1,
      borderTopLeftRadius: 1,
      borderTopRightRadius: 1,
      borderColor: "lightgray",
    }}
  >
    <Typography style={{ fontWeight: 500 }}>
      {title}
      <ChevronLeftIcon
        style={{ transform: "rotate(-90deg)" }}
        sx={{ mb: -0.8, ml: 0.5 }}
      />
    </Typography>
  </Box>
);

export default CollapsedNavBar;
