import { PropsWithChildren, ReactNode } from "react";
import { useTheme } from "@mui/material";
import { FlexColumn, FlexRow } from "./Flex";

type Props = {
  sidebar?: ReactNode;
};

export const SidebarLayout = ({
  sidebar,
  children,
}: PropsWithChildren<Props>) => {
  const theme = useTheme();

  return (
    <FlexRow style={{ position: "relative", overflow: "hidden" }}>
      {sidebar}
      <FlexColumn
        style={{
          backgroundColor: theme.palette.background.default,
        }}
      >
        {children}
      </FlexColumn>
    </FlexRow>
  );
};
