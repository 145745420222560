import { Filter } from "../../../types/filter";
import {
  getNonEmptyUniqueSortedOptions,
  handlePrefix,
  isActiveFilter,
  stringOptionFactory,
  type Prefix,
} from "../utils";

export type TractorNameQueryParam = "tractorName";
export type SomethingWithTractorName = {
  tractor?: {
    name: string | null;
  } | null;
};

export const getTractorNameFilter = (
  data: SomethingWithTractorName[],
  prefix?: Prefix
): Filter => ({
  label: "Tractor",
  queryParam: handlePrefix("tractorName", prefix),
  options: getNonEmptyUniqueSortedOptions(
    data.map((d) => stringOptionFactory(d.tractor?.name))
  ),
});

export const filterTractorName = <T extends SomethingWithTractorName>(
  data: T[],
  filters?: string[]
): T[] =>
  isActiveFilter(filters)
    ? data.filter(({ tractor }) => filters.includes(tractor?.name || ""))
    : data;
