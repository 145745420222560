// CSpell:ignore skyblue
import {
  PropsWithChildren,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { Box, Typography } from "@mui/material";
import MoveToInboxOutlinedIcon from "@mui/icons-material/MoveToInboxOutlined";
import OpenInFullSharpIcon from "@mui/icons-material/OpenInFullSharp";
import CloseFullscreen from "@mui/icons-material/CloseFullscreen";
import {
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "@hello-pangea/dnd";
import { useSelectionContext } from "../../../context/SelectionContext";
import { Title } from "../../../components/shared/Title";
import { Shipment } from "../../../services/prePlanningService.types";
import { useUnassignedShipmentIds } from "../../../hooks/react-query/useUnassignedShipments";
import { useShipmentDetailSearch } from "../../../hooks/react-query/preplanning/useShipmentDetails";
import Loading from "../../../components/shared/layout/Loading";
import { useDateSearchParamOrFallbackToToday } from "../../../hooks/useDateSearchParamOrFallbackToToday";
import { dateToInt } from "../../../utils/dateTimeHelper";
import { useSelectedServiceCenter } from "../../../hooks/useSelectedServiceCenter";
import { useIsSelectedDateTodayOrInTheFutureForCurrentSic } from "../../../hooks/useIsSelectedDateTodayOrInTheFutureForCurrentSic";
import {
  UnassignedShipmentBox,
  UnassignedShipmentDraggableBox,
} from "./UnassignedShipmentDraggableBox";

const iconStyle = {
  marginLeft: "0.5rem",
  fontSize: ".8rem ",
};

const EmptyStateFeedback = ({ children }: PropsWithChildren) => (
  <Typography
    style={{
      display: "flex",
      alignItems: "center",
      fontSize: ".6rem",
    }}
  >
    {children}
  </Typography>
);

const EmptyStateFeedbackText = ({ children }: PropsWithChildren) => (
  <Box
    component="span"
    sx={{
      fontSize: ".8rem",
      color: "gray",
    }}
  >
    {children}
  </Box>
);

type UnassignedShipmentsProps = {
  unassignedShipments: Array<Shipment>;
  droppableProvided?: DroppableProvided;
  snapshot?: DroppableStateSnapshot;
  isLoading: boolean;
  collapsed: boolean;
  selectedShipmentIds: number[];
  setSelectedShipmentIds: (selectedItems: number[]) => void;
};

function UnassignedShipments({
  unassignedShipments,
  droppableProvided,
  snapshot,
  isLoading,
  collapsed,
  selectedShipmentIds,
  setSelectedShipmentIds,
}: UnassignedShipmentsProps) {
  const isEditingAllowed = !!droppableProvided && !!snapshot;
  return (
    <Box
      {...droppableProvided?.droppableProps}
      ref={droppableProvided?.innerRef}
      color="#606060"
      sx={{
        padding: "0.4rem 0.5rem",
        border: "4px dashed lightgray",
        transition: "background-color 0.5s ease",
        backgroundColor: snapshot?.isDraggingOver ? "skyblue" : "#F8F8FF",
      }}
    >
      {droppableProvided && (
        <table>
          <tbody>{droppableProvided.placeholder}</tbody>
        </table>
      )}
      {isLoading ? (
        <Box sx={{ display: "flex", flex: 1 }}>
          <Loading />
        </Box>
      ) : unassignedShipments.length === 0 ? (
        <EmptyStateFeedback>
          {isEditingAllowed ? (
            <>
              <MoveToInboxOutlinedIcon
                style={{
                  color: "gray",
                  marginRight: "0.2rem",
                }}
              />
              <EmptyStateFeedbackText>
                Drag Shipments Here
              </EmptyStateFeedbackText>
            </>
          ) : (
            <EmptyStateFeedbackText>
              No shipments for this period
            </EmptyStateFeedbackText>
          )}
        </EmptyStateFeedback>
      ) : (
        unassignedShipments.map((shipment: Shipment, i: number) =>
          isEditingAllowed ? (
            <UnassignedShipmentDraggableBox
              index={i}
              shipmentId={shipment.id}
              id={shipment.id.toString()}
              key={shipment.id}
              shipment={shipment}
              shipments={unassignedShipments}
              collapsed={collapsed}
              setSelectedItems={setSelectedShipmentIds}
              selectedShipmentIds={selectedShipmentIds}
            />
          ) : (
            <UnassignedShipmentBox
              key={shipment.id}
              shipment={shipment}
              shipments={unassignedShipments}
              collapsed={collapsed}
              setSelectedItems={setSelectedShipmentIds}
            />
          )
        )
      )}
    </Box>
  );
}

export const UnassignedShipmentDroppable = forwardRef((props, ref) => {
  const isEditingAllowed = useIsSelectedDateTodayOrInTheFutureForCurrentSic();
  const [serviceCenter] = useSelectedServiceCenter();
  const [collapsed, setCollapsed] = useState(true);
  const [selectedShipmentIds, setSelectedShipmentIds] = useState<number[]>([]);
  const { clearSelection } = useSelectionContext();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const planDate = dateToInt(selectedDate);

  const {
    data: unassignedShipmentIds = [],
    refetch: refetchSummary,
    isLoading: unassignedShipmentIdsIsLoading,
  } = useUnassignedShipmentIds(serviceCenter.id, planDate);
  const {
    data: unassignedShipments = [],
    refetch: refetchDetails,
    isLoading: unassignedShipmentsLoading,
  } = useShipmentDetailSearch({
    search: {
      shipmentIds: unassignedShipmentIds.map(Number),
      orderBy: "consignee.name",
    },
  });

  useImperativeHandle(ref, () => ({
    refresh: () => {
      refetchSummary();
      refetchDetails();
    },
    clearSelection: () => clearSelection(),
  }));

  const isLoading =
    unassignedShipmentIdsIsLoading || unassignedShipmentsLoading;

  return (
    <Box sx={{ position: "relative", paddingX: 1, paddingY: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#DCDCDC",
          borderRadius: "0.2rem",
          padding: "0.2rem 0.4rem",
        }}
      >
        <Title variant="h4" fontSize={14}>
          Unassigned Shipments
        </Title>
        <Typography
          onClick={() => setCollapsed(!collapsed)}
          style={{
            fontSize: ".8rem ",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <span>{collapsed ? "See More" : "Collapse"}</span>
          {collapsed ? (
            <OpenInFullSharpIcon style={iconStyle} />
          ) : (
            <CloseFullscreen style={iconStyle} />
          )}
        </Typography>
      </Box>
      {isEditingAllowed ? (
        <Droppable droppableId="unassigned">
          {(
            droppableProvided: DroppableProvided,
            snapshot: DroppableStateSnapshot
          ) => (
            <UnassignedShipments
              droppableProvided={droppableProvided}
              snapshot={snapshot}
              unassignedShipments={unassignedShipments}
              isLoading={isLoading}
              collapsed={collapsed}
              selectedShipmentIds={selectedShipmentIds}
              setSelectedShipmentIds={setSelectedShipmentIds}
            />
          )}
        </Droppable>
      ) : (
        <UnassignedShipments
          unassignedShipments={unassignedShipments}
          isLoading={isLoading}
          collapsed={collapsed}
          selectedShipmentIds={selectedShipmentIds}
          setSelectedShipmentIds={setSelectedShipmentIds}
        />
      )}
    </Box>
  );
});

UnassignedShipmentDroppable.displayName = "UnassignedShipmentDroppable";
