import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  RowSelectionState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import {
  forwardRef,
  memo,
  Ref,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Table as MuiTable, SxProps } from "@mui/material";
import { ContextMenuAction } from "../../../types/contextMenuAction";
import Loading from "../layout/Loading";
import { Checkbox } from "../Checkbox";
import { useClearSelectionWhenServiceCenterChanges } from "../../../hooks/useClearSelectionWhenServiceCenterChanges";
import TableCaption from "../TableCaption";
import { useTableSelectionParam } from "../../../hooks/useTableSelectionParam";
import { SelectableProps } from "./Selectable.types";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import { ErrorMap } from "./table.types";
import { DroppableContainer } from "./DroppableContainer";

type CommonProps<TData> = {
  /** table reference */
  ref?: Ref<TableRef>;
  /** table data */
  data?: TData[];
  /** columns definition array */
  columns: ColumnDef<TData, any>[];
  /** columns before the definition */
  preColumns?: ColumnDef<TData, any>[];
  /** Table caption for a11y and testing purposes. The caption is visually hidden. */
  caption: string;
  /** display a loading indicator instead of the table when true */
  isLoading?: boolean;
  /** Rows with errors. */
  rowErrors?: ErrorMap;
  /** custom styles for Table Container */
  styles?: SxProps;
};

type DragNDropProps<TData> =
  | {
      /** can table sort/reorder rows */
      isSortable?: boolean;
      /** id of the droppable table */
      droppableTableId: (row: Row<TData>) => string;
      /** id of the droppable table if it is empty */
      emptyDroppableTableId: string;
      /** id of the droppable row */
      droppableRowId?: never;
    }
  | {
      /** id of the droppable table */
      droppableTableId?: never;
      /** id of the droppable table if it is empty */
      emptyDroppableTableId?: never;
      /** id of the droppable row */
      droppableRowId: (row: Row<TData>) => string;
      /** can table sort/reorder rows */
      isSortable?: never;
    }
  | {
      /** id of the droppable table */
      droppableTableId?: never;
      /** id of the droppable table if it is empty */
      emptyDroppableTableId?: never;
      /** id of the droppable row */
      droppableRowId?: never;
      /** can table sort/reorder rows */
      isSortable?: never;
    };

type ContextMenuProps<TData> = {
  /** context menu */
  contextMenuActions?: (row?: TData) => ContextMenuAction[];
};

type SortableProps = {
  /** default sort array */
  defaultSort?: SortingState;
};

export type TableProps<TData> = CommonProps<TData> &
  ContextMenuProps<TData> &
  DragNDropProps<TData> &
  SelectableProps<TData> &
  SortableProps & {
    /** function to get the scroll position of TableContainer */
    onContainerScroll?: (value: number) => void;
  };

export type TableRef = {
  clearSelection: () => void;
};

export const ACTION_COLUMN_MAX_SIZE = 48;

/** Abstracts all table business logic. */
const Table = <TData,>(
  {
    data,
    columns,
    preColumns = [],
    caption,
    styles,
    droppableTableId,
    droppableRowId,
    emptyDroppableTableId,
    rowSelectionMode = "unselectable",
    isLoading = false,
    isSortable = false,
    contextMenuActions,
    onRowSelection,
    getRowId,
    defaultSort = [],
    rowErrors,
  }: TableProps<TData>,
  ref?: Ref<TableRef>
) => {
  const { selection } = useTableSelectionParam();
  const initialRender = useRef<boolean>(selection.length > 0);

  const formatInitialSelection = useMemo(() => {
    const newSelection: RowSelectionState = {};
    selection
      .filter((x) => x)
      .forEach((id) => {
        newSelection[id] = true;
      });
    return newSelection;
  }, [selection]);

  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    formatInitialSelection
  );

  const [sortBy, setSortBy] = useState<SortingState>(defaultSort);

  const assembleColumns = useMemo(
    () => [
      ...preColumns,
      {
        id: "select",
        size: ACTION_COLUMN_MAX_SIZE,
        maxSize: ACTION_COLUMN_MAX_SIZE,
        header: ({ table }) =>
          Checkbox({
            name: "selectAllRows",
            ariaLabel: "Select all rows",
            checked: table.getIsAllRowsSelected(),
            disabled: false,
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }),
        cell: ({ row }) =>
          Checkbox({
            name: "selectRow",
            ariaLabel: `Trailer ${row.id}`,
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            onChange: row.getToggleSelectedHandler(),
          }),
      },
      ...columns,
    ],
    [columns, preColumns]
  );

  const table = useReactTable<TData>({
    data: data ?? [],
    columns:
      rowSelectionMode === "checkbox" ||
      rowSelectionMode === "checkbox-and-row-click"
        ? assembleColumns
        : columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting: sortBy,
      rowSelection,
    },
    defaultColumn: {
      minSize: ACTION_COLUMN_MAX_SIZE,
      size: ACTION_COLUMN_MAX_SIZE,
    },
    enableColumnResizing: true,
    columnResizeMode: "onChange",
    onSortingChange: setSortBy,
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection: rowSelectionMode !== "unselectable",
    enableMultiRowSelection: rowSelectionMode !== "unselectable",
    getRowId,
    manualPagination: true,
    onRowSelectionChange: (updater) => {
      // On the first render, use the initial rowSelection state from the URL or a pre-defined value to preserve the selection.
      // For subsequent renders, allow the updater function to modify the rowSelection state dynamically.
      const result: RowSelectionState =
        typeof updater === "function"
          ? updater(rowSelection)
          : initialRender.current
            ? rowSelection
            : updater;
      setRowSelection(result);
      onRowSelection && onRowSelection(Object.keys(result).map(String));
      initialRender.current = false;
    },
  });

  const clearSelection = () => {
    table.resetRowSelection(true);
  };

  useClearSelectionWhenServiceCenterChanges(() => clearSelection());

  useImperativeHandle(ref, () => ({
    clearSelection: () => clearSelection(),
  }));

  if (isLoading) {
    return <Loading label={caption} styles={{ margin: "1rem 0" }} />;
  }

  return (
    <DroppableContainer
      styles={styles}
      emptyDroppableTableId={emptyDroppableTableId}
    >
      <MuiTable stickyHeader size="small">
        <TableCaption>{caption}</TableCaption>
        <TableHeader table={table} />
        <TableBody
          table={table}
          rowSelectionMode={rowSelectionMode}
          contextMenuActions={contextMenuActions}
          droppableTableId={droppableTableId}
          droppableRowId={droppableRowId}
          isSortable={isSortable}
          rowErrors={rowErrors}
        />
      </MuiTable>
    </DroppableContainer>
  );
};

Table.displayName = "Table";

export default memo(forwardRef(Table)) as <TData>(
  props: TableProps<TData>,
  ref: Ref<TableRef>
) => React.JSX.Element;
