import {
  Box,
  Button,
  ListItemIcon,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { ArrowRight, Cached, WarningRounded } from "@mui/icons-material";
import { NestedMenuItem } from "mui-nested-menu";
import { PlanTableFormat } from "../../../../types/planning/plan.type";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { useDateSearchParamOrFallbackToToday } from "../../../../hooks/useDateSearchParamOrFallbackToToday";
import {
  DeliveryRouteTrapStatus,
  DeliveryRouteTrapStatusOld,
  useDeliveryRoutesTrapsStatus,
  useDeliveryRoutesTrapsStatusChange,
} from "../../../../hooks/react-query/useDeliveryRoutesTrapsStatus";

import { dateToInt } from "../../../../utils/dateTimeHelper";
import { displayMessage } from "../../../../constants/strings";
import { toast } from "../../../../utils/snackbarHelper";
import Loading from "../../../../components/shared/layout/Loading";
import { FlexColumn, FlexRow } from "../../../../components/shared/layout/Flex";
import { LightTooltip } from "../../../../components/shared/LightTooltip";
import ErrorList from "../../shared/ErrorList";
import { labelButtonStyle } from "../../../../components/shared/PopupMenu";
import { useSearchTermParam } from "../../../../hooks/useSearchTermParam";
import { useDebouncedSearch } from "../../../../hooks/useDebouncedSearch";
import { useIsFeatureFlagEnabled } from "../../../../featureFlags/useIsFeatureFlagEnabled";

type ChangeStatusMenuItemOldProps = {
  routesOrTraps: PlanTableFormat[];
  isParentOpen: boolean;
  /** Omit if don't want to call it after the action */
  handleClose?: () => void;
  clearSelection?: () => void;
};

export const ChangeStatusMenuItemOld = ({
  routesOrTraps,
  isParentOpen,
  handleClose,
  clearSelection = () => {},
}: ChangeStatusMenuItemOldProps) => {
  const isV2FetchStrategyEnabled = useIsFeatureFlagEnabled(
    "inbound-planning-table-v2-fetch-strategy-client"
  );

  const theme = useTheme();
  const [serviceCenter] = useSelectedServiceCenter();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const [searchTerm] = useSearchTermParam();
  const debouncedSearch = useDebouncedSearch(searchTerm);

  const routeIds = routesOrTraps
    .filter((route) => route.type === "delivery_route")
    .map((route) => route.id);
  const trapIds = routesOrTraps
    .filter((trap) => trap.type === "delivery_trap")
    .map((trap) => trap.id);

  const onSuccess = (newStatus: DeliveryRouteTrapStatusOld) => {
    toast(
      displayMessage.inbound.planning.statusesChanged(
        routeIds.length,
        trapIds.length,
        newStatus.name
      ),
      {
        variant: "success",
      }
    );
    clearSelection();
  };

  const { mutate: changeDeliveryRouteStatus, isPending } =
    useDeliveryRoutesTrapsStatusChange({
      sicId: serviceCenter.id,
      date: dateToInt(selectedDate),
      searchTerm: isV2FetchStrategyEnabled ? "" : debouncedSearch,
      trapIds,
      routeIds,
      onSuccess,
    });

  const changeStatus = (status: DeliveryRouteTrapStatus) => {
    changeDeliveryRouteStatus(status);
    handleClose?.();
  };

  const { data: statuses = [], isLoading } = useDeliveryRoutesTrapsStatus(
    routeIds,
    trapIds
  );

  return (
    <NestedMenuItem
      delay={300}
      sx={{
        paddingX: "1rem",
      }}
      disabled={isPending || isLoading}
      rightIcon={
        <ListItemIcon>
          <ArrowRight sx={{ color: theme.palette.grey[700] }} />
        </ListItemIcon>
      }
      leftIcon={
        <ListItemIcon>
          <Cached sx={{ padding: "6px 0", color: theme.palette.grey[700] }} />
        </ListItemIcon>
      }
      divider
      MenuProps={{
        anchorOrigin: { horizontal: "left", vertical: "top" },
        "aria-label": "statuses-menu",
      }}
      renderLabel={() => (
        <Button sx={labelButtonStyle} disableRipple>
          {isPending || isLoading ? (
            <>
              <Loading size={24} containerWidth={24} /> Loading statuses...
            </>
          ) : (
            "Change Status"
          )}
        </Button>
      )}
      parentMenuOpen={isParentOpen}
    >
      {statuses.map((status) => (
        <MenuItem
          key={status.id}
          disableRipple={!status.isAvailableForTransition}
          sx={{
            cursor: "auto",
          }}
          onClick={() => {
            if (status.isAvailableForTransition) {
              changeStatus(status);
            }
          }}
        >
          <FlexColumn>
            <Typography
              sx={{ opacity: status.isAvailableForTransition ? 1 : 0.38 }}
            >
              {status.name}
            </Typography>
            {!status.isAvailableForTransition &&
              status.errorDetails.length > 0 && (
                <FlexColumn>
                  {status.errorDetails.map((error) => (
                    <FlexRow key={error.code}>
                      <LightTooltip
                        title={
                          <Box
                            sx={{
                              overflowY: "auto",
                              maxHeight: 300,
                              paddingRight: 1,
                            }}
                          >
                            {error.message}
                            {error.data && (
                              <ErrorList
                                code={error.code}
                                errorDetail={error.data}
                                serviceCenterCode={serviceCenter.code}
                              />
                            )}
                          </Box>
                        }
                        aria-label={error.message}
                        placement="left"
                      >
                        <WarningRounded color="warning" />
                      </LightTooltip>
                      <Typography ml={1} fontSize={16} sx={{ opacity: 0.38 }}>
                        {error.subject}
                      </Typography>
                    </FlexRow>
                  ))}
                </FlexColumn>
              )}
          </FlexColumn>
        </MenuItem>
      ))}
    </NestedMenuItem>
  );
};
