import { Filter } from "../../../types/filter";
import {
  getNonEmptyUniqueSortedOptions,
  handlePrefix,
  isActiveFilter,
  stringOptionFactory,
  type Prefix,
} from "../utils";

export type LocationQueryParam = "location";
type SomethingWithLocation = {
  location?: string;
};

export const getLocationFilter = (
  data: SomethingWithLocation[],
  prefix?: Prefix
): Filter => ({
  label: "Door",
  queryParam: handlePrefix("location", prefix),
  options: getNonEmptyUniqueSortedOptions(
    data.map((d) => stringOptionFactory(d.location))
  ),
});

export const filterLocation = <T extends SomethingWithLocation>(
  data: T[],
  filters?: string[]
): T[] =>
  isActiveFilter(filters)
    ? data.filter(({ location }) => location && filters.includes(location))
    : data;
