import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import type {
  AllDragTypes,
  DropTargetGetFeedbackArgs,
  ElementDragType,
  Input,
} from "@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { disableNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/disable-native-drag-preview";
import {
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import type { Edge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/dist/types/types";
import {
  attachClosestEdge,
  extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import invariant from "tiny-invariant";
import type { DraggableState } from "../types";
import { ALWAYS_ON_TOP } from "../../../../constants/utils";
import { parseDroppableId } from "../../../../utils/dragging";

// Lib doesn't export these types, so we need to define them ourselves
type DraggableGetFeedbackArgs = {
  /**
   * The user input as a drag is trying to start (the `initial` input)
   */
  input: Input;
  /**
   * The `draggable` element
   */
  element: HTMLElement;
  /**
   * The `dragHandle` element for the `draggable`
   */
  dragHandle: Element | null;
};
interface DraggablePreview {
  element: HTMLElement;
  bounds: DOMRect;
}

interface DraggableOffset {
  x: number;
  y: number;
}
interface DraggableOptions<TElement extends HTMLElement> {
  id: string;
  index: number;
  handle?: RefObject<HTMLButtonElement>;
  element: RefObject<TElement>;
  canDrag?: (args: DraggableGetFeedbackArgs) => boolean;
  canDrop?: (args: DropTargetGetFeedbackArgs<ElementDragType>) => boolean;
  getInitialData?: (args: DraggableGetFeedbackArgs) => Record<string, unknown>;
  getData?: (
    args: DropTargetGetFeedbackArgs<AllDragTypes>
  ) => Record<string | symbol, unknown>;
}

const idleState: DraggableState = { type: "idle" };
const overState: DraggableState = { type: "over" };
const draggingState: DraggableState = { type: "dragging" };

export const useDraggable = <TElement extends HTMLElement>(
  options: DraggableOptions<TElement>
) => {
  const previewElement = useRef<HTMLElement | null>(null);

  const [dragState, setDragState] = useState({
    state: idleState as DraggableState,
    pointer: null as Input | null,
    offset: null as DraggableOffset | null,
    preview: null as DraggablePreview | null,
    closestEdge: null as Edge | null,
  });

  const resetDraggable = useCallback(() => {
    previewElement.current = null;
    setDragState({
      state: idleState,
      pointer: null,
      offset: null,
      preview: null,
      closestEdge: null,
    });
  }, []);

  useEffect(() => {
    const handle = options.handle?.current;
    const element = options.element.current;
    if (!element) return;

    const cleanup = combine(
      draggable({
        element: handle ? handle : element,
        getInitialData: options.getInitialData,
        canDrag: options.canDrag,
        onDragStart: ({ location }) => {
          setDragState((prev) => ({ ...prev, state: draggingState }));
          const { input } = location.current;

          const bounds = element.getBoundingClientRect();
          setDragState((prev) => ({
            ...prev,
            offset: {
              x: input.clientX - bounds.left - 16,
              y: input.clientY - bounds.top - 16,
            },
            pointer: input,
          }));
        },
        onDrag: ({ location }) => {
          setDragState((prev) => ({
            ...prev,
            state: draggingState,
            pointer: location.current.input,
          }));
        },
        onDrop: () => {
          resetDraggable();
        },
        onGenerateDragPreview: ({ source, nativeSetDragImage }) => {
          disableNativeDragPreview({ nativeSetDragImage });

          const bounds = source.element.getBoundingClientRect();

          setDragState((prev) => ({
            ...prev,
            preview: {
              element: source.element,
              bounds,
            },
          }));
        },
      }),
      dropTargetForElements({
        element,
        canDrop: options.canDrop,
        getData: ({ input, element, source }) => {
          invariant(
            options.getData,
            `Please provide some data for ${options.id}`
          );
          return attachClosestEdge(
            options.getData({ input, element, source }),
            {
              element,
              input,
              allowedEdges: ["top", "bottom"],
            }
          );
        },
        onDragEnter: ({ source, self }) => {
          if (parseDroppableId(String(source.data.id)).id !== options.id) {
            setDragState((prev) => ({
              ...prev,
              closestEdge: extractClosestEdge(self.data),
            }));
          }
          setDragState((prev) => ({
            ...prev,
            state: overState,
          }));
        },
        onDragLeave: () => {
          setDragState((prev) => ({
            ...prev,
            state: idleState,
            closestEdge: null,
          }));
        },
        onDrag({ self, source }) {
          const isSource = source.element === element;
          if (isSource) {
            setDragState((prev) => ({
              ...prev,
              closestEdge: null,
            }));
            return;
          }

          const closestEdge = extractClosestEdge(self.data);

          const sourceIndex = source.data.index;
          invariant(
            typeof sourceIndex === "number",
            "Please provide the index on the dragged item data definition"
          );

          const isItemBeforeSource = options.index === sourceIndex - 1;
          const isItemAfterSource = options.index === sourceIndex + 1;

          const isDropIndicatorHidden =
            (isItemBeforeSource && closestEdge === "bottom") ||
            (isItemAfterSource && closestEdge === "top");

          if (isDropIndicatorHidden) {
            setDragState((prev) => ({
              ...prev,
              closestEdge: null,
            }));
            return;
          }
          setDragState((prev) => ({
            ...prev,
            closestEdge,
          }));
        },
        onDrop: () => {
          setDragState((prev) => ({
            ...prev,
            state: idleState,
            closestEdge: null,
          }));
        },
      })
    );

    return () => cleanup();
  }, [options, resetDraggable]);

  useLayoutEffect(() => {
    const element = previewElement.current;
    if (!element) return;

    const animationFrame = requestAnimationFrame(() => {
      if (!dragState.pointer || !dragState.offset) return;
      const x = dragState.pointer.clientX - dragState.offset.x;
      const y = dragState.pointer.clientY - dragState.offset.y;
      element.style.transform = `translate(${x}px, ${y}px)`;
    });

    return () => cancelAnimationFrame(animationFrame);
  }, [previewElement, dragState.pointer, dragState.offset]);

  const { state, closestEdge, preview } = dragState;

  return {
    state,
    closestEdge,
    preview,
    previewElement,
  };
};

export const previewStyles = (preview: { bounds: DOMRect }) => ({
  position: "fixed",
  width: `${preview.bounds.width}px`,
  height: `${preview.bounds.height}px`,
  pointerEvents: "none",
  willChange: "transform",
  zIndex: ALWAYS_ON_TOP,
  top: 0,
  left: 0,
  transform: `translate(${preview.bounds.left}px, ${preview.bounds.top}px)`,
});
