import { useMutation, useQueryClient } from "@tanstack/react-query";
import { http } from "../../utils/httpCommon";
import { apiUrls } from "../../utils/apiUrls";
import type { PlanType } from "../../types/planning/plan.type";
import { plannedRoutesQueryKey } from "./usePlannedRoutes";
import { keys } from "./preplanning/useShipmentDetails";
import { shipmentsByRouteOrTrapKeys } from "./preplanning/useShipmentsByRouteOrTrap";

export interface ReorderPayload {
  routeId: number;
  data: {
    type?: PlanType;
    shipmentIds: number[];
    afterShipmentId: number;
  };
}

const reorderShipments = async ({ routeId, data }: ReorderPayload) => {
  const url = apiUrls.reorderShipments(routeId);
  const { status } = await http.patch(url, data);
  return status;
};

interface ReorderShipmentsArgs {
  date: number;
  sicId: number;
  onSuccess?: (routeId: number, count: number) => void;
  onSettled?: () => void;
}

export const useReorderShipments = ({
  date,
  sicId,
  onSuccess,
  onSettled,
}: ReorderShipmentsArgs) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: reorderShipments,
    onSuccess: (_, { routeId, data: { shipmentIds, type } }) => {
      queryClient.invalidateQueries({
        queryKey: plannedRoutesQueryKey(date, sicId),
      });
      queryClient.invalidateQueries({
        queryKey: keys.allShipmentDetails,
      });
      queryClient.invalidateQueries({
        queryKey: shipmentsByRouteOrTrapKeys.shipmentsByRouteOrTrap(
          routeId,
          type ?? "delivery_route"
        ),
      });
      onSuccess?.(routeId, shipmentIds.length);
    },
    onSettled,
  });
};

export const useOldReorderShipments = ({
  onSuccess,
}: {
  onSuccess?: (routeId: number) => Promise<void>;
}) =>
  useMutation({
    mutationFn: reorderShipments,
    onSuccess: (_, { routeId }) => onSuccess?.(routeId),
  });
