import { Box, styled } from "@mui/material";
import { ALWAYS_ON_TOP } from "../../../constants/utils";

const Indicator = styled(Box)(({ theme }) => ({
  position: "absolute",
  zIndex: ALWAYS_ON_TOP,
  backgroundColor: theme.palette.primary.main,
  pointerEvents: "none",
  boxSizing: "border-box",
  height: "2px",
  left: "4px",
  right: 0,
  "&::before": {
    content: '""',
    width: "6px",
    height: "6px",
    position: "absolute",
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: "50%",
    top: "-4px",
    left: "-10px",
  },
  "&.edge-top": {
    top: "calc(-0.65 * (var(--gap, 0px) + 2px))",
  },
  "&.edge-bottom": {
    bottom: "calc(-0.65 * (var(--gap, 0px) + 2px))",
  },
}));

const DropIndicator = ({ edge }: { edge: string }) => {
  const edgeClassMap: Record<string, string> = {
    top: "edge-top",
    bottom: "edge-bottom",
  };

  const edgeClass = edgeClassMap[edge];

  return <Indicator className={`${edgeClass}`} />;
};

export default DropIndicator;
