/** Used to control selection and DnD from tables, cards, etc. */
import { ReactNode, createContext, useContext, useState } from "react";
import {
  SummaryContext,
  SummaryContextType,
} from "../pages/inbound/shared/ActionsMenuContext";
import {
  addItem,
  addToParent,
  removeFromParent,
} from "../utils/shipmentSelectionHandler";
import type { CommonTotalizers } from "../pages/inbound/shared/inboundCommonTotalizers";

type DraggingSource = "notDragging" | "sidebar" | "mainTable";

type SelectionContextValue = {
  selected: SummaryContext[];
  totals?: CommonTotalizers | null;
  handleSelect: (selectedItem: SummaryContext) => void;
  clearSelection: (type?: SummaryContextType) => void;
  isSelected: (id: number, type: SummaryContextType) => boolean;
  isAllSelected: (shipmentIds: number[], type: SummaryContextType) => boolean;
  draggingSource: DraggingSource;
  setDraggingSource: (draggingSource: DraggingSource) => void;
};

const SelectionContext = createContext<SelectionContextValue | null>(null);

type SelectionContextProviderProps = {
  children: ReactNode;
};

export function SelectionContextProvider({
  children,
}: SelectionContextProviderProps) {
  const [selected, setSelected] = useState<SummaryContext[]>([]);
  const [draggingSource, setDraggingSource] =
    useState<DraggingSource>("notDragging");

  const handleSelect = (selectedItem: SummaryContext) => {
    const parent = selected.find((item) => item.id === selectedItem.id);
    if (parent) {
      const shipmentId = parent.shipmentIds.find((id) =>
        selectedItem.shipmentIds.includes(id)
      );

      if (shipmentId) {
        removeFromParent(selectedItem, setSelected);
      } else {
        addToParent(selectedItem, parent, setSelected);
      }
    } else {
      addItem(selectedItem, setSelected);
    }
  };

  const clearSelection = (type?: SummaryContextType) => {
    if (type) {
      setSelected(selected.filter((s) => s.type !== type));
    } else {
      setSelected([]);
    }
  };

  const isAllSelected = (
    shipmentIds: number[],
    type: SummaryContextType
  ): boolean =>
    shipmentIds.length > 0 &&
    shipmentIds.every((id) =>
      selected.some(
        (item) => item.shipmentIds.includes(id) && item.type === type
      )
    );

  const isSelected = (id: number, type: SummaryContextType): boolean =>
    Object.values(selected).some(
      (item) => item.shipmentIds.includes(id) && item.type === type
    );

  return (
    <SelectionContext.Provider
      value={{
        selected,
        handleSelect,
        clearSelection,
        isSelected,
        isAllSelected,
        draggingSource,
        setDraggingSource,
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
}

export function useSelectionContext(): SelectionContextValue {
  const selectionContext = useContext(SelectionContext);
  if (!selectionContext) {
    throw new Error(
      "useSelectionContext must be used within SelectionContextProvider"
    );
  }
  return selectionContext;
}
