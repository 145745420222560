import { Dayjs } from "dayjs";
import pluralize from "pluralize";
import { PlanType } from "../types/planning/plan.type";
import { DaylightDateFormat } from "./DaylightDateFormat";

export const getLowerAndCapitalizedCase = (word: string) => {
  const lowerCase = word.toLowerCase();
  const capitalized = lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
  return { lowerCase, capitalized };
};

export const displayMessage = {
  default: {
    noResults: "Uh oh! We couldn't find any results for you.",
    noResultsAndSearchAgain:
      "Sorry, no results found. Please try a different search.",
    emptyFilterResults:
      "Try adjusting your search or filter to find what you're looking for.",
    deleteFromSelection: "Delete from selection.",
    pageNotFound: "Unlike the shipments we handle, this page is missing!",
    internalError:
      "An internal error occurred. Please try reloading the page or try again later.",
    unauthorizedError: (email: string) =>
      `Sorry, your account ${email} doesn’t have permission to perform this action. If you believe you need access, please contact your administrator for further assistance.`,
    recordInactive: (recordName: string) => {
      const { lowerCase, capitalized } = getLowerAndCapitalizedCase(recordName);
      return `${capitalized} is inactive. Contact Safety Dept. or select new ${lowerCase}`;
    },
    recordRemoved: (recordName: string) => {
      const { lowerCase, capitalized } = getLowerAndCapitalizedCase(recordName);
      return `${capitalized} is unavailable. Select new ${lowerCase}`;
    },
  },
  linehaul: {
    unmerge: {
      success: (mergedSics: string[], primarySic?: string) =>
        `${mergedSics.map((sic) => `"${sic}"`).join(", ")} unmerged${primarySic ? ` from ${primarySic}` : ""}`,
      error: {
        default: "Uh oh! There was an issue unmerging these lanes",
      },
    },
    merge: {
      success: (primaryLane: string, secondaryLanes: string[]) =>
        `${secondaryLanes.map((lane) => `"${lane}"`).join(", ")} merged into ${primaryLane} lane`,
      error: {
        default: "Uh oh! There was an issue merging these lanes",
        selectAtLeastTwo: "Select at least 2 lanes to merge",
        selectPrimaryLane: "Select the primary lane to merge",
        notEnabled: "Merging lanes is not currently enabled",
      },
    },
    masterSchedule: {
      error: "Uh oh! There was an issue fetching the schedules.",
      empty: 'Click "Create Plan" to get started.',
      filterEmpty: "Uh oh! We couldn't find any schedules.",
    },
    templates: {
      error: "Uh oh! There was an issue fetching the templates.",
      empty: 'Click "Create Template" to get started.',
      filterEmpty: "Uh oh! We couldn't find any templates.",
    },
    template: {
      error: "Uh oh! There was an issue fetching the template.",
      empty: 'Click "New Entry" to get started.',
      filterEmpty: "Uh oh! We couldn't find any schedules.",
    },
    loadBoard: {
      error: "Uh oh! There was an issue fetching loadboard.",
      empty: "Uh oh! We couldn't find any service centers.",
    },
    serviceCenter: {
      error: "Uh oh! There was an issue fetching this service center.",
      empty: "Uh oh! We couldn't find any lanes.",
    },
    tripList: {
      error: "Uh oh! There was an issue fetching the trip list.",
      empty: "Uh oh! We couldn't find any trips.",
      updateTripNote: (tripNumber: number) =>
        `Note for trip ${tripNumber} was updated`,
    },
    rightPanel: {
      notes: {
        delete: "This action cannot be undone.",
      },
      shipments: {
        error: "Uh oh! There was an issue fetching the shipments.",
        empty: "Uh oh! We couldn't find any shipments.",
      },
      schedules: {
        error: "Uh oh! There was an issue fetching the schedules.",
        empty: "Uh oh! We couldn't find any schedules.",
      },
      trips: {
        error: "Uh oh! There was an issue fetching the trips.",
        empty: "Uh oh! We couldn't find any trips.",
      },
    },
    trips: {
      reinstateSchedule: {
        info: "You can only reinstate 1 cancelled trip at a time.",
      },
      error: "Uh oh! There was an error fetching this trip.",
    },
    copyURLToClipboard: "URL copied to clipboard",
  },
  outbound: {
    staticLoadTemplates: {
      filterEmpty: "Uh oh! We couldn't find any static load templates.",
      empty: "There are no static load templates registered for this SIC.",
    },
    presetLanes: {
      filterEmpty: "Uh oh! We couldn't find any preset lanes.",
      empty: "There are no preset lanes registered for this SIC.",
    },
    freightDetails: {
      error: "Uh oh! There was an issue fetching Freight Details.",
      filterEmpty: "Uh oh! We couldn't find any Freight Detail.",
      empty: "There are no freight details registered for this SIC.",
    },
    urgentAndCriticalCare: {
      filterEmpty: "Uh oh! We couldn't find any shipment with UC and CC.",
      empty: "There are no shipments with UC and CC registered for this SIC.",
    },
    trailerHub: {
      noRecords: "Uh oh! We couldn't find any trailers.",
      empty: "There are no trailers registered for this SIC.",
    },
    trailerHubShipments: {
      noRecords: "Uh oh! We couldn't find any shipment for this trailer.",
      empty: "There are no shipments for this trailer.",
    },
    moveShipments: {
      lane: {
        success: (items: number, loadTo: string) =>
          `${pluralize("shipment", items, true)} moved to ${loadTo} lane.`,
        defaultSuccess: (items: number) =>
          `${pluralize("shipment", items, true)} moved to lane.`,
        error: (items: number) =>
          `Failed to move ${pluralize("shipment", items)} to the lane.`,
      },
      trap: {
        success: (items: number, trapName: string) =>
          `${pluralize("shipment", items, true)} moved to ${trapName}.`,
        defaultSuccess: (items: number) =>
          `${pluralize("shipment", items, true)} moved to trap.`,
        error: (items: number) =>
          `Failed to move ${pluralize("shipment", items)} to the trap.`,
      },
      door: {
        success: (items: number, door: number) =>
          `${pluralize("shipment", items, true)} moved to door ${door}.`,
        defaultSuccess: (items: number) =>
          `${pluralize("shipment", items, true)} moved to door.`,
        error: (items: number) =>
          `Failed to move ${pluralize("shipment", items)} to the door.`,
      },
    },
    splitLane: {
      success: (destinationSic: string, originSic: string) =>
        `${destinationSic} lane split from ${originSic}.`,
      error: (destinationSic: string) =>
        `Failed to split ${destinationSic} lane.`,
    },
    addDoorToLane: {
      success: (doorNumber: string, laneSic: string) =>
        ` Door ${doorNumber} added to ${laneSic} lane.`,
      error: (laneSic: string) => `Failed to add door to ${laneSic} lane.`,
      allowShipments:
        "Only shipments with this tag will be auto-assigned to this door. All others must be added manually.",
      reassignShipments:
        "Shipments not yet loaded with selected shipment tags in this lane will be reassigned to this door.",
    },
    editDoorDetails: {
      success: (doorNumber: number) => ` Door ${doorNumber} edited.`,
      error: (doorNumber: number) =>
        `Failed to edit Door ${doorNumber} details.`,
      allowShipments:
        "Only shipments with this tag will be auto-assigned to this door. All others must be added manually.",
      reassignShipments:
        "Shipments not yet loaded with selected shipment tags in this lane will be reassigned to this door.",
    },
    removeDoorFromLane: {
      success: (doorNumber: number, laneSic: string) =>
        ` Door ${doorNumber} removed from ${laneSic} lane.`,
      error: (laneSic: string) => `Failed to remove door from ${laneSic} lane.`,
    },
    shipmentTransitEstimate: {
      success: () => "All shipments will make service.",
      error: (items: number) =>
        `Your selection will cause ${pluralize(
          "shipment",
          items,
          true
        )} to miss service.`,
      warning:
        "Unable to determine transit time. You may divert shipments to your chosen location, select an alternative, or cancel diversion.",
    },
    reconcileShipments: {
      success: () => "All shipments will make service.",
      error: (items: number) =>
        `${pluralize("shipment", items, true)} will miss service.`,
    },
    serviceDueError: "Contains shipments that will fail service.",
    serviceDueShipmentError: "Shipment will fail service.",
    routeNotFound: "System error: Route not found",
  },
  inbound: {
    changePlanDetails: {
      confirmTitle: "Review plan change",
      confirmText: (plannedShipmentsCount: number) =>
        `You have selected ${pluralize(
          "shipment",
          plannedShipmentsCount,
          true
        )} already part of a plan. Do you want to proceed?`,
    },
    planning: {
      lockRoutes: {
        invalidSelection: "Select locked or unlocked routes only",
      },
      loadingTitle: (numRoutes: number, isChecked: boolean) =>
        `${isChecked ? "Unlocking " : "Locking "} ${pluralize(
          "route",
          numRoutes
        )}...`,
      statusChanged: (routeName: string, type: PlanType, newStatus: string) =>
        `${routeName} ${type === "delivery_route" ? "route" : "trap"} changed to ${newStatus}`,
      bulkStatusChangedSuccessfully: (
        routesTrapsCount: number,
        planType: PlanType
      ) =>
        `${routesTrapsCount} ${pluralize(
          planType === "delivery_route" ? "route" : "trap",
          routesTrapsCount
        )} updated`,
      bulkStatusChangedPartialSuccess: (
        successRoutesCount: number,
        failRoutesCount: number,
        successTrapsCount: number,
        failTrapsCount: number
      ) =>
        `${successRoutesCount} ${pluralize(
          "route",
          successRoutesCount
        )} updated successfully. ${failRoutesCount} ${pluralize(
          "route",
          failRoutesCount
        )} failed. \n` +
        `${successTrapsCount} ${pluralize(
          "trap",
          successTrapsCount
        )} updated successfully. ${failTrapsCount} ${pluralize(
          "trap",
          failTrapsCount
        )} failed`,
      bulkStatusChangedFailed: () => "Failed to update routes and traps",
      statusesChanged: (
        numRoutes: number,
        numTraps: number,
        newStatus: string
      ) => {
        const routesText =
          numRoutes > 0 ? `${pluralize("route", numRoutes, true)}` : "";
        const trapsText =
          numTraps > 0 ? `${pluralize("trap", numTraps, true)}` : "";
        const separator = numRoutes > 0 && numTraps > 0 ? " and " : "";
        return `${routesText}${separator}${trapsText} changed to ${newStatus}`;
      },
    },
    dnd: {
      notAvailableForMassage:
        "The destination route must go back to CLDK status before proceeding with the action.",
    },
  },
  settings: {
    groups: {
      deleteConfirmDialog: {
        question: "Are you sure you want to delete the group? ",
        highlight: "This action cannot be undone.",
        warning:
          "Deleting this group will remove all associated permissions for users within this group. Affected users will lose access to features and functionalities immediately upon deletion. ",
      },
    },
    linehaul: {
      regions: {
        error: "Uh oh! There was an issue fetching the regions.",
        empty: 'Click "Create Region" to get started.',
      },
      sics: {
        error: "Uh oh! There was an issue fetching the sics.",
      },
      contacts: {
        error: "Uh oh! There was an issue fetching the sic contacts.",
        empty: "There is not registered contacts.",
        addContact: {
          success: "Contact added",
          error: "Uh oh! There was an issue adding the contact.",
        },
        updateContact: {
          success: "Contact updated",
          error: "Uh oh! There was an issue updating the contact.",
        },
        deleteContact: {
          success: "Contact deleted",
          error: "Uh oh! There was an issue deleting the contact.",
        },
      },
    },
  },
} as const;

export const toastMessage = {
  inbound: {
    changePlanDetails: {
      success: (type: string) => `Plan details ${type} changed`,
    },
    deleteEmptyTrap: {
      success: (title: string, name: string | null) =>
        `Empty trap "${title} ${name ? ": " + name : ""}" deleted`,
    },
    deleteTrap: {
      success: (trapsText: string, trapsCount: number) =>
        `${pluralize("Trap", trapsCount)} ${trapsText} ${
          trapsCount === 1 ? "has" : "have"
        } been deleted`,
    },
    deleteRoute: {
      success: (routesText: string, routesCount: number) =>
        `${pluralize("Route", routesCount)} ${routesText} ${
          routesCount === 1 ? "has" : "have"
        } been deleted`,
    },
    editNotAllowedForPastDates:
      "Only today or future dates allowed for plan changes.",
    editNotAllowedStatus: "Please change the status to enable editing",
    removeEquipmentNotAllowedFromTrapOrRoute: (
      equipment: string,
      type: string
    ) =>
      `Unable to remove the ${equipment}. Change the status to ${type === "Trap" ? "TPLN" : "CPLN"} to remove.`,
    removeDoorNotAllowedFromRoute:
      "Unable to remove the door. Change the status to CPLN to remove.",
    removeDoorFromNotAllowedFromTrap:
      "Unable to remove the door. Change the status to TPLN to remove.",
    selectOneTrap: "Select at least one trap to delete",
    selectEmptyTraps: "Select only traps with empty shipments to delete",
    selectOneRoute: "Select at least one route to delete",
    selectEmptyRoutes: "Select only routes with empty shipments to delete",
    selectAtLeastOnePlanned: "Select at least one planned record",
    selectAtLeastOneUnplanned: "Select at least one unplanned record",
    removeFreightOnDock: {
      failedCannotRemove:
        "Freight on Dock shipments cannot be removed from the plan",
    },
    reorder: {
      success: (count: number) =>
        `Shipment${count > 1 ? "s" : ""} reordered successfully!`,
    },
    addToPlan: {
      successAutoAndManualAssign: (
        autoAssignShipmentCount: number,
        manualShipmentCount: number
      ) => {
        const autoAssignMessage = `${pluralize(
          "shipment",
          autoAssignShipmentCount,
          true
        )} added to the plan`;

        return manualShipmentCount > 0
          ? `${pluralize(
              "shipment",
              manualShipmentCount,
              true
            )} manually assigned and ${autoAssignMessage}`
          : autoAssignMessage;
      },
      successAdding: (affectedShipmentsCount: number) =>
        `${pluralize(
          "shipment",
          affectedShipmentsCount,
          true
        )} added to the plan`,
      failed:
        "An error occurred while adding shipments to the plan. Please try again.",
      selectOne: "Select at least one trailer to add to the plan",
      emptyTrapSelected:
        "Select only traps with associated shipments to add to the plan",
      plannedTrapSelected: "The selected traps are already part of the plan",
    },
    removedFromPlan: {
      success: (shipmentCount: number) =>
        `${pluralize("shipment", shipmentCount, true)}
      removed from plan`,
    },
    assign: {
      success: (affectedShipmentsCount: number) =>
        `${pluralize("shipment", affectedShipmentsCount, true)} reassigned`,
      moveToBucketNotAllowed:
        "Shipments cannot be moved to the appointment bucket",
      noneSelected: "Select at least one record to assign",
      noShipments: "There are no shipments for this item",
      noShipmentsToAssign: "There are no shipments to assign",
    },
    mergeRoutes: {
      success: (numberOfRoutes: number, routeName: string) =>
        `${numberOfRoutes} routes merged into ${routeName} route`,
      noIndividualShipmentsAllowed:
        "Do not select individual shipments when merging. Select 2 or more routes only",
      selectAtLeastTwo: "Select 2 or more routes to merge",
    },
    mergeRoutesAndTraps: {
      success: (numShipments: number, name: string, isRoute: boolean) =>
        `${pluralize(
          "shipment",
          numShipments,
          true
        )} merged into ${name} (${isRoute ? "route" : "trap"})`,
      selectAtLeastTwo: "Select at least two routes/traps to merge",
      selectMergeDestination: "Select the merge destination",
      selectOnlyNonDispatchedRoutes:
        "Please select only routes with CPLN, CLDK, CLDV or DISP status to merge.",
      selectOnlyRoutesTrapsWithShipments:
        "Select only routes/traps with shipments associated to them",
      noIndividualShipmentsAllowed:
        "Do not select individual shipments when merging. Select 2 or more routes/traps only",
      removeDoorNotAllowedFromRoute:
        "Unable to remove the door when merging. Change the status to CPLN to remove.",
      removeDoorNotAllowedFromTrap:
        "Unable to remove the door when merging. Change the status to TPLN to remove.",
    },
    mergeTraps: {
      success: (numberOfTraps: number, trapName: string, trapTitle: string) =>
        `${numberOfTraps} traps merged into ${trapName} (${trapTitle}) trap`,
      noIndividualShipmentsAllowed:
        "Do not select individual shipments when merging. Select 2 or more traps only",
      selectAtLeastTwo: "Select 2 or more traps to merge",
    },
    autoSequencing: {
      running: "Running auto-sequencing process...",
      success: (count: number) =>
        `${pluralize("route", count, true)} auto-sequenced`,
      failed: (count: number) =>
        `${pluralize("route", count, true)} failed to auto-sequence`,
      someFailed: (successCount: number, failedCount: number) =>
        `${pluralize(
          "route",
          successCount,
          true
        )} successfully auto-sequenced; ${pluralize(
          "route",
          failedCount,
          true
        )} failed.`,
      notAllowedOnTraps: "Traps cannot be reordered",
      selectAtLeastOneRoute: "Select at least one route",
    },
    print: {
      selectAtLeastOneRoute: "Select at least one route or trap",
      selectAtLeastOneMoreRoute: "Select at least one more route",
      selectAtLeastOneTrap: "Select at least one trap",
      unableToPrintTraps: "The system is unable to print traps",
      selectAtLeastOneTrip: "Select at least one trip",
      noBills: "Select routes or traps with at least 1 bill.",
      routeOrTrapWithNoBills: (routeOrTrap: "route" | "trap") =>
        `Select a ${routeOrTrap} with at least 1 bill.`,
    },
    copyLink: {
      success: "Link copied.",
      error: "Link could not be copied.",
    },
    createTrap: {
      success: (name: string, shipmentsCount: number) =>
        `Trap ${name} with ${pluralize(
          "shipment",
          shipmentsCount,
          true
        )} created`,
      trapNameExists: "Trap name already exists",
      nameRequired: "Trap name is required",
      failed: (errorMessage: string) => `Error creating trap: ${errorMessage}`,
    },
    createRoute: {
      success: (name: string, shipmentsCount: number) =>
        `Route ${name} with ${pluralize(
          "shipment",
          shipmentsCount,
          true
        )} created`,
      routeNameExists: "Route name already exists",
      trailerAlreadyInPlan: "Trailer is already used in today's plan",
    },
    autoAssign: {
      withUnprocessed: (
        numberOfAssignedShipments: number,
        numberOfUnprocessedShipments: number
      ) =>
        `(${numberOfAssignedShipments}) ${pluralize(
          "shipment",
          numberOfAssignedShipments
        )} auto-assigned. (${numberOfUnprocessedShipments}) ${pluralize(
          "shipment",
          numberOfUnprocessedShipments
        )} not processed: already part of this plan.`,
      success: (numberOfAssignedShipments: number) =>
        `(${numberOfAssignedShipments}) ${pluralize(
          "shipment",
          numberOfAssignedShipments
        )} auto-assigned`,
      oneOrMoreFailed:
        "An error occurred. One or more of the shipments failed to auto-assign.",
      failed:
        "An error occurred while auto-assigning to the plan. Please try again.",
      selectShipments: "Select at least one shipment to auto-assign",
    },
    manualAssign: {
      selectionRequiredForManualAssignment:
        "Please select the trailer to enable manual assignment",
      onlyUnplannedShipmentsCanBeManuallyAssigned:
        "Only unplanned shipments can be manually assigned",
      oneOrMoreFailed:
        "An error occurred. One or more of the shipments failed to auto-assign.",
    },
    planDatePicker: {
      dateChanged: "Plan date changed",
      cannotChangePlanDate:
        "Unable to modify the plan date while viewing shipments.",
    },
    createPresetRoute: {
      success: (type: string) => `${type} saved.`,
    },
    updatePresetRoute: {
      success: (type: string) => `${type} edited.`,
    },
    routeStatusChange: {
      selectAtLeastOneRouteOrTrap: "Select at least one route or trap",
      routeTrapNotInPlanStatus: "Route/Trap is not in plan status anymore",
      atLeastOneRouteTrapNotInPlanStatus:
        "At least one Route/Trap is not in plan status anymore",
    },
    incompleteTrailerReinstateToPlan: {
      selectionRequired: "Please select at least one route to reinstate.",
      success: "Selected routes successfully reinstated to plan.",
      error: "There was an error reinstating the selected routes.",
    },
    reconcileShipments: {
      selectAtLeastOneTrap: "Select at least one trap to reconcile",
      selectOnlyOneTrapToReconcile: "Select only one trap to reconcile",
      onlyTrapsCanBeReconciled: "Only traps can be reconciled at this time",
      trapNotInCldkStatus:
        "The selected trap is not in CLDK status. Please ensure the trap is in CLDK status before reconciling.",
      trapShipmentsReconciliationSuccessful: (
        numberOfShipmentsReconciled: number,
        numberOfMovedShipments: number,
        existingTrapName: string,
        newTrapName: string
      ) =>
        `${numberOfShipmentsReconciled} ${pluralize("shipment", numberOfShipmentsReconciled)} reconciled in ${existingTrapName} Trap. ${numberOfMovedShipments} ${pluralize("shipment", numberOfMovedShipments)} moved to new trap: ${newTrapName}.`,
    },
  },
  outbound: {
    addTripToPlan: {
      success: (items: Array<number>) => {
        if (items.length === 1) {
          return `Trip ${items[0]} added to plan.`;
        } else {
          return `${items.length} trips added to plan.`;
        }
      },
      error: (items: Array<number>) =>
        `Failed to add ${items} ${pluralize("trip", items.length)} to plan.`,
    },
    removeTripFromPlan: {
      success: (items: Array<number>) => {
        if (items.length === 1) {
          return `Trip ${items[0]} removed from plan.`;
        } else {
          return `${items.length} trips removed from plan.`;
        }
      },
      error: (items: Array<number>) =>
        `Failed to remove ${items} ${pluralize("trip", items.length)} to plan.`,
    },
    reconcileShipments: {
      success: (items: number) =>
        `${pluralize("shipment", items, true)} reconciled.`,
      error: (items: number) =>
        `Failed to reconcile ${pluralize("shipment", items, true)}.`,
    },
    mergeLanes: {
      success: (lanes: string[], mergeLane: string) =>
        `${lanes.join(", ")} merged into lane ${mergeLane}.`,
      error: (items: number) =>
        `Failed to merge ${pluralize("lane", items, true)}.`,
      information: "Select two or more lanes to merge",
    },
    removeShipments: {
      bulkSuccess: (count: number) =>
        `${pluralize("shipment", count, true)} removed from trailer.`,
      bulkError: "Failed to remove shipments from trailer.",
      success: (proNumber: string) =>
        `Pro # ${proNumber} unreconciled from trailer.`,
      error: (proNumber: string) =>
        `Pro # ${proNumber} failed to remove from trailer.`,
      title: (proNumber: string) =>
        `Undo reconciliation for Pro # ${proNumber}`,
      question: "Are you sure you want to undo reconciliation for ",
      information:
        "This will change the shipment to its original status only. To move this shipment, please use the lane details page.",
    },
    generatePdf: {
      error: "Failed to generate.",
      success: "Manifest printed successfully.",
    },
    assignAsset: {
      success: "Asset assigned successfully.",
      error: "Failed to assign asset.",
    },
    removeAsset: {
      success: "Asset removed successfully.",
      error: "Failed to remove asset.",
    },
    reassignDoor: { success: "Door reassigned successfully." },
    trailerHub: {
      deleteLoadNote: {
        success: "Note deleted",
        error: "Failed to delete note",
      },
      updateLoadNote: {
        success: "Note updated",
        error: "Failed to update note",
      },
      createLoadNote: {
        success: "Note created",
        error: "Failed to create note",
      },
    },
  },
  linehaul: {
    calculateRollover: {
      failed: "Uh oh! There was an issue calculating the rollover fee.",
    },
    cancelTrip: {
      success: (type: "schedule" | "trip") => `Cancelled ${type}`,
      failed: (type: "schedule" | "trip") => `Failed to cancel ${type}`,
    },
    closeTrip: {
      success: (tripNumber: number) =>
        `The trip #${tripNumber} was closed successfully`,
      failed: "There was an error cancelling the trip",
    },
    changeTripStatus: {
      success: (tripNumber: number) =>
        `The trip #${tripNumber} was updated successfully`,
      failed: "There was an error updating the trip status",
    },
    enrouteTrip: {
      success: (tripNumber: number) => `Trip #${tripNumber} set to en route`,
      failed: "There was an error en routing the trip",
    },
    createSchedule: {
      success: "Schedule created",
      failed: "Failed to create schedule",
    },
    publishSchedule: {
      success: "Schedules published",
      failed: "Failed to publish schedules",
    },
    reinstateSchedule: {
      success: "Reinstated schedule",
      failed: "Failed to reinstate schedule",
    },
    unpublishTrip: {
      success: "Unpublished trip",
      failed: "Failed to unpublish trip",
    },
    deleteScheduleNote: {
      success: "Note deleted",
      failed: "Failed to delete note",
    },
    updateScheduleNote: {
      success: "Note updated",
      failed: "Failed to update note",
    },
    createScheduleNote: {
      success: "Note created",
      failed: "Failed to create note",
    },
    publishTrip: {
      success: "Trip published",
      failed: "Failed to publish trip",
    },
    updateTripPriority: {
      success: "Trip priority updated",
      failed: "Failed to update trip priority",
    },
    editTemplate: {
      errorLoading: "Error loading entry",
    },
    updateTrip: {
      success: "Trip updated successfully",
      failed: "Failed to update trip",
    },
    updateSchedule: {
      success: "Schedule updated successfully",
      failed: "Failed to update schedule",
      notFound: "Schedule couldn't be found",
    },
    updateCarrierContacts: {
      success: (carrierCode: string) => `Updated contacts for ${carrierCode}`,
      failed: (carrierCode: string) =>
        `Failed to update contacts for ${carrierCode}`,
    },
    deleteMasterSchedule: {
      success: (week: Dayjs) =>
        `Deleted master schedule for week of ${week.format(
          DaylightDateFormat.ISO_DATE
        )}`,
      failed: (week: Dayjs) =>
        `Failed to delete master schedule for week of ${week.format(
          DaylightDateFormat.ISO_DATE
        )}`,
    },
    masterSchedule: {
      createMasterScheduleSuccess: "Created master schedule",
      createMasterScheduleError: "Failed to create master schedule",
      createTemplateSuccess: (name: string) => `Created template "${name}"`,
      createTemplateError: "Failed to create template",
      updateTemplateSuccess: "Updated template",
      updateTemplateError: "Failed to update template",
      deleteTemplateSuccess: (name: string) => `Deleted template "${name}"`,
      deleteTemplateError: "Failed to delete template",
      createEntrySuccess: "Created entry",
      createEntryError: "Failed to create entry",
      updateEntrySuccess: "Updated entry",
      updateEntryError: "Failed to update entry",
      deleteEntrySuccess: "Deleted entry",
      deleteEntryError: "Failed to delete entry",
      publishSuccess: (excludedCount?: number) =>
        `Published master schedule entries${
          excludedCount ? ` (excluded ${excludedCount})` : ""
        }`,
      publishError: "Failed to publish master schedule entries",
      expireEntrySuccess: "Expired entry",
      expireEntryError: "Failed to expire entry",
      errorLoading: "Error loading entry",
    },
    assignShipments: {
      success: (count: number, tripNumber: number) =>
        `${pluralize("Shipments", count, true)} assigned to trip #${tripNumber}`,
      error: (count: number, tripNumber: number) =>
        `Failed to assign ${pluralize("Shipments", count, true)} to trip #${tripNumber}`,
    },
    unassignShipments: {
      success: (count: number) =>
        `${pluralize("Shipments", count, true)} unassigned`,
      error: (count: number) =>
        `Failed to unassign ${pluralize("Shipments", count, true)}`,
    },
  },
  settings: {
    createRouteTrap: {
      failed:
        "Could not create route/trap. The Zip Codes are already assigned to another Route/trap.",
      routeNameAlreadyExists: "Route name already exists",
      trapNameAlreadyExists: "Trap name already exists",
    },
    editRouteTrap: {
      failed:
        "Could not edit route/trap. The Zip Codes are already assigned to another Route/trap.",
    },
    editDriver: {
      failed: "There was an error saving the driver changes. Please try again",
      success: "Driver updated.",
    },
    equipments: {
      create: {
        warning: {
          duplicatedTrailerNumber: "Trailer Number already exists.",
        },
      },
    },
    moveZipCode: {
      success: (
        zipCode: string,
        sourceRoute: string,
        destinationRoute: string
      ) =>
        `Zip code ${zipCode} moved from ${sourceRoute} to ${destinationRoute}.`,
      failed: (zipCode: string) => `Failed to move Zip Code ${zipCode}.`,
    },
    user: {
      get: {
        error: () => "User not found.",
      },
      toggleActive: {
        success: (name: string, active: boolean) =>
          `${name} is set to ${active ? "active" : "inactive"}`,
      },
      removeGroup: {
        success: (numGroups: number) =>
          numGroups > 1
            ? `${numGroups} groups removed`
            : `${numGroups} group removed`,
        successWithName: (groupName: string) => `${groupName} group removed`,
      },
      updateGroup: {
        success: (name: string) => `Updated group ${name}`,
      },
    },
    groups: {
      create: {
        success: (name: string) => `Group ${name} created`,
        failed: (errorMessage: string, name: string) =>
          `Error "${errorMessage}" while trying to create Group ${name}`,
      },
      delete: {
        success: (name?: string) =>
          name ? `${name} group deleted` : "Group deleted",
        failed: (name?: string) =>
          name
            ? `Error while trying to delete ${name} group`
            : "Error while trying to delete group",
      },
      toggleActive: {
        success: (name: string, active: boolean) =>
          `${name} Group is set to ${active ? "active" : "inactive"}`,
        failed: (name: string, active: boolean) =>
          `Error while trying to set ${name} Group to ${
            active ? "active" : "inactive"
          }`,
      },
      update: {
        success: (name: string) => `${name} Group updated.`,
        failed: (name: string) => `Error while trying to update Group ${name}`,
      },
      removeUser: {
        success: (userName: string) => `${userName} removed from group`,
        failed: (userName: string) =>
          `Error while trying to remove ${userName} from group`,
      },
    },
    permissions: {
      update: {
        success: () => "Permission updated.",
        failed: () => "Error while trying to update Permission",
      },
      create: {
        success: () => "Permission created",
        failed: (errorMessage: string) =>
          `${errorMessage} Error while trying to create Permission`,
      },
    },
    modules: {
      toggleActive: {
        success: (name: string, active: boolean) =>
          `${name} Module is set to ${active ? "active" : "inactive"}`,
        failed: (name: string, active: boolean) =>
          `Error while trying to set ${name} Module to ${
            active ? "active" : "inactive"
          }`,
      },
      deleteModule: {
        success: (name: string) => `${name} Module deleted.`,
        failed: (name: string) =>
          `An error occurred while deleting the ${name} Module. Please try again`,
      },
      createModule: {
        success: (name: string) => `Module ${name} created.`,
        failed: (errorMessage: string, name: string) =>
          `Error "${errorMessage}" while creating the Module ${name}. Please try again`,
      },
      deletePermission: {
        success: "Permission deleted.",
        failed:
          "Error while trying to delete the permission. Please try again later.",
      },
    },
    equipmentUpdated: {
      success: "Equipment saved",
      failed: "Failed to save equipment",
    },
    equipmentDeleted: {
      success: "Equipment deleted",
    },
    createZone: {
      success: (zone: string) => `Zone ${zone} created successfully`,
      failed: (errorMessage: string) =>
        `Could not create the zone. ${errorMessage}`,
      invalidName: (minZoneNameLength: number) =>
        `Zone name must not exceed ${minZoneNameLength} characters.`,
    },
    updateZone: {
      success: (zone: string) => `Zone ${zone} updated successfully`,
      failed: (errorMessage: string) =>
        `Could not update the zone. ${errorMessage}`,
    },
    deleteZone: {
      failed: "An error occurred while deleting the zone. Please try again.",
    },
    deleteTrapPreset: {
      success: "Deleted Preset Lane",
      failed: (error: unknown) => `Failed to delete Preset Lane ${error}`,
    },
    roleUpdate: {
      success: "Role saved successfully",
    },
    deleteLoadTemplate: {
      success: "Deleted Static Load Template",
      failed: (error: unknown) =>
        `Failed to delete Static Load Template ${error}`,
    },
    duplicateLoadTemplate: {
      success: "Duplicated Static Load Template",
      failed: (error: unknown) =>
        `Failed to duplicate Static Load Template ${error}`,
    },
    updateLoadTemplate: {
      success: "Updated Default Load Template",
      failed: (error: unknown) =>
        `Failed to update Default Load Template ${error}`,
    },
    addPresetLane: {
      success: "Added Preset Lane",
      failed: (error: unknown) => `Failed to create Preset Lane ${error}`,
    },
    updatePresetLane: {
      success: "Updated Preset Lane",
      failed: (error: unknown) => `Failed to update Preset Lane ${error}`,
    },
    deleteRoute: {
      success: (type: "Route" | "Trap", name: string) =>
        `${name} ${type} deleted`,
      failed: (type: "Route" | "Trap", name: string) =>
        `Failed to delete ${type} ${name}`,
    },
  },
  shared: {
    lockRoutes: {
      success: (count: number, isChecked: boolean) =>
        `${count === 1 ? "Route" : count + " routes"} ${isChecked ? "un" : ""}locked`,
    },
    massageRoute: {
      success: (routeName: string, isPlanCompleted: boolean) =>
        isPlanCompleted
          ? `Route ${routeName} marked as done`
          : `Route ${routeName} marked as not done`,
      failed: "Failed to mark route as done",
      tooltipText: (isPlanCompleted: boolean) =>
        !isPlanCompleted ? "Mark as done" : "Mark not done",
    },
    drivers: {
      failedFetch:
        "An error occurred while fetching drivers. Please try again.",
    },
  },
  generics: {
    error: "Sorry, an error occurred",
    failedToLoadData: "Data failed to load",
    failedToLoadDataSubtext:
      "We couldn’t load this data. Please refresh the page or try again later.",
    fieldUpdated: (field: string) => `${field} updated successfully.`,
    failedValidation: "Please complete all required fields",
    failedFetch: (name: string) =>
      `An error occurred while fetching ${name}. Please try again.`,
    handleError: (message: string, error: unknown) => `${message} ${error}`,
  },
  dispatch: {
    routes: {
      updateStatus: {
        success: "Route status updated",
        error: "Error while updating route status",
      },
    },
    stops: {
      routeInfoMissing: (stopName: string, status: string) =>
        `Route information missing for ${status} stop ${stopName}.`,
      assignStopToRoute: {
        success: "Route assigned",
        error: "Error while assigning route",
      },
      unassignStopFromRoute: {
        success: "Stop unassigned",
        error: "Error while unassigning stop",
      },
      updateStopSic: {
        success: (sicCode: string) => `Stop moved to ${sicCode} Service Center`,
        error: "Error while updating stop Service Center",
      },
      reschedule: {
        success: (stopName: string) => `Stop ${stopName} rescheduled`,
        error: (stopName: string) => `Error while rescheduling ${stopName}`,
      },
    },
  },
} as const;
