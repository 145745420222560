import { createSearchParams, useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { IconButton } from "@mui/material";
import { MapSharp } from "@mui/icons-material";
import type { PlanTableFormat } from "../../../../types/planning/plan.type";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { useUnplannedDatetimeSearchParam } from "../../../../hooks/useUnplannedDatetimeSearchParam";
import { useDateSearchParamOrFallbackToToday } from "../../../../hooks/useDateSearchParamOrFallbackToToday";
import { Tooltip } from "../../../../components/shared/Tooltip";

interface MapViewIconButtonProps {
  routeOrTrap: PlanTableFormat;
}

export const MapViewIconButton = ({ routeOrTrap }: MapViewIconButtonProps) => {
  const navigate = useNavigate();
  const [serviceCenter] = useSelectedServiceCenter();
  const { unplannedDateTime } = useUnplannedDatetimeSearchParam();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();

  const handleMapViewClick = useCallback(
    (routeOrTrap: PlanTableFormat) => {
      navigate({
        pathname: `/operations/${serviceCenter.code}/inbound/planning/map`,
        search: createSearchParams({
          date: selectedDate,
          unplannedDateTime,
          selection: String(routeOrTrap.id),
        }).toString(),
      });
    },
    [navigate, selectedDate, serviceCenter, unplannedDateTime]
  );

  const content = `View route ${routeOrTrap.name} on map`;

  return (
    <Tooltip content={content}>
      <IconButton
        aria-label={content}
        onClick={() => handleMapViewClick(routeOrTrap)}
      >
        <MapSharp />
      </IconButton>
    </Tooltip>
  );
};
