import {
  Drawer as MuiDrawer,
  DrawerProps,
  styled as muiStyled,
} from "@mui/material";

type Props = DrawerProps & {
  width?: number | string;
};

export const Drawer = muiStyled((props: Props) => (
  <MuiDrawer variant="persistent" {...props} />
))(({ theme, width = "20%", open }) => ({
  width: open ? width : "0",
  borderRight: `1px solid ${theme.palette.divider}`,
  "& .MuiDrawer-paper": {
    boxSizing: "border-box",
    position: "absolute",
    width: open ? width : "0",
    boxShadow: "0px 1px 10px 0px #0000001F",
  },
}));

export const OldDrawer = muiStyled((props: Props) => (
  <MuiDrawer variant="persistent" {...props} />
))(({ width = "20%", open }) => ({
  overflow: "scroll",
  width: open ? width : "0",
  "& .MuiDrawer-paper": {
    boxSizing: "border-box",
    borderTop: "1px solid lightgray",
    position: "absolute",
    width: open ? width : "0",
  },
}));
