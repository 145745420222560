import {
  DeliveryRouteTrapStatus,
  DeliveryRouteTrapStatusResponse,
} from "../../../hooks/react-query/useDeliveryRoutesTrapsStatus";

export const mockOldPlannedRouteTrapStatuses = {
  default: [
    {
      id: 29,
      name: "CLDK",
      isAvailableForTransition: true,
      transitionConstraints: [],
      errorDetails: [],
    },
    {
      id: 30,
      name: "CLDV",
      isAvailableForTransition: true,
      transitionConstraints: [
        "NO_TRAILER_ASSIGNED",
        "NO_DRIVER_ASSIGNED",
        "NO_TRACTOR_ASSIGNED",
      ],
      errorDetails: [],
    },
    {
      id: 32,
      name: "DISP",
      isAvailableForTransition: false,
      transitionConstraints: [],
      errorDetails: [
        {
          subject: "Tractor",
          message: "Tractor must be assigned",
          code: "NO_TRACTOR_ASSIGNED",
          data: null,
        },
        {
          subject: "Trailer",
          message: "Trailer must be assigned",
          code: "NO_TRAILER_ASSIGNED",
          data: null,
        },
        {
          subject: "Driver",
          message: "Driver must be assigned",
          code: "NO_DRIVER_ASSIGNED",
          data: null,
        },
      ],
    },
  ],
} as const satisfies Record<string, DeliveryRouteTrapStatus[]>;

export const mockPlannedRouteTrapStatuses = {
  empty: {
    deliveryRoute: [],
    deliveryTrap: [],
  },
  default: {
    deliveryRoute: [
      {
        id: 30055,
        name: "AZUSA-1",
        statusOptions: [
          {
            id: 29,
            name: "CLDK",
            isAvailableForTransition: true,
            transitionConstraints: [],
            errorDetails: [],
          },
          {
            id: 30,
            name: "CLDV",
            isAvailableForTransition: true,
            transitionConstraints: [
              "NO_TRAILER_ASSIGNED",
              "NO_DRIVER_ASSIGNED",
              "NO_TRACTOR_ASSIGNED",
            ],
            errorDetails: [],
          },
          {
            id: 32,
            name: "DISP",
            isAvailableForTransition: false,
            transitionConstraints: [],
            errorDetails: [
              {
                subject: "Tractor",
                message: "Tractor must be assigned",
                code: "NO_TRACTOR_ASSIGNED",
                data: null,
              },
              {
                subject: "Trailer",
                message: "Trailer must be assigned",
                code: "NO_TRAILER_ASSIGNED",
                data: null,
              },
              {
                subject: "Driver",
                message: "Driver must be assigned",
                code: "NO_DRIVER_ASSIGNED",
                data: null,
              },
            ],
          },
        ],
      },
      {
        id: 312314,
        name: "AZUSA-2",
        statusOptions: [
          {
            id: 29,
            name: "CLDK",
            isAvailableForTransition: true,
            transitionConstraints: [],
            errorDetails: [],
          },
          {
            id: 30,
            name: "CLDV",
            isAvailableForTransition: true,
            transitionConstraints: [
              "NO_TRAILER_ASSIGNED",
              "NO_DRIVER_ASSIGNED",
              "NO_TRACTOR_ASSIGNED",
            ],
            errorDetails: [],
          },
          {
            id: 32,
            name: "DISP",
            isAvailableForTransition: false,
            transitionConstraints: [],
            errorDetails: [
              {
                subject: "Tractor",
                message: "Tractor must be assigned",
                code: "NO_TRACTOR_ASSIGNED",
                data: null,
              },
              {
                subject: "Trailer",
                message: "Trailer must be assigned",
                code: "NO_TRAILER_ASSIGNED",
                data: null,
              },
              {
                subject: "Driver",
                message: "Driver must be assigned",
                code: "NO_DRIVER_ASSIGNED",
                data: null,
              },
            ],
          },
        ],
      },
    ],
    deliveryTrap: [],
  },
  allTransitionsAllowed: {
    deliveryRoute: [
      {
        id: 30055,
        name: "AZUSA-1",
        statusOptions: [
          {
            id: 29,
            name: "CLDK",
            isAvailableForTransition: true,
            transitionConstraints: [],
            errorDetails: [],
          },
          {
            id: 30,
            name: "CLDV",
            isAvailableForTransition: true,
            transitionConstraints: [
              "NO_TRAILER_ASSIGNED",
              "NO_DRIVER_ASSIGNED",
              "NO_TRACTOR_ASSIGNED",
            ],
            errorDetails: [],
          },
          {
            id: 32,
            name: "DISP",
            isAvailableForTransition: true,
            transitionConstraints: [],
            errorDetails: [
              {
                subject: "Tractor",
                message: "Tractor must be assigned",
                code: "NO_TRACTOR_ASSIGNED",
                data: null,
              },
              {
                subject: "Trailer",
                message: "Trailer must be assigned",
                code: "NO_TRAILER_ASSIGNED",
                data: null,
              },
              {
                subject: "Driver",
                message: "Driver must be assigned",
                code: "NO_DRIVER_ASSIGNED",
                data: null,
              },
            ],
          },
        ],
      },
      {
        id: 312314,
        name: "AZUSA-2",
        statusOptions: [
          {
            id: 29,
            name: "CLDK",
            isAvailableForTransition: true,
            transitionConstraints: [],
            errorDetails: [],
          },
          {
            id: 30,
            name: "CLDV",
            isAvailableForTransition: true,
            transitionConstraints: [
              "NO_TRAILER_ASSIGNED",
              "NO_DRIVER_ASSIGNED",
              "NO_TRACTOR_ASSIGNED",
            ],
            errorDetails: [],
          },
          {
            id: 32,
            name: "DISP",
            isAvailableForTransition: true,
            transitionConstraints: [],
            errorDetails: [
              {
                subject: "Tractor",
                message: "Tractor must be assigned",
                code: "NO_TRACTOR_ASSIGNED",
                data: null,
              },
              {
                subject: "Trailer",
                message: "Trailer must be assigned",
                code: "NO_TRAILER_ASSIGNED",
                data: null,
              },
              {
                subject: "Driver",
                message: "Driver must be assigned",
                code: "NO_DRIVER_ASSIGNED",
                data: null,
              },
            ],
          },
        ],
      },
    ],
    deliveryTrap: [],
  },
} as const satisfies Record<string, DeliveryRouteTrapStatusResponse>;
