import { z } from "zod";
import { Dayjs } from "dayjs";
import {
  shipmentSchema,
  type Shipment,
} from "../services/prePlanningService.types";
import { SortColumn } from "../types/SortColumn.type";
import { SortDirection } from "../types/sortDirection.type";
import { isValidDate } from "../constants/filters/utils";

export const getSortComparator =
  <T>(order: SortDirection, orderBy: SortColumn) =>
  (a: T, b: T) => {
    const result = descendingComparator<T>(a, b, orderBy);
    return order === "desc" ? result : -result;
  };

const descendingComparator = <T>(a: T, b: T, orderBy: SortColumn) => {
  const aColumnValue = determineColValue(a, orderBy);
  const bColumnValue = determineColValue(b, orderBy);

  if (aColumnValue !== null && bColumnValue !== null) {
    if (typeof aColumnValue === "string" && typeof bColumnValue === "string") {
      if (
        bColumnValue.toString().toLowerCase() <
        aColumnValue.toString().toLowerCase()
      ) {
        return -1;
      }
      if (
        bColumnValue.toString().toLowerCase() >
        aColumnValue.toString().toLowerCase()
      ) {
        return 1;
      }
    } else {
      if (bColumnValue < aColumnValue) {
        return -1;
      }
      if (bColumnValue > aColumnValue) {
        return 1;
      }
    }
  }
  return 0;
};

const determineColValue = (data: any, orderBy: keyof any) => {
  switch (orderBy) {
    case "consigneeName": {
      return data.consignee?.name;
    }
    case "consigneeZip": {
      return data.consignee?.zipCode;
    }
    case "consigneeAddress": {
      return data.consignee?.address;
    }
    case "consigneeCity": {
      return data.consignee?.city;
    }
    case "shipperName": {
      return data.shipper.name;
    }
    case "destinationServiceCenterCode": {
      return data.destinationSic.name;
    }
    case "originServiceCenterCode": {
      return data.originSic.name;
    }
    case "zipCodes": {
      return data.zipCodes[0] || 0;
    }
    case "stopSequence": {
      return data.stopSeq;
    }
    case "trailerRefTrailerNumber": {
      return data.trailer?.refTrailerNumber ?? "";
    }
    case "straightTruckRefTrailerNumber": {
      return data.straightTruck?.refTrailerNumber ?? "";
    }
    case "tractorRefTrailerNumber": {
      return data.tractor?.refTrailerNumber ?? "";
    }
    case "routeDriverName": {
      return data.driver?.name ?? "";
    }
    default:
      return data[orderBy];
  }
};

export const sortNumbersAsc = (
  numberA: number | null | undefined,
  numberB: number | null | undefined
) => {
  if (numberA && !numberB) return 1;
  if (numberB && !numberA) return -1;

  const numA = numberA ?? 0;
  const numB = numberB ?? 0;

  return numA < numB ? -1 : numA > numB ? 1 : 0;
};

export const sortNumbersByPriority = (
  primaryNumberA: number | null | undefined,
  secondaryNumberA: number | null | undefined,
  primaryNumberB: number | null | undefined,
  secondaryNumberB: number | null | undefined
) => {
  // Helper function to handle nullish values
  const getValue = (num: number | null | undefined): number => num ?? -Infinity;

  const primaryA = getValue(primaryNumberA);
  const primaryB = getValue(primaryNumberB);

  // First, sort by primary value
  if (primaryA < primaryB) return -1;
  if (primaryA > primaryB) return 1;

  // If primary values are equal, sort by secondary value
  const secondaryA = getValue(secondaryNumberA);
  const secondaryB = getValue(secondaryNumberB);

  if (secondaryA < secondaryB) return -1;
  if (secondaryA > secondaryB) return 1;

  return 0; // If both primary and secondary values are equal, return 0
};

// If an invalid date is provided, fallback to a distant future date so that invalid dates are sorted last.
const distantFuture = new Date(8640000000000000);

export const sortDatesAsc = (rowA: Date | null, rowB: Date | null) => {
  const dateA = new Date(rowA ? (isValidDate(rowA) ? rowA : distantFuture) : 0);
  const dateB = new Date(rowB ? (isValidDate(rowB) ? rowB : distantFuture) : 0);
  return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
};

const sortDatesDesc = (rowA: Date | null, rowB: Date | null) => {
  const dateA = new Date(rowA ?? 0);
  const dateB = new Date(rowB ?? 0);
  return dateA < dateB ? 1 : dateA > dateB ? -1 : 0;
};

export const sortTimesAsc = (rowA?: Date | null, rowB?: Date | null) => {
  if (rowA && !rowB) return 1;
  if (rowB && !rowA) return -1;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, no-type-assertion/no-type-assertion
  const dateA = new Date(rowA!);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, no-type-assertion/no-type-assertion
  const dateB = new Date(rowB!);
  const timeA = new Date(0, 0, 0, dateA.getHours(), dateA.getMinutes());
  const timeB = new Date(0, 0, 0, dateB.getHours(), dateB.getMinutes());

  return timeA < timeB ? -1 : timeA > timeB ? 1 : 0;
};

type ShipmentWithServiceDueAndConsignee = {
  serviceDue?: Date | null;
  consignee?: { name?: string };
};

export type RowWithServiceDueAndConsignee = {
  original: ShipmentWithServiceDueAndConsignee;
};

export const sortShipmentsByServiceDue = (
  a: ShipmentWithServiceDueAndConsignee,
  b: ShipmentWithServiceDueAndConsignee
): 0 | 1 | -1 => {
  const { serviceDue: serviceDueA, consignee: consigneeA } = a;
  const { serviceDue: serviceDueB, consignee: consigneeB } = b;

  // Sort by serviceDue if it exists
  if (serviceDueA && !serviceDueB) return -1;
  if (!serviceDueA && serviceDueB) return 1;

  const sortResult = sortDatesDesc(serviceDueB ?? null, serviceDueA ?? null);

  // If dates are equal, sort by consignee name
  if (sortResult === 0) {
    if (consigneeA?.name && !consigneeB?.name) return -1;
    if (consigneeB?.name && !consigneeA?.name) return 1;

    // eslint-disable-next-line no-type-assertion/no-type-assertion
    return (consigneeA?.name ?? "-").localeCompare(consigneeB?.name ?? "-") as
      | 0
      | 1
      | -1;
  }

  return sortResult;
};

export const sortServiceDue = (
  rowA: RowWithServiceDueAndConsignee,
  rowB: RowWithServiceDueAndConsignee
) => sortShipmentsByServiceDue(rowA.original, rowB.original);

type ShipmentWithPresetRouteName = {
  presetRouteName: string | null;
};

type RowWithPresetRouteName = {
  original: ShipmentWithPresetRouteName;
};

export const sortShipmentsByPresetRoute = (
  a: ShipmentWithPresetRouteName,
  b: ShipmentWithPresetRouteName
) => {
  if (a.presetRouteName === b.presetRouteName) {
    return 0;
  }
  if (a.presetRouteName === null) {
    return 1;
  }
  if (b.presetRouteName === null) {
    return -1;
  }
  return a.presetRouteName.localeCompare(b.presetRouteName);
};

export const sortRowByPresetRoute = (
  rowA: RowWithPresetRouteName,
  rowB: RowWithPresetRouteName
) => sortShipmentsByPresetRoute(rowA.original, rowB.original);

export const sortShipmentsByConsigneeName = (
  a: z.infer<typeof shipmentSchema>,
  b: z.infer<typeof shipmentSchema>
) => {
  if (a.consignee?.name === b.consignee?.name) {
    return 0;
  } else if (a.consignee?.name && b.consignee?.name) {
    return a.consignee.name.localeCompare(b.consignee.name);
  } else if (!a.consignee?.name) {
    return 1;
  } else if (!b.consignee?.name) {
    return -1;
  }

  return 0;
};

export type SomethingWithPlannedStatus = {
  isPlanned: boolean;
  allShipmentsPlanned: boolean;
  someShipmentsPlanned: boolean;
  allShipmentsPlannedForFuture: boolean;
  futurePlanDates: number[];
};

export const sortByPlannedStatus = (
  a: Partial<SomethingWithPlannedStatus>,
  b: Partial<SomethingWithPlannedStatus>
) => {
  // First, sort by 'isPlanned'
  if (a.isPlanned !== b.isPlanned) {
    return (b.isPlanned ? 1 : 0) - (a.isPlanned ? 1 : 0);
  }
  // Then, sort by 'allShipmentsPlanned'
  if (a.allShipmentsPlanned !== b.allShipmentsPlanned) {
    return (b.allShipmentsPlanned ? 1 : 0) - (a.allShipmentsPlanned ? 1 : 0);
  }
  // Sort by future plans 'allShipmentsPlannedForFuture' and 'futurePlanDates'
  if (a.allShipmentsPlannedForFuture !== b.allShipmentsPlannedForFuture) {
    return (
      (b.allShipmentsPlannedForFuture ? 1 : 0) -
      (a.allShipmentsPlannedForFuture ? 1 : 0)
    );
  } else if (a.futurePlanDates && b.futurePlanDates) {
    for (
      let i = 0;
      i < Math.min(a.futurePlanDates.length, b.futurePlanDates.length);
      i++
    ) {
      if (a.futurePlanDates[i] !== b.futurePlanDates[i]) {
        return a.futurePlanDates[i] - b.futurePlanDates[i];
      }
    }
    // If the length is different but their prefix are equal
    if (a.futurePlanDates.length !== b.futurePlanDates.length) {
      return a.futurePlanDates.length - b.futurePlanDates.length;
    }
  }
  // Finally sort by 'someShipmentsPlanned'
  return (b.someShipmentsPlanned ? 1 : 0) - (a.someShipmentsPlanned ? 1 : 0);
};

export const sortShipmentsByAssignment = (
  assignmentA?: string,
  assignmentB?: string
) => {
  if (assignmentA === assignmentB) {
    return 0;
  }
  if (!assignmentA || assignmentA === "none") {
    return 1;
  }
  if (!assignmentB || assignmentB === "none") {
    return -1;
  }
  return assignmentA.localeCompare(assignmentB);
};

export const sortBooleanTrueFirst = (
  boolA?: boolean | null,
  boolB?: boolean | null
): number => {
  if (boolA === boolB) {
    return 0;
  }
  if (boolA === true) {
    return -1;
  }
  if (boolB === true) {
    return 1;
  }
  if (boolA === undefined || boolA === null) {
    return 1;
  }
  if (boolB === undefined || boolB === null) {
    return -1;
  }
  return 0;
};

export const sortConsigneeAddress = (
  addressA?: string,
  addressB?: string
): number => {
  if (!addressA && !addressB) return 0;
  if (!addressA) return 1;
  if (!addressB) return -1;

  const length = Math.min(addressA.length, addressB.length);

  for (let i = 0; i < length; i++) {
    const charCodeA = addressA.charCodeAt(i);
    const charCodeB = addressB.charCodeAt(i);

    if (charCodeA !== charCodeB) {
      return charCodeA - charCodeB;
    }
  }
  return addressA.length - addressB.length;
};

export function sortShipmentsByStopSequence(
  shipmentA: Shipment,
  shipmentB: Shipment
) {
  if (!shipmentA.stopSeq && !shipmentB.stopSeq) {
    return 0;
  }
  if (!shipmentA.stopSeq) return 1;
  if (!shipmentB.stopSeq) return -1;
  return shipmentA.stopSeq - shipmentB.stopSeq;
}

export const sortDayjsComparator = (a?: Dayjs, b?: Dayjs) => {
  if (!a) return -1;
  if (!b) return 1;
  if (a.isBefore(b)) return -1;
  if (a.isAfter(b)) return 1;
  return 0;
};
