import { Chip, Box, styled } from "@mui/material";
import { PropsWithChildren, ReactNode } from "react";

const StyledChip = styled(Chip)(() => ({
  ".MuiChip-label	": {
    padding: 0,
  },
}));

interface TagProps {
  label: string;
  backgroundColor?: string;
  color?: string;
  height?: string;
  fontWeight?: number;
  variant?: "outlined" | "filled";
  borderRadius?: number;
  minWidth?: number;
  arialLabel?: string;
  icon?: ReactNode;
}

export const Tag = ({
  label,
  backgroundColor = "default",
  color = "black",
  height = "24px",
  fontWeight = 600,
  variant = "filled",
  borderRadius = 50,
  minWidth = 48,
  arialLabel = "",
  icon,
}: PropsWithChildren<TagProps>) => (
  <StyledChip
    label={
      icon ? (
        <Box display="flex" alignItems="center">
          {icon}
          {label}
        </Box>
      ) : (
        label
      )
    }
    style={{
      minWidth,
      paddingTop: 1,
      height,
      backgroundColor,
      color,
      fontWeight,
      borderRadius,
    }}
    variant={variant}
    aria-label={arialLabel}
  />
);
