import { SummaryContext } from "../pages/inbound/shared/ActionsMenuContext";

export const addItem = (
  selectedItem: SummaryContext,
  updateState: (shipment: React.SetStateAction<SummaryContext[]>) => void
) => {
  updateState((prev) => [
    ...prev,
    {
      ...selectedItem,
    },
  ]);
};

export const removeFromParent = (
  selectedItem: SummaryContext,
  updateState: (shipment: React.SetStateAction<SummaryContext[]>) => void
) => {
  updateState((prev) =>
    prev
      .map((shipment) => {
        const filteredShipmentIds = shipment.shipmentIds.filter(
          (id) => !selectedItem.shipmentIds.includes(id)
        );
        return {
          ...shipment,
          shipmentIds: filteredShipmentIds,
        };
      })
      .filter((shipment) => shipment.shipmentIds.length > 0)
  );
};

export const addToParent = (
  selectedItem: SummaryContext,
  parent: SummaryContext,
  updateState: (shipment: React.SetStateAction<SummaryContext[]>) => void
) => {
  updateState((prev) =>
    prev.map((item) => {
      if (item.id === parent.id) {
        return {
          ...item,
          shipmentIds: [...item.shipmentIds, ...selectedItem.shipmentIds],
        };
      }
      return item;
    })
  );
};
