import { alpha, Box, styled, Typography } from "@mui/material";
import { MonitorWeight } from "@mui/icons-material";
import {
  Fragment,
  useRef,
  useState,
  type CSSProperties,
  type MouseEvent,
  type RefObject,
} from "react";
import { DropIndicator } from "@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box";
import { createPortal } from "react-dom";
import type { Shipment } from "../../../services/prePlanningService.types";
import StatusTag from "../StatusTag/StatusTag";
import { TagsGrid } from "../TagsGrid";
import PalletsIcon from "../icons/PalletsIcon";
import PiecesIcon from "../icons/PiecesIcon";
import { formatNumber } from "../../../constants/utils";
import { useSelectionContext } from "../../../context/SelectionContext";
import type {
  SummaryContext,
  SummaryContextType,
} from "../../../pages/inbound/shared/ActionsMenuContext";
import DragIcon from "../icons/DragIcon";
import { previewStyles, useDraggable } from "../dragndrop/hooks/useDraggable";
import type { DraggedItem } from "../dragndrop/types";
import { Checkbox } from "../Checkbox";
import {
  getDroppableId,
  parseDroppableId,
  type DragSourceOrDestination,
} from "../../../utils/dragging";
import type { ContextMenuRef } from "../ContextMenuDropdown";
import { ShipmentDragOverlay } from "./ShipmentDragOverlay";

const Wrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "flex-start",
  padding: "0.875rem 1rem 0.875rem 0.5rem",
  "&.idle:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&.dragging": {
    opacity: 0.4,
  },
  "&.over": {
    backgroundColor: `${alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)} !important`,
  },
  "&.selected": {
    backgroundColor: `${theme.palette.action.selected} !important`,
  },
  borderRadius: "0.5rem",
}));

const Summary = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
}));

const TitleWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: theme.palette.grey[600],
}));

const ActionWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "1.25rem",
  maxWidth: "1.25rem",
  maxHeight: "1.25rem",
}));

const Title = styled(Typography)(() => ({
  fontSize: "1rem",
  fontWeight: 500,
  lineHeight: "1.25rem",
}));

const Totals = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "0.75rem",
}));

const Total = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "4px",
  fontSize: "0.875rem",
  lineHeight: "1rem",
  color: theme.palette.grey[600],
}));

const SubText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  fontSize: "0.875rem",
}));

const Assignment = styled(Typography)(() => ({
  fontSize: "1rem",
  fontWeight: 600,
}));

interface ShipmentItemProps {
  index: number;
  isEditingAllowed: boolean;
  shipment: Shipment;
  type: SummaryContextType;
  source?: DragSourceOrDestination;
  contextRef?: RefObject<ContextMenuRef<SummaryContext>>;
  parentId?: number;
  isDraggable?: boolean;
  isSelected?: boolean;
  canReorder?: boolean;
}

export const ShipmentItem = ({
  index,
  isDraggable = false,
  isSelected = false,
  canReorder = false,
  isEditingAllowed,
  source = "sidebar",
  shipment,
  parentId,
  type,
  contextRef,
}: ShipmentItemProps) => {
  const itemRef = useRef<HTMLElement | null>(null);
  const dragHandleRef = useRef<HTMLButtonElement | null>(null);

  const {
    id: shipmentId,
    stopSeq,
    proNumber,
    status,
    isPlanned,
    futurePlanDates,
    tags,
    consignee,
    pallets,
    pieces,
    weight,
    weightUnit,
    assignment,
  } = shipment;

  const { selected, handleSelect } = useSelectionContext();
  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleSelection = (ids: number[]) => {
    if (!isEditingAllowed) return;
    handleSelect({
      id: parentId || shipmentId,
      type,
      shipmentIds: ids.map(Number),
    });
  };

  const handleContext = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (!isEditingAllowed) return;
    if (!isSelected) handleSelection([Number(shipmentId)]);
    contextRef &&
      contextRef.current?.openContextMenu(event, {
        id: parentId || shipmentId,
        type: "shipment",
        shipmentIds: [
          ...new Set([...selected.flatMap((x) => x.shipmentIds), shipmentId]),
        ],
      });
  };

  const data: DraggedItem<Shipment> = {
    id: getDroppableId(source, type, String(shipmentId), String(parentId)),
    item: shipment,
    index: shipment.stopSeq || index,
  };

  const { state, preview, previewElement, closestEdge } = useDraggable({
    id: String(shipmentId),
    index,
    handle: dragHandleRef,
    element: itemRef,
    getInitialData: () => data,
    getData: () => data,
    canDrag: () => isDraggable,
    canDrop: ({ source }) => {
      if (source.element === itemRef.current) return false;
      return parseDroppableId(String(source.data.id)).id !== String(shipmentId);
    },
  });

  return (
    <Fragment>
      <Wrapper
        ref={itemRef}
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
        className={`${state.type} ${isSelected && "selected"}`}
        onContextMenu={handleContext}
      >
        <TitleWrapper>
          <ActionWrapper>
            <DragIcon
              ref={dragHandleRef}
              label={`${proNumber} Drag handle`}
              sx={{
                visibility: `${(isSelected || isMouseOver) && isDraggable ? "visible" : "hidden"}`,
                cursor: "grab",
                fontSize: "24px",
              }}
            />
          </ActionWrapper>
          {isEditingAllowed ? (
            <ActionWrapper>
              <Checkbox
                name={`${shipment.proNumber} checkbox`}
                ariaLabel={
                  isSelected
                    ? `Uncheck ${shipment.proNumber}`
                    : `Check ${shipment.proNumber}`
                }
                checked={isSelected}
                disableRipple
                onClick={() => handleSelection([shipmentId])}
                styles={{
                  width: "100%",
                  maxWidth: "1.25rem",
                  maxHeight: "1.25rem",
                }}
              />
            </ActionWrapper>
          ) : (
            ""
          )}
          {stopSeq ? (
            <ActionWrapper>
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: 700,
                  fontSize: "0.875rem",
                  lineHeight: "20px",
                }}
              >
                {stopSeq}
              </Typography>
            </ActionWrapper>
          ) : (
            ""
          )}
        </TitleWrapper>
        <Box sx={{ width: "100%", marginLeft: "0.25rem" }}>
          <Summary paddingBottom="0.25rem">
            <Title>Pro #{proNumber}</Title>
            <StatusTag
              label={status}
              isPlannedFor={
                isPlanned
                  ? "today"
                  : futurePlanDates.length > 0
                    ? "future"
                    : "notPlanned"
              }
            />
          </Summary>
          <TagsGrid tags={tags} />
          <SubText>{consignee?.name}</SubText>
          <SubText paddingBottom="0.25rem">
            {consignee?.city} {consignee?.zipCode}
          </SubText>
          <Totals>
            <Total>
              <PalletsIcon />
              {formatNumber(pallets)}
            </Total>
            <Total>
              <PiecesIcon />
              {formatNumber(pieces)}
            </Total>
            <Total>
              <MonitorWeight sx={{ fontSize: "1rem" }} />
              {`${formatNumber(weight)} ${weightUnit}`}
            </Total>
          </Totals>
          {type === "shipment" && source !== "unassigned" && (
            <Box marginTop={1}>
              <SubText>Current Assignment</SubText>
              <Assignment aria-label={`${proNumber} assignment`}>
                {assignment}
              </Assignment>
            </Box>
          )}
        </Box>
        {canReorder && closestEdge && <DropIndicator edge={closestEdge} />}
      </Wrapper>
      {preview &&
        createPortal(
          <Box
            ref={previewElement}
            style={previewStyles(preview) as CSSProperties}
          >
            <ShipmentDragOverlay source="sidebar" shipment={shipment} />
          </Box>,
          document.body
        )}
    </Fragment>
  );
};
