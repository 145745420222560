import { useSearchParams } from "react-router-dom";
import FilterMultiselect, {
  FilterProps,
} from "../../../../components/shared/FilterMultiselect";
import { useServiceCenters } from "../../../../hooks/react-query/useServiceCenters";
import { FilterOption } from "./RegionFilter";

export const ORIGIN_FILTER_KEY = "origin";
export const DESTINATION_FILTER_KEY = "dest";
export const REGION_FILTER_KEY = "region";

type ServiceCenterFilterProps = FilterProps & {
  filterKey:
    | typeof ORIGIN_FILTER_KEY
    | typeof DESTINATION_FILTER_KEY
    | typeof REGION_FILTER_KEY;
  options?: FilterOption[];
};

export const ServiceCenterFilter = ({
  filterKey,
  initialOpen = false,
  onRemove,
  options,
}: ServiceCenterFilterProps) => {
  const [search, setSearch] = useSearchParams();

  const activeOriginFilter = search.getAll(filterKey);
  const serviceCenters = useServiceCenters("All");
  function getFilterOptions() {
    if (options) return options.map((o) => ({ code: o.key }));
    return serviceCenters.data ?? [];
  }
  const filterOptions = getFilterOptions();

  const selectedOptions = filterOptions.filter((o) =>
    activeOriginFilter.includes(o.code)
  );

  return (
    <FilterMultiselect
      id={`${filterKey}-filter`}
      label={filterKey === ORIGIN_FILTER_KEY ? "Origin" : "Destination"}
      options={filterOptions}
      value={selectedOptions}
      initialOpen={initialOpen}
      getDisplayValue={(value) => value.code}
      getSelectedDisplayValue={(selected) =>
        selected.length === 0
          ? "All"
          : selected.length === 1
            ? selected[0].code
            : `${selected[0].code} (${selected.length})`
      }
      onChange={(selectedOptions) => {
        setSearch((prev) => {
          prev.delete(filterKey);
          selectedOptions.forEach((o) => prev.append(filterKey, o.code));
          return prev;
        });
      }}
      onRemove={() => {
        setSearch((prev) => {
          prev.delete(filterKey);
          return prev;
        });
        onRemove(filterKey);
      }}
    />
  );
};
