import type { UseMutateAsyncFunction } from "@tanstack/react-query";
import { DASH_PLACEHOLDER } from "../../../constants/dashPlaceholder";
import { EMPTY_ROUTE_OBJECT } from "../../../constants/planning/RouteEmptyObject";
import {
  Consignee,
  Shipment,
} from "../../../services/prePlanningService.types";
import {
  PlanSummary,
  PlanTableFormat,
} from "../../../types/planning/plan.type";
import { Route } from "../../../types/route.type";
import { dashIfEmpty } from "../../../constants/utils";
import { dateToFormat } from "../../../utils/dateTimeHelper";
import { DaylightDateFormat } from "../../../constants/DaylightDateFormat";
import type { DeliveryRouteSequenceStatus } from "../../../hooks/react-query/useDeliveryRoutesSequenceStatus";
import { queryClient } from "../../../queryClientProvider";
import { plannedRoutesQueryKey } from "../../../hooks/react-query/usePlannedRoutes";
import { toast } from "../../../utils/snackbarHelper";
import { toastMessage } from "../../../constants/strings";

const getRouteByRouteId = (routeId: number, allRoutes: Route[]): Route =>
  allRoutes.find((route) => route.id === routeId) ?? EMPTY_ROUTE_OBJECT;

// TODO: All these formatting functions should be replaced by a typed and mapped zod schema to fullfil this format.
export const getFormattedPlanShipments = (
  sicId: number,
  date: number,
  plannedRoutes: PlanSummary[],
  routes: Route[],
  shipments: Shipment[]
): PlanTableFormat[] =>
  plannedRoutes.map((plan) => {
    const shipmentsMap = new Map();

    const plannedShipments = shipments.filter(
      (shipment) =>
        plan.shipmentIds.includes(shipment.id) &&
        (!shipment.routeId || shipment.routeId === plan.id)
    );

    plannedShipments.forEach((shipment) => {
      const previous = shipmentsMap.get(shipment.id);
      shipmentsMap.set(shipment.id, { ...previous, ...shipment });
    });

    const planTableFormat: PlanTableFormat = {
      ...plan,
      bills: plan.shipmentsCount,
      date,
      pallets: plan.shipmentsPallets,
      pieces: plan.shipmentsPieces,
      route: getRouteByRouteId(plan.presetRouteId, routes),
      routeEnd: plan.endPoint,
      routeName: plan.name,
      serviceCenterCode: plan.sic,
      shipments: [...shipmentsMap.values()],
      sicId,
      tags: plan.shipmentsTags,
      weight: plan.shipmentsWeight,
      weightUnit: plan.shipmentsWeightUnit,
      zones: plan.zone ? plan.zone.split(",") : [DASH_PLACEHOLDER],
    };

    return planTableFormat;
  });

export const getFormattedPlannedRoutes = (
  sicId: number,
  date: number,
  plannedRoutes: PlanSummary[],
  routes: Route[],
  shipments: Shipment[],
  searchTerm = "",
  // TODO: Require in Phase 2
  lockedRoutes?: number[]
): PlanTableFormat[] =>
  plannedRoutes
    .map((plan) => {
      const shipmentsMap = new Map();

      // TODO: Get stop sequence and geocode for summary shipments
      const plannedShipments = shipments.filter(
        (shipment) =>
          plan.shipmentIds.includes(shipment.id) &&
          (!shipment.routeId || shipment.routeId === plan.id)
      );

      plannedShipments.forEach((shipment) => {
        const previous = shipmentsMap.get(shipment.id);
        shipmentsMap.set(shipment.id, { ...previous, ...shipment });
      });

      const formattedShipments =
        searchTerm && !plan.hasSearchTerm
          ? [...shipmentsMap.values()].filter((shipment) =>
              filterShipmentBySearchTerm(shipment, searchTerm)
            )
          : [...shipmentsMap.values()];

      const planTableFormat: PlanTableFormat = {
        ...plan,
        bills: plan.shipmentsCount,
        date,
        isCommitted:
          plan.isCommitted ||
          (lockedRoutes ? lockedRoutes.includes(plan.id) : false),
        pallets: plan.shipmentsPallets,
        pieces: plan.shipmentsPieces,
        route: getRouteByRouteId(plan.presetRouteId, routes),
        routeEnd: plan.endPoint,
        routeName: plan.name,
        serviceCenterCode: plan.sic,
        shipments: formattedShipments,
        sicId,
        tags: plan.shipmentsTags,
        weight: plan.shipmentsWeight,
        weightUnit: plan.shipmentsWeightUnit,
        zones: plan.zone ? plan.zone.split(",") : [DASH_PLACEHOLDER],
      };

      return planTableFormat;
    })
    .filter((planItem) =>
      searchTerm
        ? planItem.hasSearchTerm ||
          planItem.shipments.some((s) => s.highlightedRow)
        : true
    );

export const oldGetFormattedPlannedRoutes = (
  sicId: number,
  date: number,
  plannedRoutes: PlanSummary[],
  lockedRoutes: number[],
  routes: Route[],
  shipments: Shipment[],
  searchTerm = ""
): PlanTableFormat[] =>
  plannedRoutes.map((plan) => {
    const shipmentsMap = new Map();

    // TODO: Get stop sequence and geocode for summary shipments
    const plannedShipments = shipments.filter(
      (shipment) =>
        plan.shipmentIds.includes(shipment.id) &&
        (!shipment.routeId || shipment.routeId === plan.id)
    );

    plannedShipments.forEach((shipment) => {
      const previous = shipmentsMap.get(shipment.id);
      shipmentsMap.set(shipment.id, { ...previous, ...shipment });
    });

    const formattedShipments = [...shipmentsMap.values()];

    const planTableFormat: PlanTableFormat = {
      ...plan,
      bills: plan.shipmentsCount,
      date,
      isCommitted: plan.isCommitted || lockedRoutes.includes(plan.id),
      pallets: plan.shipmentsPallets,
      pieces: plan.shipmentsPieces,
      route: getRouteByRouteId(plan.presetRouteId, routes),
      routeEnd: plan.endPoint,
      routeName: plan.name,
      serviceCenterCode: plan.sic,
      shipments: searchTerm
        ? formattedShipments.filter(
            (s) =>
              s.highlightedRow ||
              (plan.name &&
                plan.name
                  .toString()
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase()))
          )
        : formattedShipments,
      sicId,
      tags: plan.shipmentsTags,
      weight: plan.shipmentsWeight,
      weightUnit: plan.shipmentsWeightUnit,
      zones: plan.zone ? plan.zone.split(",") : [DASH_PLACEHOLDER],
    };

    return planTableFormat;
  });

export const getConsigneeAddress = (consignee: Consignee | null): string => {
  if (!consignee) return DASH_PLACEHOLDER;

  const address = dashIfEmpty(consignee.address);
  const city = dashIfEmpty(consignee.city);
  const state = dashIfEmpty(consignee.state);
  const zipCode = dashIfEmpty(consignee.zipCode);

  return `${address}, ${city}, ${state} ${zipCode}`;
};

export const filterShipmentsBySearchTerm = (
  shipment: Shipment,
  searchTerm: string,
  timeZone?: string
): boolean =>
  shipment.proNumber.toLowerCase().includes(searchTerm) ||
  dateToFormat(
    shipment.serviceDueDate,
    DaylightDateFormat.DATE,
    timeZone
  ).includes(searchTerm) ||
  shipment.shipper?.name?.toLowerCase().includes(searchTerm) ||
  shipment.consignee?.name?.toLowerCase().includes(searchTerm) ||
  shipment.consignee?.address?.toLowerCase().includes(searchTerm) ||
  shipment.consignee?.city?.toLowerCase().includes(searchTerm) ||
  shipment.consignee?.zipCode?.toLowerCase().includes(searchTerm) ||
  shipment.destinationSicCode?.toLowerCase().includes(searchTerm) ||
  shipment.pallets.toString().toLowerCase().includes(searchTerm) ||
  shipment.pieces.toString().toLowerCase().includes(searchTerm) ||
  shipment.weight.toString().toLowerCase().includes(searchTerm) ||
  shipment.tags.some((tag) => tag.name.toLowerCase().includes(searchTerm)) ||
  shipment.status.toLowerCase().includes(searchTerm) ||
  shipment.tripNumber?.toString().toLowerCase().includes(searchTerm) ||
  dateToFormat(
    shipment.etaDate,
    DaylightDateFormat.DATE_TIME_12,
    timeZone
  ).includes(searchTerm) ||
  false;

export const filterPlanSummaryBySearchTerm = (
  planSummary: PlanSummary,
  timeZoneId?: string,
  searchTerm?: string
): PlanSummary => {
  if (!searchTerm) {
    return planSummary;
  }

  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  return {
    ...planSummary,
    hasSearchTerm:
      planSummary.name.toLowerCase().includes(lowerCaseSearchTerm) ||
      planSummary.zone.toLowerCase().includes(lowerCaseSearchTerm) ||
      (planSummary.type === "delivery_route" &&
        dateToFormat(
          planSummary.startTime,
          DaylightDateFormat.MILITARY_TIME,
          timeZoneId
        ).includes(lowerCaseSearchTerm)) ||
      planSummary.driver?.name?.toLowerCase().includes(lowerCaseSearchTerm) ||
      planSummary.tractor?.name?.toLowerCase().includes(lowerCaseSearchTerm) ||
      planSummary.trailer?.name?.toLowerCase().includes(lowerCaseSearchTerm) ||
      planSummary.straightTruck?.name
        ?.toLowerCase()
        .includes(lowerCaseSearchTerm) ||
      planSummary.shipmentsCount.toString().includes(lowerCaseSearchTerm) ||
      planSummary.shipmentsPallets.toString().includes(lowerCaseSearchTerm) ||
      planSummary.shipmentsWeight.toString().includes(lowerCaseSearchTerm) ||
      planSummary.shipmentsTags.some((tag) =>
        tag.name.toLowerCase().includes(lowerCaseSearchTerm)
      ) ||
      false,
  };
};

export const filterPlanTableFormatBySearchTerm = (
  plans: PlanTableFormat[],
  timeZoneId?: string,
  searchTerm?: string
): PlanTableFormat[] => {
  if (!searchTerm) {
    return plans;
  }

  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  return plans.filter(
    (plan) =>
      plan.name.toLowerCase().includes(lowerCaseSearchTerm) ||
      plan.zones.includes(lowerCaseSearchTerm) ||
      (plan.type === "delivery_route" &&
        dateToFormat(
          plan.startTime,
          DaylightDateFormat.MILITARY_TIME,
          timeZoneId
        ).includes(lowerCaseSearchTerm)) ||
      plan.driver?.name?.toLowerCase().includes(lowerCaseSearchTerm) ||
      plan.tractor?.name?.toLowerCase().includes(lowerCaseSearchTerm) ||
      plan.trailer?.name?.toLowerCase().includes(lowerCaseSearchTerm) ||
      plan.straightTruck?.name?.toLowerCase().includes(lowerCaseSearchTerm) ||
      plan.shipments.some((shipment) =>
        shipment.proNumber.toLowerCase().includes(lowerCaseSearchTerm)
      ) ||
      plan.shipments.some((shipment) =>
        shipment.consignee?.name?.toLowerCase().includes(lowerCaseSearchTerm)
      ) ||
      plan.shipments.some((shipment) =>
        shipment.consignee?.city?.toLowerCase().includes(lowerCaseSearchTerm)
      ) ||
      plan.shipments.some((shipment) =>
        shipment.consignee?.zipCode?.toLowerCase().includes(lowerCaseSearchTerm)
      ) ||
      plan.shipments.some((shipment) =>
        shipment.shipper?.name?.toLowerCase().includes(lowerCaseSearchTerm)
      )
  );
};

export const filterShipmentBySearchTerm = (
  shipment: Shipment,
  searchTerm: string
): boolean => {
  if (!searchTerm) return true;
  return (
    shipment.proNumber.includes(searchTerm) ||
    shipment.consignee?.name?.toLowerCase().includes(searchTerm) ||
    shipment.shipper?.name?.toLowerCase().includes(searchTerm) ||
    false
  );
};

export const onAutoSequenceRoutesFinished = (
  planDate: number,
  sicId: number,
  sequenceResult: DeliveryRouteSequenceStatus[],
  retryMutation: UseMutateAsyncFunction<number[], Error, number[], unknown>,
  searchTerm?: string,
  clearSelection?: () => void
) => {
  queryClient.setQueryData<PlanTableFormat[]>(
    plannedRoutesQueryKey(planDate, sicId, searchTerm),
    (previousStateOfPlans) => {
      if (!previousStateOfPlans) return [];

      // Map over the previous state to create a new array
      return previousStateOfPlans.map((plan) => {
        // Find the matching sequence status
        const sequence = sequenceResult.find((seq) => seq.id === plan.id);

        // If there's a matching sequence status, update the plan accordingly
        if (sequence) {
          if (sequence.sequenceStatus === "error") {
            return {
              ...plan,
              error: {
                message: "Auto-Sequence Failed",
                onRetry: () => retryMutation([plan.id]),
                isRetryLoading: false,
              },
            };
          }
          if (sequence.sequenceStatus === "processed") {
            return {
              ...plan,
              sequenceStatus: "processed",
            };
          }
        }
        // Return the plan as-is if no matching sequence status
        return plan;
      });
    }
  );
  if (sequenceResult.every((route) => route.sequenceStatus === "processed")) {
    toast(toastMessage.inbound.autoSequencing.success(sequenceResult.length), {
      variant: "success",
      preventDuplicate: true,
    });
  } else if (
    sequenceResult.every((route) => route.sequenceStatus === "error")
  ) {
    toast(toastMessage.inbound.autoSequencing.failed(sequenceResult.length), {
      variant: "error",
      preventDuplicate: true,
    });
  } else {
    const routesWithErrors = sequenceResult.filter(
      (promise) => promise.sequenceStatus === "error"
    );
    toast(
      toastMessage.inbound.autoSequencing.someFailed(
        sequenceResult.length - routesWithErrors.length,
        routesWithErrors.length
      ),
      {
        variant: "warning",
        preventDuplicate: true,
      }
    );
  }
  clearSelection && clearSelection();
};
