import { IndeterminateCheckBox } from "@mui/icons-material";
import { Checkbox as MuiCheckbox, type SxProps } from "@mui/material";
import { ChangeEvent, MouseEventHandler } from "react";

interface CheckboxProps {
  checked?: boolean;
  name: string;
  indeterminate?: boolean;
  disabled?: boolean;
  onChange?:
    | ((event: ChangeEvent<HTMLInputElement>, checked: boolean) => void)
    | undefined;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  ariaLabel?: string;
  disableRipple?: boolean;
  styles?: SxProps;
}
export function Checkbox({
  checked,
  indeterminate,
  disabled,
  onChange,
  onClick,
  name,
  ariaLabel,
  disableRipple,
  styles,
}: CheckboxProps) {
  return (
    <MuiCheckbox
      {...{
        checked,
        indeterminate,
        onChange,
        onClick,
        disabled,
        disableRipple,
        indeterminateIcon: <IndeterminateCheckBox />,
        sx: {
          position: "relative",
          zIndex: 1,
          padding: 0,
          ...styles,
        },
        name,
        inputProps: { "aria-label": ariaLabel },
      }}
    />
  );
}
