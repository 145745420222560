import { memo, MouseEvent, useRef, useState } from "react";
import { AccordionDetails, Box, IconButton, useTheme } from "@mui/material";
import {
  Article as CountIcon,
  CalendarViewMonth as PalletsIcon,
  ViewQuilt as PiecesIcon,
  ArrowDropDown as ArrowDropDownIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { Droppable, DroppableProvided } from "@hello-pangea/dnd";
import {
  Accordion,
  AccordionSummary,
} from "../../../../components/shared/layout/Accordion";
import { WeightUnit } from "../../../../services/prePlanningService.types";
import { useSelectionContext } from "../../../../context/SelectionContext";
import {
  SummaryContext,
  SummaryContextType,
  useActionsMenuContext,
} from "../ActionsMenuContext";
import { PrePlanningSection } from "../../../../types/inbound/prePlanning/sections.type";
import { FlexRow } from "../../../../components/shared/layout/Flex";
import ContextMenuDropdown, {
  ContextMenuRef,
} from "../../../../components/shared/ContextMenuDropdown";
import { contextMenuActions } from "../../prePlanning/tables/contextMenuActions";
import Loading from "../../../../components/shared/layout/Loading";
import {
  getDroppableId,
  getDroppableStyleCard,
} from "../../../../utils/dragging";
import { useDeleteTrapById } from "../../../../hooks/react-query/preplanning/useTraps";
import { toast } from "../../../../utils/snackbarHelper";
import { toastMessage } from "../../../../constants/strings";
import {
  ParentRecords,
  useValidateMergeRequest,
} from "../../../../hooks/useValidateMergeRequest";
import {
  SortShipmentsBy,
  useShipmentDetailSearch,
} from "../../../../hooks/react-query/preplanning/useShipmentDetails";
import { dateToInt } from "../../../../utils/dateTimeHelper";
import ShipmentTypography from "./ShipmentTypography";
import ShipmentItem from "./ShipmentItem";

export interface CollapsibleSectionItemProps {
  id: number | string;
  title: string;
  name: string | null;
  shipmentIds: number[];
  shipmentsCount: number;
  shipmentsPallets: number;
  shipmentsPieces: number;
  shipmentsWeight: number;
  shipmentsWeightUnit: WeightUnit;
  section: PrePlanningSection;
  isDropDisabled: boolean;
  isAvailableForMassage: boolean;
  planDate: string;
  serviceCenterId: number;
  parentRecords?: ParentRecords;
  isEditingAllowed?: boolean;
  isNew?: boolean;
  sortBy?: SortShipmentsBy;
}

type AccordionHeaderProps = {
  id: number;
  title: string;
  name: string | null;
  shipmentIds: number[];
  shipmentsCount: number;
  shipmentsPallets: number;
  shipmentsPieces: number;
  shipmentsWeight: number;
  shipmentsWeightUnit: WeightUnit;
  isNew?: boolean;
  isSelected: boolean;
  expanded: boolean;
  section: PrePlanningSection;
  droppableProvided?: DroppableProvided;
  isEditingAllowed: boolean;
  serviceCenterId: number;
  onExpand: (expanded: boolean) => void;
  onSelected: (shipmentIds: number[]) => void;
  onRightClick: (event: MouseEvent<HTMLElement>) => void;
};

const AccordionHeader = ({
  id,
  title,
  name,
  shipmentIds,
  shipmentsCount,
  shipmentsPallets,
  shipmentsPieces,
  shipmentsWeight,
  shipmentsWeightUnit,
  isNew,
  onSelected,
  onRightClick,
  isSelected,
  onExpand,
  expanded,
  section,
  isEditingAllowed,
  serviceCenterId,
}: AccordionHeaderProps) => {
  const theme = useTheme();
  const sxAnimation = isNew
    ? {
        "@keyframes blink": {
          to: {
            color: "black",
            backgroundColor: "green",
          },
        },
        animation: "blink 500ms ease-in-out",
      }
    : { backgroundColor: isSelected ? theme.palette.action.selected : "white" };

  const deleteEmptyTrap = useDeleteTrapById(id, serviceCenterId, () => {
    toast(toastMessage.inbound.deleteEmptyTrap.success(title, name), {
      variant: "success",
    });
  });

  const handleToggleAccordion = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation();
    onExpand(!expanded);
  };

  const handleDeleteEmptyTrap = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation();
    deleteEmptyTrap.mutate();
  };

  // The delete action icon should ONLY apply to empty traps
  const shouldShowArrowDownIcon = shipmentIds.length > 0;
  const shouldShowDeleteTrapIcon =
    isEditingAllowed && section === "traps" && shipmentIds.length === 0;

  const actionIcon = (
    <IconButton
      sx={{ height: "100%" }}
      aria-label={
        shouldShowDeleteTrapIcon
          ? `Delete ${title}`
          : expanded
            ? "Collapse accordion"
            : "Expand accordion"
      }
      onClick={(event) =>
        shouldShowDeleteTrapIcon
          ? handleDeleteEmptyTrap(event)
          : handleToggleAccordion(event)
      }
    >
      {shouldShowDeleteTrapIcon && <DeleteIcon />}
      {shouldShowArrowDownIcon && <ArrowDropDownIcon />}
    </IconButton>
  );

  return (
    <AccordionSummary
      aria-controls="panel1d-content"
      id="panel1d-header"
      sx={sxAnimation}
      onClick={() => onSelected(shipmentIds)}
      onContextMenu={onRightClick}
      expandIcon={actionIcon}
    >
      {deleteEmptyTrap.isPending ? (
        <FlexRow style={{ justifyContent: "center", padding: "16px" }}>
          <Loading />
        </FlexRow>
      ) : (
        <Box>
          <ShipmentTypography sx={{ fontWeight: "bold" }} display="inline">
            {title}
          </ShipmentTypography>
          {name && (
            <ShipmentTypography sx={{ m: 1 }} display="inline">
              ({name})
            </ShipmentTypography>
          )}
          <Box display="flex" alignItems="center" gap={2}>
            <Box display="flex" alignItems="center">
              <CountIcon sx={{ margin: "10" }} />
              <ShipmentTypography>
                {shipmentsCount.toLocaleString()}
              </ShipmentTypography>
            </Box>
            <Box display="flex" alignItems="center">
              <PalletsIcon />
              <ShipmentTypography>
                {shipmentsPallets.toLocaleString()}
              </ShipmentTypography>
            </Box>
            <Box display="flex" alignItems="center">
              <PiecesIcon />
              <ShipmentTypography>
                {shipmentsPieces.toLocaleString()}
              </ShipmentTypography>
            </Box>
            <Box display="flex" alignItems="center">
              <ShipmentTypography>
                {shipmentsWeight.toLocaleString()} {shipmentsWeightUnit}
              </ShipmentTypography>
            </Box>
          </Box>
        </Box>
      )}
    </AccordionSummary>
  );
};

const CollapsibleSectionItem = ({
  id,
  title,
  name,
  shipmentIds,
  shipmentsCount,
  shipmentsPallets,
  shipmentsPieces,
  shipmentsWeight,
  shipmentsWeightUnit,
  serviceCenterId,
  planDate,
  section = "appointments",
  isDropDisabled,
  isEditingAllowed = false,
  parentRecords,
  isNew,
  isAvailableForMassage,
  sortBy,
}: CollapsibleSectionItemProps) => {
  const contextMenuRef = useRef<ContextMenuRef<any>>(null);
  const { openDialog } = useActionsMenuContext();

  const { selected, handleSelect, isSelected, isAllSelected, clearSelection } =
    useSelectionContext();

  const { validateMergeRequest } = useValidateMergeRequest(
    selected,
    parentRecords ?? []
  );

  const [isExpanded, setIsExpanded] = useState(false);
  const [contextMenuShipment, setContextMenuShipment] =
    useState<SummaryContext | null>();

  const { data: shipments = [], isLoading } = useShipmentDetailSearch({
    search: {
      sicId: serviceCenterId,
      planDate: dateToInt(planDate),
      shipmentIds,
    },
    enabled: isExpanded,
    sortBy,
  });

  function getType(): SummaryContextType {
    switch (section) {
      case "appointments":
        return "bucket";
      case "sections":
        return "section";
      case "routes":
        return "route";
      case "traps":
        return "trap";
      default:
        return "bucket";
    }
  }

  const type = getType();

  const handleSelection = (ids: number[]) => {
    if (!isEditingAllowed) return;
    if (contextMenuShipment) {
      setContextMenuShipment(null);
    }
    handleSelect({
      id,
      type,
      shipmentIds: ids.map(Number),
    });
  };

  const handleRightClick = (
    ev: MouseEvent<HTMLElement>,
    shipmentIds: number[]
  ) => {
    ev.stopPropagation();
    contextMenuRef.current?.openContextMenu(ev);
    if (selected.length === 0) {
      setContextMenuShipment({
        id,
        type,
        shipmentIds,
      });
    }
  };

  const contextMenuShipments = contextMenuShipment
    ? [contextMenuShipment]
    : selected.length
      ? selected
      : [];

  return (
    <>
      <Droppable
        isDropDisabled={isDropDisabled || !isEditingAllowed}
        droppableId={getDroppableId("sidebar", type, id)}
      >
        {(droppableProvided, snapshot) => (
          <Box
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
            sx={{
              ...getDroppableStyleCard(snapshot.isDraggingOver),
            }}
          >
            <Accordion expanded={isExpanded}>
              <AccordionHeader
                id={Number(id)}
                title={title}
                name={name}
                shipmentIds={shipmentIds}
                shipmentsCount={shipmentsCount}
                shipmentsPallets={shipmentsPallets}
                shipmentsPieces={shipmentsPieces}
                shipmentsWeight={shipmentsWeight}
                shipmentsWeightUnit={shipmentsWeightUnit}
                isNew={isNew}
                onSelected={handleSelection}
                onRightClick={(ev) => handleRightClick(ev, shipmentIds)}
                isSelected={isAllSelected(shipmentIds, type)}
                onExpand={(value) => setIsExpanded(value)}
                expanded={isExpanded}
                section={section}
                droppableProvided={droppableProvided}
                isEditingAllowed={isEditingAllowed}
                serviceCenterId={serviceCenterId}
              />
              <AccordionDetails sx={{ padding: 0 }}>
                {isLoading ? (
                  <FlexRow
                    style={{ justifyContent: "center", padding: "16px" }}
                  >
                    <Loading label={`sidebar ${section}`} />
                  </FlexRow>
                ) : (
                  <>
                    {shipments.map((shipment, index) => (
                      <ShipmentItem
                        planDate={planDate}
                        index={index}
                        section={section}
                        key={shipment.id}
                        item={shipment}
                        isSelected={isSelected(Number(shipment.id), type)}
                        isDraggable={
                          isEditingAllowed &&
                          type !== "bucket" &&
                          isAvailableForMassage
                        }
                        onSelected={handleSelection}
                        onRightClick={(ev, shipmentId) =>
                          handleRightClick(ev, shipmentId)
                        }
                      />
                    ))}
                    {droppableProvided.placeholder}
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      </Droppable>
      {isEditingAllowed && (
        <ContextMenuDropdown
          ref={contextMenuRef}
          actions={() =>
            contextMenuActions({
              shipmentContext: contextMenuShipments,
              section,
              openDialog,
              clearSelection,
              validateMergeRequest,
            })
          }
        />
      )}
    </>
  );
};

export default memo(CollapsibleSectionItem);
