import dayjs from "dayjs";
import {
  loadAndHoldReport,
  TripsReport,
  UrgentCareReport,
} from "../../hooks/react-query/linehaul/useReports";

export const mockTripsReport = {
  default: [
    {
      tripNumber: 3070408,
      bookingNumber: "2050636239",
      refTrailer: "TA208143",
      mixWithCodes: [],
      originSicId: 111,
      destinationSicId: 5,
      destinationSicCode: "BOS",
      pickupDate: dayjs("2024-11-26T00:00:00"),
      carrierCode: "L004",
      carrierName: "AVILENO TRUCKING LLC",
      tagNames: ["UC", "APPT"],
      reportNote: {
        note: "Test Note",
        firstName: "Test",
        lastName: "User",
        createdOn: dayjs(),
      },
      priorityNumber: 0,
      employeeDrivers: [],
    },
    {
      tripNumber: 3070412,
      bookingNumber: "2050636279",
      refTrailer: "TA201143",
      mixWithCodes: [],
      originSicId: 121,
      destinationSicId: 5,
      destinationSicCode: "BOS",
      pickupDate: dayjs("2024-11-26T00:00:00"),
      carrierCode: "L004",
      carrierName: "AVILENO TRUCKING LLC",
      tagNames: ["UC", "APPT"],
      reportNote: null,
      priorityNumber: 0,
      employeeDrivers: [],
    },
  ],
} satisfies Record<string, TripsReport[]>;

export const mockLoadAndHoldReport = {
  default: [
    {
      tripNumber: 7362543,
      bookingNumber: "2050636239",
      refTrailer: "TA208143",
      mixWithCodes: [],
      originSicId: 111,
      destinationSicId: 5,
      destinationSicCode: "SAT",
      priorityNumber: 1,
      carrierName: "SCHNEIDER NATIONAL CARRIERS INC.",
      carrierCode: "L021",
      employeeDrivers: [],
      reportNote: null,
      tagNames: [],
      pickupDate: dayjs(),
    },
    {
      tripNumber: 1298762,
      bookingNumber: "2050636240",
      refTrailer: "TA208141",
      mixWithCodes: [],
      originSicId: 112,
      destinationSicId: 5,
      destinationSicCode: "SAT",
      priorityNumber: 1,
      carrierName: "SCHNEIDER NATIONAL CARRIERS INC.",
      carrierCode: "L021",
      employeeDrivers: [],
      reportNote: {
        note: "Test Note",
        firstName: "Test",
        lastName: "User",
        createdOn: dayjs(),
      },
      tagNames: [],
      pickupDate: dayjs(),
    },
  ],
} satisfies Record<string, loadAndHoldReport[]>;

export const mockUrgentCareReport = {
  default: [
    {
      proNumber: "34456432",
      bookingNumber: "1234",
      lhTripNumber: "1234",
      obTripNumber: 1234,
      pickupDateUtc: dayjs(),
      dueServiceTimestampUtc: dayjs(),
      pcs: 1,
      plts: 1,
      weight: 1,
      weightUnit: "lbs",
      originSicCode: "1234",
      destinationSicCode: "1234",
      currentLocationSicCode: "1234",
      shipmentTags: ["UC"],
      loadedDate: dayjs(),
      statusCode: "ACTIVE",
      shipperName: "1234",
      shipperCity: "1234",
      conCity: "1234",
      conState: "1234",
      updatedBy: "1234",
      updatedOn: dayjs(),
      carrierEmployee: "Driver 1",
    },
    {
      proNumber: "99769686",
      bookingNumber: "1235",
      lhTripNumber: "1235",
      obTripNumber: 1235,
      pickupDateUtc: dayjs(),
      dueServiceTimestampUtc: dayjs(),
      pcs: 1,
      plts: 1,
      weight: 1,
      weightUnit: "lbs",
      originSicCode: "1235",
      destinationSicCode: "1235",
      currentLocationSicCode: "1235",
      shipmentTags: ["UC", "APPT"],
      loadedDate: dayjs(),
      statusCode: "ACTIVE",
      shipperName: "1235",
      shipperCity: "1235",
      conCity: "1235",
      conState: "1235",
      updatedBy: "1235",
      updatedOn: dayjs(),
      carrierEmployee: "Driver 1",
    },
  ],
} satisfies Record<string, UrgentCareReport[]>;

/* const mockVendorComplianceReport = { //TODO: use for future PW tests
  default: [
    {
      proNumber: "ABC123",
      pickupDateUtc: dayjs(),
      dueServiceTimestampUtc: dayjs(),
      pieces: 10,
      weight: 250,
      weightUnit: "lbs",
      originSicCode: "ORIG123",
      destinationSicCode: "DEST456",
      tags: ["UC"],
      shipperName: "Best Shipper Inc.",
      shipperCity: "Seattle",
      consigneeName: "Acme Distribution",
    },
    {
      proNumber: "XYZ789",
      pickupDateUtc: dayjs(),
      dueServiceTimestampUtc: null,
      pieces: 5,
      weight: 100,
      weightUnit: "kg",
      originSicCode: "ORIG999",
      destinationSicCode: "DEST888",
      tags: ["APPT", "UC"],
      shipperName: "Another Shipper",
      shipperCity: "Denver",
      consigneeName: "Some Consignee LLC",
    },
  ],
} satisfies Record<string, VendorComplianceReport[]>; */
