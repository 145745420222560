import { z } from "zod";
import dayjs from "dayjs";
import { shiftSchema } from "./shift.type";

export const driversLicenseCategories = [
  "Class A",
  "Class B",
  "Class C",
  "Class D",
  "Class M",
  "Commercial Driver's License (CDL)",
  "Learner's Permit",
  "Probationary License",
  "Chauffeur's License",
  "Enhanced Driver's License (EDL)",
] as const;
export const driversLicenseCategorySchema = z.enum(driversLicenseCategories);

export const driverTypes = ["Employee", "Contractor", "Heavy Duty"] as const;
export const driverTypeSchema = z.enum(driverTypes);

export const driverSchema = z
  .object({
    id: z.number(),
    code: z.string(),
    name: z.string().nullable(),
    refCode: z.string().optional(),
    inService: z.boolean().default(false),
    isAvailable: z.boolean().default(false),
    phoneNumber: z.string().nullish().default(""),
    driversLicense: z.string().nullish().default(""),
    driversLicenseCategory: driversLicenseCategorySchema.default("Class A"),
    driversLicenseExpirationDate: z
      .date()
      .optional()
      .default(dayjs("0001-01-01T00:00:00").toDate()),
    driversLicenseExpiringInDays: z.number().optional().default(-1),
    driverType: driverTypeSchema.default("Employee"),
    inspectionDate: z
      .date()
      .optional()
      .default(dayjs("0001-01-01T00:00:00").toDate()),
    equipmentId: z.number().nullable(),
    medCard: z.string().nullish().default(""),
    medCardExpirationDate: z
      .date()
      .optional()
      .default(dayjs("0001-01-01T00:00:00").toDate()),
    medCardExpiringInDays: z.number().optional().default(-1),
    notes: z.string().nullish().default(""),
    shifts: z.array(shiftSchema),
    routeReady: z.boolean().default(false),
    routeChange: z.boolean().default(false),
    currentSicId: z.number(),
    homeSicId: z.number(),
  })
  .transform((data) => ({
    ...data,
    name: data.name || "",
  }));

export const driversSchema = z.array(driverSchema);

export type Driver = z.infer<typeof driverSchema>;
