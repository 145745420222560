// CSpell:ignore cbll
import { Link } from "react-router-dom";
import { Box, Tooltip, styled } from "@mui/material";
import { Warning } from "@mui/icons-material";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { shipmentTagsColumn } from "../../../../utils/tableColumns/commons";
import {
  sortServiceDue,
  sortDatesAsc,
  sortNumbersAsc,
  sortConsigneeAddress,
} from "../../../../utils/sortComparator";
import {
  formatAppointmentDateTableField,
  formatEtaTableField,
  formatServiceDueTableField,
} from "../../../../utils/tableFormat";
import StatusTag from "../../../../components/shared/StatusTag/StatusTag";
import { dashIfEmpty, formatNumber } from "../../../../constants/utils";
import { TableShipment } from "../../../../components/shared/old-table/ShipmentsTableBase";
import { AssignShipmentType } from "../../../../types/assignShipment.type";
import { PlanTableFormat } from "../../../../types/planning/plan.type";
import { PlanShipmentsMenuIconButton } from "../PlanningContextMenu/PlanShipmentsMenuIconButton";

import PrintShipment from "./PrintShipment";

const CustomLink = styled(Link)({
  display: "flex",
  alignItems: "center",
  gap: "4px",
});

const getGoogleMapsUrl = (
  shipment: TableShipment,
  parentType?: AssignShipmentType
) => {
  const { consignee } = shipment;
  const address = `${consignee?.address}, ${consignee?.city}, ${consignee?.zipCode}, ${consignee?.state}`;

  // t=k for Satellite view
  const googleMapsUrl = `http://maps.google.com/maps?q=${address}&t=k`;
  const hasLatAndLng = !!(shipment.lat && shipment.lng);
  const showWarning = parentType === "route" && !hasLatAndLng;
  const tooltip = showWarning ? "Invalid address" : "Google Maps Url";
  const ariaLabel = showWarning
    ? "Invalid geocode. Verify address."
    : `Google Maps Url for address: ${address}`;

  return (
    <Tooltip title={tooltip} aria-label={ariaLabel}>
      <CustomLink
        to={googleMapsUrl}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          color: (theme) =>
            showWarning ? theme.palette.warning.main : undefined,
        }}
      >
        {showWarning && <Warning color="warning" />}
        {dashIfEmpty(consignee?.address)}
      </CustomLink>
    </Tooltip>
  );
};

type PlanShipmentColumnsProps = {
  isEditable: boolean;
  isLoading: boolean;
  timeZone: string;
  routeOrTrap?: PlanTableFormat;
  parentType?: AssignShipmentType;
};

const planShipmentColumns = ({
  isEditable,
  isLoading,
  routeOrTrap,
  timeZone,
  parentType,
}: PlanShipmentColumnsProps): ColumnDef<TableShipment>[] => {
  const planShipmentColumns: ColumnDef<TableShipment>[] = [
    {
      accessorKey: "proNumber",
      header: "PRO",
      accessorFn: ({ proNumber }) => dashIfEmpty(proNumber),
    },
    {
      accessorKey: "serviceDue",
      header: "Service Due",
      accessorFn: ({ serviceDue }) => formatServiceDueTableField(serviceDue),
      sortingFn: sortServiceDue,
    },
    {
      accessorKey: "shipperName",
      accessorFn: ({ shipper }) => dashIfEmpty(shipper?.name),
      header: "Shipper",
    },
    {
      accessorKey: "consigneeName",
      header: "Consignee Name",
      accessorFn: ({ consignee }) => dashIfEmpty(consignee?.name),
    },
    {
      accessorKey: "consigneeAddress",
      header: "Consignee Address",
      cell: ({ row }) => getGoogleMapsUrl(row.original, parentType),
      sortingFn: (rowA, rowB) =>
        sortConsigneeAddress(
          rowA.original.consignee?.address,
          rowB.original.consignee?.address
        ),
    },
    {
      header: "Con. City/Zip",
      accessorFn: ({ consignee }) =>
        `${dashIfEmpty(consignee?.city)} ${dashIfEmpty(consignee?.zipCode)}`,
    },
    {
      accessorKey: "destinationSicCode",
      header: "Dest SIC",
      accessorFn: ({ destinationSicCode }) => dashIfEmpty(destinationSicCode),
      sortDescFirst: false,
    },
    {
      header: "Appointment",
      accessorFn: ({ appointmentDate }) =>
        formatAppointmentDateTableField(appointmentDate, timeZone),
      sortingFn: (rowA, rowB) =>
        sortDatesAsc(
          rowA.original.appointmentDate,
          rowB.original.appointmentDate
        ),
    },
    {
      accessorKey: "pltsPcs",
      header: "Plts | Pcs",
      accessorFn: ({ pallets, pieces }) =>
        `${formatNumber(pallets)} | ${formatNumber(pieces)}`,
      sortingFn: (rowA, rowB) =>
        sortNumbersAsc(rowA.original.pallets, rowB.original.pallets),
    },
    {
      accessorKey: "weight",
      header: "Weight",
      accessorFn: ({ weight, weightUnit }) =>
        `${formatNumber(weight)} ${weightUnit}`,
      sortingFn: (rowA, rowB) =>
        sortNumbersAsc(rowA.original.weight, rowB.original.weight),
    },
    shipmentTagsColumn("tags"),
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ getValue, row }: CellContext<TableShipment, unknown>) => (
        <StatusTag
          label={getValue<string>()}
          isPlannedFor={
            row.original.isPlanned
              ? row.original.futurePlanDates.length > 0
                ? "future"
                : "today"
              : "notPlanned"
          }
        />
      ),
    },
    {
      accessorKey: "location",
      header: "Trailer #",
      accessorFn: ({ location }) => dashIfEmpty(location),
    },
    {
      accessorKey: "eta",
      header: "ETA",
      accessorFn: ({ etaDate }) => formatEtaTableField(etaDate, timeZone),
      sortingFn: (rowA, rowB) =>
        sortDatesAsc(rowA.original.etaDate, rowB.original.etaDate),
    },
  ];

  if (!isLoading && routeOrTrap?.type === "delivery_route") {
    const stopSeqColumn: ColumnDef<TableShipment>[] = [
      {
        accessorKey: "stopSeq",
        header: "Stop",
        cell: ({ getValue }) => getValue(),
      },
    ];
    return [...stopSeqColumn, ...planShipmentColumns];
  }

  if (isEditable && !isLoading) {
    if (!routeOrTrap) {
      throw new Error("routeOrTrap is required when table is editable");
    }
    const editableEndColumns: ColumnDef<TableShipment>[] = [
      {
        accessorKey: "menu",
        header: "",
        enableResizing: false,
        enableSorting: false,
        cell: ({ row }) => (
          <Box display="flex" alignItems="center">
            <PrintShipment row={row} />
            <PlanShipmentsMenuIconButton
              routeOrTrap={routeOrTrap}
              shipment={row.original}
            />
          </Box>
        ),
      },
    ];
    return [...planShipmentColumns, ...editableEndColumns];
  }
  return planShipmentColumns;
};

export default planShipmentColumns;
