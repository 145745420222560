import { z } from "zod";
import { shipmentTagSchema } from "../hooks/react-query/useInboundUrgentAndCriticalCare";
import { equipmentTypeMap } from "./equipment.type";

const DeliveryEquipmentTypeSchema = z.union([
  z.literal(equipmentTypeMap.trailer),
  z.literal(equipmentTypeMap.straightTruck),
]);

export const routeSchema = z.object({
  id: z.number(),
  name: z.string(),
  sicId: z.number().optional().default(0),
  zones: z.array(z.string()),
  zoneIds: z.number().array().nullish().default([]),
  zipCodes: z.array(z.string()),
  startPoint: z.string().optional(),
  startTime: z.string().nullable().default(""),
  endPoint: z.string().nullable(),
  endTime: z.string().nullish().default(""),
  trailerId: z.number().nullable(),
  tractorId: z.number().nullable(),
  straightTruckId: z.number().nullable(),
  equipmentType: DeliveryEquipmentTypeSchema.nullish(),
  doorId: z.number().optional().default(0),
  driverId: z.number().optional().default(0),
  isActive: z.boolean().default(false),
  hereFeatureId: z.string().nullable(),
  isRoute: z.boolean().default(true),
});

export const routesSchema = z.array(routeSchema);
export type Route = z.infer<typeof routeSchema>;

const zipCodeSchema = z.object({
  zipCode: z.string(),
  shipmentTags: z.array(shipmentTagSchema).nullable(),
});
const routeV2Schema = z.object({
  id: z.number(),
  name: z.string(),
  sicId: z.number().optional().default(0),
  zones: z.array(z.string()),
  zoneIds: z.number().array().nullish().default([]),
  startPoint: z.string().optional(),
  startTime: z.string().nullable().default(""),
  endPoint: z.string().nullable(),
  endTime: z.string().nullish().default(""),
  trailerId: z.number().nullable(),
  tractorId: z.number().nullable(),
  straightTruckId: z.number().nullable(),
  equipmentType: DeliveryEquipmentTypeSchema.nullish(),
  doorId: z.number().optional().default(0),
  driverId: z.number().optional().default(0),
  isActive: z.boolean().default(false),
  hereFeatureId: z.string().nullable(),
  isRoute: z.boolean().default(true),
  zipCodes: z.array(zipCodeSchema),
});

export const routesV2Schema = z.array(routeV2Schema);
export type RouteV2 = z.infer<typeof routeV2Schema>;
// Update route (usePresetRoutes.ts useEditPresetRoute) won't update Zip Codes.
// Therefore we omit the zipCodes field.
const updateRouteSchema = routeSchema.omit({ zipCodes: true });
export type UpdateRoute = z.infer<typeof updateRouteSchema>;
