import { memo, PropsWithChildren, useMemo, type CSSProperties } from "react";
import { Link, useSearchParams } from "react-router-dom";

import { keepSpecificParams } from "../../../utils/searchParamHelper";
import { SELECTED_DATE_PARAM_KEY } from "../../../hooks/useDateSearchParamOrFallbackToToday";
import { UNPLANNED_SELECTED_DATE } from "../../../hooks/useUnplannedDatetimeSearchParam";
import { tableSectionToIconMap } from "../../../pages/inbound/shared/TableActionBar";
import { PlanType } from "../../../types/planning/plan.type";
import { InboundTrailerIcon } from "../../../pages/inbound/shared/InboundTrailerIcon";
import type {
  InboundTabs,
  InboundView,
} from "../../../types/inbound/inboundView.type";

export const TRAILER_TITLE_PARAM = "trailerTitle";
const TRAILER_NAME_PARAM = "trailerName";
export const SHIPMENT_TABLE_PARAM = "shipmentTable";
const PLAN_TYPE_PARAM = "planType";
const SHIPMENT_IDS_PARAM = "shipmentIds";

type TableLinkProps = {
  id: string;
  title: string;
  name?: string;
  inboundView?: InboundView;
  tab?: InboundTabs;
  shipmentIds?: number[];
  icon?: keyof typeof tableSectionToIconMap;
  iconSize?: "small" | "large";
  isLockedRoute?: boolean | null;
  planType?: PlanType;
  style?: CSSProperties;
};

const TableLink = ({
  id,
  title,
  name,
  inboundView,
  tab,
  shipmentIds,
  children,
  icon,
  iconSize,
  isLockedRoute = false,
  planType,
  style,
}: PropsWithChildren<TableLinkProps>) => {
  const [search] = useSearchParams();

  const currentSearchParams = useMemo(() => {
    const newSearchParam = keepSpecificParams(new URLSearchParams(search), [
      SELECTED_DATE_PARAM_KEY,
      UNPLANNED_SELECTED_DATE,
    ]);
    newSearchParam.set(SHIPMENT_TABLE_PARAM, "true");
    newSearchParam.set(TRAILER_TITLE_PARAM, title);
    if (name) {
      newSearchParam.set(TRAILER_NAME_PARAM, name);
    }
    if (planType) {
      newSearchParam.set(PLAN_TYPE_PARAM, planType);
    }
    if (shipmentIds) {
      newSearchParam.set(SHIPMENT_IDS_PARAM, shipmentIds.join(","));
    }
    return newSearchParam;
  }, [search, title, name, planType, shipmentIds]);

  const fragments = useMemo(() => {
    let path = "";
    path += `${inboundView ? inboundView + "/" : ""}`;
    path += `${tab ? tab + "/" : ""}`;
    return path;
  }, [inboundView, tab]);

  return (
    <Link
      style={{
        display: "inline-flex",
        alignItems: "center",
        gap: 8,
        fontWeight: "700",
        ...style,
      }}
      to={{
        pathname: `${fragments}${id}/shipments`,
        search: currentSearchParams.toString(),
      }}
      onClick={(ev) => ev.stopPropagation()}
    >
      {icon && (
        <InboundTrailerIcon
          isLockedRoute={Boolean(isLockedRoute)}
          icon={icon}
          size={iconSize}
        />
      )}
      {children}
    </Link>
  );
};
export default memo(TableLink);
