import { Summary } from "../../hooks/react-query/dispatch/useSummary";

export const mockSummary = {
  default: {
    routes: {
      total: 68,
      pending: 8,
      dispatched: 18,
      returning: 12,
      completed: 30,
    },
    stops: {
      total: 144,
      pending: 28,
      unassigned: 7,
      completed: 89,
      incomplete: 20,
    },
    deliveries: {
      total: 99,
      pending: 14,
      completed: 62,
      incomplete: 23,
    },
    pickups: {
      total: 75,
      pending: 21,
      unassigned: 11,
      completed: 33,
      incomplete: 10,
    },
  },
  "2025-02-25": {
    routes: {
      total: 40,
      pending: 10,
      dispatched: 5,
      returning: 6,
      completed: 19,
    },
    stops: {
      total: 60,
      pending: 20,
      unassigned: 10,
      completed: 12,
      incomplete: 18,
    },
    deliveries: {
      total: 50,
      pending: 15,
      completed: 12,
      incomplete: 23,
    },
    pickups: {
      total: 72,
      pending: 12,
      unassigned: 20,
      completed: 32,
      incomplete: 8,
    },
  },
} as const satisfies Record<string, Summary>;
