import { Start } from "@mui/icons-material";
import { Typography, IconButton, styled } from "@mui/material";
import { Box } from "@mui/system";

const TitleContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const Date = styled(Typography)(() => ({
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "20px",
  letterSpacing: "0.4px",
  opacity: 0.6,
}));

const ServiceCenter = styled(Typography)(() => ({
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "28px",
  letterSpacing: "0.15px",
}));

const Header = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  padding: "1rem",
}));

export type SidebarHeaderProps = {
  date?: string;
  serviceCenter: string;
  onClose?: () => void;
  fixed?: boolean;
  closeIconTitle?: string;
  closeButtonAriaLabel?: string;
};

export const SidebarHeader = ({
  date = "",
  serviceCenter = "",
  onClose = () => {},
  fixed,
  closeIconTitle = "Close",
  closeButtonAriaLabel = "Close",
}: SidebarHeaderProps) => (
  <Header>
    <TitleContainer>
      <Box>
        <Date>{date}</Date>
        <ServiceCenter>{serviceCenter} Service Center</ServiceCenter>
      </Box>
      {fixed ? null : (
        <IconButton
          aria-label={closeButtonAriaLabel}
          title={closeIconTitle}
          onClick={onClose}
          size="small"
          sx={{ padding: 0, width: "2rem", height: "2rem" }}
        >
          <Start
            fontSize="small"
            sx={{
              transform: "rotate(180deg)",
            }}
          />
        </IconButton>
      )}
    </TitleContainer>
  </Header>
);
