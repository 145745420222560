import { Fragment, useCallback, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { ContentPaste } from "@mui/icons-material";
import { CollapsibleItem } from "../../../../components/shared/sidebar/CollapsibleItem";
import { useSelectionContext } from "../../../../context/SelectionContext";
import { tableSectionToIconMap } from "../TableActionBar";
import type { PlanTableFormat } from "../../../../types/planning/plan.type";
import type { ContextMenuRef } from "../../../../components/shared/ContextMenuDropdown";
import type { SummaryContext } from "../ActionsMenuContext";
import { planningContextMenuActions } from "../../inboundPlanning/PlanningContextMenu/planningContextMenuActions";
import { unifyShipmentContext } from "../../../../utils/unifyShipmentContext";
import { useValidateMergeRequest } from "../../../../hooks/useValidateMergeRequest";
import ContextMenuDropdown from "../../../../components/shared/ContextMenuDropdown";
import type { ServiceCenter } from "../../../../types/serviceCenter.type";
import { useFilterSearchParams } from "../../../../hooks/useFilterSearchParams";
import { filterPlanTableFormatBySearchTerm } from "../../inboundPlanning/utils";
import {
  filterPlannedSidebarRoutesAndTraps,
  plannedSidebarRoutesAndTrapsFilters,
} from "../../../../constants/filters/plannedSidebarRoutesAndTrapsFilters";
import Filters from "../Filter/Filters";
import { DroppableSectionItem } from "./DroppableSectionItem";
import { NoResultFound } from "./SectionItem";

type PlannedTrapsProps = {
  traps: PlanTableFormat[];
  selectedDate: string;
  serviceCenter: ServiceCenter;
  isEditingAllowed: boolean;
};

export const PlannedTraps = ({
  traps,
  selectedDate,
  serviceCenter,
  isEditingAllowed,
}: PlannedTrapsProps) => {
  const contextMenuRef = useRef<ContextMenuRef<SummaryContext>>(null);
  const { selected, clearSelection, isAllSelected } = useSelectionContext();
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [searchParams] = useSearchParams();

  const [
    {
      trapsZone: zone,
      trapsLocation: location,
      trapsStartTime: startTime,
      trapsEndTime: endTime,
      trapsRouteEnd: routeEnd,
      trapsBills: bills,
      trapsPallets: pallets,
      trapsWeight: weight,
      trapsPieces: pieces,
      trapsTrailerName: trailerName,
      trapsTractorName: tractorName,
      trapsDriverName: driverName,
      trapsName: name,
      trapsStatus: status,
      trapsTags: tags,
    },
  ] = useFilterSearchParams({
    trapsZone: [],
    trapsLocation: [],
    trapsStartTime: [],
    trapsEndTime: [],
    trapsRouteEnd: [],
    trapsBills: [],
    trapsPallets: [],
    trapsWeight: [],
    trapsPieces: [],
    trapsTrailerName: [],
    trapsTractorName: [],
    trapsDriverName: [],
    trapsName: [],
    trapsStatus: [],
    trapsTags: [],
  });

  const filteredPlansBySearchTerm = filterPlanTableFormatBySearchTerm(
    traps,
    serviceCenter.timeZone,
    searchTerm
  );

  const filteredTraps = filterPlannedSidebarRoutesAndTraps(
    filteredPlansBySearchTerm,
    serviceCenter.timeZone,
    {
      bills,
      driverName,
      endTime,
      location,
      name,
      pallets,
      pieces,
      routeEnd,
      startTime,
      status,
      tags,
      tractorName,
      trailerName,
      weight,
      zone,
    }
  );

  const selectionToContext = useCallback(
    (plan?: SummaryContext): SummaryContext[] => {
      if (plan) {
        return [
          {
            id: String(plan.id),
            shipmentIds: plan.shipmentIds,
            type: plan.type,
          },
        ];
      }
      return unifyShipmentContext(
        selected.map(({ id }) => {
          const plan = traps.find((plan) => plan.id.toString() === id);
          return {
            id,
            shipmentIds: plan ? plan.shipmentIds : [],
            type: plan
              ? plan.type === "delivery_route"
                ? "route"
                : "trap"
              : "route",
          };
        })
      );
    },
    [traps, selected]
  );
  const selectedContext = selectionToContext();

  const { validateMergeRequest } = useValidateMergeRequest(
    selectedContext,
    traps
  );

  const contextActions = useCallback(
    (plan?: SummaryContext) => {
      if (plan?.type === "shipment") {
        const trap = traps.find((trap) => trap.id === plan.id);
        if (trap) {
          return planningContextMenuActions({
            section: "shipments",
            shipmentContext: selectionToContext(plan),
            routeOrTrap: trap,
            clearSelection,
          });
        }
      }
      return planningContextMenuActions({
        section: "planning",
        shipmentContext: selectionToContext(plan),
        clearSelection,
        plannedRoutesOrTraps: traps,
        validateMergeRequest,
      });
    },
    [clearSelection, traps, selectionToContext, validateMergeRequest]
  );

  return (
    <Box>
      <CollapsibleItem
        title="Traps"
        displayCount={traps.length}
        icon={tableSectionToIconMap["trap"]}
        isExpanded={isExpanded}
        onExpand={(value: boolean) => setIsExpanded(value)}
      >
        <Filters
          filters={plannedSidebarRoutesAndTrapsFilters(
            traps,
            searchParams,
            serviceCenter.timeZone,
            "traps"
          )}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          direction="column"
          placeholder="Search for trap"
          filterSectionId="Traps"
        />
        {filteredTraps.map((trap) => (
          <Fragment key={trap.id}>
            <DroppableSectionItem
              canReorder={false}
              data={trap}
              tab="list"
              isEditingAllowed={isEditingAllowed}
              isSelected={isAllSelected(trap.shipmentIds, "trap")}
              serviceCenterId={serviceCenter.id}
              planType="delivery_trap"
              selectedDate={selectedDate}
              inboundView="traps"
              icon="trap"
              contextRef={contextMenuRef}
            />
          </Fragment>
        ))}
        {filteredTraps.length === 0 && (
          <NoResultFound>
            <ContentPaste sx={{ fontSize: "2rem" }} />
            <Typography>No traps found.</Typography>
          </NoResultFound>
        )}
      </CollapsibleItem>
      <ContextMenuDropdown actions={contextActions} ref={contextMenuRef} />
    </Box>
  );
};
