import { Trip } from "../../../types/trip.type";

export const mockStops = {
  empty: [],
  default: [
    {
      tripNumber: 3000888,
      trailerNumber: "480489",
      originSic: "BOS",
      destinationSic: "MIS",
      stopSic: "EWR",
      shipmentsCount: 11,
      shipmentsPallets: 41,
      shipmentsPieces: 44,
      shipmentsWeight: 542,
      status: "LDDK",
      etaTimestampUtc: new Date("2024-03-17T07:17:00-04:00"),
      actualArrivalTime: new Date("2024-03-18T06:43:04-04:00"),
      dueServiceTimestampUtc: new Date("2024-03-18T06:43:04-04:00"),
      shipmentIds: [],
      shipmentsTags: [
        {
          id: 6,
          name: "LG",
          description: "Liftgate",
          colorHex: "#679CB2",
          position: 40,
        },
        {
          id: 8,
          name: "LM",
          description: "Large Mark - 6 plts or greater-6,000 lbs or greater",
          colorHex: "#E280B4",
          position: 50,
        },
        {
          id: 74,
          name: "RESI",
          description: "Residential",
          colorHex: "#6B8DEB",
          position: 60,
        },
        {
          id: 76,
          name: "LACC",
          description: "Limited Access",
          colorHex: "#DDB272",
          position: 80,
        },
        {
          id: 77,
          name: "INDL",
          description: "Inside Delivery",
          colorHex: "#9CA8E5",
          position: 90,
        },
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
        {
          id: 9,
          name: "DTCL",
          description: "Delivery to Connecting Line",
          colorHex: "#5E9FE0",
          position: 170,
        },
      ],
      allShipmentsPlanned: false,
      allShipmentsPlannedForFuture: false,
      someShipmentsPlanned: false,
      allFuturePlanDates: [],
      tags: [
        {
          id: 6,
          name: "LG",
          description: "Liftgate",
          colorHex: "#679CB2",
          position: 40,
        },
        {
          id: 8,
          name: "LM",
          description: "Large Mark - 6 plts or greater-6,000 lbs or greater",
          colorHex: "#E280B4",
          position: 50,
        },
        {
          id: 74,
          name: "RESI",
          description: "Residential",
          colorHex: "#6B8DEB",
          position: 60,
        },
        {
          id: 76,
          name: "LACC",
          description: "Limited Access",
          colorHex: "#DDB272",
          position: 80,
        },
        {
          id: 77,
          name: "INDL",
          description: "Inside Delivery",
          colorHex: "#9CA8E5",
          position: 90,
        },
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
        {
          id: 9,
          name: "DTCL",
          description: "Delivery to Connecting Line",
          colorHex: "#5E9FE0",
          position: 170,
        },
      ],
      serviceDueDate: null,
      eta: new Date("2024-03-17T07:17:00-04:00"),
      originSicCode: "",
      destinationSicCode: "",
      bills: 11,
      pallets: 122,
      pieces: 15,
      weight: 255,
      weightUnit: "lbs",
    },
    {
      tripNumber: 3000630,
      trailerNumber: "41497",
      originSic: "ONT",
      destinationSic: "BOS",
      stopSic: "EWR",
      shipmentsCount: 10,
      shipmentsPallets: 20,
      shipmentsPieces: 30,
      shipmentsWeight: 410,
      status: "ENRT",
      etaTimestampUtc: new Date("2024-03-17T07:17:00-04:00"),
      actualArrivalTime: new Date("2024-03-18T06:43:04-04:00"),
      dueServiceTimestampUtc: new Date("2024-03-18T06:43:04-04:00"),
      shipmentIds: [],
      shipmentsTags: [
        {
          id: 81,
          name: "VC",
          description: "Vendor Compliance",
          colorHex: "#AA7EA0",
          position: 30,
        },
        {
          id: 8,
          name: "LM",
          description: "Large Mark - 6 plts or greater-6,000 lbs or greater",
          colorHex: "#E280B4",
          position: 50,
        },
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
      ],
      allShipmentsPlanned: false,
      allShipmentsPlannedForFuture: false,
      someShipmentsPlanned: false,
      allFuturePlanDates: [],
      tags: [
        {
          id: 6,
          name: "LG",
          description: "Liftgate",
          colorHex: "#679CB2",
          position: 40,
        },
        {
          id: 8,
          name: "LM",
          description: "Large Mark - 6 plts or greater-6,000 lbs or greater",
          colorHex: "#E280B4",
          position: 50,
        },
        {
          id: 74,
          name: "RESI",
          description: "Residential",
          colorHex: "#6B8DEB",
          position: 60,
        },
        {
          id: 76,
          name: "LACC",
          description: "Limited Access",
          colorHex: "#DDB272",
          position: 80,
        },
        {
          id: 77,
          name: "INDL",
          description: "Inside Delivery",
          colorHex: "#9CA8E5",
          position: 90,
        },
        {
          id: 4,
          name: "APTR",
          description: "Appointment Required ",
          colorHex: "#777DE9",
          position: 110,
        },
        {
          id: 9,
          name: "DTCL",
          description: "Delivery to Connecting Line",
          colorHex: "#5E9FE0",
          position: 170,
        },
      ],
      serviceDueDate: null,
      eta: new Date("2024-03-17T07:17:00-04:00"),
      originSicCode: "",
      destinationSicCode: "",
      bills: 10,
      pallets: 20,
      pieces: 30,
      weight: 40,
      weightUnit: "lbs",
    },
  ],
} as const satisfies Record<string, Trip[]>;
