import { Close as CloseIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { closeSnackbar, SnackbarProvider } from "notistack";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import "./init";
import "./main.css";
import { hotjar } from "react-hotjar";
import { configureAmplify } from "./services/authenticationService";
import { AppTheme } from "./theme";
// TODO: Lazy load this so it's excluded from the prod bundle for performance. Using a dynamic import fails the prod build, so need to find a workaround.
import ErrorFallback from "./components/shared/ErrorFallback";
import { dayjsConfig } from "./dayjsConfig";
import { queryClient } from "./queryClientProvider";
import { env } from "./utils/env";
import { useAuthenticatedUser } from "./hooks/react-query/useAuthenticatedUser";
import { LoadingBackdrop } from "./components/shared/LoadingBackdrop";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import UnprotectedRoutesProvider from "./routes/UnprotectedRoutesProvider";
import { initSentry } from "./sentry";

dayjsConfig.executeDayJsPlugins();

initSentry();

configureAmplify();

if (env.VITE_ENABLE_HOTJAR) {
  hotjar.initialize(3837886, 6);
}

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("root element not found");
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <SnackbarProvider
        action={(snackbarId) => (
          <IconButton
            aria-label="Close"
            color="inherit"
            onClick={() => closeSnackbar(snackbarId)}
          >
            <CloseIcon />
          </IconButton>
        )}
      >
        <QueryClientProvider client={queryClient}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={AppTheme}>
              <DndProvider backend={HTML5Backend}>
                <App />
              </DndProvider>
            </ThemeProvider>
            <ReactQueryDevtools
              initialIsOpen={false}
              // buttonPosition prop was removed because is making fail the test in navigation.spec.ts
            />
          </LocalizationProvider>
        </QueryClientProvider>
      </SnackbarProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

function App() {
  const { data: user = null, isLoading } = useAuthenticatedUser();
  if (isLoading) return <LoadingBackdrop />;
  return user ? <ProtectedRoutes user={user} /> : <UnprotectedRoutesProvider />;
}
