import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { apiUrls } from "../../utils/apiUrls";
import { http } from "../../utils/httpCommon";
import { toast } from "../../utils/snackbarHelper";
import { toastMessage } from "../../constants/strings";
import { shipmentSchema } from "../../services/prePlanningService.types";

const shipmentSearchSchema = z.object({
  location: z.enum(["delivery_trap", "delivery_route", "unassigned_shipments"]),
  locationId: z.string(),
  shipmentId: z.number(),
});

const addToPlanResponseSchema = z
  .object({
    failedProcessedShipmentCount: z.number(),
    failedShipmentIds: z.array(z.number()),
    failedShipments: z.array(shipmentSchema),
    processedShipmentCount: z.number(),
    processedShipmentIds: z.array(z.number()),
    shipmentCountSubmitted: z.number(),
    // cSpell:ignore Consigee
    // TODO: Fix this on the API Response
    shipmentIdsWithMissingConsigeeInfo: z.array(z.number()).optional(),
    successfullyProcessedShipmentCount: z.number(),
    unprocessedShipmentCount: z.number(),
    unprocessedShipmentSearchResults: z.array(shipmentSearchSchema),
  })
  .transform((input) => ({
    ...input,
    shipmentIdsWithMissingConsigneeInfo:
      input.shipmentIdsWithMissingConsigeeInfo,
  }));

export type AddToPlanResponse = z.infer<typeof addToPlanResponseSchema>;

// trips and traps args used to invalidate shipment details queries
type UseAddToPlanArgs = {
  date: number;
  sicId: number;
  shipmentIds: number[];
  onSuccess?: (
    affectedShipmentsCount: number,
    unprocessedShipmentCount: number
  ) => void;
};

export const useAddToPlan = ({
  date,
  sicId,
  shipmentIds,
  onSuccess,
}: UseAddToPlanArgs) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const url = apiUrls.addToPlan(sicId, date);
      const { status, data } = await http.post(url, {
        shipmentIds,
      });
      return { status, data: addToPlanResponseSchema.parse(data) };
    },
    onSuccess: (response) => {
      onSuccess?.(
        response.data.successfullyProcessedShipmentCount,
        response.data.unprocessedShipmentCount
      );
    },
    onError: (e) => {
      // Ignoring 400 error, it will be implemented post MVP
      if (axios.isAxiosError(e) && e.response?.status === 400) {
        onSuccess?.(0, e.response.data.unprocessedShipmentCount);
        return;
      }
      toast(toastMessage.inbound.addToPlan.failed, {
        variant: "error",
      });
    },
    onSettled: () => {
      // TODO: Be more specific
      queryClient.invalidateQueries();
    },
  });
};
