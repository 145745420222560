import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { http } from "../../utils/httpCommon";
import { apiUrls } from "../../utils/apiUrls";

const unassignShipmentIdsQueryKey = (
  serviceCenterId: number,
  planDate: number
) => ["unassignedShipments", serviceCenterId, planDate];

const unassignedShipmentsResponseSchema = z.array(z.number());

export const useUnassignedShipmentIds = (
  serviceCenterId: number,
  planDate: number
) =>
  useQuery({
    queryKey: unassignShipmentIdsQueryKey(serviceCenterId, planDate),
    queryFn: async () => {
      const url = apiUrls.getUnassignedShipments(serviceCenterId, planDate);
      const { data } = await http.get(url);
      return unassignedShipmentsResponseSchema.parse(data);
    },
  });
