import dayjs from "dayjs";
import {
  Stop,
  StopShipmentNotes,
  SuggestedSic,
  Shipment,
} from "../../hooks/react-query/dispatch/useStops";
import { getBusinessDayStart } from "../mockUtils";
import { tagNameToShipmentTagMap } from "../../types/shipmentTag.type";
import { Miscellaneous } from "../../hooks/react-query/dispatch/useShipments";

export const mockDispatchMiscellaneous = [
  {
    url: "/empty-truck.png",
    createdAt: dayjs("2023-09-09"),
  },
  {
    url: "/dylt-logo-header.png",
    createdAt: dayjs("2023-09-10"),
  },
] as const satisfies Miscellaneous[];

export const mockStopShipment: Shipment = {
  id: 1,
  proNumber: "123451587",
  miscellaneous: mockDispatchMiscellaneous,
  pallets: 2,
  pieces: 10,
  weight: 200,
  statusName: "DISP",
  shipmentTags: tagNameToShipmentTagMap(["UC", "INDL"]),
};

const pendingMixedPickupsAndDeliveries: Stop = {
  id: 1,
  routeId: 1,
  routeName: "Route 1",
  driver: "John Doe",
  sequence: 5,
  name: "Customer Name XYZ",
  statusName: "pending",
  address: "1234 Main St, Destination A",
  state: "CA",
  city: "Los Angeles",
  zipCode: "77077",
  arrivedAt: null,
  departedAt: null,
  isAtRisk: false,
  coordinates: {
    latitude: 34.2122,
    longitude: -117.6437,
  },
  eta: getBusinessDayStart().add(1, "hour"),
  appointment: getBusinessDayStart().add(1, "hour").add(10, "minutes"),
  readyTime: getBusinessDayStart(),
  closeTime: getBusinessDayStart().add(10, "hours"),
  pickups: [
    mockStopShipment,
    {
      id: 2,
      proNumber: "151289287",
      miscellaneous: mockDispatchMiscellaneous,

      pallets: 2,
      pieces: 10,
      weight: 200,
      statusName: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["UC", "WC"]),
    },
    {
      id: 3,
      proNumber: "1234515874",
      miscellaneous: mockDispatchMiscellaneous,

      pallets: 2,
      pieces: 10,
      weight: 200,
      statusName: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["$$$"]),
    },
    {
      id: 4,
      proNumber: "1512892873",
      miscellaneous: mockDispatchMiscellaneous,

      pallets: 2,
      pieces: 10,
      weight: 200,
      statusName: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["OSAD"]),
    },
    {
      id: 5,
      proNumber: "P8415263",
      miscellaneous: mockDispatchMiscellaneous,

      pallets: 2,
      pieces: 10,
      weight: 200,
      statusName: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["WC"]),
    },
    {
      id: 6,
      proNumber: "1512892871",
      miscellaneous: mockDispatchMiscellaneous,

      pallets: 2,
      pieces: 10,
      weight: 200,
      statusName: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["WC"]),
    },
  ],
  deliveries: [
    {
      id: 7,
      proNumber: "543211587",
      miscellaneous: mockDispatchMiscellaneous,

      pallets: 212,
      pieces: 611,
      weight: 126,
      statusName: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["$$$", "MOCK"]),
    },
    {
      id: 8,
      proNumber: "5432121587",
      miscellaneous: mockDispatchMiscellaneous,

      pallets: 1,
      pieces: 5,
      weight: 100,
      statusName: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["APTM", "$$$"]),
    },
    {
      id: 9,
      proNumber: "5432115871",
      miscellaneous: mockDispatchMiscellaneous,

      pallets: 1,
      pieces: 5,
      weight: 100,
      statusName: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["APTM"]),
    },
    {
      id: 10,
      proNumber: "54321215871",
      miscellaneous: mockDispatchMiscellaneous,

      pallets: 1,
      pieces: 5,
      weight: 100,
      statusName: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["UC"]),
    },
  ],
  bills: 10,
  pallets: 3,
  pieces: 15,
  weight: 300,
  pickupBills: 6,
  pickupPallets: 2,
  pickupPieces: 10,
  pickupWeight: 200,
  deliveryBills: 4,
  deliveryPallets: 3,
  deliveryPieces: 5,
  deliveryWeight: 426,
  miscellaneous: mockDispatchMiscellaneous,

  shipmentTags: tagNameToShipmentTagMap(["UC", "WC", "APTM", "$$$"]),
  filters:
    "123451587_151289287_1234515874_1512892873_P8415263_1512892871_Route 1_Customer Name XYZ_1234 Main St_Los Angeles_John Doe_$$$_MOCK_APTM_WC",
};

const pendingOnlyDeliveries: Stop = {
  id: 2,
  routeId: 1,
  routeName: "Route 1",
  driver: "John Doe",
  sequence: 4,
  name: "Another Customer",
  statusName: "pending",
  address: "5678 Secondary St, Destination A",
  state: "CA",
  city: "Los Angeles",
  zipCode: "77077",
  arrivedAt: null,
  departedAt: null,
  isAtRisk: false,
  coordinates: {
    latitude: 34.1122,
    longitude: -117.5437,
  },
  eta: getBusinessDayStart().add(2, "hours"),
  readyTime: getBusinessDayStart(),
  closeTime: getBusinessDayStart().add(10, "hours"),
  pickups: [],
  deliveries: [
    {
      id: 11,
      proNumber: "678901587",
      miscellaneous: mockDispatchMiscellaneous,

      pallets: 1,
      pieces: 2,
      weight: 50,
      statusName: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["UC"]),
    },
  ],
  bills: 1,
  pallets: 1,
  pieces: 2,
  weight: 50,
  pickupBills: 0,
  pickupPallets: 0,
  pickupPieces: 0,
  pickupWeight: 0,
  deliveryBills: 1,
  deliveryPallets: 1,
  deliveryPieces: 2,
  deliveryWeight: 50,
  miscellaneous: mockDispatchMiscellaneous,

  shipmentTags: tagNameToShipmentTagMap(["UC"]),
  filters:
    "678901587_Route 1_Another Customer_5678 Secondary St_Los Angeles_John Doe_UC",
};

const unassigned: Stop = {
  id: 4,
  name: "Mock Customer With a Name That Requires Tooltip",
  routeId: null,
  driver: null,
  statusName: "unassigned",
  address:
    "9012 Tertiary This is a Really Long Mocked Address That helps Test Tooltips, Wrapping and Ellipsis Rd, Destination B",
  state: "CA",
  city: "Los Angeles",
  zipCode: "77077",
  coordinates: {
    latitude: 34.1522,
    longitude: -117.4437,
  },
  eta: getBusinessDayStart().add(2, "hours"),
  arrivedAt: getBusinessDayStart().add(2, "hours"),
  departedAt: getBusinessDayStart().add(2, "hours").add(30, "minutes"),
  readyTime: getBusinessDayStart(),
  closeTime: getBusinessDayStart().add(12, "hours"),
  isAtRisk: false,
  pickups: [
    {
      id: 12,
      proNumber: "098761587",
      miscellaneous: mockDispatchMiscellaneous,

      pallets: 3,
      pieces: 15,
      weight: 300,
      statusName: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["DTCL", "CC"]),
    },
  ],
  deliveries: [],
  bills: 4,
  pallets: 3,
  pieces: 15,
  weight: 300,
  pickupBills: 4,
  pickupPallets: 3,
  pickupPieces: 15,
  pickupWeight: 300,
  deliveryBills: 0,
  deliveryPallets: 0,
  deliveryPieces: 0,
  deliveryWeight: 0,
  miscellaneous: mockDispatchMiscellaneous,

  shipmentTags: tagNameToShipmentTagMap(["DTCL", "CC"]),
  filters:
    "098761587_Mock Customer With a Name That Requires Tooltip_9012 Tertiary This is a Really Long Mocked Address That helps Test Tooltips_Los Angeles_DTCL_CC",
};

const multiStop = [
  {
    id: 11,
    name: "Unassigned Customer",
    statusName: "unassigned",
    routeId: null,
    driver: null,
    address: "606 Maple St, Destination X",
    state: "CA",
    city: "Los Angeles",
    zipCode: "77079",
    eta: null,
    arrivedAt: null,
    departedAt: null,
    coordinates: {
      latitude: 34.030103,
      longitude: -117.68401,
    },
    readyTime: getBusinessDayStart(),
    closeTime: getBusinessDayStart().add(12, "hours"),
    isAtRisk: false,
    pickups: [],
    deliveries: [
      {
        id: 13,
        proNumber: "5432112003",
        miscellaneous: mockDispatchMiscellaneous,

        pallets: 1,
        pieces: 4,
        weight: 150,
        statusName: "DISP",
        shipmentTags: tagNameToShipmentTagMap(["OSAD"]),
      },
    ],
    bills: 2,
    pallets: 1,
    pieces: 4,
    weight: 150,
    pickupBills: 0,
    pickupPallets: 0,
    pickupPieces: 0,
    pickupWeight: 0,
    deliveryBills: 2,
    deliveryPallets: 1,
    deliveryPieces: 4,
    deliveryWeight: 150,
    miscellaneous: mockDispatchMiscellaneous,

    shipmentTags: tagNameToShipmentTagMap(["OSAD"]),
    filters: "5432112003_Unassigned Customer_606 Maple St_Los Angeles_OSAD",
  },
  {
    id: 12,
    routeId: 7,
    routeName: "ONT-2",
    driver: "Emily Clarke",
    sequence: 2,
    name: "Customer Epsilon",
    statusName: "incomplete",
    address: "606 Maple St, Destination X",
    state: "CA",
    city: "Los Angeles",
    zipCode: "77078",
    arrivedAt: null,
    departedAt: null,
    isAtRisk: false,
    coordinates: {
      latitude: 34.0822,
      longitude: -118.0237,
    },
    eta: getBusinessDayStart().add(2, "hours"),
    readyTime: getBusinessDayStart(),
    closeTime: getBusinessDayStart().add(10, "hours"),
    pickups: [],
    deliveries: [
      {
        id: 14,
        proNumber: "5432112002",
        miscellaneous: mockDispatchMiscellaneous,

        pallets: 2,
        pieces: 6,
        weight: 250,
        statusName: "DISP",
        shipmentTags: tagNameToShipmentTagMap(["UC", "CC"]),
      },
    ],
    bills: 3,
    pallets: 2,
    pieces: 6,
    weight: 250,
    pickupBills: 0,
    pickupPallets: 0,
    pickupPieces: 0,
    pickupWeight: 0,
    deliveryBills: 3,
    deliveryPallets: 2,
    deliveryPieces: 6,
    deliveryWeight: 250,
    miscellaneous: mockDispatchMiscellaneous,

    shipmentTags: tagNameToShipmentTagMap(["UC", "CC"]),
    filters:
      "5432112002_ONT-2_Customer Epsilon_606 Maple St_Los Angeles_Emily Clarke_UC_CC",
  },
] as const satisfies Stop[];

const completed: Stop = {
  id: 5,
  routeId: 5,
  routeName: "Route 2",
  driver: "Jane Doe",
  sequence: 2,
  name: "Customer Name ABC",
  statusName: "completed",
  address: "3456 Quaternary Blvd, Destination B",
  state: "CA",
  city: "Los Angeles",
  zipCode: "77077",
  eta: null,
  coordinates: {
    latitude: 34.0522,
    longitude: -117.2437,
  },
  arrivedAt: getBusinessDayStart().add(2, "hours"),
  departedAt: getBusinessDayStart().add(2, "hours").add(30, "minutes"),
  readyTime: getBusinessDayStart(),
  closeTime: getBusinessDayStart().add(12, "hours"),
  isAtRisk: false,
  pickups: [
    {
      id: 15,
      proNumber: "654321587",
      miscellaneous: mockDispatchMiscellaneous,

      pallets: 2,
      pieces: 10,
      weight: 150,
      statusName: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["OSAD", "WC"]),
    },
  ],
  deliveries: [],
  bills: 2,
  pallets: 2,
  pieces: 10,
  weight: 150,
  pickupBills: 2,
  pickupPallets: 2,
  pickupPieces: 10,
  pickupWeight: 150,
  deliveryBills: 0,
  deliveryPallets: 0,
  deliveryPieces: 0,
  deliveryWeight: 0,
  miscellaneous: mockDispatchMiscellaneous,

  shipmentTags: tagNameToShipmentTagMap(["OSAD", "WC"]),
  filters:
    "654321587_Route 2_Customer Name ABC_3456 Quaternary Blvd_Los Angeles_Jane Doe_OSAD_WC",
};

const pendingOnlyPickups: Stop = {
  id: 6,
  routeId: 6,
  routeName: "LBY-1",
  driver: "Mike O'Connor",
  sequence: 4,
  name: "Customer Alpha",
  statusName: "pending",
  address: "101 Main St, Destination F",
  state: "CA",
  city: "Los Angeles",
  zipCode: "77077",
  coordinates: {
    latitude: 34.0322,
    longitude: -117.3437,
  },
  eta: getBusinessDayStart().add(30, "minutes"),
  arrivedAt: getBusinessDayStart().add(1, "hour"),
  departedAt: getBusinessDayStart().add(1, "hour").add(15, "minutes"),
  readyTime: getBusinessDayStart(),
  closeTime: getBusinessDayStart().add(9, "hours"),
  isAtRisk: false,
  pickups: [
    {
      id: 16,
      proNumber: "1234510001",
      miscellaneous: mockDispatchMiscellaneous,

      pallets: 1,
      pieces: 5,
      weight: 150,
      statusName: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["APTM", "UC"]),
    },
  ],
  deliveries: [],
  bills: 2,
  pallets: 1,
  pieces: 5,
  weight: 150,
  pickupBills: 2,
  pickupPallets: 1,
  pickupPieces: 5,
  pickupWeight: 150,
  deliveryBills: 0,
  deliveryPallets: 0,
  deliveryPieces: 0,
  deliveryWeight: 0,
  miscellaneous: mockDispatchMiscellaneous,

  shipmentTags: tagNameToShipmentTagMap(["APTM", "UC"]),
  filters:
    "1234510001_LBY-1_Customer Alpha_101 Main St_Los Angeles_Mike O'Connor_APTM_UC",
};

const incomplete: Stop = {
  id: 8,
  routeId: 6,
  routeName: "LBY-1",
  driver: "Mike O'Connor",
  sequence: 3,
  name: "Customer Gamma",
  statusName: "incomplete",
  address: "303 Pine St, Destination F",
  state: "CA",
  city: "Los Angeles",
  zipCode: "77077",
  arrivedAt: null,
  departedAt: null,
  isAtRisk: false,
  coordinates: {
    latitude: 34.0522,
    longitude: -117.1637,
  },
  eta: getBusinessDayStart().add(3, "hours"),
  readyTime: getBusinessDayStart(),
  closeTime: getBusinessDayStart().add(10, "hours"),
  pickups: [],
  deliveries: [
    {
      id: 17,
      proNumber: "1234510003",
      miscellaneous: mockDispatchMiscellaneous,
      pallets: 3,
      pieces: 10,
      weight: 300,
      statusName: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["OSAD", "UC"]),
    },
  ],
  bills: 4,
  pallets: 3,
  pieces: 10,
  weight: 300,
  pickupBills: 0,
  pickupPallets: 0,
  pickupPieces: 0,
  pickupWeight: 0,
  deliveryBills: 4,
  deliveryPallets: 3,
  deliveryPieces: 10,
  deliveryWeight: 300,
  miscellaneous: mockDispatchMiscellaneous,
  shipmentTags: tagNameToShipmentTagMap(["OSAD", "UC"]),
  filters:
    "1234510003_LBY-1_Customer Gamma_303 Pine St_Los Angeles_Mike O'Connor_OSAD_UC",
};

export const mockDispatchStops = {
  pendingMixedPickupsAndDeliveries: [pendingMixedPickupsAndDeliveries],
  pendingOnlyDeliveries: [pendingOnlyDeliveries],
  unassigned: [unassigned],
  multiStop,
  completed: [completed],
  pendingOnlyPickups: [pendingOnlyPickups],
  incomplete: [incomplete],
  empty: [],
  default: [
    pendingMixedPickupsAndDeliveries,
    pendingOnlyDeliveries,
    {
      id: 3,
      routeId: 1,
      routeName: "Route 1",
      driver: "John Doe",
      sequence: 6,
      name: "Customer ABC XYZ",
      statusName: "pending",
      address: "5679 Secondary St, Destination A",
      state: "CA",
      city: "Los Angeles",
      zipCode: "77077",
      arrivedAt: null,
      departedAt: null,
      isAtRisk: false,
      coordinates: {
        latitude: 33.9522,
        longitude: -117.2837,
      },
      eta: getBusinessDayStart().add(4, "hours"),
      readyTime: getBusinessDayStart(),
      closeTime: getBusinessDayStart().add(2, "hours"),
      pickups: [],
      deliveries: [
        {
          id: 18,
          proNumber: "678901587",
          miscellaneous: mockDispatchMiscellaneous,

          pallets: 1,
          pieces: 2,
          weight: 50,
          statusName: "DISP",
          shipmentTags: tagNameToShipmentTagMap(["UC"]),
        },
      ],
      bills: 1,
      pallets: 1,
      pieces: 2,
      weight: 50,
      pickupBills: 0,
      pickupPallets: 0,
      pickupPieces: 0,
      pickupWeight: 0,
      deliveryBills: 1,
      deliveryPallets: 1,
      deliveryPieces: 2,
      deliveryWeight: 50,
      miscellaneous: mockDispatchMiscellaneous,

      shipmentTags: tagNameToShipmentTagMap(["UC"]),
      filters:
        "678901587_Route 1_Customer ABC XYZ_5679 Secondary St_Los Angeles_John Doe_UC",
    },
    {
      id: 14,
      routeId: 1,
      routeName: "Route 1",
      driver: "John Doe",
      sequence: 9,
      name: "Customer DEF",
      statusName: "pending",
      address: "4609 Arlington Ave",
      state: "CA",
      city: "Riverside",
      zipCode: "92504",
      arrivedAt: null,
      departedAt: null,
      isAtRisk: false,
      coordinates: {
        latitude: 33.946864,
        longitude: -117.403303,
      },
      eta: getBusinessDayStart().add(4, "hours"),
      readyTime: getBusinessDayStart(),
      closeTime: getBusinessDayStart().add(2, "hours"),
      pickups: [],
      deliveries: [
        {
          id: 19,
          proNumber: "678901587",
          miscellaneous: mockDispatchMiscellaneous,

          pallets: 1,
          pieces: 2,
          weight: 50,
          statusName: "DISP",
          shipmentTags: tagNameToShipmentTagMap(["UC"]),
        },
      ],
      bills: 1,
      pallets: 1,
      pieces: 2,
      weight: 50,
      pickupBills: 0,
      pickupPallets: 0,
      pickupPieces: 0,
      pickupWeight: 0,
      deliveryBills: 1,
      deliveryPallets: 1,
      deliveryPieces: 2,
      deliveryWeight: 50,
      miscellaneous: mockDispatchMiscellaneous,

      shipmentTags: tagNameToShipmentTagMap(["UC"]),
      filters:
        "678901587_Route 1_Customer ABC XYZ_5679 Secondary St_Los Angeles_John Doe_UC",
    },
    unassigned,
    completed,
    pendingOnlyPickups,
    {
      id: 7,
      routeId: 6,
      routeName: "LBY-1",
      driver: "Mike O'Connor",
      sequence: 2,
      name: "Customer Beta",
      statusName: "completed",
      address: "202 Oak St, Destination F",
      state: "CA",
      city: "Los Angeles",
      zipCode: "77077",
      coordinates: {
        latitude: 34.1422,
        longitude: -117.2437,
      },
      eta: getBusinessDayStart().add(2, "hours"),
      arrivedAt: getBusinessDayStart().add(2, "hours").add(10, "minutes"),
      departedAt: getBusinessDayStart().add(2, "hours").add(35, "minutes"),
      readyTime: getBusinessDayStart(),
      closeTime: getBusinessDayStart().add(10, "hours"),
      isAtRisk: false,
      pickups: [],
      deliveries: [
        {
          id: 20,
          proNumber: "1234510002",
          miscellaneous: mockDispatchMiscellaneous,

          pallets: 2,
          pieces: 8,
          weight: 220,
          statusName: "DISP",
          shipmentTags: tagNameToShipmentTagMap(["CC", "UC"]),
        },
      ],
      bills: 3,
      pallets: 2,
      pieces: 8,
      weight: 220,
      pickupBills: 0,
      pickupPallets: 0,
      pickupPieces: 0,
      pickupWeight: 0,
      deliveryBills: 3,
      deliveryPallets: 2,
      deliveryPieces: 8,
      deliveryWeight: 220,
      miscellaneous: mockDispatchMiscellaneous,

      shipmentTags: tagNameToShipmentTagMap(["CC", "UC"]),
      filters:
        "1234510002_LBY-1_Customer Beta_202 Oak St_Los Angeles_Mike O'Connor_CC_UC",
    },
    incomplete,
    {
      id: 9,
      routeId: 7,
      routeName: "ONT-2",
      driver: "Emily Clarke",
      sequence: 7,
      name: "Customer Delta",
      statusName: "pending",
      address: "404 Birch St, Destination G",
      state: "CA",
      city: "Los Angeles",
      zipCode: "77078",
      coordinates: {
        latitude: 34.0622,
        longitude: -117.3237,
      },
      eta: getBusinessDayStart().add(1, "hour"),
      arrivedAt: getBusinessDayStart().add(1, "hour").add(10, "minutes"),
      departedAt: getBusinessDayStart().add(1, "hour").add(40, "minutes"),
      readyTime: getBusinessDayStart(),
      closeTime: getBusinessDayStart().add(9, "hours"),
      isAtRisk: false,
      pickups: [],
      deliveries: [
        {
          id: 21,
          proNumber: "5432112001",
          miscellaneous: mockDispatchMiscellaneous,

          pallets: 1,
          pieces: 4,
          weight: 150,
          statusName: "DISP",
          shipmentTags: tagNameToShipmentTagMap(["UC", "APTM"]),
        },
      ],
      bills: 2,
      pallets: 1,
      pieces: 4,
      weight: 150,
      pickupBills: 0,
      pickupPallets: 0,
      pickupPieces: 0,
      pickupWeight: 0,
      deliveryBills: 2,
      deliveryPallets: 1,
      deliveryPieces: 4,
      deliveryWeight: 150,
      miscellaneous: mockDispatchMiscellaneous,

      shipmentTags: tagNameToShipmentTagMap(["UC", "APTM"]),
      filters:
        "5432112001_ONT-2_Customer Delta_404 Birch St_Los Angeles_Emily Clarke_UC_APTM",
    },
    {
      id: 10,
      routeId: 7,
      routeName: "ONT-2",
      driver: "Emily Clarke",
      sequence: 2,
      name: "Customer Epsilon",
      statusName: "incomplete",
      address: "505 Cedar St, Destination G",
      state: "CA",
      city: "Los Angeles",
      zipCode: "77078",
      arrivedAt: null,
      departedAt: null,
      coordinates: {
        latitude: 34.0722,
        longitude: -117.2037,
      },
      eta: getBusinessDayStart().add(2, "hours"),
      readyTime: getBusinessDayStart(),
      closeTime: getBusinessDayStart().add(10, "hours"),
      isAtRisk: false,
      pickups: [],
      deliveries: [
        {
          id: 22,
          proNumber: "5432112002",
          miscellaneous: mockDispatchMiscellaneous,

          pallets: 2,
          pieces: 6,
          weight: 250,
          statusName: "DISP",
          shipmentTags: tagNameToShipmentTagMap(["UC", "CC"]),
        },
      ],
      bills: 3,
      pallets: 2,
      pieces: 6,
      weight: 250,
      pickupBills: 0,
      pickupPallets: 0,
      pickupPieces: 0,
      pickupWeight: 0,
      deliveryBills: 3,
      deliveryPallets: 2,
      deliveryPieces: 6,
      deliveryWeight: 250,
      miscellaneous: mockDispatchMiscellaneous,
      shipmentTags: tagNameToShipmentTagMap(["UC", "CC"]),
      filters:
        "5432112002_ONT-2_Customer Epsilon_505 Cedar St_Los Angeles_Emily Clarke_UC_CC",
    },
    ...multiStop,
  ],
} as const satisfies Record<string, Stop[]>;

export const mockDispatchStopShipmentNotes = {
  default: [
    [
      {
        note: "This shipment is a mock shipment with a lot of notes. Very long notes. Extremely long notes. Notes that are so long that they are longer than the longest notes you have ever seen. This shipment is a mock shipment with a lot of notes. Very long notes. Extremely long notes. Notes that are so long that they are longer than the longest notes you have ever seen. This shipment is a mock shipment with a lot of notes. Very long notes. Extremely long notes. Notes that are so long that they are longer than the longest notes you have ever seen. This shipment is a mock shipment with a lot of notes. Very long notes. Extremely long notes. Notes that are so long that they are longer than the longest notes you have ever seen.",
      },
      { note: "This shipment is a short mock shipment with short notes." },
      {
        note: "This shipment is a mock shipment with a lot of notes. But not as many notes as the other shipment. This shipment is a mock shipment with a lot of notes. But not as many notes as the other shipment. This shipment is a mock shipment with a lot of notes. But not as many notes as the other shipment. This shipment is a mock shipment with a lot of notes. But not as many notes as the other shipment.",
      },
    ],
    [
      { note: "This shipment is a short mock shipment with short notes." },
      {
        note: "This shipment is a mock shipment with a lot of notes. But not as many notes as the other shipment. This shipment is a mock shipment with a lot of notes. But not as many notes as the other shipment. This shipment is a mock shipment with a lot of notes. But not as many notes as the other shipment. This shipment is a mock shipment with a lot of notes. But not as many notes as the other shipment.",
      },
    ],
    [
      {
        note: "This shipment is a mock shipment with a lot of notes. Very long notes. Extremely long notes. Notes that are so long that they are longer than the longest notes you have ever seen. This shipment is a mock shipment with a lot of notes. Very long notes. Extremely long notes. Notes that are so long that they are longer than the longest notes you have ever seen. This shipment is a mock shipment with a lot of notes. Very long notes. Extremely long notes. Notes that are so long that they are longer than the longest notes you have ever seen. This shipment is a mock shipment with a lot of notes. Very long notes. Extremely long notes. Notes that are so long that they are longer than the longest notes you have ever seen.",
      },
    ],
    [
      { note: "This shipment is a short mock shipment with short notes." },
      { note: "This shipment is a short mock shipment with short notes." },
      {
        note: "This shipment is a mock shipment with a lot of notes. But not as many notes as the other shipment. This shipment is a mock shipment with a lot of notes. But not as many notes as the other shipment. This shipment is a mock shipment with a lot of notes. But not as many notes as the other shipment. This shipment is a mock shipment with a lot of notes. But not as many notes as the other shipment.",
      },
    ],
    [
      { note: "This shipment is a short mock shipment with short notes." },
      { note: "This shipment is a short mock shipment with short notes." },
    ],
  ],
} as const satisfies Record<string, StopShipmentNotes[]>;

export const mockDispatchStopSuggestedSics = {
  default: [
    {
      sicId: 2,
    },
    {
      sicId: 1,
    },
    {
      sicId: 111,
    },
  ],
} as const satisfies Record<string, SuggestedSic[]>;
