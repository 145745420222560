import { Fragment, useCallback, useRef, useState } from "react";
import { Box } from "@mui/material";
import { CollapsibleItem } from "../../../../components/shared/sidebar/CollapsibleItem";
import { useSelectionContext } from "../../../../context/SelectionContext";
import { useDateSearchParamOrFallbackToToday } from "../../../../hooks/useDateSearchParamOrFallbackToToday";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { dateToInt } from "../../../../utils/dateTimeHelper";
import { isSelectedDateTodayOrInTheFutureForCurrentSic } from "../../../../hooks/useIsSelectedDateTodayOrInTheFutureForCurrentSic";
import { useGetUnplannedTrapsSummary } from "../../../../hooks/react-query/preplanning/useTraps";
import { tableSectionToIconMap } from "../TableActionBar";
import Loading from "../../../../components/shared/layout/Loading";
import {
  useActionsMenuContext,
  type SummaryContext,
} from "../ActionsMenuContext";
import type { ContextMenuRef } from "../../../../components/shared/ContextMenuDropdown";
import ContextMenuDropdown from "../../../../components/shared/ContextMenuDropdown";
import { unifyShipmentContext } from "../../../../utils/unifyShipmentContext";
import { contextMenuActions } from "../../prePlanning/tables/contextMenuActions";
import { DroppableSectionItem } from "./DroppableSectionItem";

export const Traps = () => {
  const contextMenuRef = useRef<ContextMenuRef<SummaryContext>>(null);
  const { openDialog } = useActionsMenuContext();
  const { selected, isAllSelected, clearSelection } = useSelectionContext();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const planDate = dateToInt(selectedDate);
  const [serviceCenter] = useSelectedServiceCenter();

  const [isExpanded, setIsExpanded] = useState(false);

  const { data: traps, isLoading } = useGetUnplannedTrapsSummary(
    serviceCenter.id,
    planDate
  );

  const isEditingAllowed = isSelectedDateTodayOrInTheFutureForCurrentSic(
    selectedDate,
    serviceCenter
  );

  const selectionToContext = useCallback(
    (item?: SummaryContext): SummaryContext[] => {
      if (item) {
        return [
          {
            id: String(item.id),
            shipmentIds: item.shipmentIds,
            type: item.type,
          },
        ];
      }
      return unifyShipmentContext(
        selected.map(({ id }) => {
          const trap = traps?.find((trap) => trap.id.toString() === id);
          return {
            id,
            shipmentIds: trap ? trap.shipmentIds : [],
            type: "trap",
          };
        })
      );
    },
    [selected, traps]
  );

  const contextActions = useCallback(
    (item?: SummaryContext) =>
      contextMenuActions({
        section: "traps",
        shipmentContext: selectionToContext(item),
        openDialog,
        clearSelection,
      }),
    [clearSelection, openDialog, selectionToContext]
  );

  if (isLoading) {
    return <Loading size={32} label="sidebar traps" />;
  }

  // If traps is undefined, since it's the entire section, so do not render it.
  if (!traps) {
    return;
  }

  return (
    <Box>
      <CollapsibleItem
        title="Traps"
        displayCount={traps.length}
        icon={tableSectionToIconMap["trap"]}
        isExpanded={isExpanded}
        onExpand={(value: boolean) => setIsExpanded(value)}
      >
        {traps.map((trap) => {
          const { id, shipmentIds } = trap;
          return (
            <Fragment key={id}>
              <DroppableSectionItem
                data={trap}
                isEditingAllowed={isEditingAllowed}
                isSelected={isAllSelected(shipmentIds, "trap")}
                serviceCenterId={serviceCenter.id}
                selectedDate={selectedDate}
                inboundView="traps"
                icon="trap"
                contextRef={contextMenuRef}
              />
            </Fragment>
          );
        })}
      </CollapsibleItem>
      <ContextMenuDropdown actions={contextActions} ref={contextMenuRef} />
    </Box>
  );
};
