import {
  Fragment,
  useState,
  type MouseEvent,
  type PropsWithChildren,
  type RefObject,
} from "react";

import { Box, IconButton, styled, Typography, useTheme } from "@mui/material";
import {
  ArrowDropDown,
  ContentPaste,
  MonitorWeight,
  Receipt,
} from "@mui/icons-material";
import Loading from "../../../../components/shared/layout/Loading";
import { useSelectionContext } from "../../../../context/SelectionContext";
import {
  useShipmentDetailSearch,
  type SortShipmentsBy,
} from "../../../../hooks/react-query/preplanning/useShipmentDetails";
import { dateToInt } from "../../../../utils/dateTimeHelper";
import type { SummaryContext, SummaryContextType } from "../ActionsMenuContext";
import type { tableSectionToIconMap } from "../TableActionBar";
import type { PlanType } from "../../../../types/planning/plan.type";
import TableLink from "../../../../components/shared/table/TableLink";
import PlannedStatusTag from "../../../../components/shared/StatusTag/PlannedStatusTag";
import { TagsGrid } from "../../../../components/shared/TagsGrid";
import { formatNumber } from "../../../../constants/utils";
import PalletsIcon from "../../../../components/shared/icons/PalletsIcon";
import PiecesIcon from "../../../../components/shared/icons/PiecesIcon";
import { ShipmentItem } from "../../../../components/shared/sidebar/ShipmentItem";
import {
  Accordion,
  AccordionDetails,
} from "../../../../components/shared/layout/Accordion";
import type {
  InboundTabs,
  InboundView,
} from "../../../../types/inbound/inboundView.type";
import type { Tag } from "../../../../types/freight/tag.type";
import type { ContextMenuRef } from "../../../../components/shared/ContextMenuDropdown";

export const Item = styled(Box)(({ theme }) => ({
  padding: "1rem",
  borderRadius: "0.5rem",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const ItemSummary = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const Summary = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "4px",
}));

export const Totals = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "4px",
  fontSize: "0.875rem",
  lineHeight: "1rem",
  color: theme.palette.grey[600],
}));

export const NoResultFound = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  padding: "1rem",
  color: theme.palette.grey[600],
}));

type SectionItemData = {
  id?: number;
  title?: string;
  name?: string | null;
  bills: number;
  pallets: number;
  pieces: number;
  weight: number;
  weightUnit?: string;
  shipmentIds?: number[];
  tags?: Tag[];
  status?: string;
  allFuturePlanDates?: number[];
  allShipmentsPlannedForFuture?: boolean;
  allShipmentsPlanned?: boolean;
  isCommitted?: boolean | null;
};

interface SectionItemProps<T> {
  isEditingAllowed: boolean;
  serviceCenterId: number;
  selectedDate: string;
  data: T;
  tab?: InboundTabs;
  isSelected?: boolean;
  icon?: keyof typeof tableSectionToIconMap;
  contextRef?: RefObject<ContextMenuRef<SummaryContext>>;
  planType?: PlanType;
  sortBy?: SortShipmentsBy;
  inboundView?: InboundView;
}

export const SectionItem = <T extends SectionItemData>({
  isEditingAllowed,
  data,
  selectedDate,
  serviceCenterId,
  icon,
  tab,
  isSelected,
  planType,
  inboundView,
  sortBy,
  contextRef,
}: PropsWithChildren<SectionItemProps<T>>) => {
  const theme = useTheme();

  const {
    id,
    title,
    name,
    bills,
    pallets,
    pieces,
    weight,
    weightUnit,
    allFuturePlanDates,
    allShipmentsPlanned,
    allShipmentsPlannedForFuture,
    isCommitted,
    shipmentIds = [],
    status,
    tags,
  } = data;

  const identifier = id ?? title ?? name ?? "";

  const {
    isSelected: isShipmentSelected,
    isAllSelected,
    handleSelect,
  } = useSelectionContext();

  const [isExpanded, setIsExpanded] = useState(false);

  const { data: shipments = [], isLoading } = useShipmentDetailSearch({
    search: {
      sicId: serviceCenterId,
      planDate: dateToInt(selectedDate),
      shipmentIds,
    },
    enabled: isExpanded,
    sortBy,
  });

  const handleSelection = (ids: number[]) => {
    if (!isEditingAllowed) return;
    handleSelect({
      id: identifier,
      type,
      shipmentIds: ids.map(Number),
    });
  };

  function getType(): SummaryContextType {
    switch (inboundView) {
      case "appointments":
        return "bucket";
      case "sections":
        return "section";
      case "routes":
        return "route";
      case "traps":
        return "trap";
      default:
        return "bucket";
    }
  }

  const type = getType();

  const handleContext = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (!isEditingAllowed) return;
    if (!isAllSelected(shipmentIds, type)) handleSelection(shipmentIds);
    contextRef &&
      contextRef.current?.openContextMenu(event, {
        id: identifier,
        shipmentIds,
        type,
      });
  };

  const isSectionPlanned =
    planType || allShipmentsPlanned
      ? "today"
      : allShipmentsPlannedForFuture
        ? "future"
        : undefined;

  return (
    <Accordion
      sx={{
        border: 0,
        borderRadius: "0.5rem",
        backgroundColor: isSelected
          ? `${theme.palette.action.selected} !important`
          : "unset",
      }}
      expanded={isExpanded}
      slotProps={{ transition: { unmountOnExit: true } }}
      onContextMenu={handleContext}
    >
      <Item onClick={() => handleSelection(shipmentIds)}>
        <ItemSummary>
          <Summary>
            <IconButton
              aria-label={
                isExpanded
                  ? `Collapse ${type} ${identifier}`
                  : `Expand ${type} ${identifier}`
              }
              sx={{ padding: "4px" }}
              onClick={(event) => {
                event.stopPropagation();
                setIsExpanded(!isExpanded);
              }}
            >
              <ArrowDropDown
                sx={{
                  transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            </IconButton>
            <TableLink
              id={String(id)}
              title={name || title || ""}
              inboundView={inboundView}
              icon={icon}
              tab={tab}
              iconSize="small"
              shipmentIds={shipmentIds}
              isLockedRoute={isCommitted}
              planType={planType}
              style={{ fontWeight: 400 }}
            >
              {name || title || ""}
            </TableLink>
          </Summary>
          {status && (
            <PlannedStatusTag
              label={status}
              isPlannedFor={isSectionPlanned}
              futurePlanDates={allFuturePlanDates}
              selectedDate={selectedDate}
            />
          )}
        </ItemSummary>
        <Box sx={{ marginLeft: "2rem" }}>
          <ItemSummary sx={{ marginBottom: "4px" }}>
            <TagsGrid tags={tags} />
          </ItemSummary>
          <ItemSummary>
            <Totals>
              <Receipt sx={{ fontSize: "1rem" }} />
              {formatNumber(bills)}
            </Totals>
            <Totals>
              <PalletsIcon />
              {formatNumber(pallets)}
            </Totals>
            <Totals>
              <PiecesIcon />
              {formatNumber(pieces)}
            </Totals>
            <Totals>
              <MonitorWeight sx={{ fontSize: "1rem" }} />
              {`${formatNumber(weight)} ${weightUnit}`}
            </Totals>
          </ItemSummary>
        </Box>
      </Item>
      <AccordionDetails sx={{ padding: 0, border: 0 }}>
        {isLoading && (
          <Box sx={{ padding: "1rem" }}>
            <Loading size={32} />
          </Box>
        )}
        {shipments.map((shipment, index) => (
          <Fragment key={shipment.id}>
            <ShipmentItem
              parentId={Number(id)}
              index={index}
              isEditingAllowed={isEditingAllowed}
              shipment={shipment}
              type={type}
              isSelected={isShipmentSelected(shipment.id, type)}
            />
          </Fragment>
        ))}
        {shipments.length === 0 && !isLoading && (
          <NoResultFound>
            <ContentPaste sx={{ fontSize: "2rem" }} />
            <Typography>No shipments found.</Typography>
          </NoResultFound>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
