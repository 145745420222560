import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  type Dispatch,
  type PropsWithChildren,
} from "react";
import {
  alpha,
  Box,
  styled,
  Typography,
  useTheme,
  type SxProps,
} from "@mui/material";
import { AssignmentLate, MoveToInboxOutlined } from "@mui/icons-material";
import invariant from "tiny-invariant";
import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { CollapsibleItem } from "../../../../components/shared/sidebar/CollapsibleItem";
import { useDateSearchParamOrFallbackToToday } from "../../../../hooks/useDateSearchParamOrFallbackToToday";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { isSelectedDateTodayOrInTheFutureForCurrentSic } from "../../../../hooks/useIsSelectedDateTodayOrInTheFutureForCurrentSic";
import { useShipmentDetailSearch } from "../../../../hooks/react-query/preplanning/useShipmentDetails";
import { useUnassignedShipmentIds } from "../../../../hooks/react-query/useUnassignedShipments";
import { dateToInt } from "../../../../utils/dateTimeHelper";
import Loading from "../../../../components/shared/layout/Loading";
import { ShipmentItem } from "../../../../components/shared/sidebar/ShipmentItem";
import { useSelectionContext } from "../../../../context/SelectionContext";
import { getDroppableId } from "../../../../utils/dragging";

const EmptyFeedback = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: "0.5rem",
  color: theme.palette.grey[500],
}));

export const UnassignedShipments = () => {
  const theme = useTheme();

  const [isDraggedOverHeader, setIsDraggedOverHeader] = useState(false);
  const { isSelected } = useSelectionContext();

  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const [serviceCenter] = useSelectedServiceCenter();
  const planDate = dateToInt(selectedDate);

  const [isExpanded, setIsExpanded] = useState(false);

  const isEditingAllowed = isSelectedDateTodayOrInTheFutureForCurrentSic(
    selectedDate,
    serviceCenter
  );

  const {
    data: unassignedShipmentIds = [],
    isLoading: unassignedShipmentIdsIsLoading,
  } = useUnassignedShipmentIds(serviceCenter.id, planDate);
  const {
    data: unassignedShipments = [],
    isLoading: unassignedShipmentsLoading,
  } = useShipmentDetailSearch({
    search: {
      shipmentIds: unassignedShipmentIds.map(Number),
      orderBy: "consignee.name",
    },
  });

  const isLoading =
    unassignedShipmentIdsIsLoading || unassignedShipmentsLoading;
  if (isLoading) {
    return (
      <Loading
        styles={{ marginY: "1rem" }}
        size={32}
        label="sidebar unassigned shipments"
      />
    );
  }

  const isDroppedOnHeader: SxProps = {
    backgroundColor: theme.palette.action.hover,
    borderRadius: "0.5rem",
  };

  return (
    <DroppableHeader setIsDraggedOver={setIsDraggedOverHeader}>
      <CollapsibleItem
        style={isDraggedOverHeader ? { ...isDroppedOnHeader } : null}
        title="Unassigned Shipments"
        displayCount={unassignedShipments.length}
        icon={<AssignmentLate />}
        isExpanded={isExpanded}
        onExpand={(value: boolean) => setIsExpanded(value)}
      >
        <Droppable>
          {unassignedShipments.length <= 0 ? (
            <EmptyFeedback>
              <MoveToInboxOutlined />
              <Typography sx={{ fontSize: "0.8rem" }}>
                Drop shipments here
              </Typography>
            </EmptyFeedback>
          ) : (
            unassignedShipments.map((shipment, index) => (
              <Fragment key={shipment.id}>
                <ShipmentItem
                  isDraggable
                  source="unassigned"
                  isSelected={isSelected(shipment.id, "shipment")}
                  isEditingAllowed={isEditingAllowed}
                  index={index}
                  shipment={shipment}
                  type="shipment"
                />
              </Fragment>
            ))
          )}
        </Droppable>
      </CollapsibleItem>
    </DroppableHeader>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.background.default,
  borderRadius: "0.25rem",
  border: `2px dotted ${theme.palette.divider}`,
  padding: "1rem",
  transition: "all 0.2s ease-in-out",
  "&.isOver": {
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    borderColor: theme.palette.primary.main,
  },
}));

interface DroppableHeaderProps {
  setIsDraggedOver: Dispatch<React.SetStateAction<boolean>>;
}

const DroppableHeader = ({
  children,
  setIsDraggedOver,
}: PropsWithChildren<DroppableHeaderProps>) => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const sectionEl = sectionRef.current;
    invariant(sectionEl, "Section component should exist");
    return dropTargetForElements({
      element: sectionEl,
      onDragEnter: () => setIsDraggedOver(true),
      onDragLeave: () => setIsDraggedOver(false),
      onDrop: () => setIsDraggedOver(false),
      getData: () => ({
        // Source: unassigned, Type: shipment, Id: unassigned
        id: getDroppableId("unassigned", "shipment", "unassigned"),
      }),
    });
  }, [setIsDraggedOver]);

  return <Box ref={sectionRef}>{children}</Box>;
};

const Droppable = ({ children }: PropsWithChildren) => {
  const sectionRef = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  useEffect(() => {
    const sectionEl = sectionRef.current;
    invariant(sectionEl, "Section component should exist");
    return dropTargetForElements({
      element: sectionEl,
      onDragEnter: () => setIsDraggedOver(true),
      onDragLeave: () => setIsDraggedOver(false),
      onDrop: () => setIsDraggedOver(false),
      getData: () => ({
        // Source: unassigned, Type: shipment, Id: unassigned
        id: getDroppableId("unassigned", "shipment", "unassigned"),
      }),
    });
  }, []);

  return (
    <Wrapper
      ref={sectionRef}
      className={isDraggedOver ? "isOver" : ""}
      aria-label="Unassign droppable area"
    >
      {children}
    </Wrapper>
  );
};
