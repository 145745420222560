import * as React from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { Add } from "@mui/icons-material";

export type FilterOption<TOptionValue extends string> = {
  label: string;
  value: TOptionValue;
  initialOpen?: boolean;
};

type AddFilterButtonProps<TOptionValue extends string> = {
  options: FilterOption<TOptionValue>[];
  onClose: (value?: FilterOption<TOptionValue>) => void;
};

export function AddFilterButton<TOptionValue extends string>({
  options,
  onClose,
}: Readonly<AddFilterButtonProps<TOptionValue>>) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = (option?: FilterOption<TOptionValue>) => {
    setAnchorEl(null);
    onClose(option);
  };

  return (
    <>
      <Button
        id="add-filter-button"
        aria-controls={open ? "add-filter-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          fontFamily: "Roboto",
          textTransform: "none",
          fontSize: "13px",
          color: (theme) => theme.palette.text.primary,
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        endIcon={<Add fontSize="small" sx={{ color: "black" }} />}
      >
        Add Filter
      </Button>
      <Menu
        id="add-filter-menu"
        aria-labelledby="add-filter-button"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          minWidth: 200,
        }}
      >
        {options.map((option) => (
          <MenuItem
            aria-label={`Add ${option.label} Filter`}
            key={option.value}
            sx={{ minWidth: 200 }}
            onClick={() => handleClose(option)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
