import dayjs from "dayjs";
import {
  EmployeeDriver,
  LoadBoardSchedule,
} from "../../hooks/react-query/linehaul/useLoadBoard";

const NOW = dayjs();

const mockEmployeeDrivers: EmployeeDriver[] = [
  {
    id: 1,
    driverId: 11,
    name: "Daylight Driver",
    ratePerMile: 15.0,
    order: 0,
    loadBoardScheduleId: 99,
  },
];

export const mockLoadBoardSchedulePair: Record<
  "multipleLoad" | "singleLoad",
  LoadBoardSchedule[]
> = {
  multipleLoad: [
    {
      scheduleId: 1,
      loadPairScheduleId: 1,
      bookingNumber: "Harold",
      bookingStatus: "Confirmed",
      carrierRatePerMile: 0,
      tripNumber: 12345678,
      tripStatus: "LinehaulLoaded",
      tripPriority: 1,
      hasLoadPair: true,
      isPublished: true,
      trailerNumber: "T1234567",
      employeeDrivers: mockEmployeeDrivers,
      carrierName: "Avileno Trucking Inc.",
      carrierCode: "M123",
      loadType: "Start",
      originSicCode: "ONT",
      destinationSicCode: "EWR",
      serviceType: "Dedicated",
      loadingDate: NOW,
      pickupDate: NOW,
      gateCutDate: NOW,
      stops: ["DAL", "LAX"],
      deadheads: [{ originSicCode: "EWR", destinationSicCode: "NWY" }],
      tonu: 200,
      totalCost: 1000,
      totalRevenue: 2000,
      netLinehaul: 50,
      isCancelled: false,
      rolloverFee: 250,
    },
    {
      scheduleId: 2,
      carrierRatePerMile: 0,
      loadPairScheduleId: 1,
      bookingNumber: "Harold",
      bookingStatus: "Confirmed",
      tripNumber: 12345678,
      tripStatus: "LinehaulLoaded",
      tripPriority: 1,
      hasLoadPair: false,
      isPublished: true,
      trailerNumber: "T1234567",
      employeeDrivers: mockEmployeeDrivers,
      carrierName: "Avileno Trucking Inc.",
      carrierCode: "M123",
      loadType: "Start",
      originSicCode: "ONT",
      destinationSicCode: "EWR",
      serviceType: "Dedicated",
      loadingDate: NOW,
      pickupDate: NOW,
      gateCutDate: NOW,
      stops: ["DAL", "LAX"],
      deadheads: [{ originSicCode: "EWR", destinationSicCode: "NWY" }],
      tonu: 200,
      totalCost: 1000,
      totalRevenue: 500,
      netLinehaul: -50,
      isCancelled: false,
      rolloverFee: null,
    },
  ],
  singleLoad: [
    {
      scheduleId: 3,
      loadPairScheduleId: 2,
      carrierRatePerMile: 0,
      bookingNumber: "Harold",
      bookingStatus: "Confirmed",
      tripNumber: 12345678,
      tripStatus: "LinehaulLoaded",
      tripPriority: 1,
      hasLoadPair: false,
      isPublished: true,
      trailerNumber: "T1234567",
      employeeDrivers: mockEmployeeDrivers,
      carrierName: "Avileno Trucking Inc.",
      carrierCode: "M123",
      loadType: "Start",
      originSicCode: "ONT",
      destinationSicCode: "EWR",
      serviceType: "Dedicated",
      loadingDate: NOW,
      pickupDate: NOW,
      gateCutDate: NOW,
      stops: ["DAL", "LAX"],
      deadheads: [{ originSicCode: "EWR", destinationSicCode: "NWY" }],
      tonu: 200,
      totalCost: 1000,
      totalRevenue: 3000,
      netLinehaul: 60,
      isCancelled: false,
      rolloverFee: null,
    },
  ],
};
