import { z } from "zod";

export const summarySchema = z.object({
  shipmentIds: z.number().array(),
  shipmentsCount: z.number(),
  shipmentsPallets: z.number(),
  shipmentsPieces: z.number(),
  shipmentsWeight: z.number(),
  shipmentsWeightUnit: z.enum(["lbs"]).optional(),
});

type Summary = z.infer<typeof summarySchema>;

export const summaryTransformation = <T extends Summary>(input: T) => ({
  ...input,
  bills: input.shipmentsCount,
  pallets: input.shipmentsPallets,
  pieces: input.shipmentsPieces,
  weight: input.shipmentsWeight,
  weightUnit: input.shipmentsWeightUnit,
});
