import { Fragment, useState } from "react";
import { Route } from "@mui/icons-material";
import CollapsibleSectionItem from "../AccordionShipmentItem/CollapsibleSectionItem";
import { AccordionWithContentAndActionBar } from "../AccordionWithContentAndActionBar";
import { useDateSearchParamOrFallbackToToday } from "../../../../hooks/useDateSearchParamOrFallbackToToday";
import { useDeliveryRoutesSummary } from "../../../../hooks/react-query/useDeliveryRoutes";
import Loading from "../../../../components/shared/layout/Loading";
import { dateToInt } from "../../../../utils/dateTimeHelper";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { useSelectionContext } from "../../../../context/SelectionContext";
import { useActionsMenuContext } from "../ActionsMenuContext";

import { MergeButton } from "../MergeButton";
import { isSelectedDateTodayOrInTheFutureForCurrentSic } from "../../../../hooks/useIsSelectedDateTodayOrInTheFutureForCurrentSic";
import { AddRoutesButton } from "./actions/AddRoutesButton";

export const DeliveryRoutes = () => {
  const { selected, clearSelection } = useSelectionContext();
  const { shipments, openDialog } = useActionsMenuContext();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const [serviceCenter] = useSelectedServiceCenter();
  const title = "Routes";
  const planDate = dateToInt(selectedDate);
  const [isExpanded, setIsExpanded] = useState(false);
  const isEditingAllowed = isSelectedDateTodayOrInTheFutureForCurrentSic(
    selectedDate,
    serviceCenter
  );

  const { data: routes = [], isLoading } = useDeliveryRoutesSummary(
    planDate,
    serviceCenter.id
  );

  selected.concat(shipments);

  const selectedRoutes = selected.filter((s) => s.type === "route");

  if (isLoading) return <Loading label="sidebar routes" />;

  return (
    <AccordionWithContentAndActionBar
      onChange={(value: boolean) => setIsExpanded(value)}
      title={`${title} (${routes.length})`}
      icon={<Route />}
      actionButtons={
        isEditingAllowed
          ? [
              <AddRoutesButton key={1} date={selectedDate}>
                Add Route
              </AddRoutesButton>,
              <MergeButton
                key={2}
                selected={selectedRoutes}
                parentRecords={routes}
                openDialog={() => {
                  openDialog({
                    name: "merge-routes",
                    shipmentContext: selectedRoutes,
                    clearSelection: () => clearSelection("route"),
                  });
                }}
              >
                Merge Routes
              </MergeButton>,
            ]
          : []
      }
    >
      {routes.map((routeSummary) => {
        const {
          id,
          title,
          type,
          shipmentIds,
          shipmentsCount,
          shipmentsPallets,
          shipmentsPieces,
          shipmentsWeight,
          shipmentsWeightUnit,
          isAvailableForMassage,
        } = routeSummary;
        return (
          <Fragment key={routeSummary.id}>
            <CollapsibleSectionItem
              id={id}
              title={title}
              name={type ?? ""}
              serviceCenterId={serviceCenter.id}
              isEditingAllowed={isEditingAllowed}
              shipmentIds={shipmentIds}
              shipmentsCount={shipmentsCount}
              shipmentsPallets={shipmentsPallets}
              shipmentsPieces={shipmentsPieces}
              shipmentsWeight={shipmentsWeight}
              shipmentsWeightUnit={shipmentsWeightUnit || "lbs"}
              section="routes"
              isDropDisabled={!isExpanded}
              isAvailableForMassage={isAvailableForMassage}
              sortBy="consigneeName"
              planDate={selectedDate}
            />
          </Fragment>
        );
      })}
    </AccordionWithContentAndActionBar>
  );
};
