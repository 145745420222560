import { autoScrollForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import { useEffect, useRef, type PropsWithChildren } from "react";
import styled from "@emotion/styled";
import invariant from "tiny-invariant";
import { Drawer } from "../Drawer";
import { SidebarHeader, type SidebarHeaderProps } from "./SidebarHeader";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;

type SidebarProps = SidebarHeaderProps & {
  isOpen: boolean;
  label?: string;
  fixed?: boolean;
  width?: number;
  position?: "left" | "right";
  children?: React.ReactNode;
  closeIconTitle?: string;
  closeIconAriaLabel?: string;
};

/** A Collapsible Sidebar component that's usually used as a helper of the main content, holding filters and sometimes items that can dragged. */
export const CollapsibleSidebar = ({
  isOpen,
  onClose,
  width = 340,
  position = "left",
  date,
  serviceCenter,
  fixed = false,
  children,
  closeIconTitle,
  closeButtonAriaLabel,
  label,
}: PropsWithChildren<SidebarProps>) => {
  const ref = useRef<any>(null);

  useEffect(() => {
    const element = ref.current;
    invariant(element, "Drawer Content element not set to scroll");
    return autoScrollForElements({
      element,
    });
  });

  return (
    <Drawer anchor={position} open={isOpen} width={width} aria-label={label}>
      <SidebarHeader
        fixed={fixed}
        date={date}
        serviceCenter={serviceCenter}
        onClose={onClose}
        closeIconTitle={closeIconTitle}
        closeButtonAriaLabel={closeButtonAriaLabel}
      />
      <Content ref={ref}>{children}</Content>
    </Drawer>
  );
};
