import { Box, Checkbox } from "@mui/material";
import {
  CalendarViewMonth as PalletsIcon,
  ViewQuilt as PiecesIcon,
} from "@mui/icons-material";
import { Shipment } from "../../../services/prePlanningService.types";
import ShipmentTypography from "../shared/AccordionShipmentItem/ShipmentTypography";
import { LeftColumn, RightColumn } from "./ShipmentItem";

interface SideBarRowDetailsProps {
  shipment: Shipment;
  selectedShipmentArr: Shipment[];
  individualCheckBoxHandler?: (
    event: React.ChangeEvent<HTMLInputElement>,
    selectedShipment: Shipment
  ) => void;
  padding?: string;
}
export const SideBarRowDetails = ({
  shipment,
  selectedShipmentArr,
  individualCheckBoxHandler,
  padding = "",
}: SideBarRowDetailsProps) => {
  const individualCheckBoxStatus = (shipmentId: number) => {
    const selectedShipmentIds = selectedShipmentArr.map(
      (shipment: Shipment) => shipment.id
    );
    return selectedShipmentIds.includes(shipmentId);
  };
  const consignee = shipment.consignee;
  return (
    <Box
      style={{
        display: "flex",
        borderBottom: "1px solid rgba(0, 0, 0, .125)",
        padding,
      }}
    >
      {individualCheckBoxHandler && (
        <Box style={{ width: "15%", verticalAlign: "top" }}>
          <Checkbox
            color="secondary"
            style={{ flex: 1 }}
            checked={individualCheckBoxStatus(shipment.id)}
            onChange={(e) => {
              individualCheckBoxHandler(e, shipment);
            }}
          />
        </Box>
      )}
      <Box style={{ width: "85%", position: "relative" }}>
        <Box display="flex">
          {!consignee ? (
            "No consignee details available"
          ) : (
            <>
              <LeftColumn
                consignee={consignee}
                proNumber={shipment.proNumber}
                shipper={shipment.shipper}
              />
              <RightColumn
                appointmentDate={shipment.appointmentDate}
                location={shipment.location}
                sic={shipment.sic}
                status={shipment.status}
                tags={shipment.tags}
                isPlanned={shipment.isPlanned}
              />
            </>
          )}
        </Box>
        <Box display="flex" gap={5}>
          <Box display="flex" alignItems="center">
            <PalletsIcon />
            <ShipmentTypography>
              {shipment.pallets.toLocaleString()}
            </ShipmentTypography>
          </Box>
          <Box display="flex" alignItems="center">
            <PiecesIcon />
            <ShipmentTypography>
              {shipment.pieces.toLocaleString()}
            </ShipmentTypography>
          </Box>
          <Box display="flex" alignItems="center">
            <ShipmentTypography>
              {shipment.weight.toLocaleString()} {shipment.weightUnit}
            </ShipmentTypography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
