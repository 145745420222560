import AddTaskIcon from "@mui/icons-material/AddTask";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { memo } from "react";
import { Box, IconButton, useTheme } from "@mui/material";
import { useMassageRoute } from "../../../hooks/react-query/useDeliveryRoutes";
import Loading from "../../../components/shared/layout/Loading";
import { PlanTableFormat } from "../../../types/planning/plan.type";
import { useSelectedServiceCenter } from "../../../hooks/useSelectedServiceCenter";
import { useDateSearchParamOrFallbackToToday } from "../../../hooks/useDateSearchParamOrFallbackToToday";
import { dateToInt } from "../../../utils/dateTimeHelper";
import { toast } from "../../../utils/snackbarHelper";
import { toastMessage } from "../../../constants/strings";
import { Tooltip } from "../../../components/shared/Tooltip";

type MassageRouteProps = {
  plan: PlanTableFormat;
  style?: React.CSSProperties;
};

export const MassageRoute = memo(({ plan, style }: MassageRouteProps) => {
  const [serviceCenter] = useSelectedServiceCenter();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const dateInt = dateToInt(selectedDate);

  const theme = useTheme();

  const massageRoute = useMassageRoute({
    date: dateInt,
    sicId: serviceCenter.id,
    onSuccess: () => {
      toast(
        toastMessage.shared.massageRoute.success(
          plan.routeName,
          plan.isPlanCompleted
        ),
        {
          variant: "success",
          preventDuplicate: true,
        }
      );
    },
  });

  const handleMassageRoute = () => {
    massageRoute.mutate({
      id: plan.id,
      isPlanCompleted: !plan.isPlanCompleted,
    });
  };

  if (massageRoute.isPending) {
    return <Loading containerWidth={32} size={32} />;
  }

  return (
    <Box sx={style}>
      <Tooltip
        aria-label={`Massage Route ${plan.id}`}
        content={toastMessage.shared.massageRoute.tooltipText(
          plan.isPlanCompleted
        )}
      >
        <IconButton
          onClick={handleMassageRoute}
          aria-label={`Massage Route Button ${plan.id}`}
        >
          {plan.isPlanCompleted ? (
            <CheckCircleIcon style={{ color: theme.palette.primary.main }} />
          ) : (
            <AddTaskIcon style={{ color: theme.palette.grey[700] }} />
          )}
        </IconButton>
      </Tooltip>
    </Box>
  );
});

MassageRoute.displayName = "MassageRoute";
