import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl as FormControlMUI,
  IconButton,
  styled,
  TextField,
} from "@mui/material";
import { FormEvent, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { Close, DoorSliding } from "@mui/icons-material";
import { FlexRow } from "../../../../../components/shared/layout/Flex";
import { useDoorsBySicIdAndPlanDate } from "../../../../../hooks/react-query/useDoors";
import {
  CreateTrapRequest,
  useCreateTrap,
  useInvalidateTraps,
} from "../../../../../hooks/react-query/preplanning/useTraps";
import { Trap } from "../../../../../types/planning/trap.type";
import { dateToInt } from "../../../../../utils/dateTimeHelper";
import Loading from "../../../../../components/shared/layout/Loading";
import { useInvalidateQueryPlannedRoutes } from "../../../../../hooks/react-query/usePlannedRoutes";
import { toast } from "../../../../../utils/snackbarHelper";
import { LoadingButton } from "../../../../../components/shared/LoadingButton";
import { MAX_TRAP_NAME_LENGTH } from "../../../../../constants/fieldNameLengthConstants";
import { useInvalidateQueryRoutes } from "../../../../../hooks/react-query/useDeliveryRoutes";
import { toastMessage } from "../../../../../constants/strings";
import UniqueShipmentIdCountMessage from "../../../../../components/shared/UniqueShipmentIdCountMessage";
import { useActionsMenuContext } from "../../../shared/ActionsMenuContext";
import { useSelectedServiceCenter } from "../../../../../hooks/useSelectedServiceCenter";
import { useDateSearchParamOrFallbackToToday } from "../../../../../hooks/useDateSearchParamOrFallbackToToday";
import { useInvalidateAllQueryShipmentDetails } from "../../../../../hooks/react-query/preplanning/useShipmentDetails";
import ClosedStatusWarning from "../../../shared/ClosedStatusWarning";
import { EquipmentSelection } from "../../../shared/EquipmentSelection";
import { getDoorOptionsFromDoors } from "../../../shared/doorNumberUtils";
import { AutocompleteAvailabilityOption } from "../../../../../components/shared/AutocompleteAvailabilityOption";
import { useInvalidateQueryAppointmentBucketData } from "../../../../../hooks/react-query/preplanning/useGetAppointmentBucket";
import { isErrorResponse } from "../../../../../utils/errorResponse";

type ValidationErrors = {
  trapName?: string;
};

const FormControl = styled(FormControlMUI)(() => ({
  width: "100%",
  marginTop: "1rem",
}));

export type AddTrapFormData = {
  title: string;
  name: string;
  doorId: number | null;
  tractorEquipmentId: number | null;
  trailerEquipmentId: number | null;
  straightTruckEquipmentId: number | null;
};

const emptyForm: AddTrapFormData = {
  title: "",
  name: "",
  doorId: null,
  tractorEquipmentId: null,
  trailerEquipmentId: null,
  straightTruckEquipmentId: null,
};

export const AddTrapDialog = () => {
  const {
    closeDialog,
    shipments,
    setShipments,
    planSummaries,
    clearSelection,
  } = useActionsMenuContext();

  const [serviceCenter] = useSelectedServiceCenter();
  const [formData, setFormData] = useState(emptyForm);
  const [searchParams, setSearchParams] = useSearchParams();
  const [date] = useDateSearchParamOrFallbackToToday();
  const planDate = dateToInt(date);

  // Queries
  const doorsQuery = useDoorsBySicIdAndPlanDate(serviceCenter.id, planDate);
  const doorOptions = getDoorOptionsFromDoors(doorsQuery.data ?? []);

  const invalidateQueryPlannedRoutes = useInvalidateQueryPlannedRoutes();
  const invalidateQueryRoutes = useInvalidateQueryRoutes();
  const { invalidateAllQueryShipmentDetails } =
    useInvalidateAllQueryShipmentDetails();

  const { invalidateQueryAppointmentBucketData } =
    useInvalidateQueryAppointmentBucketData();
  const invalidateTraps = useInvalidateTraps();

  const [formValidationErrors, setFormValidationErrors] =
    useState<ValidationErrors>({});

  const showClosedStatusWarning = planSummaries.some((plan) =>
    shipments.some(
      (s) =>
        Number(s.id) === plan.id &&
        plan.type.includes(s.type) &&
        !plan.isAvailableForMassage
    )
  );

  const validateForm = () => {
    const validationErrors: ValidationErrors = {};
    if (formData.name.length === 0) {
      validationErrors.trapName = "Trap Name is required";
    }
    setFormValidationErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSuccess = (data: Trap, variables: CreateTrapRequest) => {
    closeDialog();

    setFormData(emptyForm);
    createTrap.reset();
    invalidateQueryPlannedRoutes(planDate, serviceCenter.id);
    invalidateQueryRoutes(planDate, serviceCenter.id);
    invalidateAllQueryShipmentDetails();
    clearSelection?.();
    setShipments([]);

    searchParams.set("highlightedTrapId", String(data.id));
    setSearchParams(searchParams);

    toast(
      toastMessage.inbound.createTrap.success(
        formData.name,
        variables.assignments.flatMap((assignment) => assignment.shipmentIds)
          .length
      ),
      {
        variant: "success",
      }
    );
  };

  const handleError = (error: unknown) => {
    if (!axios.isAxiosError(error)) return;

    const errorData = error.response?.data;

    if (!isErrorResponse(errorData)) {
      toast(toastMessage.generics.error, { variant: "error" });
      return;
    }

    const errorMessage = errorData.errorMessage;

    const variant = errorData.errorMessage ? "warning" : "error";

    toast(errorMessage, { variant });
  };

  const createTrap = useCreateTrap({
    onSuccess: handleSuccess,

    onError: handleError,
    onSettled: () => {
      invalidateQueryAppointmentBucketData(serviceCenter.id);
      invalidateTraps();
    },
  });

  const handleCreateTrap = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateForm()) return;
    if (formData.name.length > 0) {
      createTrap.mutate({
        ...formData,
        sicId: serviceCenter.id,
        assignments: shipments.map((assignment) => ({
          id: assignment.id,
          shipmentIds: assignment.shipmentIds,
          type: assignment.type,
          date: planDate,
          sicId: serviceCenter.id,
        })),
        unixDate: planDate,
      });
    }
  };

  if (!doorsQuery.data) {
    return (
      <Dialog open>
        <DialogTitle>Loading...</DialogTitle>
        <DialogContent>
          <Loading />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open>
      <form onSubmit={handleCreateTrap}>
        <DialogTitle sx={{ fontWeight: "bold" }}>Create New Trap</DialogTitle>
        {showClosedStatusWarning && <ClosedStatusWarning />}
        <Box position="absolute" top={10} right={10}>
          <IconButton onClick={closeDialog}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent sx={{ width: "60vw", maxWidth: "500px" }}>
          <UniqueShipmentIdCountMessage shipments={shipments} />
          <DialogContentText fontSize={14} fontWeight={600} mb={1}>
            Trap Details
          </DialogContentText>
          <FlexRow>
            <FormControl>
              <TextField
                sx={{ width: "100%" }}
                value={formData.name}
                onChange={(e) => {
                  setFormData((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }));
                }}
                id="trap-name"
                placeholder="Trap Name *"
                label="Trap Name *"
                error={!!formValidationErrors.trapName}
                inputProps={{ maxLength: MAX_TRAP_NAME_LENGTH }}
                helperText={formValidationErrors.trapName}
              />
            </FormControl>
          </FlexRow>
          <FlexRow>
            <FormControl>
              <Autocomplete
                value={
                  doorOptions.find(
                    (d) => Number(d.value) === formData.doorId
                  ) ?? null
                }
                options={doorOptions}
                onChange={(_, newValue) => {
                  setFormData((prev) => ({
                    ...prev,
                    doorId: newValue?.value ?? null,
                  }));
                }}
                getOptionLabel={(door) => door.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Door"
                    label="Door Assignment"
                  />
                )}
                groupBy={(option) => option.group}
                renderOption={(props, option) => (
                  <AutocompleteAvailabilityOption
                    key={option.name}
                    listItemProps={props}
                    name={option.name}
                    assignment={option.assignment}
                    leftIcon={<DoorSliding />}
                  />
                )}
              />
            </FormControl>
          </FlexRow>
          <FlexRow style={{ marginTop: "15px" }}>
            <EquipmentSelection
              trailerId={formData.trailerEquipmentId}
              tractorId={formData.tractorEquipmentId}
              straightTruckId={formData.straightTruckEquipmentId}
              setTrailerValue={(newValue) =>
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  trailerEquipmentId: newValue?.id || null,
                }))
              }
              setStraightTruckValue={(newValue) =>
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  straightTruckEquipmentId: newValue?.id || null,
                }))
              }
            />
          </FlexRow>
        </DialogContent>
        <DialogActions sx={{ margin: "10px 20px 20px 20px" }}>
          <Button variant="text" onClick={closeDialog}>
            Cancel
          </Button>
          <LoadingButton
            isLoading={createTrap.isPending}
            loadingLabel="Creating Trap..."
          >
            Create Trap
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
