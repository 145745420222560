import { NestedMenuItem } from "mui-nested-menu";
import { Button, ListItemIcon, MenuItem, useTheme } from "@mui/material";
import { Add, ArrowRight, Route, ViewModule } from "@mui/icons-material";
import type { PlanTableFormat } from "../../../../types/planning/plan.type";
import { labelButtonStyle } from "../../../../components/shared/PopupMenu";
import {
  useActionsMenuContext,
  type SummaryContext,
} from "../../shared/ActionsMenuContext";

type CreateRouteTrapMenuItemProps = {
  routesOrTraps: PlanTableFormat[];
  shipmentContext: SummaryContext[];
  isParentOpen: boolean;
  handleClose?: () => void;
};

export const CreateRouteTrapMenuItem = ({
  routesOrTraps,
  shipmentContext,
  isParentOpen,
  handleClose,
}: CreateRouteTrapMenuItemProps) => {
  const theme = useTheme();
  const { openDialog } = useActionsMenuContext();

  const handleCreateRoute = () => {
    openDialog({
      name: "create-new-route",
      planSummaries: routesOrTraps,
      shipmentContext,
    });
    handleClose?.();
  };

  const handleCreateTrap = () => {
    openDialog({
      name: "create-new-trap",
      planSummaries: routesOrTraps,
      shipmentContext,
    });
    handleClose?.();
  };

  return (
    <NestedMenuItem
      delay={300}
      sx={{
        paddingX: "1rem",
      }}
      rightIcon={
        <ListItemIcon>
          <ArrowRight sx={{ color: theme.palette.grey[700] }} />
        </ListItemIcon>
      }
      leftIcon={
        <ListItemIcon>
          <Add sx={{ padding: "6px 0", color: theme.palette.grey[700] }} />
        </ListItemIcon>
      }
      MenuProps={{
        anchorOrigin: { horizontal: "left", vertical: "top" },
        "aria-label": "statuses-menu",
      }}
      renderLabel={() => (
        <Button sx={labelButtonStyle} disableRipple>
          Create
        </Button>
      )}
      parentMenuOpen={isParentOpen}
    >
      <MenuItem onClick={handleCreateRoute}>
        <ListItemIcon>
          <Route />
        </ListItemIcon>
        <Button sx={labelButtonStyle} disableRipple>
          Route
        </Button>
      </MenuItem>
      <MenuItem onClick={handleCreateTrap}>
        <ListItemIcon>
          <ViewModule />
        </ListItemIcon>
        <Button sx={labelButtonStyle} disableRipple>
          Trap
        </Button>
      </MenuItem>
    </NestedMenuItem>
  );
};
