import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectProps } from "@mui/material/Select";
import { Typography, Box, FormHelperText } from "@mui/material";
import { ReactNode } from "react";

type SelectOption = {
  name: string;
  value: string | number;
  metadata?: Record<string, string | number>;
};

export type DropdownSelectProps<T> = Omit<SelectProps, "error" | "variant"> & {
  /** Input label */
  label: string;

  /** Array of options */
  options: (SelectOption & T)[];

  /** Error message to display below the select. Also styles the input in red when provided. */
  error?: string;

  /** Function to render custom menu item children */
  renderOption?: (option: SelectOption & T) => ReactNode;
};

const DropdownSelect = <T,>({
  label,
  options,
  title,
  required,
  sx,
  error,
  renderOption,
  ...rest
}: DropdownSelectProps<T>) => {
  const labelId = `select-${label}-label`;

  return (
    <Box sx={sx}>
      {title && (
        <Typography fontSize={14} fontWeight={600} mb={1}>
          {title}
        </Typography>
      )}

      <FormControl
        sx={{ width: "100%" }}
        size="small"
        required={required}
        error={Boolean(error && error.length > 0)}
      >
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
          labelId={labelId}
          label={label}
          title={title}
          size="small"
          required={required}
          {...rest}
        >
          <MenuItem disabled={required} value={undefined}>
            <em>None</em>
          </MenuItem>
          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              aria-label={option.name}
            >
              {renderOption ? renderOption(option) : option.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default DropdownSelect;
