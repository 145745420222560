import * as React from "react";
import { Button } from "@mui/material";

type ClearFilterButtonProps = {
  onClick: () => void;
};

export function ClearFilterButton({ onClick }: ClearFilterButtonProps) {
  return (
    <Button
      aria-label="Clear Filters"
      color="inherit"
      sx={{ textTransform: "none", fontSize: 13, fontFamily: "Roboto" }}
      onClick={onClick}
    >
      Clear All
    </Button>
  );
}
