import { Filter } from "../../../types/filter";
import {
  Prefix,
  getNonEmptyUniqueSortedOptions,
  handlePrefix,
  isActiveFilter,
  stringOptionFactory,
} from "../utils";

export type NameQueryParam = "name";
type SomethingWithName = {
  name?: string | null;
};

export const getNameFilter = (
  data: SomethingWithName[],
  label?: string,
  prefix?: Prefix
): Filter => ({
  label: label ?? "Name",
  queryParam: handlePrefix("name", prefix),
  options: getNonEmptyUniqueSortedOptions(
    data.map((d) => stringOptionFactory(d.name))
  ),
});

export const filterName = <T extends SomethingWithName>(
  data: T[],
  filters?: string[]
): T[] =>
  isActiveFilter(filters)
    ? data.filter(({ name }) => filters.includes(name ?? ""))
    : data;
