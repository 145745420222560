import { useMutation, useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { http } from "../../../utils/httpCommon";
import { apiUrls } from "../../../utils/apiUrls";
import { queryClient } from "../../../queryClientProvider";
import { AssignUnassignSics } from "../../../pages/settings/linehaul/regions/AssignUnassignSicsDialog";

const queryKeys = {
  regions: (query: string) => ["linehaul", "regions", query],
  regionsId: () => ["linehaul", "regions", "ids"],
};

const regionIdSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const useGetRegionsIds = () =>
  useQuery({
    queryKey: queryKeys.regionsId(),
    queryFn: async () => {
      const { data } = await http.get(apiUrls.getRegionsWithSics);
      return regionIdSchema.array().parse(data);
    },
  });

const regionSchema = z.object({
  id: z.number(),
  name: z.string(),
  serviceCenters: z
    .object({
      code: z.string(),
    })
    .array()
    .optional(),
});

export type Region = z.infer<typeof regionSchema>;

export const useRegions = (query: string) =>
  useQuery({
    queryKey: queryKeys.regions(query),
    queryFn: async () => {
      const { data } = await http.get(apiUrls.getRegionsWithSics);
      return regionSchema.array().parse(data);
    },
    select(data) {
      return data.filter((x) =>
        x.name.toUpperCase().includes(query.toUpperCase())
      );
    },
  });

export const useCreateRegion = (query: string) =>
  useMutation({
    mutationFn: (name: string) => http.post(apiUrls.createRegion, { name }),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.regions(query),
      });
    },
  });

export const useUpdateRegion = (query: string) =>
  useMutation({
    mutationFn: (value: AssignUnassignSics) =>
      http.put(apiUrls.updateRegion(value.id), value),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.regions(query),
      });
    },
  });

export const useUnassignRegionFromSic = (query: string) =>
  useMutation({
    mutationFn: (code: string) => http.put(apiUrls.unassignSic(code)),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.regions(query),
      });
    },
  });

export const useDeleteRegion = (query: string) =>
  useMutation({
    mutationFn: (id: number) => http.delete(apiUrls.deleteRegion(id)),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.regions(query),
      });
    },
  });
