import { Button, ListItemIcon, MenuItem } from "@mui/material";
import { AssignmentLate } from "@mui/icons-material";
import Loading from "../../../../components/shared/layout/Loading";
import { actionsMap, type ShipmentActionTypeId } from "../ShipmentActionDialog";
import { toast } from "../../../../utils/snackbarHelper";
import type { PlanTableFormat } from "../../../../types/planning/plan.type";
import { routeTrapType } from "../../../../constants/RouteTrapTypes";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { useDateSearchParamOrFallbackToToday } from "../../../../hooks/useDateSearchParamOrFallbackToToday";
import { dateToInt } from "../../../../utils/dateTimeHelper";
import { labelButtonStyle } from "../../../../components/shared/PopupMenu";
import type { SummaryContext } from "../../shared/ActionsMenuContext";
import { useUnassignShipments } from "../../../../hooks/react-query/useAssignShipments";

type UnassignMenuItemProps = {
  routesOrTraps: PlanTableFormat[];
  shipmentContext: SummaryContext[];
  clearSelection?: () => void;
};

export const UnassignMenuItem = ({
  routesOrTraps,
  shipmentContext,
  clearSelection,
}: UnassignMenuItemProps) => {
  const [serviceCenter] = useSelectedServiceCenter();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const planDate = dateToInt(selectedDate);

  const onSuccessfulAssignment = (actionType: ShipmentActionTypeId) => {
    const { description } = actionsMap[actionType];

    toast(description, {
      variant: "success",
    });

    clearSelection?.();
  };

  const { mutate: unassignShipment, isPending: isUnassignPending } =
    useUnassignShipments({
      onSuccess: async () => {
        await onSuccessfulAssignment("unassign");
      },
    });

  const handleUnassign = () => {
    if (isUnassignPending) return;
    unassignShipment({
      origins: routesOrTraps.map((routeOrTrap) => ({
        id: String(routeOrTrap.id),
        type: routeTrapType[routeOrTrap.type],
        shipmentIds: shipmentContext.flatMap((context) => context.shipmentIds),
      })),
      target: {
        date: planDate,
        sicId: serviceCenter.id,
        type: "unassign",
      },
    });
  };

  return (
    <MenuItem onClick={handleUnassign}>
      <ListItemIcon>
        {isUnassignPending ? (
          <Loading size={32} containerWidth={32} />
        ) : (
          <AssignmentLate />
        )}
      </ListItemIcon>
      <Button sx={labelButtonStyle} disableRipple>
        {isUnassignPending ? "Loading..." : "Unassign"}
      </Button>
    </MenuItem>
  );
};
