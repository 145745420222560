// Cspell: ignore ASSGN
// Cspell: ignore LOADINGMAN
import { z } from "zod";
import { tagsSchema } from "../types/freight/tag.type";
import { summarySchema, summaryTransformation } from "../types/summary.type";
import { sortShipmentTags } from "../utils/shipmentTags";

const shipperSchema = z.object({
  id: z
    .number()
    .nullish()
    .transform((x) => x ?? undefined),
  name: z
    .string()
    .nullish()
    .transform((x) => x ?? undefined),
});
export type Shipper = z.infer<typeof shipperSchema>;

const consigneeSchema = z.object({
  id: z
    .number()
    .nullish()
    .transform((x) => x ?? undefined),
  name: z
    .string()
    .nullish()
    .transform((x) => x ?? undefined),
  address: z
    .string()
    .nullish()
    .transform((x) => x ?? undefined),
  city: z
    .string()
    .nullish()
    .transform((x) => x ?? undefined),
  state: z
    .string()
    .nullish()
    .transform((x) => x ?? undefined),
  zipCode: z
    .string()
    .nullish()
    .transform((x) => x ?? undefined),
});

export type Consignee = z.infer<typeof consigneeSchema>;

export const driverSchema = z.object({
  id: z.number(),
  name: z.string().nullable(),
  refCode: z.string().nullish(), // TODO: When removing "inbound-planning-driver-ref-client" flag, change this to "nullable" instead
  isAvailable: z.boolean().default(false),
});

export type Driver = z.infer<typeof driverSchema>;

export const equipmentSchema = z.object({
  id: z.number(),
  name: z.string().nullable(),
  isActive: z.boolean().default(false),
});

export type Equipment = z.infer<typeof equipmentSchema>;

// In the future this schema will contain more than just the lbs unit
// IODD-1356, removes anything but lbs
const weightUnitSchema = z.enum(["lbs"]);
export type WeightUnit = z.infer<typeof weightUnitSchema>;

export const shipmentSchema = z
  .object({
    id: z.number(),
    tripNumber: z.number().or(z.string()).nullable(),
    proNumber: z.string(),
    routeId: z.number().nullable().optional(),
    serviceDue: z.date().nullable(),
    appointmentDate: z.date().nullable(),
    appointmentEndDate: z.date().nullable(),
    etaDate: z.date().nullable(),
    shipper: shipperSchema.nullish().transform((x) => x ?? undefined),
    consignee: consigneeSchema.nullish().transform((x) => x ?? undefined),
    pallets: z.number(),
    pieces: z.number(),
    weight: z.number(),
    weightUnit: weightUnitSchema,
    status: z.string(),
    isPlanned: z.boolean().default(false),
    futurePlanDates: z.array(z.number()).default([]),
    tags: tagsSchema,
    sic: z.string().nullable(),
    appointmentTime: z.number().optional(),
    assignment: z.string().optional(),
    location: z.string().nullable(),
    stopSeq: z.number().optional().nullable(),
    lat: z.number().optional().nullable(),
    lng: z.number().optional().nullable(),
    presetRouteName: z.string().nullable(),
    presetRouteId: z.number().nullable(),
    isManualAssign: z.boolean().default(false),
    highlightedRow: z
      .boolean()
      .nullish()
      .transform((x) => x ?? undefined),
    equipment: equipmentSchema.nullish(),
    isAvailableToReconcile: z.boolean().default(false),
  })
  .transform((input) => ({
    ...input,
    serviceDueDate: input.serviceDue,
    destinationSicCode: input.sic,
    futurePlanDates: input.futurePlanDates,
  }));

export type Shipment = z.infer<typeof shipmentSchema>;
export const shipmentsSchema = z.array(shipmentSchema);

const sectionSchema = summarySchema
  .extend({
    id: z.number(),
    title: z.string(),
    name: z.string().nullable(),
    date: z.date().optional(),
    shipmentsTags: tagsSchema,
  })
  .transform(summaryTransformation)
  .transform((input) => ({
    ...input,
    tags: sortShipmentTags(input.shipmentsTags),
    shipmentsTags: sortShipmentTags(input.shipmentsTags),
  }));

export const sectionsSchema = z.array(sectionSchema);
export type Section = z.infer<typeof sectionSchema>;

export const responseSectionSchema = z.array(sectionSchema);

const routeSummarySchema = summarySchema.extend({
  id: z.number(),
  title: z.string(),
  name: z.string().optional(),
  type: z.string().optional(),
  isAvailableForMassage: z.boolean(),
  status: z.string(),
});

export const routesSummaryResponse = z.array(routeSummarySchema);

export const allRouteTableSchema = routeSummarySchema
  .extend({
    door: z.number().nullish(),
    zone: z.string().optional(),
    location: z.string().optional(),
    startTime: z.date().nullable(),
    endTime: z.date().nullable(),
    endPoint: z.string().nullable(),
    driver: driverSchema.nullish(),
    trailer: equipmentSchema.nullish(),
    tractor: equipmentSchema.nullish(),
    straightTruck: equipmentSchema.nullish(),
    status: z.string(),
    shipmentsTags: tagsSchema,
    allShipmentsPlanned: z.boolean().default(false),
    allFuturePlanDates: z.array(z.number()),
    isAvailableForMassage: z.boolean(),
  })
  .transform(summaryTransformation)
  .transform((input) => ({
    ...input,
    tags: sortShipmentTags(input.shipmentsTags),
    shipmentsTags: sortShipmentTags(input.shipmentsTags),
    doorId: input.door,
  }));

const appointmentSchema = summarySchema
  .extend({
    title: z.string(),
    allShipmentsPlanned: z.boolean().default(false),
  })
  .transform(summaryTransformation);

export const appointmentResponseSchema = z.array(appointmentSchema);

export type RouteSummary = z.infer<typeof routeSummarySchema>;
export type Route = z.infer<typeof allRouteTableSchema>;
export type Appointment = z.infer<typeof appointmentSchema>;

const assignToOriginSchema = z.object({
  /** shipmentId */
  id: z.string(),
  type: z.string(),
  shipmentIds: z.number().array(),
});

const assignToTargetSchema = z.object({
  id: z.string(),
  type: z.string(),
  date: z.number(),
  sicId: z.number(),
  afterShipmentId: z.number().optional(),
  isDroppedOnHeader: z.boolean().optional(),
});

const assignToSchema = z.object({
  origins: z.array(assignToOriginSchema),
  target: assignToTargetSchema,
});

const unassignSchema = z.object({
  origins: z.array(assignToOriginSchema),
  target: z.object({
    type: z.enum(["unassign"]),
    date: z.number(),
    sicId: z.number(),
  }),
});

export type AssignToRequest = z.infer<typeof assignToSchema>;
export type AssignToOrigin = z.infer<typeof assignToOriginSchema>;
export type AssignToTarget = z.infer<typeof assignToTargetSchema>;
export type UnassignRequest = z.infer<typeof unassignSchema>;
