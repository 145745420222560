import { BaseQueryParams, Filter } from "../../types/filter";
import {
  BillsQueryParam,
  SomethingWithBills,
  filterBills,
  getBillsFilter,
} from "./commonFilters/billsFilter";
import {
  PalletsQueryParam,
  SomethingWithPallets,
  filterPallets,
  getPalletsFilter,
} from "./commonFilters/palletsFilter";
import {
  PiecesQueryParam,
  SomethingWithPieces,
  filterPieces,
  getPiecesFilter,
} from "./commonFilters/piecesFilter";
import {
  SomethingWithWeight,
  WeightQueryParam,
  filterWeight,
  getWeightFilter,
} from "./commonFilters/weightFilter";
import { Prefix } from "./utils";

export type SummaryQueryParams =
  | PiecesQueryParam
  | PalletsQueryParam
  | WeightQueryParam
  | BillsQueryParam;
type SummaryFilters = Record<SummaryQueryParams, string[] | undefined>;
export type SomethingWithSummary = SomethingWithBills &
  SomethingWithWeight &
  SomethingWithPallets &
  SomethingWithPieces;

export const filterSummaryData = <T extends SomethingWithSummary>(
  summaries: T[],
  { pieces, pallets, weight, bills }: SummaryFilters
): T[] => {
  let result = filterBills(summaries, bills);
  result = filterPieces(result, pieces);
  result = filterPallets(result, pallets);
  result = filterWeight(result, weight);

  return result;
};

export const getSummaryFilters = (
  summaries: (param: BaseQueryParams) => SomethingWithSummary[],
  prefix?: Prefix
): Filter[] => [
  getBillsFilter(summaries("bills"), prefix),
  getPalletsFilter(summaries("pallets"), prefix),
  getPiecesFilter(summaries("pieces"), prefix),
  getWeightFilter(summaries("weight"), prefix),
];
