import dayjs from "dayjs";
import { ShipmentDetails } from "../../../../hooks/react-query/outbound/freightDetails/useGetShipmentDetails";

export const shipmentDetails = {
  default: {
    summary: {
      bills: 2,
      loadedBills: 2,
      weight: 200,
      loadedWeight: 200,
      pieces: 0,
      pallets: 2,
      tonnageByFinalDestination: [
        {
          sicId: 111,
          sic: "ONT",
          weight: 100,
          color: "#B0BEC5",
          bills: 1,
          pallets: 1,
          pieces: 0,
        },
        {
          sicId: 1,
          sic: "EWR",
          weight: 100,
          color: "#78909C",
          bills: 1,
          pallets: 1,
          pieces: 0,
        },
        {
          sicId: 1,
          sic: "ATM",
          weight: 100,
          color: "#78909C",
          bills: 1,
          pallets: 1,
          pieces: 0,
        },
      ],
      indicators: {
        unassignedBillsCount: 1,
        missServiceBillsCount: 2,
        noTrailersBillsCount: 3,
        noTripsBillsCount: 3,
        noDoorBillsCount: 2,
      },
    },
    shipments: [
      {
        shipmentId: 1234,
        proNumber: "P1234",
        doorNumber: 1,
        originSic: "DAL",
        loadToSic: "ONT",
        originalLane: "ONT",
        laneType: "Regular",
        destinationSic: "ONT",
        trailerNumber: "DAL-1234",
        tripNumber: 891027,
        serviceDue: dayjs("2024-09-26T00:00:00"),
        pieces: 0,
        pallets: 1,
        weight: 100,
        willMakeService: true,
        isUnassigned: true,
        status: "ARRV",
        tags: [
          {
            id: 1,
            name: "APTR",
            color: "#123456",
          },
        ],
      },
      {
        shipmentId: 12987473,
        proNumber: "P12987473",
        doorNumber: 1,
        originSic: "DAL",
        loadToSic: "LAX",
        originalLane: "ONT",
        laneType: "Regular",
        destinationSic: "LAX",
        trailerNumber: "DAL-3452",
        tripNumber: 891029,
        serviceDue: dayjs("2024-09-27T00:00:00"),
        pieces: 0,
        pallets: 2,
        weight: 300,
        willMakeService: false,
        isUnassigned: true,
        status: "ARRV",
        tags: [
          {
            id: 1,
            name: "VC",
            color: "#aa7ea0",
          },
        ],
      },
      {
        shipmentId: 132813622,
        proNumber: "162987440",
        doorNumber: null,
        originSic: "ONT",
        loadToSic: "ATM",
        originalLane: "ONT",
        laneType: "Regular",
        destinationSic: "ATM",
        trailerNumber: "",
        tripNumber: null,
        serviceDue: dayjs("2024-11-11T18:55:00"),
        pieces: 6,
        pallets: 1,
        weight: 296,
        willMakeService: true,
        isUnassigned: false,
        status: "ENRT",
        tags: [
          {
            id: 81,
            name: "VC",
            color: "#AA7EA0",
          },
        ],
      },
      {
        shipmentId: 132813626,
        proNumber: "162987473",
        doorNumber: null,
        originSic: "ONT",
        loadToSic: "ATM",
        originalLane: "ONT",
        laneType: "Regular",
        destinationSic: "ATM",
        trailerNumber: "",
        tripNumber: null,
        serviceDue: dayjs("2024-11-11T18:55:00"),
        pieces: 11,
        pallets: 2,
        weight: 566,
        willMakeService: true,
        isUnassigned: false,
        status: "ENRT",
        tags: [
          {
            id: 81,
            name: "VC",
            color: "#AA7EA0",
          },
        ],
      },
    ],
  },
} as const satisfies Record<string, ShipmentDetails>;
