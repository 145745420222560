import { Menu } from "@mui/material";
import { memo } from "react";
import { PlanTableFormat } from "../../../../types/planning/plan.type";
import type { Shipment } from "../../../../services/prePlanningService.types";
import { routeTrapType } from "../../../../constants/RouteTrapTypes";
import { type SummaryContext } from "../../shared/ActionsMenuContext";
import { CreateRouteTrapMenuItem } from "./CreateRouteTrapMenuItem";
import { AssignToMenuItem } from "./AssignToMenuItem";
import { UnassignMenuItem } from "./UnassignMenuItem";

type PlanShipmentsContextMenuProps = {
  anchor: HTMLButtonElement | null;
  open: boolean;
  handleClose: () => void;
  routeOrTrap: PlanTableFormat;
  shipment: Shipment;
  clearSelection?: () => void;
};

const PlanShipmentsContextMenu = ({
  anchor,
  open,
  handleClose,
  routeOrTrap,
  shipment,
}: PlanShipmentsContextMenuProps) => {
  const shipmentContext: SummaryContext[] = [
    {
      id: routeOrTrap.id,
      shipmentIds: [shipment.id],
      type: routeTrapType[routeOrTrap.type],
    },
  ];

  return (
    <Menu anchorEl={anchor} open={open} onClose={handleClose}>
      <CreateRouteTrapMenuItem
        isParentOpen={open}
        routesOrTraps={[routeOrTrap]}
        handleClose={handleClose}
        shipmentContext={shipmentContext}
      />
      <AssignToMenuItem
        routeOrTrap={routeOrTrap}
        shipmentContext={shipmentContext}
      />
      <UnassignMenuItem
        routesOrTraps={[routeOrTrap]}
        shipmentContext={shipmentContext}
      />
    </Menu>
  );
};

export default memo(PlanShipmentsContextMenu);
