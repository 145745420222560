import type {
  DragLocationHistory,
  ElementDragPayload,
} from "@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types";
import { parseDroppableId } from "../../../utils/dragging";
import { useSelectionContext } from "../../../context/SelectionContext";
import { useTableSelectionParam } from "../../../hooks/useTableSelectionParam";
import { toast } from "../../../utils/snackbarHelper";
import { toastMessage } from "../../../constants/strings";
import { useAssignShipments } from "../../../hooks/react-query/useAssignShipments";

type Drop = {
  source: ElementDragPayload;
  location: DragLocationHistory;
  date: number;
  sicId: number;
};

export const usePrePlanningDragAndDrop = () => {
  const { selected, clearSelection: clearSidebarSelection } =
    useSelectionContext();
  const { selection, clearSelection: clearTableSelection } =
    useTableSelectionParam();

  const clearSelection = () => {
    clearSidebarSelection();
    clearTableSelection();
  };

  const assignShipments = useAssignShipments({
    onSuccess: ({ affectedShipmentsCount }) => {
      toast(toastMessage.inbound.assign.success(affectedShipmentsCount), {
        variant: "success",
      });
      clearSelection();
    },
  });

  const handleDrop = ({ source, location, date, sicId }: Drop) => {
    const isValidDestination = location.current.dropTargets.length;
    if (!isValidDestination) {
      return;
    }

    const destination = parseDroppableId(
      String(location.current.dropTargets[0].data.id)
    );
    const origin = parseDroppableId(String(source.data.id));

    if (destination.id === origin.parentId) {
      return;
    }

    if (destination.type === "bucket") {
      toast(toastMessage.inbound.assign.moveToBucketNotAllowed, {
        variant: "error",
      });
      return;
    }

    let shipmentIds =
      selected.length > 0
        ? selected.flatMap((trailer) => trailer.shipmentIds)
        : [Number(origin.id)];
    if (selection.length > 0) {
      shipmentIds = [...selection.map(Number), Number(origin.id)];
    }
    // Remove duplicates
    shipmentIds = shipmentIds.filter(
      (id, index, array) => array.indexOf(id) === index
    );

    assignShipments.mutate({
      target: {
        id:
          destination.source === "shipmentTable"
            ? destination.parentId || destination.id
            : destination.id,
        type: destination.type,
        date,
        sicId,
      },
      origins: [
        {
          id: origin.parentId || origin.id,
          shipmentIds,
          type: origin.type,
        },
      ],
    });

    clearSelection();
  };

  return { handleDrop };
};
