import { useSearchParams } from "react-router-dom";
import FilterMultiselect, {
  FilterProps,
} from "../../../../components/shared/FilterMultiselect";
import { useGetRegionsIds } from "../../../../hooks/react-query/linehaul/useRegions";

export const REGION_FILTER_KEY = "region";
export const UNASSIGNED_REGION = 0;

export const defaultRegionOptions = [
  { name: "Unassigned", key: UNASSIGNED_REGION.toString() },
  { name: "EAST", key: "1" },
  { name: "WEST", key: "2" },
  { name: "MIDWEST", key: "3" },
  { name: "NORTHEAST", key: "4" },
  { name: "LAX/SFO/VIS", key: "5" },
  { name: "ONT", key: "6" },
];

export type FilterOption = {
  key: string;
  name: string;
};

type RegionFilterProps = FilterProps & {
  regionOptions?: FilterOption[];
};

export const RegionFilter = ({
  initialOpen = false,
  onRemove,
  regionOptions = defaultRegionOptions,
}: RegionFilterProps) => {
  const [search, setSearch] = useSearchParams();

  const { data = [], isLoading } = useGetRegionsIds();
  const mappedOptions = regionOptions
    .filter((ro) => ro.key !== UNASSIGNED_REGION.toString())
    .map((ro) => {
      const match = data.find((d) => d.id.toString() === ro.key);
      if (!match) return ro;
      return { key: match.id.toString(), name: match.name };
    })
    .sort((a, b) => {
      if (a.key === "0") return 1;
      if (b.key === "0") return -1;
      return parseInt(a.key) - parseInt(b.key);
    });

  const activeCarrierFilter = search.getAll(REGION_FILTER_KEY);

  const selectedOptions = mappedOptions.filter((o) =>
    activeCarrierFilter.includes(o.key)
  );

  return (
    <FilterMultiselect
      id="region-filter"
      label="Region"
      options={isLoading ? [] : mappedOptions}
      value={selectedOptions}
      initialOpen={initialOpen}
      getDisplayValue={(value) => value.name}
      getSelectedDisplayValue={(selected) =>
        selected.length === 0
          ? "All"
          : selected.length === 1
            ? selected[0].name
            : `${selected[0].name} (${selected.length})`
      }
      onChange={(selectedOptions) => {
        setSearch((old) => {
          const prev = new URLSearchParams(old);
          prev.delete(REGION_FILTER_KEY);
          selectedOptions.forEach((o) => prev.append(REGION_FILTER_KEY, o.key));
          return prev;
        });
      }}
      onRemove={() => {
        setSearch((old) => {
          const prev = new URLSearchParams(old);
          prev.delete(REGION_FILTER_KEY);
          return prev;
        });
        onRemove(REGION_FILTER_KEY);
      }}
    />
  );
};
