import { Box, styled, Typography } from "@mui/material";
import { forwardRef } from "react";
import invariant from "tiny-invariant";
import type {
  Consignee,
  Shipment,
} from "../../../services/prePlanningService.types";
import { useSelectionContext } from "../../../context/SelectionContext";
import { ALWAYS_ON_TOP } from "../../../constants/utils";
import { useTableSelectionParam } from "../../../hooks/useTableSelectionParam";

const Wrapper = styled(Box)(() => ({
  position: "relative",
  width: "200px",
}));

const Overlay = styled(Box)(() => ({
  position: "relative",
  display: "flex",
  alignItems: "flex-start",
  width: "100%",
  padding: "0.875rem 1rem",
  borderRadius: "0.5rem",
  cursor: "grabbing",
  backgroundColor: "white",
  boxShadow: "0px 3px 14px 2px #0000001F",
  gap: "0.5rem",
  marginLeft: "1rem",
  zIndex: ALWAYS_ON_TOP,
}));

const SelectionChip = styled(Box)(({ theme }) => ({
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  top: "-1rem",
  right: "-1rem",
  fontWeight: "bold",
  color: "white",
  backgroundColor: theme.palette.primary.main,
  borderRadius: "100%",
  width: "32px",
  height: "32px",
}));

const Summary = styled(Box)(() => ({
  width: "100%",
}));

const Title = styled(Typography)(() => ({
  fontSize: "1rem",
  fontWeight: 500,
  lineHeight: 1.5,
}));

const Consignee = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  fontSize: "0.875rem",
}));

const SecondLayer = styled(Box)(() => ({
  display: "block",
  position: "absolute",
  top: "0.25rem",
  left: "1.5rem",
  padding: "1rem",
  width: "100%",
  backgroundColor: "white",
  boxShadow: "0px 3px 14px 2px #0000001F",
  borderRadius: "0.5rem",
}));

interface ShipmentDragOverlayProps {
  shipment: Partial<Shipment>;
  source: "table" | "sidebar";
}

export const ShipmentDragOverlay = forwardRef(
  ({ shipment, source = "table" }: ShipmentDragOverlayProps, ref) => {
    const { selected } = useSelectionContext();
    const { selection } = useTableSelectionParam();
    const count =
      source === "table"
        ? selection.length
        : selected.reduce((total, item) => total + item.shipmentIds.length, 0);
    invariant(shipment.proNumber, "Drag overlay depends on Pro Number data");
    invariant(shipment.consignee, "Drag overlay depends on Consignee data");
    return (
      <Wrapper>
        <Overlay ref={ref}>
          {count > 1 && <SelectionChip>{count}</SelectionChip>}
          <Summary paddingBottom="0.25rem">
            <Title>Pro #{shipment.proNumber}</Title>
            <Consignee>{shipment.consignee.name}</Consignee>
            <Consignee>
              {shipment.consignee.city} {shipment.consignee.zipCode}
            </Consignee>
          </Summary>
        </Overlay>
        {count > 1 && (
          <SecondLayer>
            {/* Adding the same info to keep size consistency with the parent */}
            <Summary paddingBottom="0.25rem">
              <Title>Pro #{shipment.proNumber}</Title>
              <Consignee>{shipment.consignee.name}</Consignee>
              <Consignee>
                {shipment.consignee.city} {shipment.consignee.zipCode}
              </Consignee>
            </Summary>
          </SecondLayer>
        )}
      </Wrapper>
    );
  }
);

ShipmentDragOverlay.displayName = "ShipmentDragOverlay";
