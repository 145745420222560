import {
  AssignmentReturn,
  Autorenew,
  CallMerge,
  Route,
  ViewModule,
} from "@mui/icons-material";
import { ContextMenuAction } from "../../../../types/contextMenuAction";
import { PrePlanningSection } from "../../../../types/inbound/prePlanning/sections.type";
import {
  OpenDialogParams,
  OpenDialogWithMergeRouteParams,
  SummaryContext,
} from "../../shared/ActionsMenuContext";
import { unifyShipmentContext } from "../../../../utils/unifyShipmentContext";
import { toast } from "../../../../utils/snackbarHelper";
import { toastMessage } from "../../../../constants/strings";
import { ValidateMergeRequestResult } from "../../../../hooks/useValidateMergeRequest";
import { UnplannedSection } from "../../unplannedFreight/types";
import { PlanTableFormat } from "../../../../types/planning/plan.type";
import ContextMenuSelectionCount from "../../../../components/shared/ContextMenuSelectionCount";
import type { Appointment } from "../../../../services/prePlanningService.types";
import type { Trap } from "../../../../types/planning/trap.type";
import AddToPlanMenuItem from "../../shared/AddToPlanMenuItem";
import { UnassignMenuItem } from "../../inboundPlanning/PlanningContextMenu/UnassignMenuItem";
import { CreateRouteTrapMenuItem } from "../../inboundPlanning/PlanningContextMenu/CreateRouteTrapMenuItem";

type ContextMenuActionsArgs =
  | {
      isNewTableEnabled?: boolean;
      splitCreateActions?: boolean;
      shipmentContext: SummaryContext[];
      section: PrePlanningSection | UnplannedSection;
      openDialog: (openDialogParams: OpenDialogParams) => void;
      clearSelection?: () => void;
      refetch?: () => never;
      plannedRoutesAndTraps?: never;
      validateMergeRequest?: () => ValidateMergeRequestResult;
      selectedTrapOrBucket?: Array<Appointment | Trap>;
    }
  | {
      isNewTableEnabled?: boolean;
      splitCreateActions?: boolean;
      shipmentContext: SummaryContext[];
      section: "planning" | "shipments";
      openDialog: (
        openDialogParams: OpenDialogParams | OpenDialogWithMergeRouteParams
      ) => void;
      clearSelection?: () => void;
      plannedRoutesAndTraps?: PlanTableFormat[];
      validateMergeRequest?: () => ValidateMergeRequestResult;
      selectedTrapOrBucket?: never;
    };

export const contextMenuActions = ({
  splitCreateActions = false,
  isNewTableEnabled = false,
  shipmentContext,
  section,
  openDialog,
  clearSelection,
  plannedRoutesAndTraps = [],
  selectedTrapOrBucket = [],
  validateMergeRequest,
}: ContextMenuActionsArgs): ContextMenuAction[] => {
  const unifiedShipmentContext = unifyShipmentContext(shipmentContext);
  const actions: ContextMenuAction[] = [
    ...(isNewTableEnabled
      ? [
          {
            label: "Selection count",
            itemComponent: (
              <ContextMenuSelectionCount
                count={
                  section !== "shipments"
                    ? shipmentContext.length
                    : shipmentContext.flatMap(
                        (shipment) => shipment.shipmentIds
                      ).length
                }
              />
            ),
          },
        ]
      : []),
    ...(section === "routes"
      ? [
          ...createActions({
            splitCreateActions,
            openDialog,
            section,
            shipmentContext,
            clearSelection,
          }),
          {
            label: "Merge Routes",
            barLabel: "Merge Routes",
            icon: <CallMerge />,
            handleClick: () => {
              const warningMessage = validateMergeRequest?.();
              if (warningMessage) {
                toast(toastMessage.inbound.mergeRoutes[warningMessage], {
                  variant: "warning",
                });
                return;
              }
              openDialog({
                name: "merge-routes",
                shipmentContext: unifiedShipmentContext,
                clearSelection,
                planSummaries: plannedRoutesAndTraps,
              });
            },
          },
        ]
      : []),
    ...(section === "traps"
      ? [
          ...createActions({
            splitCreateActions,
            openDialog,
            section,
            shipmentContext,
            clearSelection,
          }),
          {
            label: "Merge Traps",
            barLabel: "Merge Traps",
            icon: <CallMerge />,
            handleClick: () => {
              const warningMessage = validateMergeRequest?.();
              if (warningMessage) {
                toast(toastMessage.inbound.mergeTraps[warningMessage], {
                  variant: "warning",
                });
                return;
              }
              openDialog({
                name: "merge-traps",
                shipmentContext: unifiedShipmentContext,
                clearSelection,
                planSummaries: plannedRoutesAndTraps,
              });
            },
          },
        ]
      : []),
    ...(section === "appointments"
      ? [
          ...createActions({
            splitCreateActions,
            openDialog,
            section,
            shipmentContext,
            clearSelection,
          }),
        ]
      : []),
    ...(section === "planning" && !isNewTableEnabled
      ? [
          ...createActions({
            splitCreateActions,
            openDialog,
            section,
            shipmentContext,
            clearSelection,
            plannedRoutesAndTraps,
          }),
          {
            label: "Merge",
            barLabel: "Merge",
            icon: <Autorenew />,
            handleClick: () => {
              const warningMessage =
                validateMergeRequest && validateMergeRequest();
              if (warningMessage) {
                toast(
                  toastMessage.inbound.mergeRoutesAndTraps[warningMessage],
                  {
                    variant: "warning",
                  }
                );
                return;
              }
              openDialog({
                name: "merge-routes-traps",
                shipmentContext: unifiedShipmentContext,
                clearSelection,
                planSummaries: plannedRoutesAndTraps,
              });
            },
          },
        ]
      : []),
    {
      label: "Assign To",
      barLabel: "Assign To",
      icon: <AssignmentReturn />,
      disabled: shipmentContext.length <= 0,
      handleClick: () => {
        if (!shipmentContext.length) {
          toast(toastMessage.inbound.assign.noneSelected, {
            variant: "warning",
          });
        } else if (
          unifiedShipmentContext.map((s) => s.shipmentIds).flat().length === 0
        ) {
          toast(toastMessage.inbound.assign.noShipmentsToAssign, {
            variant: "warning",
          });
        } else {
          openDialog({
            name: "assign-to",
            shipmentContext: unifiedShipmentContext,
            clearSelection,
            planSummaries: plannedRoutesAndTraps,
          });
        }
      },
    },
    ...(section === "traps" || section === "appointments"
      ? [
          {
            label: "Add to Plan",
            barLabel: "Add to Plan",
            itemComponent: (
              <AddToPlanMenuItem
                selectedRowsContext={unifiedShipmentContext}
                selectedTrapOrBucket={selectedTrapOrBucket}
                clearSelection={clearSelection}
              />
            ),
            keepOpenAfterClick: true,
          },
        ]
      : []),
    ...(section === "planning"
      ? [
          {
            label: "Unassign",
            barLabel: "Unassign",
            itemComponent: (
              <UnassignMenuItem
                shipmentContext={shipmentContext}
                clearSelection={clearSelection}
                routesOrTraps={plannedRoutesAndTraps.filter((plan) =>
                  shipmentContext.some((context) => context.id === plan.id)
                )}
              />
            ),
            keepOpenAfterClick: true,
          },
        ]
      : []),
  ];

  return actions;
};

export const createActions = ({
  splitCreateActions,
  openDialog,
  shipmentContext,
  clearSelection,
  plannedRoutesAndTraps,
}: ContextMenuActionsArgs): ContextMenuAction[] => {
  const unifiedShipmentContext = unifyShipmentContext(shipmentContext);
  if (splitCreateActions) {
    return [
      {
        label: "Create New Route",
        barLabel: "Route",
        icon: <Route />,
        handleClick: () => {
          openDialog({
            name: "create-new-route",
            shipmentContext: unifiedShipmentContext,
            clearSelection,
            planSummaries: plannedRoutesAndTraps,
          });
        },
      },
      {
        label: "Create New Trap",
        barLabel: "Trap",
        icon: <ViewModule />,
        handleClick: () => {
          openDialog({
            name: "create-new-trap",
            shipmentContext: unifiedShipmentContext,
            clearSelection,
            planSummaries: plannedRoutesAndTraps,
          });
        },
      },
    ];
  } else {
    return [
      {
        label: "Create New Route or Trap",
        barLabel: "Route or Trap",
        itemComponent: (
          <CreateRouteTrapMenuItem
            isParentOpen={true}
            routesOrTraps={plannedRoutesAndTraps || []}
            shipmentContext={shipmentContext}
          />
        ),
        keepOpenAfterClick: true,
      },
    ];
  }
};
