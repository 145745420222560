import { SxProps, Theme } from "@mui/material";
import { z } from "zod";
import { tableDraggingColor } from "../constants/dragAndDrop";
import {
  AssignShipmentType,
  assignShipmentTypeSchema,
} from "../types/assignShipment.type";
import type { ShipmentWithSequence } from "../types/planning/shipmentSeq.type";

// CSpell:ignore skyblue
export function getDroppableStyleRow(isDraggingOver?: boolean): SxProps {
  return {
    background: isDraggingOver ? "skyblue" : "unset",
    border: 1,
    borderRadius: 1,
    margin: 1,
    padding: 2,
    transition: "background-color 0.5s ease",
  };
}

export function getDroppableStyleCard(isDraggingOver: boolean): SxProps {
  return {
    background: isDraggingOver ? "skyblue" : "",
    margin: isDraggingOver ? 1 : undefined,
    padding: isDraggingOver ? 2 : undefined,
    transition: "background-color 0.5s ease",
  };
}

export function getDraggableStyleCard(
  isDragging: boolean,
  draggableStyle: any,
  theme: Theme,
  draggingBackgroundColor?: string,
  isSelected?: boolean
): React.CSSProperties {
  return {
    userSelect: "none",
    backgroundColor: isDragging
      ? draggingBackgroundColor
      : isSelected
        ? theme.palette.action.selected
        : "white",
    ...draggableStyle,
  };
}

export function getDraggableStyleRow(
  isDragging: boolean,
  draggableStyle: any,
  theme: Theme,
  isSelected?: boolean
): React.CSSProperties {
  return {
    userSelect: "none",
    position: !isDragging ? "absolute !important" : undefined,
    backgroundColor: isDragging
      ? tableDraggingColor
      : isSelected
        ? theme.palette.action.selected
        : "white",
    ...draggableStyle,
  };
}

const deliveryDataType = z.enum(["delivery_trap", "delivery_route"]);
type DeliveryDataType = z.infer<typeof deliveryDataType>;

export const getDroppableId = (
  source: DragSourceOrDestination,
  dataType: AssignShipmentType | DeliveryDataType,
  id: string | number,
  parentId?: string | number,
  suffix?: string
) => {
  if (dataType === "delivery_route") {
    dataType = "route";
  } else if (dataType === "delivery_trap") {
    dataType = "trap";
  }

  let string = `${source}-${dataType}-${id}`;
  if (parentId) string += `-${parentId}`;
  if (suffix) string += `-${suffix}`;

  return string;
};

const dragSourceOrDestinationSchema = z.enum([
  "mainTable",
  "shipmentTable",
  "sidebar",
  "mainGrid",
  "unassigned",
]);
export type DragSourceOrDestination = z.infer<
  typeof dragSourceOrDestinationSchema
>;

const droppableIdSchema = z
  .array(z.string())
  .min(3, "droppableId must have at least three elements.")
  .max(5, "droppableId must have a maximum of five elements.")
  .superRefine((parts, ctx) => {
    if (!dragSourceOrDestinationSchema.safeParse(parts[0]).success) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "The field 'source' is invalid against the expected schema.",
        path: [0],
      });
    }
    if (!assignShipmentTypeSchema.safeParse(parts[1]).success) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "The field 'type' is invalid against the expected schema.",
        path: [1],
      });
    }
    if (parts.length > 2 && !parts[2]) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "The 'id' field is required",
        path: [2],
      });
    }
  });

export type Droppable = {
  source: DragSourceOrDestination;
  type: AssignShipmentType;
  id: string;
  parentId?: string;
  suffix?: string;
};

/** Accepts a droppableId and returns a structured object.
 * @param droppableId - The droppableId to parse using the droppableIdSchema
 */
export function parseDroppableId(droppableId: string): Droppable {
  const [source, type, id, parentId, suffix] = droppableIdSchema.parse(
    droppableId.split("-")
  );

  const droppable: Droppable = {
    source: dragSourceOrDestinationSchema.parse(source),
    type: assignShipmentTypeSchema.parse(type),
    id,
    parentId,
    suffix,
  };

  return droppable;
}

export const getAfterShipmentId = (
  shipments: ShipmentWithSequence[],
  destinationStopSeq: number,
  edge: "top" | "bottom"
) => {
  // To get the correct afterShipmentId position, we need to know if the destination is higher up or down
  // from the source stop sequence. If the destination is stop sequence 1, we use "0" to indicate
  // that it should be put at the first position
  if (destinationStopSeq === 1) {
    return 0;
  } else {
    const targetStopSeq =
      edge === "top" ? destinationStopSeq - 1 : destinationStopSeq;
    const shipment = shipments.find(
      (shipment) => shipment.stopSeq === targetStopSeq
    );

    if (shipment) {
      return shipment.id;
    } else {
      return undefined;
    }
  }
};
