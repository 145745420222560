import { z } from "zod";

const departmentSchema = z.enum(["settings", "operations", "linehaul"]);
const operationsSection = z
  .enum(["outbound", "inbound", "dispatch"])
  .optional();
const inboundSectionSchema = z
  .enum([
    "planning",
    "pre-planning",
    "dock-manager",
    "unplanned-freight",
    "uc-cc",
  ])
  .optional();

type Department = z.infer<typeof departmentSchema>;
type OperationsSection = z.infer<typeof operationsSection>;
type InboundSection = z.infer<typeof inboundSectionSchema>;

type SectionDetails =
  | {
      type: "operations";
      section: OperationsSection;
    }
  | {
      type: "inbound";
      section: InboundSection;
    };

/** Return department and section details for a given URL */
export function parseUrl(url: string): {
  department: Department;
  section?: SectionDetails;
} {
  const [, department, , section, inboundSection] = url.split("/");

  let sectionDetails: SectionDetails | undefined;

  if (department === "operations") {
    if (section === "inbound") {
      sectionDetails = {
        type: "inbound",
        section: inboundSectionSchema.parse(inboundSection),
      };
    } else {
      sectionDetails = {
        type: "operations",
        section: operationsSection.parse(section),
      };
    }
  }

  return {
    department: departmentSchema.parse(department),
    section: sectionDetails,
  };
}
