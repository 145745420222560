import { PropsWithChildren } from "react";
import { Box, useTheme } from "@mui/material";
import { Draggable } from "@hello-pangea/dnd";
import { useSelectionContext } from "../../../context/SelectionContext";
import { sidebarDraggingColor } from "../../../constants/dragAndDrop";
import { DraggableSelectionCounter } from "../../../components/shared/old-table/DraggableSelectionCounter";
import { getDroppableId } from "../../../utils/dragging";

export type DraggableProps = {
  id: string;
  shipmentId: number;
  index: number;
  setSelectedItems: (selectedShipmentIds: number[]) => void;
  selectedShipmentIds: number[];
};

export default function DraggableBox({
  id,
  shipmentId,
  index,
  selectedShipmentIds,
  children,
}: PropsWithChildren<DraggableProps>) {
  const theme = useTheme();
  const { selected, isSelected } = useSelectionContext();
  return (
    <Draggable
      draggableId={getDroppableId("unassigned", "shipment", id)}
      index={index}
    >
      {(provided, snapshot) => (
        <Box
          {...provided.draggableProps}
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
            userSelect: "none",
            backgroundColor: snapshot.isDragging
              ? sidebarDraggingColor
              : isSelected(shipmentId, "shipment")
                ? theme.palette.action.selected
                : "white",
          }}
        >
          {children}
          {(selected.length > 1 || selectedShipmentIds.length > 0) &&
            snapshot.isDragging && (
              <DraggableSelectionCounter>
                {selected.length === 1
                  ? selectedShipmentIds.length
                  : selected.length > 1
                    ? selected.length
                    : 1}
              </DraggableSelectionCounter>
            )}
        </Box>
      )}
    </Draggable>
  );
}
