import { PlanTableFormat } from "../../types/planning/plan.type";
import { BaseQueryParams } from "../../types/filter";
import {
  DriverNameQueryParam,
  filterDriverName,
  getDriverNameFilter,
} from "./commonFilters/driverNameFilter";
import {
  EndTimeQueryParam,
  filterEndTime,
} from "./commonFilters/endTimeFilter";
import {
  TrailerNameQueryParam,
  filterTrailerName,
  getTrailerNameFilter,
} from "./commonFilters/trailerNameFilter";
import {
  LocationQueryParam,
  filterLocation,
  getLocationFilter,
} from "./commonFilters/locationFilter";
import {
  RouteEndQueryParam,
  filterRouteEnd,
} from "./commonFilters/routeEndFilter";
import {
  StartTimeQueryParam,
  filterStartTime,
  getStartTimeFilter,
} from "./commonFilters/startTimeFilter";
import {
  StatusQueryParam,
  filterStatus,
  getStatusFilter,
} from "./commonFilters/statusFilter";
import {
  ZoneQueryParam,
  filterZones,
  getZonesFilter,
} from "./commonFilters/zonesFilter";
import {
  SummaryQueryParams,
  filterSummaryData,
  getSummaryFilters,
} from "./summaryFilters";
import {
  NameQueryParam,
  filterName,
  getNameFilter,
} from "./commonFilters/nameFilter";
import {
  TractorNameQueryParam,
  filterTractorName,
  getTractorNameFilter,
} from "./commonFilters/tractorNameFilter";
import { filterDataByInboundFilterURLParams } from "./utils";
import {
  filterTags,
  getTagsFilter,
  type TagsQueryParam,
} from "./commonFilters/tagsFilter";

type PlannedRoutesQueryParam =
  | ZoneQueryParam
  | LocationQueryParam
  | StartTimeQueryParam
  | EndTimeQueryParam
  | RouteEndQueryParam
  | SummaryQueryParams
  | DriverNameQueryParam
  | NameQueryParam
  | TrailerNameQueryParam
  | TractorNameQueryParam
  | TagsQueryParam
  | StatusQueryParam;

type PlannedRoutesFilters = Record<
  PlannedRoutesQueryParam,
  string[] | undefined
>;

export const filterPlannedSidebarRoutesAndTraps = <T extends PlanTableFormat>(
  plannedRoutesOrTraps: T[],
  timeZone: string,
  {
    zone,
    location,
    startTime,
    endTime,
    routeEnd,
    driverName,
    name,
    trailerName,
    tractorName,
    status,
    tags,
    ...summaryFilters
  }: PlannedRoutesFilters
) => {
  let result = plannedRoutesOrTraps;
  result = filterZones(result, zone);
  result = filterLocation(result, location);
  result = filterStartTime(result, timeZone, startTime);
  result = filterEndTime(result, timeZone, endTime);
  result = filterRouteEnd(result, routeEnd);
  result = filterSummaryData(result, summaryFilters);
  result = filterTrailerName(result, trailerName);
  result = filterTractorName(result, tractorName);
  result = filterDriverName(result, driverName);
  result = filterName(result, name);
  result = filterTags(result, tags);
  result = filterStatus(result, status);

  return result;
};

export const plannedSidebarRoutesAndTrapsFilters = (
  routes: PlanTableFormat[],
  searchParams: URLSearchParams,
  timeZone: string,
  prefix: "routes" | "traps"
) => {
  const getRoutes = (param: BaseQueryParams) =>
    filterDataByInboundFilterURLParams(
      searchParams,
      param,
      routes,
      timeZone,
      prefix
    );

  return [
    getNameFilter(getRoutes("name"), "Route", prefix),
    getZonesFilter(getRoutes("zone"), prefix),
    getLocationFilter(getRoutes("location"), prefix),
    getStartTimeFilter(getRoutes("startTime"), timeZone, prefix),
    ...getSummaryFilters(getRoutes, prefix),
    getDriverNameFilter(getRoutes("driverName"), prefix),
    getTractorNameFilter(getRoutes("tractorName"), prefix),
    getTrailerNameFilter(getRoutes("trailerName"), prefix),
    getTagsFilter(getRoutes("tags"), prefix),
    getStatusFilter(getRoutes("status"), prefix),
  ];
};
