import dayjs from "dayjs";
import { MasterScheduleEntry } from "../../hooks/react-query/linehaul/useMasterSchedule";

export const mockMasterScheduleList = {
  empty: [],
  default: [
    {
      id: 1,
      lhRegionId: 3,
      carrierName: "AVILENO TRUCKING LLC",
      carrierCode: "M163",
      loadingDate: dayjs().day(1),
      pickupDate: dayjs().day(2).hour(12).minute(0),
      serviceType: "1-way",
      isActive: true,
      isPublished: false,
      bookingNumber: "Harold",
      effectiveFromUtc: new Date(),
      effectiveToUtc: new Date(),
      originSicCode: "ONT",
      destinationSicCode: "EWR",
      notes: "Test Note 2",
      loadPairScheduleId: 1,
      employeeDrivers: [],
      stops: ["ABE"],
      deadheads: [
        {
          originSicCode: "ATC",
          destinationSicCode: "ATB",
          originId: 0,
          destinationId: 0,
        },
      ],
    },
    {
      id: 2,
      lhRegionId: 5,
      carrierName: "AVILENO TRUCKING LLC",
      carrierCode: "M163",
      loadingDate: dayjs().day(1),
      pickupDate: dayjs().day(2).hour(16).minute(0),
      serviceType: "1-way",
      isActive: true,
      isPublished: true,
      bookingNumber: "Harold",
      effectiveFromUtc: new Date(),
      effectiveToUtc: new Date(),
      originSicCode: "ONT",
      destinationSicCode: "EWR",
      notes: "",
      loadPairScheduleId: 1,
      employeeDrivers: [],
      stops: [],
      deadheads: [],
    },
    {
      id: 3,
      lhRegionId: 6,
      carrierName: "AM EXPRESS LINE, INC",
      carrierCode: "M110",
      loadingDate: dayjs().day(2),
      pickupDate: dayjs().day(4).hour(14).minute(0),
      serviceType: "Fleet",
      isActive: false,
      isPublished: false,
      bookingNumber: "Jon",
      effectiveFromUtc: new Date(),
      effectiveToUtc: new Date(),
      originSicCode: "LAX",
      destinationSicCode: "DFW",
      notes: "Test Note 1",
      loadPairScheduleId: 2,
      employeeDrivers: [],
      stops: [],
      deadheads: [],
    },
    {
      id: 4,
      lhRegionId: 1,
      carrierName: "AM EXPRESS LINE, INC",
      carrierCode: "M110",
      loadingDate: dayjs().day(3),
      pickupDate: dayjs().day(4).hour(14).minute(0),
      serviceType: "Solo",
      isActive: true,
      isPublished: false,
      bookingNumber: "Roger",
      effectiveFromUtc: new Date(),
      effectiveToUtc: new Date(),
      originSicCode: "LAX",
      destinationSicCode: "DFW",
      notes: "",
      loadPairScheduleId: 2,
      employeeDrivers: [],
      stops: [],
      deadheads: [],
    },
    {
      id: 3048936,
      lhRegionId: 3,
      carrierName: "COVENANT TRANSPORT INC",
      carrierCode: "L004",
      loadingDate: dayjs().day(4),
      pickupDate: dayjs().day(6).hour(16).minute(0),
      serviceType: "1-way",
      isActive: true,
      isPublished: false,
      bookingNumber: "Roger",
      effectiveFromUtc: new Date(),
      effectiveToUtc: new Date(),
      originSicCode: "CHT",
      destinationSicCode: "LAX",
      notes: "",
      loadPairScheduleId: 3084634,
      employeeDrivers: [],
      stops: [],
      deadheads: [],
    },
    {
      id: 6,
      lhRegionId: 3,
      carrierName: "GTO TRANSPORTATION",
      carrierCode: "SH138",
      loadingDate: dayjs().day(1),
      pickupDate: dayjs().day(1).hour(22).minute(0),
      serviceType: "Shuttle",
      isActive: true,
      isPublished: false,
      bookingNumber: "JAVIER",
      effectiveFromUtc: new Date(),
      effectiveToUtc: new Date(),
      originSicCode: "ONT",
      destinationSicCode: "SFO",
      notes: "No deadhead",
      loadPairScheduleId: 4,
      employeeDrivers: [],
      stops: [],
      deadheads: [],
    },
  ],
  deletable: [
    {
      id: 1,
      lhRegionId: 3,
      carrierName: "Avileno Trucking Inc.",
      carrierCode: "M123",
      loadingDate: dayjs().day(1),
      pickupDate: dayjs().day(2).hour(12).minute(0),
      serviceType: "1-way",
      isActive: true,
      isPublished: false,
      bookingNumber: "Harold",
      effectiveFromUtc: new Date(),
      effectiveToUtc: new Date(),
      originSicCode: "ONT",
      destinationSicCode: "EWR",
      notes: "Test Note 2",
      loadPairScheduleId: 1,
      employeeDrivers: [],
      stops: [],
      deadheads: [],
    },
  ],
} as const satisfies Record<string, MasterScheduleEntry[]>;
