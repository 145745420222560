import { dateToFormat } from "../../../utils/dateTimeHelper";
import { DaylightDateFormat } from "../../DaylightDateFormat";
import { isActiveFilter } from "../utils";

export type EndTimeQueryParam = "endTime";
type SomethingWithEndTime = {
  endTime: Date | null;
};

export const filterEndTime = <T extends SomethingWithEndTime>(
  data: T[],
  timeZone: string,
  filters?: string[]
): T[] =>
  isActiveFilter(filters)
    ? data.filter(({ endTime }) => {
        const formattedEndTime = dateToFormat(
          endTime,
          DaylightDateFormat.TIME_12,
          timeZone
        );
        return filters.includes(formattedEndTime);
      })
    : data;
