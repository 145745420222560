import dayjs from "dayjs";
import { DoorShipments } from "../../hooks/react-query/outbound/freightDetails/useGetDoorShipments";

export const doorShipmentsResponse = {
  default: {
    door: {
      doorNumber: 91,
      priority: null,
      doorSics: ["ONT", "DAL"],
      doorTags: [
        {
          id: 4,
          name: "APTR",
          color: "#777DE9",
          description: "",
        },
      ],
      doorExclusiveForSicsAssigned: false,
      doorExclusiveForTagsAssigned: true,
      baySics: ["EWR"],
      bayTags: [
        {
          id: 81,
          name: "VC",
          color: "#AA7EA0",
          description: "",
        },
        {
          id: 2,
          name: "UC",
          color: "#FBC02D",
          description: "",
        },
      ],
      bayExclusiveForSicsAssigned: true,
      bayExclusiveForTagsAssigned: false,
      bills: 15,
      pieces: 222,
      pallets: 19,
      weight: 15690,
      loadedWeight: 1780,
      currentPull: {
        plannedPullId: 36870,
        trailerNumber: "ONTGHOST03",
        trailerEquipmentId: 24243,
        tripNumber: 3051120,
        stopSic: "",
        stopSicId: null,
        carrierName: "D E L TRANSPORT INC",
        status: "LDDK",
        isLoadAndHold: false,
        stopSicCode: "",
      },
    },
    doorShipments: [
      {
        shipmentId: 132890010,
        proNumber: "162990279",
        originSic: "ONT",
        loadTo: "ATL",
        originalLane: "ATL",
        laneType: "Merged",
        destinationSic: "ATL",
        doorNumber: 91,
        trailerNumber: "",
        tripNumber: null,
        serviceDue: dayjs("2024-11-27T19:06:00"),
        pieces: 61,
        pallets: 2,
        weight: 972,
        shipmentTags: [
          {
            id: 81,
            name: "VC",
            color: "#AA7EA0",
            description: "Vendor Compliance",
          },
          {
            id: 4,
            name: "APTR",
            color: "#777DE9",
            description: "Appointment Required ",
          },
        ],
        status: "CLOS",
        willMakeService: true,
      },
      {
        shipmentId: 132897688,
        proNumber: "P8542721",
        originSic: "ONT",
        loadTo: "ATL",
        originalLane: "ATL",
        laneType: "Merged",
        destinationSic: "ATL",
        doorNumber: 91,
        trailerNumber: "",
        tripNumber: null,
        serviceDue: dayjs("2024-11-26T18:58:00"),
        pieces: 63,
        pallets: 0,
        weight: 970,
        shipmentTags: [
          {
            id: 81,
            name: "VC",
            color: "#AA7EA0",
            description: "Vendor Compliance",
          },
        ],
        status: "CARV",
        willMakeService: true,
      },
      {
        shipmentId: 132904134,
        proNumber: "153921416",
        originSic: "ONT",
        loadTo: "ATL",
        originalLane: "ATL",
        laneType: "Merged",
        destinationSic: "ATL",
        doorNumber: 91,
        trailerNumber: "",
        tripNumber: null,
        serviceDue: dayjs("2024-11-27T00:00:00"),
        pieces: 0,
        pallets: 1,
        weight: 700,
        shipmentTags: [
          {
            id: 4,
            name: "APTR",
            color: "#777DE9",
            description: "Appointment Required ",
          },
        ],
        status: "CLOS",
        willMakeService: true,
      },
    ],
    bayShipments: [
      {
        shipmentId: 132904207,
        proNumber: "154734735",
        originSic: "ONT",
        loadTo: "ATL",
        originalLane: "ATL",
        laneType: "Merged",
        destinationSic: "ATL",
        doorNumber: 91,
        trailerNumber: "",
        tripNumber: null,
        serviceDue: dayjs("2024-11-26T00:00:00"),
        pieces: 0,
        pallets: 1,
        weight: 84,
        shipmentTags: [],
        status: "LDDK",
        willMakeService: true,
      },
      {
        shipmentId: 132904428,
        proNumber: "P8545612",
        originSic: "ONT",
        loadTo: "ATL",
        originalLane: "ATL",
        laneType: "Merged",
        destinationSic: "ATL",
        doorNumber: 91,
        trailerNumber: "",
        tripNumber: null,
        serviceDue: dayjs("2024-11-26T15:00:00"),
        pieces: 0,
        pallets: 1,
        weight: 1015,
        shipmentTags: [],
        status: "CLOS",
        willMakeService: true,
      },
    ],
  },
} as const satisfies Record<string, DoorShipments>;
