import { AssignmentLate } from "@mui/icons-material";
import { LoadingButton } from "../../../../components/shared/LoadingButton";
import { useDateSearchParamOrFallbackToToday } from "../../../../hooks/useDateSearchParamOrFallbackToToday";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { dateToInt } from "../../../../utils/dateTimeHelper";
import { toast } from "../../../../utils/snackbarHelper";
import { buttonStyle } from "../../prePlanning/actionbar/StyledMenuButton";
import { actionsMap, type ShipmentActionTypeId } from "../ShipmentActionDialog";
import type { SummaryContext } from "../../shared/ActionsMenuContext";
import type { AssignToOrigin } from "../../../../services/prePlanningService.types";
import { useUnassignShipments } from "../../../../hooks/react-query/useAssignShipments";

type UnassignButtonProps = {
  selectionContext: SummaryContext[];
};

export const UnassignButton = ({ selectionContext }: UnassignButtonProps) => {
  const [serviceCenter] = useSelectedServiceCenter();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const planDate = dateToInt(selectedDate);

  const onSuccessfulAssignment = (actionType: ShipmentActionTypeId) => {
    const { description } = actionsMap[actionType];

    toast(description, {
      variant: "success",
    });
  };

  const { mutate: unassignShipment, isPending: isUnassignPending } =
    useUnassignShipments({
      onSuccess: async () => {
        await onSuccessfulAssignment("unassign");
      },
    });

  const handleUnassign = () => {
    if (isUnassignPending) return;
    const mappedContext: AssignToOrigin[] = selectionContext.map((context) => ({
      ...context,
      id: String(context.id),
    }));
    unassignShipment({
      origins: mappedContext,
      target: {
        date: planDate,
        sicId: serviceCenter.id,
        type: "unassign",
      },
    });
  };

  return (
    <LoadingButton
      sx={buttonStyle}
      startIcon={<AssignmentLate />}
      isLoading={isUnassignPending}
      variant="outlined"
      loadingLabel="Loading..."
      onClick={handleUnassign}
    >
      Unassign
    </LoadingButton>
  );
};
