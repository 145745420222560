import type {
  BaseEventPayload,
  DragLocationHistory,
  ElementDragPayload,
  ElementDragType,
} from "@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types";
import invariant from "tiny-invariant";
import { z } from "zod";
import { useCallback, type RefObject } from "react";
import { getAfterShipmentId, parseDroppableId } from "../../../utils/dragging";
import type {
  AssignShipmentFn,
  UnassignShipmentFn,
} from "../../../types/assignShipment.type";
import { useSelectionContext } from "../../../context/SelectionContext";
import { useTableSelectionParam } from "../../../hooks/useTableSelectionParam";
import type { PlanTableFormat } from "../../../types/planning/plan.type";
import { toast } from "../../../utils/snackbarHelper";
import { toastMessage } from "../../../constants/strings";
import type { ReorderPayload } from "../../../hooks/react-query/useReorderShipments";
import type {
  AssignToRequest,
  UnassignRequest,
} from "../../../services/prePlanningService.types";
import type {
  ConfirmShipmentMoveDialogRef,
  ShipmentToMove,
} from "./ConfirmShipmentMoveDialog";
import type { ShipmentActionDialogRef } from "./ShipmentActionDialog";

type ActionType = "assign" | "unassign";

const actionMap: Record<string, ActionType> = {
  "sidebar->mainTable": "assign",
  "unassigned->mainTable": "assign",
  "unassigned->shipmentTable": "assign",
  "unassigned->sidebar": "assign",
  "shipmentTable->sidebar": "assign",
  "sidebar->shipmentTable": "assign",
  "sidebar->sidebar": "assign",
  "shipmentTable->shipmentTable": "assign",
  "sidebar->unassigned": "unassign",
  "shipmentTable->unassigned": "unassign",
};

type Drop = {
  source: ElementDragPayload;
  location: DragLocationHistory;
  handleAssignShipments: AssignShipmentFn;
  handleUnassignShipments: UnassignShipmentFn;
};

export const useInboundDragAndDrop = () => {
  const { selected, clearSelection } = useSelectionContext();
  const { selection } = useTableSelectionParam();

  const handleDrop = ({
    source,
    location,
    handleAssignShipments,
    handleUnassignShipments,
  }: Drop) => {
    const isValidDestination = location.current.dropTargets.length;
    if (!isValidDestination) {
      return;
    }

    const destination = parseDroppableId(
      String(location.current.dropTargets[0].data.id)
    );
    const destinationIndex = Number(location.current.dropTargets[0].data.index);

    const origin = parseDroppableId(String(source.data.id));

    if (destination.id === origin.parentId) {
      return;
    }

    const isSidebarMovementOnly =
      destination.source === "sidebar" && origin.source === "sidebar";

    let shipmentIds =
      selected.length > 0
        ? selected.flatMap((trailer) => trailer.shipmentIds)
        : [Number(origin.id)];
    if (!isSidebarMovementOnly && selection.length > 0) {
      shipmentIds = [...selection.map(Number), Number(origin.id)];
    }
    // Remove duplicates
    shipmentIds = shipmentIds.filter(
      (id, index, array) => array.indexOf(id) === index
    );

    const key = `${origin.source}->${destination.source}`;
    const action = actionMap[key];

    invariant(action, `No action defined for movement: ${key}`);

    switch (action) {
      case "assign":
        handleAssignShipments({
          location,
          destinationIndex,
          origin,
          destination,
          shipmentIds,
        });
        clearSelection();
        break;
      case "unassign":
        handleUnassignShipments({
          sourceId: origin.parentId || origin.id,
          sourceType: origin.type,
          shipmentIds,
        });
        clearSelection();
        break;
      default:
        throw new Error(`Unhandled action type: ${action}`);
    }
  };

  return { handleDrop };
};

interface GetStopSequenceArgs {
  plan: PlanTableFormat;
  destinationIndex: number;
  location: DragLocationHistory;
}

export const getStopSequence = ({
  plan,
  location,
  destinationIndex,
}: GetStopSequenceArgs) => {
  const locationData = location.current.dropTargets[0].data;
  const symbols = Object.getOwnPropertySymbols(locationData);
  const closestEdge = locationData[symbols[0]];

  if (!closestEdge) return undefined;

  const edge = z.enum(["top", "bottom"]).parse(closestEdge);

  return getAfterShipmentId(plan.shipments, destinationIndex, edge);
};

interface InboundDragAndDropHandlersProps {
  plans: PlanTableFormat[];
  planDate: number;
  sicId: number;
  shipmentActionDialogRef: RefObject<ShipmentActionDialogRef>;
  confirmDialogRef: RefObject<ConfirmShipmentMoveDialogRef>;
  reOrderShipment: (payload: ReorderPayload) => void;
  assignShipment: (request: AssignToRequest) => void;
  unassignShipment: (request: UnassignRequest) => void;
}

export const useInboundDragAndDropHandlers = ({
  plans,
  planDate,
  sicId,
  shipmentActionDialogRef,
  confirmDialogRef,
  reOrderShipment,
  assignShipment,
  unassignShipment,
}: InboundDragAndDropHandlersProps) => {
  const { handleDrop } = useInboundDragAndDrop();

  const handleAssignShipments: AssignShipmentFn = useCallback(
    ({ destination, origin, shipmentIds, destinationIndex, location }) => {
      const destinationId = destination.parentId || destination.id;
      const originId = origin.parentId || origin.id;

      const destinationPlan = plans.find(
        (plan) => parseInt(destinationId) === plan.id
      );

      const originPlan = plans.find((plan) => parseInt(originId) === plan.id);

      if (destinationPlan === undefined) {
        shipmentActionDialogRef.current?.close();
        throw new Error(
          `Droppable Destination not found with type: ${destination.type} and id: ${destinationId}`
        );
      }

      // Reorder shipments
      if (destination.parentId === origin.parentId) {
        if (origin.type === "trap") {
          toast(toastMessage.inbound.autoSequencing.notAllowedOnTraps, {
            variant: "error",
          });
          return;
        }

        if (destinationPlan.shipments.length > 0) {
          const afterShipmentId =
            getStopSequence({
              plan: destinationPlan,
              destinationIndex,
              location,
            }) || 0;
          shipmentActionDialogRef.current?.open("loading", "reorder");
          reOrderShipment({
            routeId: Number(destinationId),
            data: {
              shipmentIds,
              afterShipmentId,
            },
          });
        }
        return;
      }

      const shipmentToMove: ShipmentToMove = {
        destination: {
          name: destinationPlan.name,
          status: destinationPlan.status,
          type: destination.type,
        },
        source: {
          name: originPlan?.name || "",
          status: originPlan?.status || "",
          type: origin.type,
        },
        shipmentIds,
        rollbackMessage: undefined,
        onConfirm: () => {
          assignShipment({
            origins: [
              {
                id: origin.id,
                type: origin.type,
                shipmentIds,
              },
            ],
            target: {
              date: planDate,
              sicId,
              id: destination.id,
              type: destination.type,
            },
          });
        },
      };

      // On handleAssign, Destination is ALWAYS Route/Trap
      const onlyDestinationIsRouteOrTrap =
        originPlan?.type !== "delivery_route" &&
        originPlan?.type !== "delivery_trap";

      const bothAreRouteOrTrap =
        originPlan?.type === "delivery_route" ||
        originPlan?.type === "delivery_trap";

      // Shows rollback message saying that both going to return to CLDK
      if (
        bothAreRouteOrTrap &&
        !destinationPlan.isAvailableForMassage &&
        !originPlan.isAvailableForMassage
      ) {
        confirmDialogRef.current?.open({
          ...shipmentToMove,
          rollbackMessage: {
            toDestination: true,
            toSource: true,
          },
        });
      }
      // Shows rollback message only for the not available one
      else if (
        bothAreRouteOrTrap &&
        (!destinationPlan.isAvailableForMassage ||
          !originPlan.isAvailableForMassage)
      ) {
        confirmDialogRef.current?.open({
          ...shipmentToMove,
          rollbackMessage: {
            toDestination: !destinationPlan.isAvailableForMassage,
            toSource: !originPlan.isAvailableForMassage,
          },
        });
      }
      // Shows rollback message only to the destination
      else if (
        onlyDestinationIsRouteOrTrap &&
        !destinationPlan.isAvailableForMassage
      ) {
        confirmDialogRef.current?.open({
          ...shipmentToMove,
          rollbackMessage: {
            toDestination: true,
            toSource: false,
          },
        });
      }
      // DON'T SHOW MODAL
      else {
        shipmentActionDialogRef.current?.open("loading", "assign");
        assignShipment({
          origins: [
            {
              id: origin.id.toString(),
              type: origin.type,
              shipmentIds,
            },
          ],
          target: {
            date: planDate,
            sicId,
            id: destinationId.toString(),
            type: destination.type,
          },
        });
      }
    },
    [
      assignShipment,
      confirmDialogRef,
      planDate,
      plans,
      reOrderShipment,
      shipmentActionDialogRef,
      sicId,
    ]
  );

  const handleUnassignShipments: UnassignShipmentFn = useCallback(
    ({ shipmentIds, sourceId, sourceType }) => {
      const source = plans.find((trap) => parseInt(sourceId) === trap.id);

      if (!source) {
        shipmentActionDialogRef.current?.close();
        throw new Error(
          `Draggable source not found with type: ${sourceType} and id: ${sourceId}`
        );
      }

      const unassignShipmentFn = () =>
        unassignShipment({
          origins: [
            {
              id: sourceId,
              type: sourceType,
              shipmentIds,
            },
          ],
          target: {
            date: planDate,
            sicId,
            type: "unassign",
          },
        });

      // Shows rollback message only to the source
      if (!source.isAvailableForMassage) {
        confirmDialogRef.current?.open({
          destination: {
            name: "unassign",
            type: "shipment",
          },
          source: {
            name: source.name,
            status: source.status,
            type: sourceType,
          },
          shipmentIds,
          rollbackMessage: {
            toDestination: false,
            toSource: true,
          },
          onConfirm: unassignShipmentFn,
        });
      } else {
        shipmentActionDialogRef.current?.open("loading", "unassign");
        unassignShipmentFn();
      }
    },
    [
      confirmDialogRef,
      planDate,
      plans,
      shipmentActionDialogRef,
      sicId,
      unassignShipment,
    ]
  );

  const dropHandler = useCallback(
    ({ source, location }: BaseEventPayload<ElementDragType>) => {
      handleDrop({
        source,
        location,
        handleAssignShipments,
        handleUnassignShipments,
      });
    },
    [handleAssignShipments, handleDrop, handleUnassignShipments]
  );

  return { dropHandler };
};
