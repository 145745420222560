import { Box, IconButton } from "@mui/material";
import CollapsedNavBar from "../CollapsedNavBar";

type Props = {
  isSidebarOpen: boolean;
  onClick: () => void;
  title?: string;
  left?: number;
};

export const OpenSidebarTabButton = ({
  isSidebarOpen,
  onClick,
  title = "Collapsed Navbar",
  left = -149,
}: Props) =>
  isSidebarOpen ? null : (
    <Box>
      <IconButton
        color="inherit"
        disableRipple={true}
        aria-label="open drawer"
        onClick={onClick}
        style={{
          position: "absolute",
          transformOrigin: "right",
          transform: "rotate(-90deg)",
          whiteSpace: "nowrap",
          left,
          top: 0,
          padding: 0,
        }}
      >
        <CollapsedNavBar title={title} />
      </IconButton>
    </Box>
  );
