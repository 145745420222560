import { Trap } from "../../../../hooks/react-query/outbound/freightDetails/useGetTrapDetails";

export const trapDetails = {
  default: [
    {
      id: 10000068,
      name: "TRAP-1 MON",
      statusId: 141,
      status: "TPLN",
      bills: 5,
      loadedBills: 2,
      pallets: 10,
      pieces: 8,
      weight: 3,
      loadedWeight: 1,
    },
    {
      id: 10000069,
      name: "TRAP-2 TUE",
      statusId: 141,
      status: "TPLN",
      bills: 0,
      loadedBills: 0,
      pallets: 0,
      pieces: 0,
      weight: 0,
      loadedWeight: 0,
    },
  ],
} as const satisfies Record<string, Trap[]>;
