import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { http } from "../../../utils/httpCommon";
import { apiUrls } from "../../../utils/apiUrls";

export const linehaulCarrierSchema = z.object({
  id: z.number().positive("Please select a carrier."),
  name: z.string(),
  code: z.string(),
});

export type LinehaulCarrier = z.infer<typeof linehaulCarrierSchema>;

export function useLinehaulCarriers(enabled = true) {
  return useQuery({
    enabled,
    queryKey: ["linehaul", "carriers"],
    queryFn: async () => {
      const { data } = await http.get(apiUrls.masterSchedule.getCarriers);
      return linehaulCarrierSchema.array().parse(data);
    },
    select: (data) => data.sort((a, b) => -b.code.localeCompare(a.code)),
    staleTime: Infinity,
  });
}

const employeeDriverSchema = z.object({
  id: z.number(),
  code: z.string(),
  name: z.string(),
  driverId: z.number().default(0),
  ratePerMile: z.number().nullable().catch(null),
  order: z.number().nullable().catch(null),
  loadBoardScheduleId: z.number().nullable().catch(null),
});

export type EmployeeDriver = z.infer<typeof employeeDriverSchema>;

export function useEmployeeDrivers() {
  return useQuery({
    queryKey: ["employee", "drivers"],
    queryFn: async () => {
      const { data } = await http.get(apiUrls.getEmployeeDrivers);
      return employeeDriverSchema.array().parse(data);
    },
    staleTime: Infinity,
  });
}

const carrierScacCodesSchema = z.object({
  carrierCode: z.string(),
  scacCode: z.string(),
});

const carrierScacCodesResponseSchema = z.array(carrierScacCodesSchema);

export type ScacCode = z.infer<typeof carrierScacCodesSchema>;

const keys = {
  scacCodes: ["linehaul", "carriers", "scac-codes"],
};

export function useCarrierScacCodes(enabled = true) {
  return useQuery({
    enabled,
    queryKey: keys.scacCodes,
    queryFn: async () => {
      const { data } = await http.get(apiUrls.getCarrierScacCodes);
      return carrierScacCodesResponseSchema.parse(data);
    },
  });
}
