import { Fragment, useCallback, useRef, useState } from "react";
import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { Label } from "@mui/icons-material";
import { CollapsibleItem } from "../../../../components/shared/sidebar/CollapsibleItem";
import { useDateSearchParamOrFallbackToToday } from "../../../../hooks/useDateSearchParamOrFallbackToToday";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import Loading from "../../../../components/shared/layout/Loading";
import { dateToInt } from "../../../../utils/dateTimeHelper";
import { useShipmentDetailSearch } from "../../../../hooks/react-query/preplanning/useShipmentDetails";
import type { Shipment } from "../../../../services/prePlanningService.types";
import type { Tag } from "../../../../types/freight/tag.type";
import { ShipmentTagsFilter } from "../Filter/ShipmentTagsFilter";
import { ShipmentItem } from "../../../../components/shared/sidebar/ShipmentItem";
import { isSelectedDateTodayOrInTheFutureForCurrentSic } from "../../../../hooks/useIsSelectedDateTodayOrInTheFutureForCurrentSic";
import { useSelectionContext } from "../../../../context/SelectionContext";
import { sortShipmentsByStopSequence } from "../../../../utils/sortComparator";
import ContextMenuDropdown, {
  type ContextMenuRef,
} from "../../../../components/shared/ContextMenuDropdown";
import type { SummaryContext } from "../ActionsMenuContext";
import { planningContextMenuActions } from "../../inboundPlanning/PlanningContextMenu/planningContextMenuActions";
import { unifyShipmentContext } from "../../../../utils/unifyShipmentContext";
import type { PlanTableFormat } from "../../../../types/planning/plan.type";

type ShipmentWithRouteId = Shipment & { routeId: number };
type TagWithShipmentCount = { tag: Tag; count: number };
type TagWithShipmentCountMap = { [tagName: string]: TagWithShipmentCount };

function getShipmentsWithRouteId(
  shipments: Shipment[],
  routes: PlanTableFormat[]
): ShipmentWithRouteId[] {
  // We need to add the parent route ID to shipments because it's needed in ShipmentsSection.
  // The routeId property of shipments is not always populated by the backend at the time of writing
  return shipments.map((shipment) => {
    const parentRoute = routes.find((route) =>
      route.shipmentIds.includes(shipment.id)
    );
    if (!parentRoute) {
      throw new Error(`Route not found for shipmentId ${shipment.id}`);
    }
    return { ...shipment, routeId: parentRoute.id };
  });
}

function getUniqueTagsWithCount(shipments: Shipment[]) {
  const tagCounter: TagWithShipmentCountMap = {};

  shipments.forEach((shipment) => {
    shipment.tags.forEach((tag) => {
      if (!(tag.name in tagCounter)) tagCounter[tag.name] = { tag, count: 0 };
      tagCounter[tag.name].count += 1;
    });
  });
  return Object.values(tagCounter);
}

interface SpecialShipmentsProps {
  plannedRoutes: PlanTableFormat[];
}

export const SpecialShipments = ({ plannedRoutes }: SpecialShipmentsProps) => {
  const theme = useTheme();

  const contextMenuRef = useRef<ContextMenuRef<SummaryContext>>(null);

  const { selected, isSelected, clearSelection } = useSelectionContext();
  const [serviceCenter] = useSelectedServiceCenter();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const [tagsApplied, setTagsApplied] = useState<string[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);

  const isEditingAllowed = isSelectedDateTodayOrInTheFutureForCurrentSic(
    selectedDate,
    serviceCenter
  );

  const planDate = dateToInt(selectedDate);

  const routesShipmentsIds = plannedRoutes.flatMap(
    (route) => route.shipmentIds
  );

  const { data: shipmentsDetails = [], isLoading: isLoadingShipments } =
    useShipmentDetailSearch({
      search: {
        sicId: serviceCenter.id,
        planDate,
        shipmentIds: routesShipmentsIds,
      },
    });

  const shipmentsWithTags = shipmentsDetails.filter(
    (shipment) => shipment.tags.length > 0
  );

  const shipments = getShipmentsWithRouteId(shipmentsWithTags, plannedRoutes);
  const tags = getUniqueTagsWithCount(shipments);

  const handleTagFilterChange = (tagsApplied: TagWithShipmentCount[]) => {
    const tagNames = tagsApplied.map((tag) => tag.tag.name);
    setTagsApplied(tagNames);
    if (tagNames.length === 0) {
      clearSelection();
    }
  };

  const filteredShipments = shipments
    .filter((shipment) =>
      shipment.tags.some((tag) => tagsApplied.includes(tag.name))
    )
    .sort((a, b) => a.assignment?.localeCompare(b.assignment ?? "") ?? 0);

  const selectionToContext = useCallback(
    (plan?: SummaryContext): SummaryContext[] => {
      if (plan) {
        return [
          {
            id: String(plan.id),
            shipmentIds: plan.shipmentIds,
            type: plan.type,
          },
        ];
      }
      return unifyShipmentContext(
        selected.map(({ id }) => {
          const plan = plannedRoutes.find((plan) => plan.id.toString() === id);
          return {
            id,
            shipmentIds: plan ? plan.shipmentIds : [],
            type: plan
              ? plan.type === "delivery_route"
                ? "route"
                : "trap"
              : "route",
          };
        })
      );
    },
    [plannedRoutes, selected]
  );

  const contextActions = useCallback(
    (plan?: SummaryContext) => {
      if (!plan) return [];
      const route = plannedRoutes.find(
        (route) => String(route.id) === String(plan.id)
      );
      if (route) {
        return planningContextMenuActions({
          section: "shipments",
          shipmentContext: selectionToContext(plan),
          routeOrTrap: route,
          clearSelection,
        });
      }
      return [];
    },
    [clearSelection, plannedRoutes, selectionToContext]
  );

  return (
    <Box>
      <CollapsibleItem
        title="Special Shipments"
        displayCount={`${filteredShipments.length} of ${shipments.length}`}
        icon={<Label />}
        isExpanded={isExpanded}
        onExpand={(value: boolean) => setIsExpanded(value)}
      >
        <ShipmentTagsFilter
          value={tags.filter((tag) => tagsApplied.includes(tag.tag.name))}
          tags={tags}
          onChange={handleTagFilterChange}
          width="100%"
        />
        {isLoadingShipments ? (
          <Loading
            styles={{ marginY: "1rem" }}
            label="sidebar special shipments"
            size={32}
          />
        ) : filteredShipments.length === 0 ? (
          <>
            <Divider />
            <Stack spacing={2} sx={{ px: 2, py: 3 }}>
              <Typography variant="body2" color={theme.palette.grey[600]}>
                No shipments to show. Select from the filter above.
              </Typography>
            </Stack>
          </>
        ) : (
          <>
            {filteredShipments
              .sort(sortShipmentsByStopSequence)
              .map((shipment, index) => (
                <Fragment key={shipment.id}>
                  <ShipmentItem
                    isEditingAllowed={isEditingAllowed}
                    index={index}
                    shipment={shipment}
                    isSelected={isSelected(shipment.id, "shipment")}
                    type="shipment"
                    parentId={shipment.routeId}
                    contextRef={contextMenuRef}
                  />
                </Fragment>
              ))}
            <ContextMenuDropdown
              ref={contextMenuRef}
              actions={contextActions}
            />
          </>
        )}
      </CollapsibleItem>
    </Box>
  );
};
