import {
  BaseSelectProps,
  ListSubheader,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import { Trap } from "../../types/planning/plannedTrap.type";

import DropdownStatusItem, {
  DropdownStatusItemProps,
} from "./DropdownStatusItem";

type SelectTrapProps = {
  label: string;
  onTrapChange: (trapId: number) => void;
  traps: Trap[];
} & BaseSelectProps<number>;

const SelectTrap = ({
  value,
  label,
  onTrapChange,
  traps = [],
  ...rest
}: SelectTrapProps) => {
  const theme = useTheme();

  const plannedTraps = traps.filter(({ type }) => type === "Planned");
  const existingTraps = traps.filter(({ type }) => type !== "Planned");

  const renderTrapMenuItem = (
    trap: Trap,
    additionalProps?: Partial<DropdownStatusItemProps>
  ) => (
    <MenuItem key={trap.id} value={trap.id} aria-label={`Trap ${trap.id}`}>
      <DropdownStatusItem
        shipmentDetails={{
          bills: trap.bills,
          pallets: trap.pallets,
          pieces: trap.pieces,
          weight: trap.weight,
        }}
        label={trap.name}
        status={trap.status}
        {...additionalProps}
      />
    </MenuItem>
  );

  return (
    <Select
      {...rest}
      value={value}
      fullWidth
      required
      label={label}
      onChange={(e) => onTrapChange(Number(e.target.value))}
    >
      <ListSubheader>Existing Traps</ListSubheader>
      {existingTraps.length === 0 && <MenuItem disabled>None</MenuItem>}
      {existingTraps.map((trap) => renderTrapMenuItem(trap))}

      <ListSubheader>Planned Traps</ListSubheader>
      {plannedTraps.length === 0 && <MenuItem disabled>None</MenuItem>}
      {plannedTraps.map((trap) =>
        renderTrapMenuItem(trap, {
          bgColor: theme.palette.success.main,
          color: theme.palette.common.white,
        })
      )}
    </Select>
  );
};

export default SelectTrap;
