interface ErrorResponse {
  errorCode: string;
  errorMessage: string;
}

export function isErrorResponse(data: unknown): data is ErrorResponse {
  if (typeof data !== "object" || data === null) return false;

  const record = data as Record<string, unknown>;
  return (
    typeof record.errorCode === "string" &&
    typeof record.errorMessage === "string"
  );
}
