import { Route, ViewModule } from "@mui/icons-material";
import { Box, ListItem, Typography, useTheme } from "@mui/material";
import { AssignmentDetails } from "../../types/assignment.type";
import { ReadOnlyStatusTag } from "../../pages/inbound/shared/ReadOnlyStatusTag";

type AutocompleteAvailabilityOptionDriverProps = {
  listItemProps?: React.HTMLAttributes<HTMLLIElement>;
  name: string;
  assignment: AssignmentDetails | null;
  refCode?: string;
};

const greyedOutOpacityStyle = { opacity: 0.54 };

export const AutocompleteAvailabilityOptionDriver = ({
  listItemProps,
  name,
  assignment,
  refCode,
}: AutocompleteAvailabilityOptionDriverProps) => {
  const theme = useTheme();

  return (
    <ListItem {...listItemProps} key={name}>
      <Box display="flex" flexDirection="row" alignItems="center" mb={1} mt={1}>
        <Box display="flex" alignItems="center">
          <Typography fontSize={16} mt="2px" mr="5px" width={180}>
            {name}
          </Typography>
        </Box>

        {refCode && (
          <Box display="flex" alignContent="center">
            <Typography
              mt="2px"
              mr={1}
              fontSize={14}
              sx={{ color: theme.palette.secondary.light }}
            >
              {refCode}
            </Typography>
          </Box>
        )}
        {assignment?.name && (
          <>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius={2}
              mr={1}
              sx={{ backgroundColor: "#F5F5F5" }}
            >
              {assignment.type === "Route" ? (
                <Route sx={{ margin: "2px", ...greyedOutOpacityStyle }} />
              ) : (
                <ViewModule sx={{ margin: "2px", ...greyedOutOpacityStyle }} />
              )}
            </Box>
            <Typography
              width={140}
              mt="2px"
              fontSize={14}
              sx={greyedOutOpacityStyle}
            >
              {assignment.name}
            </Typography>
            <Box ml={1}>
              <ReadOnlyStatusTag status={assignment.status} />
            </Box>
            {assignment.extraAssignmentsCount > 0 && (
              <Typography ml={1} sx={greyedOutOpacityStyle} fontSize={12}>
                {`+${assignment.extraAssignmentsCount} more`}
              </Typography>
            )}
          </>
        )}
      </Box>
    </ListItem>
  );
};
