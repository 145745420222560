import { Fragment, useCallback, useRef, useState } from "react";
import { Box } from "@mui/material";
import { CollapsibleItem } from "../../../../components/shared/sidebar/CollapsibleItem";
import { useSelectionContext } from "../../../../context/SelectionContext";
import { useDateSearchParamOrFallbackToToday } from "../../../../hooks/useDateSearchParamOrFallbackToToday";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { useGetAppointmentBucketData } from "../../../../hooks/react-query/preplanning/useGetAppointmentBucket";
import { isSelectedDateTodayOrInTheFutureForCurrentSic } from "../../../../hooks/useIsSelectedDateTodayOrInTheFutureForCurrentSic";
import { tableSectionToIconMap } from "../TableActionBar";
import Loading from "../../../../components/shared/layout/Loading";
import type { ContextMenuRef } from "../../../../components/shared/ContextMenuDropdown";
import {
  useActionsMenuContext,
  type SummaryContext,
} from "../ActionsMenuContext";
import ContextMenuDropdown from "../../../../components/shared/ContextMenuDropdown";
import { contextMenuActions } from "../../prePlanning/tables/contextMenuActions";
import { unifyShipmentContext } from "../../../../utils/unifyShipmentContext";
import { SectionItem } from "./SectionItem";

export const Appointments = () => {
  const contextMenuRef = useRef<ContextMenuRef<SummaryContext>>(null);
  const { openDialog } = useActionsMenuContext();
  const { selected, clearSelection, isAllSelected } = useSelectionContext();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const [serviceCenter] = useSelectedServiceCenter();

  const [isExpanded, setIsExpanded] = useState(false);

  const isEditingAllowed = isSelectedDateTodayOrInTheFutureForCurrentSic(
    selectedDate,
    serviceCenter
  );

  const { data: appointments, isLoading } = useGetAppointmentBucketData(
    serviceCenter.id
  );

  const selectionToContext = useCallback(
    (item?: SummaryContext): SummaryContext[] => {
      if (item) {
        return [
          {
            id: String(item.id),
            shipmentIds: item.shipmentIds,
            type: item.type,
          },
        ];
      }
      return unifyShipmentContext(
        selected.map(({ id }) => {
          const appt = appointments?.find(
            (bucket) => bucket.title.toString() === id
          );
          return {
            id,
            shipmentIds: appt ? appt.shipmentIds : [],
            type: "bucket",
          };
        })
      );
    },
    [selected, appointments]
  );

  const contextActions = useCallback(
    (item?: SummaryContext) =>
      contextMenuActions({
        section: "appointments",
        shipmentContext: selectionToContext(item),
        openDialog,
        clearSelection,
      }),
    [clearSelection, openDialog, selectionToContext]
  );

  if (isLoading || !appointments) {
    return <Loading size={32} label="sidebar appointments" />;
  }

  return (
    <Box>
      <CollapsibleItem
        title="Appointments"
        displayCount={appointments.length}
        icon={tableSectionToIconMap["appointment"]}
        isExpanded={isExpanded}
        onExpand={(value: boolean) => setIsExpanded(value)}
      >
        {appointments.map((bucket) => {
          const { title, shipmentIds } = bucket;
          return (
            <Fragment key={title}>
              <SectionItem
                data={bucket}
                isEditingAllowed={isEditingAllowed}
                isSelected={isAllSelected(shipmentIds, "bucket")}
                serviceCenterId={serviceCenter.id}
                selectedDate={selectedDate}
                inboundView="appointments"
                icon="appointment"
                contextRef={contextMenuRef}
              />
            </Fragment>
          );
        })}
      </CollapsibleItem>
      <ContextMenuDropdown actions={contextActions} ref={contextMenuRef} />
    </Box>
  );
};
