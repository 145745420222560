import dayjs from "dayjs";
import { DaySummary } from "../../hooks/react-query/linehaul/useLoadBoard";

export const mockDaySummary = {
  default: [
    {
      originSic: {
        id: 103,
        code: "ONT",
        ownership: "V",
        lhRegionId: 0,
      },
      destinationSic: {
        id: 3,
        code: "LAX",
        ownership: "V",
        lhRegionId: 5,
      },
      schedules: [],
      shipments: [
        {
          shipmentId: 132327682,
          proNumber: "888888",
          tags: [],
          serviceDue: null,
          pickupDate: dayjs("2024-08-01T00:00:00"),
          tripNumber: null,
          // tripNumber: 999999,
          shipperName: "SEFL",
          shipperZipCode: "71202",
          consigneeName: "SCOTT LABORATORIES",
          consigneeZipCode: "94954",
          weight: 559,
          pallets: 1,
          pieces: 0,
          revenue: 0,
          originSicCode: "ONT",
          destinationSicCode: "LAX",
          currentSicCode: "AFW",
          originSic: {
            id: 103,
            code: "ONT",
            ownership: "V",
            lhRegionId: null,
          },
          destinationSic: {
            id: 3,
            code: "LAX",
            ownership: "V",
            lhRegionId: 5,
          },
          currentSic: {
            id: 84,
            code: "AFW",
            ownership: "V",
            lhRegionId: null,
          },
          timeOnDock: null,
        },

        {
          shipmentId: 132327682,
          proNumber: "558888",
          tags: [],
          serviceDue: null,
          pickupDate: dayjs("2024-08-01T00:00:00"),
          tripNumber: 77,
          shipperName: "SEFL",
          shipperZipCode: "71202",
          consigneeName: "SCOTT LABORATORIES",
          consigneeZipCode: "94954",
          weight: 559,
          pallets: 1,
          pieces: 0,
          revenue: 0,
          originSicCode: "ONT",
          destinationSicCode: "LAX",
          currentSicCode: "AFW",
          originSic: {
            id: 103,
            code: "ONT",
            ownership: "V",
            lhRegionId: null,
          },
          destinationSic: {
            id: 3,
            code: "LAX",
            ownership: "V",
            lhRegionId: 5,
          },
          currentSic: {
            id: 84,
            code: "AFW",
            ownership: "V",
            lhRegionId: null,
          },
          timeOnDock: null,
        },

        {
          shipmentId: 132327682,
          proNumber: "668888",
          tags: [],
          serviceDue: null,
          pickupDate: dayjs("2024-08-01T00:00:00"),
          tripNumber: 999999,
          shipperName: "SEFL",
          shipperZipCode: "71202",
          consigneeName: "SCOTT LABORATORIES",
          consigneeZipCode: "94954",
          weight: 559,
          pallets: 1,
          pieces: 0,
          revenue: 0,
          originSicCode: "ONT",
          destinationSicCode: "LAX",
          currentSicCode: "AFW",
          originSic: {
            id: 103,
            code: "ONT",
            ownership: "V",
            lhRegionId: null,
          },
          destinationSic: {
            id: 3,
            code: "LAX",
            ownership: "V",
            lhRegionId: 5,
          },
          currentSic: {
            id: 84,
            code: "AFW",
            ownership: "V",
            lhRegionId: null,
          },
          timeOnDock: null,
        },
      ],
    },
    {
      originSic: {
        id: 84,
        code: "ONT",
        ownership: "V",
        lhRegionId: 0,
      },
      destinationSic: {
        id: 103,
        code: "LAX",
        ownership: "V",
        lhRegionId: 0,
      },
      schedules: [
        {
          scheduleId: 3047785,
          loadPairScheduleId: 3080546,
          bookingNumber: "",
          bookingStatus: "PendingConfirmation",
          tripStatus: null,
          originSicCode: "ONT",
          destinationSicCode: "LAX",
          loadingDate: dayjs("2024-08-01T00:00:00"),
          pickupDate: dayjs("2024-08-01T00:00:00"),
          gateCutDate: dayjs("2024-08-01T00:00:00"),
          serviceType: "1-way",
          carrierName: "COVENANT TRANSPORT INC",
          carrierCode: "L004",
          tripNumber: 999999,
          trailerNumber: "",
          deadheads: [],
          stops: [],
          tripPriority: 0,
          totalRevenue: 0,
          totalCost: 0,
          netLinehaul: 0,
          isPublished: false,
          isCancelled: false,
          loadType: "Start",
          hasLoadPair: false,
          tonu: 0,
          employeeDrivers: [],
          carrierRatePerMile: 0,
          rolloverFee: 250,
        },
        {
          scheduleId: 3047784,
          loadPairScheduleId: 3080545,
          bookingNumber: "",
          bookingStatus: "PendingConfirmation",
          tripStatus: "PLAN",
          originSicCode: "ONT",
          destinationSicCode: "LAX",
          loadingDate: dayjs("2024-08-01T00:00:00"),
          pickupDate: dayjs("2024-08-01T00:00:00"),
          gateCutDate: dayjs("2024-08-01T00:00:00"),
          serviceType: "Dedicated",
          carrierName: "DAYLIGHT EMPLOYEE LH DRIVERS",
          carrierCode: "N999",
          tripNumber: 999999,
          trailerNumber: "",
          deadheads: [],
          stops: [],
          tripPriority: 1,
          totalRevenue: 0,
          totalCost: 0,
          netLinehaul: 0,
          isPublished: true,
          isCancelled: false,
          rolloverFee: null,
          loadType: "Start",
          hasLoadPair: false,
          tonu: 0,
          employeeDrivers: [
            {
              id: 963,
              loadBoardScheduleId: 963,
              name: "DIAZ, MARIO ANTONIO",
              driverId: 5069,
              order: 1,
              ratePerMile: 0,
            },
            {
              id: 964,
              loadBoardScheduleId: 964,
              name: "SINGH, GURBRINDER ",
              driverId: 5085,
              order: 1,
              ratePerMile: 0,
            },
          ],
          carrierRatePerMile: 0,
        },
      ],
      shipments: [],
    },
  ],
} as const satisfies Record<string, DaySummary[]>;
