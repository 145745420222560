import { Box, Card, CardContent } from "@mui/material";
import {
  CalendarViewMonth as PalletsIcon,
  ViewQuilt as PiecesIcon,
} from "@mui/icons-material";
import { memo, PropsWithChildren } from "react";
import {
  Consignee,
  Shipment,
  Shipper,
} from "../../../services/prePlanningService.types";
import ShipmentTypography from "../shared/AccordionShipmentItem/ShipmentTypography";
import { TagsGrid } from "../shared/AccordionShipmentItem/TagsGrid";
import { Tag } from "../../../types/freight/tag.type";
import StatusTag from "../../../components/shared/StatusTag/StatusTag";
import { dashIfEmpty } from "../../../constants/utils";
import { DASH_PLACEHOLDER } from "../../../constants/dashPlaceholder";

interface ShipmentItemProps {
  item: Shipment;
}

const TextLine = ({
  label,
  children,
}: PropsWithChildren<{ label: string }>) => (
  <ShipmentTypography>
    <b style={{ marginRight: "8px", textTransform: "uppercase" }}>{label}:</b>
    {children
      ? typeof children === "string"
        ? dashIfEmpty(children)
        : children
      : DASH_PLACEHOLDER}
  </ShipmentTypography>
);

type LeftColumnProps = {
  proNumber: string;
  shipper?: Shipper;
  consignee?: Consignee;
};

export const LeftColumn = ({
  proNumber,
  shipper,
  consignee,
}: LeftColumnProps) => {
  const consigneeName = dashIfEmpty(consignee?.name);
  const consigneeCity = dashIfEmpty(consignee?.city);
  const consigneeZipCode = dashIfEmpty(consignee?.zipCode);
  return (
    <Box sx={{ mr: 2, maxWidth: "160px" }}>
      <TextLine label="PRO #">{proNumber}</TextLine>
      <TextLine label="SHIPPER">{shipper?.name}</TextLine>
      <TextLine label="CONSIGNEE">
        {!consignee
          ? DASH_PLACEHOLDER
          : `${consigneeName} ${consigneeCity} ${consigneeZipCode}`}
      </TextLine>
    </Box>
  );
};

type RightColumnProps = {
  tags: Tag[];
  appointmentDate: Date | null;
  location: string | null;
  sic: string | null;
  status: string;
  isPlanned: boolean;
};

export const RightColumn = memo(
  ({
    tags,
    appointmentDate,
    location,
    sic,
    status,
    isPlanned,
  }: RightColumnProps) => (
    <Box sx={{ width: 120 }}>
      <TagsGrid tags={tags} />
      <TextLine label="APPT">{appointmentDate?.toLocaleDateString()}</TextLine>
      <TextLine label="LOC">{location}</TextLine>
      <TextLine label="SIC">{sic}</TextLine>
      <TextLine label="STATUS">
        <StatusTag
          label={status}
          isPlannedFor={isPlanned ? "today" : undefined}
          type="plain"
        />
      </TextLine>
    </Box>
  )
);
RightColumn.displayName = "RightColumn";

export const ShipmentItem = ({ item }: ShipmentItemProps) => (
  <Card
    sx={{
      borderRadius: 0,
      boxShadow: "none",
    }}
  >
    <CardContent sx={{ padding: 0 }}>
      <Box display="flex">
        <LeftColumn
          consignee={item.consignee}
          proNumber={item.proNumber}
          shipper={item.shipper}
        />
        <RightColumn
          appointmentDate={item.appointmentDate}
          location={item.location}
          sic={item.sic}
          status={item.status}
          tags={item.tags}
          isPlanned={item.isPlanned}
        />
      </Box>

      <Box display="flex" gap={5}>
        <Box display="flex" alignItems="center">
          <PalletsIcon />
          <ShipmentTypography>
            {item.pallets.toLocaleString()}
          </ShipmentTypography>
        </Box>
        <Box display="flex" alignItems="center">
          <PiecesIcon />
          <ShipmentTypography>
            {item.pieces.toLocaleString()}
          </ShipmentTypography>
        </Box>
        <Box display="flex" alignItems="center">
          <ShipmentTypography>
            {item.weight.toLocaleString()} {item.weightUnit}
          </ShipmentTypography>
        </Box>
      </Box>
    </CardContent>
  </Card>
);
