import { z } from "zod";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

const TABLE_SELECTION_PARAM_KEY = "selection";
const TABLE_SELECTION_SEPARATOR = ",";

const tableSelectionSchema = z.array(z.string());

export const useTableSelectionParam = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getSelectionParam = () => {
    const param = searchParams.get(TABLE_SELECTION_PARAM_KEY) || "";
    const result = param.split(TABLE_SELECTION_SEPARATOR);

    const validation = tableSelectionSchema.safeParse(result);
    if (!validation.success) {
      throw new Error("Invalid selection param:", validation.error);
    }

    return result.filter((x) => x);
  };

  const updateSelectionParam = useCallback(
    (selection: string[]) => {
      if (selection.length === 0) {
        searchParams.delete(TABLE_SELECTION_PARAM_KEY);
      } else {
        searchParams.set(
          TABLE_SELECTION_PARAM_KEY,
          selection.join(TABLE_SELECTION_SEPARATOR)
        );
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const clearSelection = () => updateSelectionParam([]);

  return {
    selection: getSelectionParam(),
    setSelection: updateSelectionParam,
    clearSelection,
  } as const;
};
