import { Filter } from "../../../types/filter";
import {
  getNonEmptyUniqueSortedOptions,
  handlePrefix,
  isActiveFilter,
  stringOptionFactory,
  type Prefix,
} from "../utils";

export type ZoneQueryParam = "zone";
type SomethingWithZones = {
  zones: string[];
};

export const getZonesFilter = (
  data: SomethingWithZones[],
  prefix?: Prefix
): Filter => ({
  label: "Zone",
  queryParam: handlePrefix("zone", prefix),
  options: getNonEmptyUniqueSortedOptions(
    data.flatMap(({ zones }) => zones.map(stringOptionFactory))
  ),
});

export const filterZones = <T extends SomethingWithZones>(
  data: T[],
  filters?: string[]
): T[] =>
  isActiveFilter(filters)
    ? data.filter(({ zones }) => filters.some((f) => zones.includes(f)))
    : data;
