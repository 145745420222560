import {
  Fragment,
  MouseEvent,
  RefObject,
  memo,
  useRef,
  type CSSProperties,
} from "react";
import {
  alpha,
  Box,
  TableRow as MuiTableRow,
  SxProps,
  useTheme,
} from "@mui/material";
import { Row, RowSelectionState, Table } from "@tanstack/react-table";
import { TableCell as MuiTableCell } from "@mui/material";

import { createPortal } from "react-dom";
import { ContextMenuRef } from "../ContextMenuDropdown";
import DragIcon from "../icons/DragIcon";
import { previewStyles, useDraggable } from "../dragndrop/hooks/useDraggable";
import { ShipmentDragOverlay } from "../sidebar/ShipmentDragOverlay";
import DropIndicator from "../dragndrop/DropIndicator";
import type { DraggedItem } from "../dragndrop/types";
import { parseDroppableId } from "../../../utils/dragging";
import TableCell from "./TableCell";
import { RowSelectionMode } from "./Selectable.types";

type TDataWithStopSeq = {
  stopSeq?: number;
};

export type TableRowProps<TData> = {
  table: Table<TData>;
  row: Row<TData>;
  isSelected: boolean;
  contextMenuRef: RefObject<ContextMenuRef<TData>>;
  selectionCount: number;
  dragAndDropId?: string;
  isDraggableRow?: boolean;
  isSortableRow?: boolean;
  rowSelectionMode?: RowSelectionMode;
};

const TableRow = <TData extends TDataWithStopSeq>({
  table,
  row,
  isSelected,
  contextMenuRef,
  rowSelectionMode = "unselectable",
  isDraggableRow = false,
  isSortableRow,
  dragAndDropId,
}: TableRowProps<TData>) => {
  const rowRef = useRef<HTMLTableRowElement | null>(null);
  const handleRef = useRef<HTMLButtonElement | null>(null);

  const theme = useTheme();

  const handleRowSelection = (
    event: MouseEvent<HTMLTableRowElement | HTMLInputElement>
  ) => {
    if (rowSelectionMode === "unselectable") return;
    // Do nothing if the selection is through the checkboxes
    const target = event.target;
    if ("type" in target && target.type === "checkbox") {
      return;
    } else if (
      rowSelectionMode === "row-click" ||
      rowSelectionMode === "checkbox-and-row-click"
    ) {
      if (event.ctrlKey || event.metaKey) {
        // User clicked the row, with ctrl or cmd pressed will add the row to the selection
        row.toggleSelected();
      } else if (table.getIsSomeRowsSelected() && event.shiftKey) {
        // User clicked the row, with shift pressed will add the rows on the interval to the selection
        // Ex: check row 1, press shift and check row 4, will select from 1 to 4.
        const rows = table.getRowModel().rows;
        const firstSelectedRow = table.getSelectedRowModel().rows[0];
        if (typeof firstSelectedRow === "undefined") {
          throw new Error(
            "Error while selecting a row in a table without rows."
          );
        }
        const selectedRowIdMap: RowSelectionState = {};
        // From top to bottom
        if (firstSelectedRow.index < row.index) {
          rows.slice(firstSelectedRow.index, row.index + 1).forEach((row) => {
            selectedRowIdMap[row.id] = true;
          });
        }
        // From bottom to top
        else {
          rows.slice(row.index, firstSelectedRow.index + 1).forEach((row) => {
            selectedRowIdMap[row.id] = true;
          });
        }
        table.setRowSelection(selectedRowIdMap);
      } else {
        // User clicked the row, but didn't click shift, so select the single row
        const selectedRowIdMap: RowSelectionState = {};
        selectedRowIdMap[row.id] = true;
        table.setRowSelection(selectedRowIdMap);
      }
    }
  };

  const selectRowOnContext = async () => {
    if (rowSelectionMode === "unselectable") return;
    const selectedRowIdMap: RowSelectionState = {};
    selectedRowIdMap[row.id] = true;
    await table.setRowSelection(selectedRowIdMap);
  };

  const dragData: DraggedItem<TData> = {
    id: dragAndDropId || row.id,
    index: row.original.stopSeq ?? row.index,
    item: row.original,
  };

  const { state, closestEdge, preview, previewElement } = useDraggable({
    id: row.id,
    index: row.index,
    element: rowRef,
    handle: handleRef,
    getInitialData: () => dragData,
    getData: () => dragData,
    canDrag: () => isDraggableRow,
    canDrop: ({ source }) => {
      if (source.element === rowRef.current) return false;
      return !isDraggableRow
        ? parseDroppableId(String(source.data.id)).parentId !== row.id
        : parseDroppableId(String(source.data.id)).id !== row.id;
    },
  });

  const styles: SxProps = {
    position: "relative",
    width: "100%",
    height: "51px",
    backgroundColor:
      state.type === "over"
        ? `${alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          )}`
        : isSelected
          ? `${theme.palette.action.selected} !important`
          : "unset",
    userSelect: "none",
    "&:hover": {
      backgroundColor: theme.palette.grey[50],
    },
  };

  return (
    <Fragment>
      <MuiTableRow
        ref={rowRef}
        sx={styles}
        onClick={handleRowSelection}
        onContextMenu={async (event) => {
          if (!row.getIsSelected()) {
            await selectRowOnContext();
          }
          contextMenuRef.current?.openContextMenu(event, row.original);
        }}
      >
        {row.getVisibleCells().map((cell) => {
          if (cell.column.id === "drag") {
            return (
              <MuiTableCell key={cell.id} sx={{ padding: "0 0 0 1rem" }}>
                <DragIcon
                  ref={handleRef}
                  label={`Table row ${row.id} drag handle`}
                />
              </MuiTableCell>
            );
          }
          return <TableCell key={cell.id} cell={cell} />;
        })}
        {isDraggableRow && isSortableRow && closestEdge && (
          <DropIndicator edge={closestEdge} />
        )}
      </MuiTableRow>
      {preview &&
        createPortal(
          <Box
            ref={previewElement}
            style={previewStyles(preview) as CSSProperties}
          >
            <ShipmentDragOverlay source="table" shipment={row.original} />
          </Box>,
          document.body
        )}
    </Fragment>
  );
};

export default memo(TableRow) as <TData>(
  props: TableRowProps<TData>
) => React.JSX.Element;
