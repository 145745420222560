import { z } from "zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Driver, driverSchema, driversSchema } from "../../types/driver.type";
import { http } from "../../utils/httpCommon";
import { apiUrls } from "../../utils/apiUrls";
import { toastMessage } from "../../constants/strings";
import {
  assignmentSchema,
  planningAssignmentsSchema,
} from "../../types/assignment.type";
import { toast } from "../../utils/snackbarHelper";

type Params = {
  serviceCenterId: number;
  isAvailable?: boolean;
  searchTerm?: string;
};

const useDriversKeys = {
  drivers: (
    serviceCenterId: number,
    searchTerm?: string,
    isAvailable?: boolean
  ) => ["drivers", serviceCenterId, searchTerm, isAvailable],
  driver: (driverId: number) => ["driver", driverId],
  driverUsage: (serviceCenterId: number, planDate: number) => [
    "driversUsage",
    serviceCenterId,
    planDate,
  ],
};

const driverUsageSchema = z.object({
  id: z.number().positive(),
  name: z.string(),
  refCode: z.string().nullable(),
  inUseByInfo: assignmentSchema.nullable(),
  planningAssignmentsInfo: planningAssignmentsSchema,
});
export type DriverUsage = z.infer<typeof driverUsageSchema>;

export const useDrivers = (params: Params, enabled = true) => {
  const { isAvailable, serviceCenterId, searchTerm } = params;
  return useQuery({
    enabled,
    queryKey: useDriversKeys.drivers(serviceCenterId, searchTerm, isAvailable),
    queryFn: async () => {
      const { data } = await http.get(apiUrls.getDrivers(serviceCenterId), {
        params: { isAvailable, searchTerm },
      });

      return driversSchema.parse(data);
    },
    meta: {
      errorMessage: toastMessage.shared.drivers.failedFetch,
    },
  });
};

const useDriverUsageSelect = (data: DriverUsage[]): DriverUsage[] => [
  ...data.filter((driver) => !driver.inUseByInfo),
  ...data.filter((driver) => driver.inUseByInfo),
];

export const useDriversUsage = (
  sicId: number,
  planDate: number,
  enabled: boolean
) =>
  useQuery({
    enabled,
    queryKey: useDriversKeys.driverUsage(sicId, planDate),
    queryFn: async () => {
      const { data } = await http.get(
        apiUrls.getDriversUsageBySicIdAndPlanDate(sicId, planDate)
      );
      return z.array(driverUsageSchema).parse(data);
    },
    meta: {
      errorMessage: toastMessage.shared.drivers.failedFetch,
    },
    select: useDriverUsageSelect,
  });

export const useDriverById = (id: number | null) =>
  useQuery({
    queryKey: useDriversKeys.driver(id ?? 0),
    queryFn: async () => {
      if (!id) return;

      const { data } = await http.get(apiUrls.getDriverById(id));
      return driverSchema.parse(data);
    },
    enabled: Boolean(id),
  });

export const useEditDriver = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (driver: Driver) => {
      const { data } = await http.put(apiUrls.putDriver(driver.id), driver);

      return driverSchema.parse(data);
    },
    onSuccess,
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries({
        queryKey: useDriversKeys.drivers(variables.homeSicId),
      });
      queryClient.invalidateQueries({
        queryKey: useDriversKeys.driver(variables.id),
      });
    },
    onError: () =>
      toast(toastMessage.settings.editDriver.failed, {
        variant: "error",
      }),
  });
};
