import { ErrorBoundary } from "react-error-boundary";
import { useMemo } from "react";
import { useShipmentDetailSearch } from "../../../../hooks/react-query/preplanning/useShipmentDetails";
import { usePlannedRoutes } from "../../../../hooks/react-query/usePlannedRoutes";
import { useRoutesAndTraps } from "../../../../hooks/react-query/useRoutes";
import { useDateSearchParamOrFallbackToToday } from "../../../../hooks/useDateSearchParamOrFallbackToToday";
import { isSelectedDateTodayOrInTheFutureForCurrentSic } from "../../../../hooks/useIsSelectedDateTodayOrInTheFutureForCurrentSic";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import type { PlanTableFormat } from "../../../../types/planning/plan.type";
import { dateToInt } from "../../../../utils/dateTimeHelper";
import { getFormattedPlannedRoutes } from "../../inboundPlanning/utils";
import ErrorFallback from "../../../../components/shared/ErrorFallback";
import { ClearSidebarSelection } from "../ClearSidebarSelection";
import { useSelectionContext } from "../../../../context/SelectionContext";
import { useValidateMergeRequest } from "../../../../hooks/useValidateMergeRequest";
import { contextMenuActions } from "../../prePlanning/tables/contextMenuActions";
import { useActionsMenuContext } from "../ActionsMenuContext";
import { PlannedRoutes } from "./PlannedRoutes";
import { PlannedTraps } from "./PlannedTraps";
import { SpecialShipments } from "./SpecialShipments";

export type PlanningPrefixes = "planning" | "map" | "grid" | "routes" | "traps";

export const PlannedRoutesAndTraps = () => {
  const { openDialog } = useActionsMenuContext();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const planDate = dateToInt(selectedDate);
  const [serviceCenter] = useSelectedServiceCenter();

  const isEditingAllowed = isSelectedDateTodayOrInTheFutureForCurrentSic(
    selectedDate,
    serviceCenter
  );

  const { selected, clearSelection } = useSelectionContext();

  const { data: routes = [] } = useRoutesAndTraps(serviceCenter.id);

  const { data: plannedRoutes = [] } = usePlannedRoutes(
    planDate,
    serviceCenter.id
  );

  const { data: shipments = [] } = useShipmentDetailSearch({
    search: {
      shipmentIds: [
        ...new Set(plannedRoutes.flatMap(({ shipmentIds }) => shipmentIds)),
      ],
      sicId: serviceCenter.id,
      planDate,
      addPlanningInfo: true,
    },
    enabled: true,
    onlyIncludeHighlightedRows: true,
  });

  const formattedPlannedRoutesAndTraps: PlanTableFormat[] = useMemo(
    () =>
      getFormattedPlannedRoutes(
        serviceCenter.id,
        planDate,
        plannedRoutes,
        routes,
        shipments
      ),
    [serviceCenter.id, planDate, plannedRoutes, routes, shipments]
  );

  const plannedDeliveryRoutes = formattedPlannedRoutesAndTraps.filter(
    (route) => route.type === "delivery_route"
  );

  const plannedTraps = formattedPlannedRoutesAndTraps.filter(
    (trap) => trap.type === "delivery_trap"
  );

  const isAnySelected =
    selected.reduce((total, item) => total + item.shipmentIds.length, 0) > 0;

  const { validateMergeRequest } = useValidateMergeRequest(
    selected,
    formattedPlannedRoutesAndTraps
  );

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <SpecialShipments plannedRoutes={plannedDeliveryRoutes} />
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <PlannedRoutes
          selectedDate={selectedDate}
          serviceCenter={serviceCenter}
          isEditingAllowed={isEditingAllowed}
          routes={plannedDeliveryRoutes}
        />
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <PlannedTraps
          selectedDate={selectedDate}
          serviceCenter={serviceCenter}
          isEditingAllowed={isEditingAllowed}
          traps={plannedTraps}
        />
      </ErrorBoundary>
      {isAnySelected && (
        <ClearSidebarSelection
          contextActions={() =>
            contextMenuActions({
              shipmentContext: selected,
              validateMergeRequest,
              plannedRoutesAndTraps: formattedPlannedRoutesAndTraps,
              section: "planning",
              openDialog,
              clearSelection,
            }).filter((action) => !action.label.startsWith("Merge"))
          }
        />
      )}
    </>
  );
};
