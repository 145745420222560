import {
  getDefaultFilterParams,
  inboundDefaultFilters,
} from "../constants/filters/inboundDefaultFilters";
import { useIsFeatureFlagEnabled } from "../featureFlags/useIsFeatureFlagEnabled";
import { useSelectedServiceCenter } from "./useSelectedServiceCenter";
import { SELECTED_DATE_PARAM_KEY } from "./useDateSearchParamOrFallbackToToday";
import { UNPLANNED_SELECTED_DATE } from "./useUnplannedDatetimeSearchParam";
import { LaneType } from "./react-query/outbound/freightDetails/useFreightDetails";

type InboundProps = {
  planDate: string;
  unplannedDateTime?: string;
  sicCode?: string;
};

type OutboundShipmentProps = {
  view: string;
  tripNumber?: number;
  plannedPullId?: number;
  etaDate?: string;
  trapId?: number;
};

// These URLs vary based on the service center, so we need to get the service center from the context
export function usePageUrls() {
  const [serviceCenter] = useSelectedServiceCenter();
  const isMapViewEnabled = useIsFeatureFlagEnabled(
    "inbound-planning-map-link-client"
  );

  return {
    inboundPlanning: ({
      planDate,
      unplannedDateTime,
      sicCode,
    }: InboundProps) => {
      const searchParams = new URLSearchParams();

      searchParams.append(SELECTED_DATE_PARAM_KEY, planDate);

      if (unplannedDateTime) {
        searchParams.append("unplannedDateTime", unplannedDateTime);
      }

      const oldUrl = `/operations/${sicCode ?? serviceCenter.code}/inbound/planning?${searchParams.toString()}`;
      const url = `/operations/${sicCode ?? serviceCenter.code}/inbound/planning/list?${searchParams.toString()}`;

      return isMapViewEnabled ? url : oldUrl;
    },
    inboundUrgentAndCriticalCare: (sicCode?: string) =>
      `/operations/${sicCode ?? serviceCenter.code}/inbound/uc-cc`,
    inboundPrePlanning: ({
      planDate,
      unplannedDateTime,
      sicCode,
    }: InboundProps) => {
      const searchParams = new URLSearchParams();
      searchParams.append(SELECTED_DATE_PARAM_KEY, planDate);
      if (unplannedDateTime) {
        searchParams.append(UNPLANNED_SELECTED_DATE, unplannedDateTime);
      }

      const url = `/operations/${sicCode ?? serviceCenter.code}/inbound/pre-planning/appointments?${searchParams.toString()}`;
      return url;
    },

    inboundUnplannedFreight: ({
      planDate,
      unplannedDateTime,
      sicCode,
    }: InboundProps) => {
      const searchParams = new URLSearchParams();

      searchParams.append(SELECTED_DATE_PARAM_KEY, planDate);

      if (unplannedDateTime) {
        searchParams.append(UNPLANNED_SELECTED_DATE, unplannedDateTime);
      }

      const defaultFilters = getDefaultFilterParams(
        inboundDefaultFilters["unplanned-freight"]
      );

      const defaultFilterParams = new URLSearchParams(defaultFilters);

      defaultFilterParams.forEach((value, key) => {
        searchParams.append(key, value);
      });

      return `/operations/${sicCode ?? serviceCenter.code}/inbound/unplanned-freight?${searchParams.toString()}`;
    },
    outboundLaneDetails: (sicCode?: string) =>
      `/operations/${sicCode ?? serviceCenter.code}/outbound/lane-details/?view=lane`,
    outboundFreightDetailsDoorView: `/operations/${serviceCenter.code}/outbound/lane-details/?view=door`,
    outboundShipmentDetails: (
      loadToSicId: number,
      loadToSicCode: string,
      laneType: LaneType
    ) =>
      `/operations/${serviceCenter.code}/outbound/lane-details/shipment-details?view=lane&loadToSicId=${loadToSicId}&loadToSicCode=${loadToSicCode}&laneType=${laneType}`,
    outboundShipmentDetailsManageDoors: (
      loadToSicId: number,
      loadToSicCode: string,
      laneType: LaneType
    ) =>
      `/operations/${serviceCenter.code}/outbound/lane-details/shipment-details?view=lane&loadToSicId=${loadToSicId}&loadToSicCode=${loadToSicCode}&laneType=${laneType}&laneTab=manageDoors`,
    outboundShipmentDetailsByDoor: (
      doorNumber: number,
      loadToSicId: number,
      loadToSicCode: string
    ) =>
      `/operations/${serviceCenter.code}/outbound/lane-details/shipment-details?view=door&viewDoorNumber=${doorNumber}&loadToSicId=${loadToSicId}&loadToSicCode=${loadToSicCode}`,
    outboundTrailerShipments: ({
      view,
      tripNumber,
      plannedPullId,
      trapId,
    }: OutboundShipmentProps) =>
      `/operations/${serviceCenter.code}/outbound/trailer-hub/shipments?view=${view}${trapId ? `&trapId=${trapId}` : ""}${tripNumber ? `&tripNumber=${tripNumber}` : ""}${plannedPullId ? `&plannedPullId=${plannedPullId}` : ""}`,
    outboundTrailers: (view: string) =>
      `/operations/${serviceCenter.code}/outbound/trailer-hub?&view=${view}`,
    zonesListInbound: `/settings/${serviceCenter.code}/inbound/zones/list?route=all&serviceCenterId=${serviceCenter.id}`,
    settings: (sicCode?: string) =>
      `/settings/${sicCode ?? serviceCenter.code}/`,
    dispatch: (sicCode?: string) =>
      `/operations/${sicCode ?? serviceCenter.code}/dispatch`,
  };
}
