import { Filter, Option } from "../../../types/filter";
import {
  Prefix,
  getUniqueOptions,
  handlePrefix,
  isActiveFilter,
} from "../utils";

export type BillsQueryParam = "bills";
export type SomethingWithBills = Record<BillsQueryParam, number>;

const getBillsOptions = (data: SomethingWithBills[]): Option[] =>
  getUniqueOptions(
    data
      .map(({ bills }) => ({
        label: bills.toString(),
        value: bills,
      }))
      .sort((a, b) => a.value - b.value)
  );

export const getBillsFilter = (
  data: SomethingWithBills[],
  prefix?: Prefix
): Filter => ({
  label: "Bills",
  queryParam: handlePrefix("bills", prefix),
  options: getBillsOptions(data),
});

export const filterBills = <T extends SomethingWithBills>(
  data: T[],
  filters?: string[]
): T[] =>
  isActiveFilter(filters)
    ? data.filter((entry) => filters.includes(entry.bills.toString()))
    : data;
