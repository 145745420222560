import { useSearchParams } from "react-router-dom";
import { useLinehaulCarriers } from "../../../../hooks/react-query/linehaul/useLinehaulCarriers";
import FilterMultiselect, {
  FilterProps,
} from "../../../../components/shared/FilterMultiselect";
import { FilterOption } from "./RegionFilter";

export const CARRIER_FILTER_KEY = "carrier";

export type CarrierSort = (
  a: { code: string; name: string },
  b: { code: string; name: string }
) => number;

type CarrierFilterProps = FilterProps & {
  carrierCodeOptions?: FilterOption[];
  sortCarriers?: CarrierSort;
};

export const CarrierFilter = ({
  initialOpen = false,
  onRemove,
  carrierCodeOptions,
  sortCarriers = () => 1,
}: CarrierFilterProps) => {
  const [search, setSearch] = useSearchParams();
  const getCarriersFromAPI =
    !carrierCodeOptions || carrierCodeOptions.length === 0;
  const activeCarrierFilter = search.getAll(CARRIER_FILTER_KEY);

  const { data: carriers = [] } = useLinehaulCarriers(getCarriersFromAPI);

  function getFilterOptions() {
    if (carrierCodeOptions) {
      return carrierCodeOptions.map((o) => ({ code: o.key, name: o.name }));
    }
    return carriers;
  }
  const filterOptions = getFilterOptions().sort(sortCarriers);

  const selectedOptions = filterOptions.filter((o) =>
    activeCarrierFilter.includes(o.code)
  );

  return (
    <FilterMultiselect
      id="carrier-filter"
      label="Carrier"
      options={filterOptions}
      value={selectedOptions}
      initialOpen={initialOpen}
      getDisplayValue={(value) => `${value.code} - ${value.name}`}
      getSelectedDisplayValue={(selected) =>
        selected.length === 0
          ? "All"
          : selected.length === 1
            ? selected[0].code
            : `${selected[0].code} (${selected.length})`
      }
      onChange={(selectedOptions) => {
        setSearch((old) => {
          const prev = new URLSearchParams(old);
          prev.delete(CARRIER_FILTER_KEY);
          selectedOptions.forEach((o) =>
            prev.append(CARRIER_FILTER_KEY, o.code)
          );
          return prev;
        });
      }}
      onRemove={() => {
        setSearch((old) => {
          const prev = new URLSearchParams(old);
          prev.delete(CARRIER_FILTER_KEY);
          return prev;
        });
        onRemove(CARRIER_FILTER_KEY);
      }}
    />
  );
};
