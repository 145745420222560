import {
  Paper,
  TableContainer,
  useTheme,
  alpha,
  colors,
  type SxProps,
} from "@mui/material";
import { useEffect, useRef, useState, type PropsWithChildren } from "react";
import invariant from "tiny-invariant";
import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { FlexColumn } from "../layout/Flex";

interface DroppableContainerProps {
  styles?: SxProps;
  emptyDroppableTableId?: string;
}

export const DroppableContainer = ({
  styles,
  emptyDroppableTableId,
  children,
}: PropsWithChildren<DroppableContainerProps>) => {
  const theme = useTheme();
  const ref = useRef(null);

  const [isDragged, setIsDragged] = useState(false);

  useEffect(() => {
    if (!emptyDroppableTableId) return;
    const tableEl = ref.current;
    invariant(tableEl, "Section component should exist");
    return dropTargetForElements({
      element: tableEl,
      onDragEnter: () => setIsDragged(true),
      onDragLeave: () => setIsDragged(false),
      onDrop: () => setIsDragged(false),
      getData: () => ({ id: emptyDroppableTableId }),
      getIsSticky: () => true,
    });
  }, [emptyDroppableTableId]);

  return (
    <FlexColumn>
      <TableContainer
        ref={ref}
        component={Paper}
        sx={{
          ...styles,
          flexGrow: 1,
          backgroundColor: isDragged
            ? `${alpha(
                theme.palette.primary.main,
                theme.palette.action.selectedOpacity
              )}`
            : colors.common.white,
        }}
      >
        {children}
      </TableContainer>
    </FlexColumn>
  );
};
