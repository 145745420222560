import { ShipmentDetailsResponse } from "../../../../hooks/react-query/outbound/freightDetails/useGetShipmentDetailsOld";

export const shipmentDetailsHistory = {
  default: {
    shipmentId: 133362626,
    proNumber: "153080023",
    destinationSic: "AFW",
    originSic: "ONT",
    shipper: "NICOLAS LOGISTICS INC, RIVERSIDE, CA, 92507",
    consignee: "77 RDC ARDMORE, ARDMORE, OK, 73401",
    cutTime: "",
    serviceDue: new Date("2025-02-26"),
    loadTo: "AFW",
    loadToDoor: 9,
    pieces: 0,
    pallets: 9,
    weight: 5583,
    status: "LDDK",
    location: "",
    tags: [
      {
        id: 81,
        name: "VC",
        color: "#AA7EA0",
        description: "Vendor Compliance",
      },
      {
        id: 4,
        name: "APTR",
        color: "#777DE9",
        description: "Appointment Required ",
      },
    ],
    history: [
      {
        id: 300011888,
        statusStartDate: new Date("2025-03-06T17:40:36.565991+00:00"),
        statusEndDate: null,
        statusId: 21,
        description: "Linehaul Loaded",
        details: "Trip Number: #10001398",
      },
      {
        id: 60909249,
        statusStartDate: new Date("2025-02-22T11:02:27.113+00:00"),
        statusEndDate: new Date("2025-02-22T11:02:27.113+00:00"),
        statusId: 18,
        description: "Unloaded",
        details: "ONT",
      },
    ],
  },
} as const satisfies Record<string, ShipmentDetailsResponse>;
