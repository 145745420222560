import { Shipment } from "../../hooks/react-query/outbound/linehaulTrailers/useShipmentsSearch";

export const shipmentSearchResponse = {
  default: [
    {
      shipmentId: 131246111,
      proNumber: "P7862906",
      currentLane: "IND-PITD",
      newLane: "IND-PITD",
      finalDestinationSic: "IND-PITD",
      doorNumber: 15,
      dueDate: new Date("2024-02-06"),
      transitDays: 1,
      makeService: true,
      newETA: new Date("2024-06-15"),
      shipmentTags: [
        {
          id: 9,
          name: "DTCL",
          color: "#5E9FE0",
          description: "Delivery to Connecting Line",
        },
      ],
      status: "CLDV",
      checked: false,
      isShipmentOnCurrentSic: false,
    },
    {
      shipmentId: 131246222,
      proNumber: "110950987",
      currentLane: "IND-PITD",
      newLane: "IND-PITD",
      finalDestinationSic: "IND-PITD",
      doorNumber: 15,
      dueDate: new Date("2024-02-06"),
      transitDays: 1,
      makeService: true,
      newETA: new Date("2024-06-15"),
      shipmentTags: [
        {
          id: 9,
          name: "DTCL",
          color: "#5E9FE0",
          description: "Delivery to Connecting Line",
        },
      ],
      status: "CLDV",
      checked: false,
      isShipmentOnCurrentSic: false,
    },
    {
      shipmentId: 131246321,
      proNumber: "110951084",
      currentLane: "IND-PITD",
      newLane: "IND-PITD",
      finalDestinationSic: "IND-PITD",
      doorNumber: 15,
      dueDate: new Date("2024-02-06"),
      transitDays: 1,
      makeService: true,
      newETA: new Date("2024-06-15"),
      shipmentTags: [
        {
          id: 9,
          name: "DTCL",
          color: "#5E9FE0",
          description: "Delivery to Connecting Line",
        },
      ],
      status: "CLDV",
      checked: false,
      isShipmentOnCurrentSic: false,
    },
    {
      shipmentId: 123456789,
      proNumber: "P1234567",
      currentLane: "ONT",
      newLane: "LAX",
      finalDestinationSic: "LAX",
      doorNumber: 15,
      dueDate: new Date("2024-02-06"),
      transitDays: 1,
      makeService: true,
      newETA: new Date("2024-06-15"),
      shipmentTags: [
        {
          id: 9,
          name: "DTCL",
          color: "#5E9FE0",
          description: "Delivery to Connecting Line",
        },
      ],
      status: "CLDV",
      checked: false,
      isShipmentOnCurrentSic: false,
    },
  ],
} as const satisfies Record<string, Shipment[]>;
