import { Box, IconButton, Tooltip, useTheme } from "@mui/material";
import type { Dispatch, SetStateAction } from "react";
import { Info } from "@mui/icons-material";
import { PlanTableFormat } from "../../../../types/planning/plan.type";
import { LockRouteIconButton } from "./LockRouteIconButton";
import { CopyLinkIconButton } from "./CopyLinkIconButton";
import { PrintIconButton } from "./PrintIconButton";
import { EditIconButton } from "./EditIconButton";
import { MapViewIconButton } from "./MapViewIconButton";

type ShipmentTableIconActionsProps = {
  isMapViewEnabled: boolean;
  routeOrTrap: PlanTableFormat;
  clearSelection: () => void;
  isInfoBarOpen: boolean;
  setIsInfoBarOpen: Dispatch<SetStateAction<boolean>>;
};

export const ShipmentTableIconActions = ({
  isMapViewEnabled,
  routeOrTrap,
  clearSelection,
  isInfoBarOpen = false,
  setIsInfoBarOpen,
}: ShipmentTableIconActionsProps) => {
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center">
      {isMapViewEnabled && routeOrTrap.type === "delivery_route" && (
        <MapViewIconButton routeOrTrap={routeOrTrap} />
      )}
      {routeOrTrap.type === "delivery_route" && (
        <LockRouteIconButton
          route={routeOrTrap}
          clearSelection={clearSelection}
        />
      )}
      <CopyLinkIconButton />
      <PrintIconButton routeOrTrap={routeOrTrap} />
      <EditIconButton routeOrTrap={routeOrTrap} />
      <Tooltip
        title={`${routeOrTrap.type === "delivery_route" ? "Route" : "Trap"} information`}
      >
        <IconButton
          sx={{
            backgroundColor: isInfoBarOpen ? theme.palette.grey[200] : "unset",
          }}
          onClick={() => setIsInfoBarOpen(!isInfoBarOpen)}
        >
          <Info />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
