import { Box, useTheme, type SxProps } from "@mui/material";
import { tableSectionToIconMap } from "./TableActionBar";

type InboundTrailerIconProps = {
  isLockedRoute: boolean;
  icon: keyof typeof tableSectionToIconMap;
  size?: "small" | "large";
  sx?: SxProps;
};

export const InboundTrailerIcon = ({
  isLockedRoute,
  icon,
  size = "large",
  sx,
}: InboundTrailerIconProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={sx}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: size === "small" ? 20 : 24,
        maxHeight: size === "small" ? 20 : 24,
        color: isLockedRoute
          ? theme.palette.background.paper
          : theme.palette.grey[600],
        backgroundColor: isLockedRoute
          ? theme.palette.grey[600]
          : theme.palette.grey[50],
        borderRadius: 4,
        padding: 2,
      }}
    >
      {tableSectionToIconMap[icon]}
    </Box>
  );
};
