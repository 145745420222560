import {
  Fragment,
  useEffect,
  useRef,
  useState,
  type MouseEvent,
  type PropsWithChildren,
  type RefObject,
} from "react";

import { alpha, Box, IconButton, Typography, useTheme } from "@mui/material";
import {
  ArrowDropDown,
  ContentPaste,
  Lock,
  MonitorWeight,
  Receipt,
  CheckCircle,
} from "@mui/icons-material";
import invariant from "tiny-invariant";
import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import Loading from "../../../../components/shared/layout/Loading";
import { useSelectionContext } from "../../../../context/SelectionContext";
import {
  useShipmentDetailSearch,
  type SortShipmentsBy,
} from "../../../../hooks/react-query/preplanning/useShipmentDetails";
import { dateToInt } from "../../../../utils/dateTimeHelper";
import type { SummaryContext, SummaryContextType } from "../ActionsMenuContext";
import type { Tag } from "../../../../types/freight/tag.type";
import type { tableSectionToIconMap } from "../TableActionBar";
import type { PlanType } from "../../../../types/planning/plan.type";
import TableLink from "../../../../components/shared/table/TableLink";
import PlannedStatusTag from "../../../../components/shared/StatusTag/PlannedStatusTag";
import { TagsGrid } from "../../../../components/shared/TagsGrid";
import { formatNumber } from "../../../../constants/utils";
import PalletsIcon from "../../../../components/shared/icons/PalletsIcon";
import PiecesIcon from "../../../../components/shared/icons/PiecesIcon";
import { ShipmentItem } from "../../../../components/shared/sidebar/ShipmentItem";
import {
  Accordion,
  AccordionDetails,
} from "../../../../components/shared/layout/Accordion";
import type {
  InboundTabs,
  InboundView,
} from "../../../../types/inbound/inboundView.type";
import { getDroppableId } from "../../../../utils/dragging";
import type { ContextMenuRef } from "../../../../components/shared/ContextMenuDropdown";
import { sortShipmentsByStopSequence } from "../../../../utils/sortComparator";
import {
  Item,
  ItemSummary,
  NoResultFound,
  Summary,
  Totals,
} from "./SectionItem";

type DroppableSectionItemData = {
  id: number;
  title?: string;
  name: string | null;
  bills: number;
  pallets: number;
  pieces: number;
  weight: number;
  weightUnit?: string;
  shipmentIds?: number[];
  tags?: Tag[];
  status?: string;
  allFuturePlanDates?: number[];
  allShipmentsPlannedForFuture?: boolean;
  allShipmentsPlanned?: boolean;
  isCommitted?: boolean | null;
  isPlanCompleted?: boolean | null;
};

interface DroppableSectionItemProps<T> {
  isEditingAllowed: boolean;
  serviceCenterId: number;
  selectedDate: string;
  data: T;
  contextRef?: RefObject<ContextMenuRef<SummaryContext>>;
  tab?: InboundTabs;
  isSelected?: boolean;
  canReorder?: boolean;
  icon?: keyof typeof tableSectionToIconMap;
  planType?: PlanType;
  sortBy?: SortShipmentsBy;
  inboundView?: InboundView;
}

export const DroppableSectionItem = <T extends DroppableSectionItemData>({
  isEditingAllowed,
  canReorder = false,
  data,
  selectedDate,
  serviceCenterId,
  icon,
  contextRef,
  tab,
  isSelected,
  planType,
  inboundView,
  sortBy,
}: PropsWithChildren<DroppableSectionItemProps<T>>) => {
  const sectionRef = useRef(null);
  const theme = useTheme();

  const {
    id,
    title,
    name,
    bills,
    pallets,
    pieces,
    weight,
    weightUnit,
    allFuturePlanDates,
    allShipmentsPlanned,
    allShipmentsPlannedForFuture,
    isCommitted,
    shipmentIds = [],
    status,
    tags,
    isPlanCompleted,
  } = data;

  const {
    isSelected: isShipmentSelected,
    isAllSelected,
    handleSelect,
  } = useSelectionContext();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  const { data: shipments = [], isLoading } = useShipmentDetailSearch({
    search: {
      sicId: serviceCenterId,
      planDate: dateToInt(selectedDate),
      shipmentIds,
    },
    enabled: isExpanded,
    sortBy,
  });

  const handleSelection = () => {
    if (!isEditingAllowed) return;
    handleSelect({
      id,
      type,
      shipmentIds: shipmentIds.map(Number),
    });
  };

  function getType(): SummaryContextType {
    switch (inboundView) {
      case "appointments":
        return "bucket";
      case "sections":
        return "section";
      case "routes":
        return "route";
      case "traps":
        return "trap";
      default:
        return "bucket";
    }
  }

  const type = getType();

  const handleContext = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (!isEditingAllowed) return;
    if (!isAllSelected(shipmentIds, type)) handleSelection();
    contextRef &&
      contextRef.current?.openContextMenu(event, {
        id,
        shipmentIds,
        type,
      });
  };

  useEffect(() => {
    const sectionEl = sectionRef.current;
    invariant(sectionEl, "Section component should exist");
    return dropTargetForElements({
      element: sectionEl,
      onDragStart: () => setIsDraggedOver(true),
      onDragEnter: () => setIsDraggedOver(true),
      onDragLeave: () => setIsDraggedOver(false),
      onDrop: () => setIsDraggedOver(false),
      getData: () => ({ id: getDroppableId("sidebar", type, id) }),
      getIsSticky: () => true,
    });
  }, [id, type]);

  const isSectionPlanned =
    planType || allShipmentsPlanned
      ? "today"
      : allShipmentsPlannedForFuture
        ? "future"
        : undefined;

  const sectionNameMaxWidth = (): number => {
    const widthAdjustments = [isPlanCompleted ? 28 : 0, isCommitted ? 28 : 0];
    return 139 - widthAdjustments.reduce((acc, curr) => acc + curr, 0);
  };

  return (
    <Accordion
      sx={{
        border: 0,
        borderRadius: "0.5rem",
        backgroundColor: isDraggedOver
          ? `${alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)} !important`
          : isSelected
            ? `${theme.palette.action.selected} !important`
            : "unset",
      }}
      expanded={isExpanded}
      slotProps={{ transition: { unmountOnExit: true } }}
      onContextMenu={handleContext}
    >
      <Item ref={sectionRef} onClick={() => handleSelection()}>
        <Summary sx={{ flexWrap: "nowrap" }}>
          <IconButton
            aria-label={
              isExpanded ? `Collapse ${type} ${id}` : `Expand ${type} ${id}`
            }
            sx={{ padding: "4px" }}
            onClick={(event) => {
              event.stopPropagation();
              setIsExpanded(!isExpanded);
            }}
          >
            <ArrowDropDown
              sx={{
                transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </IconButton>
          <TableLink
            id={String(id)}
            title={name || title || ""}
            shipmentIds={shipmentIds}
            inboundView={planType ? "planning" : inboundView}
            tab={tab}
            icon={icon}
            iconSize="small"
            isLockedRoute={isCommitted}
            planType={planType}
            style={{
              fontWeight: 400,
              width: "100%",
            }}
          >
            <Typography
              sx={{
                maxWidth: sectionNameMaxWidth(),
                overflow: "hidden",
                position: "relative",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {name}
            </Typography>
          </TableLink>
          {isCommitted && <Lock sx={{ color: theme.palette.grey[600] }} />}
          {isPlanCompleted && (
            <CheckCircle style={{ color: theme.palette.primary.main }} />
          )}
          {status && (
            <PlannedStatusTag
              label={status}
              isPlannedFor={isSectionPlanned}
              futurePlanDates={allFuturePlanDates}
              selectedDate={selectedDate}
            />
          )}
        </Summary>
        <Box sx={{ marginLeft: "2rem" }}>
          <ItemSummary sx={{ marginBottom: "4px" }}>
            <TagsGrid tags={tags} maxOverlappedItems={3} />
          </ItemSummary>
          <ItemSummary>
            <Totals>
              <Receipt sx={{ fontSize: "1rem" }} />
              {formatNumber(bills)}
            </Totals>
            <Totals>
              <PalletsIcon />
              {formatNumber(pallets)}
            </Totals>
            <Totals>
              <PiecesIcon />
              {formatNumber(pieces)}
            </Totals>
            <Totals>
              <MonitorWeight sx={{ fontSize: "1rem" }} />
              {`${formatNumber(weight)} ${weightUnit}`}
            </Totals>
          </ItemSummary>
        </Box>
      </Item>
      <AccordionDetails sx={{ padding: 0, border: 0 }}>
        {isLoading && (
          <Box sx={{ padding: "1rem" }}>
            <Loading size={32} />
          </Box>
        )}
        {shipments.sort(sortShipmentsByStopSequence).map((shipment, index) => (
          <Fragment key={shipment.id}>
            <ShipmentItem
              index={index}
              canReorder={canReorder}
              isDraggable={isEditingAllowed}
              isEditingAllowed={isEditingAllowed}
              parentId={id}
              shipment={shipment}
              type={type}
              contextRef={contextRef}
              isSelected={isShipmentSelected(shipment.id, type)}
            />
          </Fragment>
        ))}
        {shipments.length === 0 && !isLoading && (
          <NoResultFound>
            <ContentPaste sx={{ fontSize: "2rem" }} />
            <Typography>No shipments found.</Typography>
          </NoResultFound>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
