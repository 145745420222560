import { useState, useEffect } from "react";
import invariant from "tiny-invariant";

function useSessionStorage<T>(key: string, initialValue?: T) {
  const [storedValue, setStoredValue] = useState<T | undefined>(() => {
    try {
      const item = sessionStorage.getItem(key);
      return item !== null ? JSON.parse(item) : initialValue;
    } catch (error) {
      invariant(`Error reading sessionStorage key "${key}"`);
      return initialValue;
    }
  });

  useEffect(() => {
    if (storedValue === undefined) {
      sessionStorage.removeItem(key);
    } else {
      try {
        sessionStorage.setItem(key, JSON.stringify(storedValue));
      } catch (error) {
        invariant(`Error setting sessionStorage key "${key}"`);
      }
    }
  }, [key, storedValue]);

  useEffect(() => {
    const handleStorage = (event: StorageEvent) => {
      if (event.key === key) {
        setStoredValue(event.newValue ? JSON.parse(event.newValue) : undefined);
      }
    };

    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, [key]);

  return [storedValue, setStoredValue] as const;
}

export default useSessionStorage;
