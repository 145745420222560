// CSpell:ignore pronotes uccc
import { useSplitTreatments } from "@splitsoftware/splitio-react";

export type FeatureFlag =
  | "operations-client"
  | "linehaul-client"
  | "dock-automation-client"
  | "operations-inbound-client"
  | "operations-outbound-client"
  | "linehaul-employee-drivers-functionality"
  | "inbound-edit-route-trap-guardrails-phase-2-client"
  | "linehaul-rate-per-mile-client"
  | "linehaul-master-schedule-employee-drivers-client"
  | "linehaul-multi-week-client"
  | "inbound-edit-route-trap-guardrails-phase-3-client"
  | "settings-drivers-view-edit-client"
  | "inbound-available-routes-traps-status-v2-client"
  | "dispatch-client"
  | "inbound-merge-trap-dialog-v2-client"
  | "inbound-merge-route-dialog-v2-client"
  | "inbound-new-table-design-client"
  | "inbound-print-city-card-load-manifest-client"
  | "inbound-print-linehaul-unload-manifest-client"
  | "inbound-print-trap-unload-manifest-report-client"
  | "linehaul-trip-list-client"
  | "dispatch-routes-filter-client"
  | "linehaul-loadboard-pronotes-client"
  | "dispatch-stops-panel-client"
  | "dispatch-summary-panel-client"
  | "inbound-planning-print-dialog-client"
  | "linehaul-loadboard-unassign-shipment-client"
  | "linehaul-loadboard-reassign-shipment-client"
  | "outbound-trailershub"
  | "dispatch-driver-latest-locations-client"
  | "inbound-routes-and-section-tabs"
  | "linehaul-loadboard-trip-status-change-client"
  | "linehaul-loadboard-schedule-table-view"
  | "linehaul-loadboard-uccc-planning-client"
  | "inbound-unplanned-freight-incomplete-trailers-client"
  | "inbound-planning-table-v2-fetch-strategy-client"
  | "linehaul-reship-client"
  | "inbound-planning-grid-view-visibility-client"
  | "linehaul-merge-client"
  | "inbound-traps-table-door-column-visibility-client"
  | "inbound-assign-driver-on-merge-client"
  | "inbound-new-planning-table-design-client"
  | "inbound-unplanned-freight-fod-assign-action-client"
  | "inbound-remove-door-not-allowed-client"
  | "service-center-tabs"
  | "linehaul-client-global-menu"
  | "inbound-planning-assign-dialog-show-shipment-detail"
  | "inbound-planning-reconcile-shipments-client"
  | "inbound-planning-map-link-client"
  | "inbound-planning-massage-route-client"
  | "linehaul-load-plan-report-client"
  | "linehaul-master-schedule-form-v3"
  | "inbound-planning-driver-ref-client"
  | "inbound-details-map-list-client"
  | "inbound-planning-stops-column-visibility-client"
  | "linehaul-rollover-client"
  | "inbound-planning-print-shipment-icon-visibility-client"
  | "inbound-planning-dock-manager-visibility-client"
  | "inbound-unplanned-freight-fod-assignment-client"
  | "linehaul-1506-service-center-overview-shipments-tab-client"
  | "inbound-2990-planning-bulk-status-update-step-one-client"
  | "inbound-3115-urgent-and-critical-care-client"
  | "inbound-3119-status-time-client"
  | "inbound-3131-list-tags-zipcodes-preset-routes-client"
  | "linehaul-1549-load-and-hold-on-new-entry-client"
  | "linehaul-1550-load-and-hold-icon-client";

export const useIsFeatureFlagEnabled = (feature: FeatureFlag) => {
  const { treatments, isReady } = useSplitTreatments({ names: [feature] });
  return isReady && treatments[feature].treatment === "on";
};
