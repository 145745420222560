import { ContentPaste, Mail, Star } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useState } from "react";
import SelectSicDialog from "./load-plan-report/SelectSicDialog";

const MenuHeader = ({ title }: { title: string }) => (
  <Box paddingBlock={0.5}>
    <Typography variant="body2" fontWeight={400}>
      {title}
    </Typography>
  </Box>
);

interface MenuItemProps {
  title: string;
  icon: React.ReactNode;
  route?: string;
  onClick?: () => void;
}

const MenuItem = ({ title, icon, route, onClick }: MenuItemProps) =>
  route ? (
    <Button
      variant="text"
      component={Link}
      to={route}
      onClick={onClick}
      sx={{ display: "block", width: "100%", color: "text.secondary" }}
    >
      <Stack direction="row" gap={2} paddingBlock={1}>
        {icon}
        <Typography variant="body1" fontWeight={400}>
          {title}
        </Typography>
      </Stack>
    </Button>
  ) : (
    <Button
      variant="text"
      onClick={onClick}
      sx={{ display: "block", width: "100%", color: "text.secondary" }}
    >
      <Stack direction="row" gap={2} paddingBlock={1}>
        {icon}
        <Typography variant="body1" fontWeight={400}>
          {title}
        </Typography>
      </Stack>
    </Button>
  );

export const LinehaulMenu = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Stack>
        <MenuHeader title="Linehaul" />

        <MenuItem icon={<Mail />} title="Email Templates" route="/linehaul" />

        <MenuItem icon={<Star />} title="TBD" route="/linehaul" />

        <MenuHeader title="Reports" />

        <MenuItem
          icon={<ContentPaste />}
          title="Load Plan"
          onClick={() => setDialogOpen(true)}
        />

        <MenuItem
          icon={<ContentPaste />}
          title="End of Shift"
          route="/linehaul/reports/end-of-shift"
        />
        <MenuItem
          icon={<ContentPaste />}
          title="Cancellations"
          route="/linehaul/reports/cancellations"
        />
      </Stack>
      {dialogOpen && (
        <SelectSicDialog open onClose={() => setDialogOpen(false)} />
      )}
    </>
  );
};
