import { Fragment, useCallback, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { ContentPaste } from "@mui/icons-material";
import { useSelectionContext } from "../../../../context/SelectionContext";
import { CollapsibleItem } from "../../../../components/shared/sidebar/CollapsibleItem";
import { tableSectionToIconMap } from "../TableActionBar";
import { type PlanTableFormat } from "../../../../types/planning/plan.type";
import { useDeliveryRoutesSequenceStatus } from "../../../../hooks/react-query/useDeliveryRoutesSequenceStatus";
import {
  filterPlanTableFormatBySearchTerm,
  onAutoSequenceRoutesFinished,
} from "../../inboundPlanning/utils";
import { dateToInt } from "../../../../utils/dateTimeHelper";
import { useAutoSequenceRoutes } from "../../../../hooks/react-query/useAutoSequenceRoute";
import Loading from "../../../../components/shared/layout/Loading";
import type { SummaryContext } from "../ActionsMenuContext";
import { unifyShipmentContext } from "../../../../utils/unifyShipmentContext";
import { useValidateMergeRequest } from "../../../../hooks/useValidateMergeRequest";
import { planningContextMenuActions } from "../../inboundPlanning/PlanningContextMenu/planningContextMenuActions";
import ContextMenuDropdown, {
  type ContextMenuRef,
} from "../../../../components/shared/ContextMenuDropdown";
import Filters from "../Filter/Filters";
import { useFilterSearchParams } from "../../../../hooks/useFilterSearchParams";

import type { ServiceCenter } from "../../../../types/serviceCenter.type";
import {
  filterPlannedSidebarRoutesAndTraps,
  plannedSidebarRoutesAndTrapsFilters,
} from "../../../../constants/filters/plannedSidebarRoutesAndTrapsFilters";
import { DroppableSectionItem } from "./DroppableSectionItem";
import { NoResultFound } from "./SectionItem";

type PlannedRoutesProps = {
  routes: PlanTableFormat[];
  selectedDate: string;
  serviceCenter: ServiceCenter;
  isEditingAllowed: boolean;
};

export const PlannedRoutes = ({
  routes,
  isEditingAllowed,
  selectedDate,
  serviceCenter,
}: PlannedRoutesProps) => {
  const contextMenuRef = useRef<ContextMenuRef<SummaryContext>>(null);

  const [searchParams] = useSearchParams();

  const { selected, clearSelection, isAllSelected } = useSelectionContext();
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [
    {
      routesZone: zone,
      routesLocation: location,
      routesStartTime: startTime,
      routesEndTime: endTime,
      routesRouteEnd: routeEnd,
      routesBills: bills,
      routesPallets: pallets,
      routesWeight: weight,
      routesPieces: pieces,
      routesTrailerName: trailerName,
      routesTractorName: tractorName,
      routesDriverName: driverName,
      routesName: name,
      routesStatus: status,
      routesTags: tags,
    },
  ] = useFilterSearchParams({
    routesZone: [],
    routesLocation: [],
    routesStartTime: [],
    routesEndTime: [],
    routesRouteEnd: [],
    routesBills: [],
    routesPallets: [],
    routesWeight: [],
    routesPieces: [],
    routesTrailerName: [],
    routesTractorName: [],
    routesDriverName: [],
    routesName: [],
    routesStatus: [],
    routesTags: [],
  });

  const filteredPlansBySearchTerm = filterPlanTableFormatBySearchTerm(
    routes,
    serviceCenter.timeZone,
    searchTerm
  );

  const filteredRoutes = filterPlannedSidebarRoutesAndTraps(
    filteredPlansBySearchTerm,
    serviceCenter.timeZone,
    {
      bills,
      driverName,
      endTime,
      location,
      name,
      pallets,
      pieces,
      routeEnd,
      startTime,
      status,
      tags,
      tractorName,
      trailerName,
      weight,
      zone,
    }
  );

  const { mutateAsync: autoSequenceRoutes, isPending } =
    useAutoSequenceRoutes();

  useDeliveryRoutesSequenceStatus({
    routeIds: filteredRoutes
      .filter(
        (route) =>
          route.type === "delivery_route" &&
          route.sequenceStatus === "processing"
      )
      .map((route) => route.id),
    timestamp: null,
    onAutoSequenceRoutesFinished: (result) =>
      onAutoSequenceRoutesFinished(
        dateToInt(selectedDate),
        serviceCenter.id,
        result,
        autoSequenceRoutes
      ),
  });

  const selectionToContext = useCallback(
    (plan?: SummaryContext): SummaryContext[] => {
      if (plan) {
        return [
          {
            id: String(plan.id),
            shipmentIds: plan.shipmentIds,
            type: plan.type,
          },
        ];
      }
      return unifyShipmentContext(
        selected.map(({ id }) => {
          const plan = filteredRoutes.find((plan) => plan.id.toString() === id);
          return {
            id,
            shipmentIds: plan ? plan.shipmentIds : [],
            type: plan
              ? plan.type === "delivery_route"
                ? "route"
                : "trap"
              : "route",
          };
        })
      );
    },
    [filteredRoutes, selected]
  );
  const selectedContext = selectionToContext();

  const { validateMergeRequest } = useValidateMergeRequest(
    selectedContext,
    routes
  );

  const contextActions = useCallback(
    (plan?: SummaryContext) => {
      if (plan?.type === "shipment") {
        const route = filteredRoutes.find((route) => route.id === plan.id);
        if (route) {
          return planningContextMenuActions({
            section: "shipments",
            shipmentContext: selectionToContext(plan),
            routeOrTrap: route,
            clearSelection,
          });
        }
      }
      return planningContextMenuActions({
        section: "planning",
        shipmentContext: selectionToContext(plan),
        clearSelection,
        plannedRoutesOrTraps: filteredRoutes,
        validateMergeRequest,
      });
    },
    [clearSelection, filteredRoutes, selectionToContext, validateMergeRequest]
  );

  if (isPending) {
    return (
      <Loading styles={{ marginY: "1rem" }} size={32} label="sidebar routes" />
    );
  }

  return (
    <Box>
      <CollapsibleItem
        title="Routes"
        displayCount={routes.length}
        icon={tableSectionToIconMap["route"]}
        isExpanded={isExpanded}
        onExpand={(value: boolean) => setIsExpanded(value)}
      >
        <Filters
          filters={plannedSidebarRoutesAndTrapsFilters(
            routes,
            searchParams,
            serviceCenter.timeZone,
            "routes"
          )}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          direction="column"
          placeholder="Search for route"
          filterSectionId="Routes"
        />
        {filteredRoutes.map((route) => (
          <Fragment key={route.id}>
            <DroppableSectionItem
              canReorder
              data={route}
              tab="list"
              isEditingAllowed={isEditingAllowed}
              isSelected={isAllSelected(route.shipmentIds, "route")}
              serviceCenterId={serviceCenter.id}
              selectedDate={selectedDate}
              inboundView="routes"
              planType="delivery_route"
              icon="route"
              contextRef={contextMenuRef}
            />
          </Fragment>
        ))}
        {filteredRoutes.length === 0 && (
          <NoResultFound>
            <ContentPaste sx={{ fontSize: "2rem" }} />
            <Typography>No routes found.</Typography>
          </NoResultFound>
        )}
      </CollapsibleItem>
      <ContextMenuDropdown actions={contextActions} ref={contextMenuRef} />
    </Box>
  );
};
