import React, { useState, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useForm, Controller } from "react-hook-form";
import { LoadingButton } from "../../../components/shared/LoadingButton";
import { useServiceCenters } from "../../../hooks/react-query/useServiceCenters";
import { BaseServiceCenter } from "../../../types/serviceCenter.type";
import { useLoadBoardDate } from "../loadboard/useLoadBoardSearchParams";
import { DaylightDateFormat } from "../../../constants/DaylightDateFormat";

interface SelectSicDialogProps {
  open: boolean;
  onClose: () => void;
}
interface FormInputs {
  serviceCenter: BaseServiceCenter | null;
}

const SelectSicDialog = ({ open, onClose }: SelectSicDialogProps) => {
  const serviceCenters = useServiceCenters("CompanyOwned");
  const [selectedSC, setSelectedSC] = useState<BaseServiceCenter | null>(null);
  const [confirmPending, setConfirmPending] = useState(false);
  const { date } = useLoadBoardDate();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<FormInputs>({
    defaultValues: {
      serviceCenter: null,
    },
  });

  const handleClosePrevented = (
    e: MouseEvent<HTMLButtonElement | HTMLElement>
  ) => {
    e.preventDefault();
    onClose();
  };

  const handleConfirm = () => {
    setConfirmPending(true);

    if (selectedSC) {
      navigate(
        `/linehaul/reports/load-plan/${selectedSC.code}?date=${date.format(
          DaylightDateFormat.ISO_DATE
        )}`
      );
    }

    setConfirmPending(false);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={handleClosePrevented}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <DialogTitle variant="h5">
        Load Plan Report
        <Box position="absolute" top={10} right={10}>
          <IconButton
            onClick={handleClosePrevented}
            aria-label="Close Load Plan Report Dialog"
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" fontWeight={500} color={colors.grey[800]}>
          Choose an origin service center
        </Typography>
        <Controller
          name="serviceCenter"
          control={control}
          rules={{ required: "Please select an origin service center" }}
          render={() => (
            <Autocomplete
              sx={{ padding: "15px 0px" }}
              options={serviceCenters.data ?? []}
              getOptionLabel={(option) => (option.id ? option.code : "")}
              isOptionEqualToValue={(option, value) =>
                option.code === value.code
              }
              value={selectedSC}
              onChange={(_, newValue) => {
                setSelectedSC(newValue);
                setValue("serviceCenter", newValue);
                clearErrors("serviceCenter");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="Origin SIC"
                  label="Origin SIC"
                  error={!!errors.serviceCenter}
                  helperText={errors.serviceCenter?.message}
                />
              )}
            />
          )}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-end", px: 3, py: 2 }}>
        <Button
          variant="outlined"
          onClick={handleClosePrevented}
          color="inherit"
          sx={{
            border: "none",
            color: colors.blue[700],
            textTransform: "none",
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          onClick={handleSubmit(handleConfirm)}
          isLoading={confirmPending}
          color="primary"
          sx={{
            ml: 1,
            textTransform: "none",
          }}
          loadingLabel="Loading..."
        >
          View Report
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SelectSicDialog;
