import { Box } from "@mui/material";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  AddFilterButton,
  FilterOption as FilterButtonOptions,
} from "../../../components/shared/AddFilterButton";
import {
  CARRIER_FILTER_KEY,
  CarrierFilter,
  CarrierSort,
} from "../shared/filter-controls/CarrierFilter";
import {
  DESTINATION_FILTER_KEY,
  ORIGIN_FILTER_KEY,
  ServiceCenterFilter,
} from "../shared/filter-controls/ServiceCenterFilter";
import {
  FilterOption,
  REGION_FILTER_KEY,
  RegionFilter,
} from "../shared/filter-controls/RegionFilter";
import { ClearFilterButton } from "../../../components/shared/ClearFilterButton";

export const SEARCH_FILTER_KEY = "search";
const allFilters = [
  { label: "Region", value: REGION_FILTER_KEY },
  { label: "Carrier", value: CARRIER_FILTER_KEY },
  { label: "Origin", value: ORIGIN_FILTER_KEY },
  { label: "Destination", value: DESTINATION_FILTER_KEY },
] as const satisfies FilterButtonOptions<string>[];

type FilterValue = (typeof allFilters)[number]["value"];

type LoadBoardFilterProps = {
  filters: FilterValue[];
  regionOptions?: FilterOption[];
  carrierCodeOptions?: FilterOption[];
  originSicOptions?: FilterOption[];
  destinationSicOptions?: FilterOption[];
  sortCarriers?: CarrierSort;
};

export function LoadBoardFilter({
  filters,
  regionOptions,
  carrierCodeOptions,
  originSicOptions,
  destinationSicOptions,
  sortCarriers = () => 1,
}: Readonly<LoadBoardFilterProps>) {
  const [searchParams, setSearchParams] = useSearchParams();

  const activeFilters = allFilters.filter((i) => filters.includes(i.value));

  const [selectedFilters, setSelectedFilters] = useState<
    FilterButtonOptions<FilterValue>[]
  >(activeFilters.filter((i) => searchParams.has(i.value)));

  const unselectedFilters = activeFilters.filter(
    (item) => !selectedFilters.find((filter) => filter.value === item.value)
  );

  const removeFilterKey = (key: string) => {
    setSelectedFilters((prev) => prev.filter((filter) => filter.value !== key));
  };

  return (
    <Box display="flex" gap={2}>
      {selectedFilters.map(({ value, initialOpen }) => {
        switch (value) {
          case REGION_FILTER_KEY:
            return (
              <RegionFilter
                key={value}
                regionOptions={regionOptions}
                initialOpen={initialOpen}
                onRemove={removeFilterKey}
              />
            );
          case CARRIER_FILTER_KEY:
            return (
              <CarrierFilter
                key={value}
                carrierCodeOptions={carrierCodeOptions}
                initialOpen={initialOpen}
                onRemove={removeFilterKey}
                sortCarriers={sortCarriers}
              />
            );
          case ORIGIN_FILTER_KEY:
            return (
              <ServiceCenterFilter
                key={value}
                filterKey="origin"
                options={originSicOptions}
                initialOpen={initialOpen}
                onRemove={removeFilterKey}
              />
            );
          case DESTINATION_FILTER_KEY:
            return (
              <ServiceCenterFilter
                key={value}
                filterKey="dest"
                options={destinationSicOptions}
                initialOpen={initialOpen}
                onRemove={removeFilterKey}
              />
            );
        }
      })}

      {unselectedFilters.length > 0 && (
        <AddFilterButton
          options={unselectedFilters}
          onClose={(value) => {
            if (value) {
              value.initialOpen = true;
              setSelectedFilters((prev) => [...prev, value]);
            }
          }}
        />
      )}

      {selectedFilters.length > 0 && (
        <ClearFilterButton
          onClick={() => {
            setSelectedFilters([]);
            setSearchParams((old) => {
              const prev = new URLSearchParams(old);
              activeFilters.forEach((item) => prev.delete(item.value));
              return prev;
            });
          }}
        />
      )}
    </Box>
  );
}
