import { Fragment, useState } from "react";
import { Business } from "@mui/icons-material";
import { CollapsibleItem } from "../../../../components/shared/sidebar/CollapsibleItem";
import { useSelectionContext } from "../../../../context/SelectionContext";
import { useDateSearchParamOrFallbackToToday } from "../../../../hooks/useDateSearchParamOrFallbackToToday";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import Loading from "../../../../components/shared/layout/Loading";
import { isSelectedDateTodayOrInTheFutureForCurrentSic } from "../../../../hooks/useIsSelectedDateTodayOrInTheFutureForCurrentSic";
import { useSections } from "../../../../hooks/react-query/preplanning/useSections";
import { SectionItem } from "./SectionItem";

export const Sections = () => {
  const { isAllSelected } = useSelectionContext();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const [serviceCenter] = useSelectedServiceCenter();

  const [isExpanded, setIsExpanded] = useState(false);

  const isEditingAllowed = isSelectedDateTodayOrInTheFutureForCurrentSic(
    selectedDate,
    serviceCenter
  );

  const { data: sections = [], isLoading } = useSections(serviceCenter.id);

  if (isLoading) return <Loading size={32} label="sidebar sections" />;

  return (
    <div>
      <CollapsibleItem
        title="Sections"
        displayCount={sections.length}
        icon={<Business />}
        isExpanded={isExpanded}
        onExpand={(value: boolean) => setIsExpanded(value)}
      >
        {sections.map((section) => {
          const { id, shipmentIds } = section;
          return (
            <Fragment key={id}>
              <SectionItem
                isEditingAllowed={isEditingAllowed}
                serviceCenterId={serviceCenter.id}
                selectedDate={selectedDate}
                inboundView="sections"
                data={section}
                icon="section"
                isSelected={isAllSelected(shipmentIds, "section")}
              />
            </Fragment>
          );
        })}
      </CollapsibleItem>
    </div>
  );
};
