// eslint-disable-next-line no-restricted-imports
import { DragIndicator } from "@mui/icons-material";
import { Button, styled, type SxProps } from "@mui/material";
import { forwardRef } from "react";

const DragHandleButton = styled(Button)(() => ({
  backgroundColor: "unset",
  "&:hover": {
    backgroundColor: "unset",
  },
  margin: 0,
  padding: 0,
  minWidth: "24px",
  maxWidth: "32px",
}));

type DragIconProps = {
  label?: string;
  sx?: SxProps;
  color?: string;
};

const DragIcon = forwardRef<HTMLButtonElement, DragIconProps>(
  ({ label, sx, color = "text.secondary" }, ref) => (
    <DragHandleButton ref={ref} aria-label={label} disableRipple>
      <DragIndicator
        sx={{
          ...sx,
          display: "block",
          color,
          "&:hover": {
            cursor: "grab",
          },
        }}
      />
    </DragHandleButton>
  )
);

DragIcon.displayName = "DragIcon";

export default DragIcon;
