import { ErrorBoundary } from "react-error-boundary";
import type { Ref } from "react";
import { ErrorContainer } from "../AccordionWithContentAndActionBar";
import { OldAssignToDialog } from "../AssignToDialog";
import { useActionsMenuContext } from "../ActionsMenuContext";
import { InboundTab } from "../../InboundLayout";
import { useIsFeatureFlagEnabled } from "../../../../featureFlags/useIsFeatureFlagEnabled";
import { UnassignedShipmentDroppable } from "../../inboundPlanning/OldUnassignedShipmentDroppable";
import type { SidebarRef } from "../sidebar/Sidebar";
import { ClearSelectionButton } from "../ClearSelectionButton";
import { SpecialShipments } from "../Filter/SpecialShipments";
import { AppointmentBuckets } from "./AppointmentBuckets";
import { Traps } from "./Traps";
import { DeliveryRoutes } from "./DeliveryRoutes";
import { Sections } from "./Sections";

type SidebarContentProps = {
  sidebarRef?: Ref<SidebarRef>;
  tab: InboundTab;
};

const ErrorFallback = () => <ErrorContainer title="Error when fetching" />;

export const SidebarContent = ({ tab, sidebarRef }: SidebarContentProps) => {
  const isRoutesAndSectionsEnabled = useIsFeatureFlagEnabled(
    "inbound-routes-and-section-tabs"
  );
  const { activeDialog } = useActionsMenuContext();
  return (
    <>
      {tab === "planning" && (
        <>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <UnassignedShipmentDroppable ref={sidebarRef} />
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <SpecialShipments />
          </ErrorBoundary>
        </>
      )}
      {isRoutesAndSectionsEnabled && (
        <>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <DeliveryRoutes />
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Sections />
          </ErrorBoundary>
        </>
      )}
      {tab === "pre-planning" && (
        <>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Traps tab={tab} />
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <AppointmentBuckets />
          </ErrorBoundary>
          <ClearSelectionButton />
        </>
      )}
      {activeDialog === "assign-to" && <OldAssignToDialog />}
    </>
  );
};
