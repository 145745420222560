import { Fragment, useState } from "react";
import { Route } from "@mui/icons-material";
import { CollapsibleItem } from "../../../../components/shared/sidebar/CollapsibleItem";
import { useSelectionContext } from "../../../../context/SelectionContext";
import { useDeliveryRoutesSummary } from "../../../../hooks/react-query/useDeliveryRoutes";
import { useDateSearchParamOrFallbackToToday } from "../../../../hooks/useDateSearchParamOrFallbackToToday";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { dateToInt } from "../../../../utils/dateTimeHelper";
import Loading from "../../../../components/shared/layout/Loading";
import { isSelectedDateTodayOrInTheFutureForCurrentSic } from "../../../../hooks/useIsSelectedDateTodayOrInTheFutureForCurrentSic";
import { SectionItem } from "./SectionItem";

export const DeliveryRoutes = () => {
  const { isAllSelected } = useSelectionContext();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const planDate = dateToInt(selectedDate);
  const [serviceCenter] = useSelectedServiceCenter();

  const [isExpanded, setIsExpanded] = useState(false);

  const isEditingAllowed = isSelectedDateTodayOrInTheFutureForCurrentSic(
    selectedDate,
    serviceCenter
  );

  const { data: routes = [], isLoading } = useDeliveryRoutesSummary(
    planDate,
    serviceCenter.id
  );

  if (isLoading) {
    return <Loading size={32} label="sidebar routes" />;
  }

  return (
    <div>
      <CollapsibleItem
        title="Routes"
        displayCount={routes.length}
        icon={<Route />}
        isExpanded={isExpanded}
        onExpand={(value: boolean) => setIsExpanded(value)}
      >
        {routes.map((route) => {
          const {
            shipmentIds,
            shipmentsCount: bills,
            shipmentsPallets: pallets,
            shipmentsPieces: pieces,
            shipmentsWeight: weight,
            shipmentsWeightUnit: weightUnit,
          } = route;
          return (
            <Fragment key={route.id}>
              <SectionItem
                isEditingAllowed={isEditingAllowed}
                serviceCenterId={serviceCenter.id}
                selectedDate={selectedDate}
                inboundView="routes"
                data={{
                  ...route,
                  bills,
                  pallets,
                  pieces,
                  weight,
                  weightUnit,
                }}
                icon="route"
                isSelected={isAllSelected(shipmentIds, "route")}
              />
            </Fragment>
          );
        })}
      </CollapsibleItem>
    </div>
  );
};
