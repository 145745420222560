import { Box, Button, IconButton, styled, Typography } from "@mui/material";
import {
  AssignmentReturn,
  Close,
  MonitorWeight,
  MoreHoriz,
  Receipt,
} from "@mui/icons-material";
import { useRef } from "react";
import { useSelectionContext } from "../../../context/SelectionContext";
import { useClearSelectionWhenServiceCenterChanges } from "../../../hooks/useClearSelectionWhenServiceCenterChanges";
import { ALWAYS_ON_TOP } from "../../../constants/utils";
import PiecesIcon from "../../../components/shared/icons/PiecesIcon";
import PalletsIcon from "../../../components/shared/icons/PalletsIcon";
import { useShipmentDetailSearch } from "../../../hooks/react-query/preplanning/useShipmentDetails";
import { useDateSearchParamOrFallbackToToday } from "../../../hooks/useDateSearchParamOrFallbackToToday";
import { dateToInt } from "../../../utils/dateTimeHelper";
import { useSelectedServiceCenter } from "../../../hooks/useSelectedServiceCenter";
import Loading from "../../../components/shared/layout/Loading";
import ContextMenuDropdown, {
  type ContextMenuRef,
} from "../../../components/shared/ContextMenuDropdown";
import type { ContextMenuAction } from "../../../types/contextMenuAction";
import { useActionsMenuContext } from "./ActionsMenuContext";

const maxWidth = 307;

const Wrapper = styled(Box)(({ theme }) => ({
  position: "fixed",
  width: "100%",
  maxWidth: `${maxWidth}px`,
  maxHeight: "145px",
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  bottom: 0,
  left: 0,
  padding: "1rem",
  zIndex: ALWAYS_ON_TOP,
}));

const ItemSummary = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "1rem",
}));

const Title = styled(Box)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: 500,
  lineHeight: 1.5,
  color: theme.palette.grey[600],
  marginBottom: "0.5rem",
}));

const Totals = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "4px",
  fontSize: "0.875rem",
  lineHeight: "1rem",
  color: theme.palette.grey[600],
}));

interface ClearSidebarSelectionProps<T> {
  contextActions?: (row?: T) => ContextMenuAction[];
}

export const ClearSidebarSelection = <T,>({
  contextActions,
}: ClearSidebarSelectionProps<T>) => {
  const contextMenuRef = useRef<ContextMenuRef<T>>(null);
  const { openDialog } = useActionsMenuContext();

  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const planDate = dateToInt(selectedDate);
  const [serviceCenter] = useSelectedServiceCenter();

  const { selected, clearSelection } = useSelectionContext();
  useClearSelectionWhenServiceCenterChanges(clearSelection);

  const handleAssignTo = () => {
    openDialog({
      name: "assign-to",
      shipmentContext: selected,
      clearSelection,
    });
  };

  const shipmentIds = selected.reduce<number[]>(
    (acc, route) => acc.concat(route.shipmentIds),
    []
  );

  const { data: shipments = [], isLoading } = useShipmentDetailSearch({
    search: {
      sicId: serviceCenter.id,
      planDate,
      shipmentIds,
    },
  });

  const shipmentMap = new Map(
    shipments.map((shipment) => [shipment.id, shipment])
  );

  const bills = shipmentIds.length;
  const { pallets, pieces, weight, weightUnit } = selected.reduce(
    (acc, route) => {
      route.shipmentIds.forEach((shipmentId) => {
        const shipment = shipmentMap.get(shipmentId);
        if (shipment) {
          acc.pieces += shipment.pieces;
          acc.pallets += shipment.pallets;
          acc.weight += shipment.weight;
          acc.weightUnit = shipment.weightUnit;
        }
      });
      return acc;
    },
    { pieces: 0, pallets: 0, weight: 0, weightUnit: "lbs" }
  );

  // Check if any item is selected
  if (bills <= 0) {
    return null;
  }

  return (
    <Wrapper>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "0.25rem",
          marginBottom: "1rem",
        }}
      >
        <IconButton
          aria-label="Clear Selection"
          onClick={() => {
            clearSelection();
          }}
        >
          <Close />
        </IconButton>
        <Box>
          {isLoading ? (
            <Loading size={32} />
          ) : (
            <>
              <Title>Selected</Title>
              <ItemSummary>
                <Totals>
                  <Receipt sx={{ fontSize: "1rem" }} />
                  {bills}
                </Totals>
                <Totals>
                  <PalletsIcon fill="#00000091" />
                  {pallets}
                </Totals>
                <Totals>
                  <PiecesIcon fill="#00000091" />
                  {pieces}
                </Totals>
                <Totals>
                  <MonitorWeight sx={{ fontSize: "1rem" }} />
                  {weight} {weightUnit}
                </Totals>
              </ItemSummary>
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "0.5rem",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <Button
          aria-label="Assign to"
          fullWidth
          variant="contained"
          sx={{
            fontSize: "0.875rem",
            fontWeight: 500,
            lineHeight: "1.5rem",
            gap: "0.5rem",
            color: "white",
          }}
          onClick={handleAssignTo}
        >
          <AssignmentReturn />
          <Typography>Assign to</Typography>
        </Button>
        {contextActions && (
          <>
            <Button
              aria-label="Open action menu"
              sx={{ minWidth: "48px", padding: 0 }}
              size="small"
              variant="outlined"
              onClick={(event) =>
                contextMenuRef.current?.openContextMenu(event)
              }
            >
              <MoreHoriz />
            </Button>
            <ContextMenuDropdown
              actions={contextActions}
              ref={contextMenuRef}
            />
          </>
        )}
      </Box>
    </Wrapper>
  );
};
