import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { z } from "zod";
import { http } from "../../utils/httpCommon";
import {
  Route,
  RouteV2,
  UpdateRoute,
  routesSchema,
  routesV2Schema,
} from "../../types/route.type";
import { apiUrls } from "../../utils/apiUrls";
import { toast } from "../../utils/snackbarHelper";
import { toastMessage } from "../../constants/strings";

export type NewRoute = Omit<
  Route,
  "id" | "driverId" | "doorId" | "equipmentType"
> & {
  driverId: number | null;
  doorId: number | null;
};

const errors = {
  presetRouteAlreadyExists: "PRESET_ROUTE_NAME_ALREADY_EXISTS",
};

export const newPresetRoute: NewRoute = {
  name: "",
  zones: [],
  zoneIds: [],
  zipCodes: [],
  startTime: "",
  endPoint: "",
  endTime: "",
  trailerId: null,
  tractorId: null,
  straightTruckId: null,
  driverId: null,
  doorId: null,
  isActive: true,
  hereFeatureId: "",
  sicId: 0,
  isRoute: true,
};

const useRoutesKeys = {
  routes: (serviceCenterId: number) => ["routes", serviceCenterId],
  route: (serviceCenterId: number, routeId: number) => [
    "routes",
    serviceCenterId,
    routeId,
  ],
  validateZipCode: (serviceCenterId: number, zipCode: string) => [
    "routes",
    serviceCenterId,
    "zipcodes",
    zipCode,
  ],
};

const getRoute = async (sicId: number, routeId: number) => {
  const { data } = await http.get(apiUrls.getRouteById, {
    params: { sicId },
  });

  const routes = routesSchema.parse(data);
  return routes.find((route) => route.id === routeId) || null;
};

export const useRoutes = (serviceCenterId: number, enabled = true) => {
  const getRoutes = async (sicId: number) => {
    const { data } = await http.get(apiUrls.getRoutesAndTraps, {
      params: { sicId, isRoute: true },
    });
    return routesSchema.parse(data);
  };

  return useQuery({
    enabled,
    queryKey: useRoutesKeys.routes(serviceCenterId),
    queryFn: () => getRoutes(serviceCenterId),
  });
};

export const useRoutesAndTraps = (serviceCenterId: number, enabled = true) => {
  const getRoutesAndTraps = async (sicId: number) => {
    const { data } = await http.get(apiUrls.getRoutesAndTraps, {
      params: { sicId },
    });
    return routesSchema.parse(data);
  };
  return useQuery({
    enabled,
    queryKey: useRoutesKeys.routes(serviceCenterId),
    queryFn: () => getRoutesAndTraps(serviceCenterId),
  });
};

export const useRoutesAndTrapsV2 = (
  serviceCenterId: number,
  enabled = true
) => {
  const getRoutesAndTraps = async (sicId: number) => {
    const { data } = await http.get(apiUrls.getRoutesAndTrapsV2, {
      params: { sicId },
    });
    return routesV2Schema.parse(data);
  };

  return useQuery({
    enabled,
    queryKey: ["routes", serviceCenterId],
    queryFn: () => getRoutesAndTraps(serviceCenterId),
  });
};

export const useRoute = (serviceCenterId: number, routeId: number) =>
  useQuery({
    queryKey: useRoutesKeys.route(serviceCenterId, routeId),
    queryFn: () => getRoute(serviceCenterId, routeId),
  });

export type PresetRouteType = "Route" | "Trap";

export const useCreatePresetRoutes = (
  onSuccess: () => void,
  type: PresetRouteType
) =>
  useMutation({
    mutationFn: async (route: NewRoute) => {
      const url = apiUrls.postRoutes;
      const { data } = await http.post<Route[]>(url, [
        {
          ...route,
          driverId: route.driverId || 0,
          tractorId: route.isRoute ? route.tractorId : null,
          endTime: route.endTime || null,
          doorId: route.doorId || 0,
          name: route.name,
        },
      ]);
      return data;
    },
    onSuccess,
    onError: (error) => {
      if (
        axios.isAxiosError(error) &&
        error.response?.data === errors.presetRouteAlreadyExists
      ) {
        return toast(
          type === "Route"
            ? toastMessage.settings.createRouteTrap.routeNameAlreadyExists
            : toastMessage.settings.createRouteTrap.trapNameAlreadyExists,
          {
            variant: "error",
          }
        );
      }
      toast(toastMessage.settings.createRouteTrap.failed, {
        variant: "error",
      });
    },
  });

export const useEditPresetRoute = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (route: Route) => {
      const updateRouteReq: UpdateRoute = {
        ...route,
        tractorId: route.isRoute ? route.tractorId : null,
        driverId: route.driverId || 0,
        doorId: route.doorId || 0,
      };
      // TODO: Replace with GET API https://dylt.atlassian.net/browse/IODD-2477
      await http.put(apiUrls.putRoutes(route.id), updateRouteReq);
      return route;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: useRoutesKeys.routes(data.sicId),
      });
      queryClient.invalidateQueries({
        queryKey: useRoutesKeys.route(data.sicId, data.id),
      });
      onSuccess();
    },
    onError: () =>
      toast(toastMessage.settings.editRouteTrap.failed, {
        variant: "error",
      }),
  });
};

export type DeleteRouteCallbackArgs = {
  routeName: string;
  routeType: "Route" | "Trap";
};

type UseDeleteRouteArgs = {
  sicId: number;
  onSuccess?: (args: DeleteRouteCallbackArgs) => void;
  onError?: (args: DeleteRouteCallbackArgs) => void;
};

export const useDeleteRoute = ({
  sicId,
  onSuccess,
  onError,
}: UseDeleteRouteArgs) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (route: RouteV2) => {
      const { status } = await http.delete(apiUrls.deleteRoute(route.id));
      return status;
    },
    onSuccess: (_data, route) => {
      onSuccess?.({
        routeName: route.name,
        routeType: route.isRoute ? "Route" : "Trap",
      });
    },
    onError: (_error, route) => {
      onError?.({
        routeName: route.name,
        routeType: route.isRoute ? "Route" : "Trap",
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: useRoutesKeys.routes(sicId),
      });
    },
  });
};
export const useOldDeleteRoute = ({
  sicId,
  onSuccess,
  onError,
}: UseDeleteRouteArgs) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (route: Route) => {
      const { status } = await http.delete(apiUrls.deleteRoute(route.id));
      return status;
    },
    onSuccess: (_data, route) => {
      onSuccess?.({
        routeName: route.name,
        routeType: route.isRoute ? "Route" : "Trap",
      });
    },
    onError: (_error, route) => {
      onError?.({
        routeName: route.name,
        routeType: route.isRoute ? "Route" : "Trap",
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: useRoutesKeys.routes(sicId),
      });
    },
  });
};

const validateZipCodeSchema = z.object({
  zipCode: z.string(),
  isValid: z.boolean(),
});

const validateZipCodesSchema = validateZipCodeSchema.array().nonempty();
type ValidatedZipCodesResponse = z.infer<typeof validateZipCodesSchema>;

type UseValidateZipCodeArgs = {
  serviceCenterId: number;
  zipCode: string;
};

export const useValidateZipCode = ({
  serviceCenterId,
  zipCode,
}: UseValidateZipCodeArgs) =>
  useQuery({
    queryKey: useRoutesKeys.validateZipCode(serviceCenterId, zipCode),
    queryFn: async (): Promise<ValidatedZipCodesResponse> => {
      const { data } = await http.post(apiUrls.validateZipCodes, [zipCode]);
      return validateZipCodesSchema.parse(data);
    },
    enabled: !!zipCode,
    select: (data) => data[0],
  });

const moveZipCodeSchema = z.object({
  zipCode: z.string(),
  sourceRouteId: z.number(),
  destinationRouteId: z.number(),
});

const moveZipCodesSchema = moveZipCodeSchema.array().nonempty();
export type MoveZipCodesRequest = z.infer<typeof moveZipCodesSchema>;

type UseMoveZipCodesArgs = {
  sicId: number;
  onSuccess?: (response: MoveZipCodesRequest) => void;
  onError?: (response: MoveZipCodesRequest) => void;
  onSettled?: () => void;
};

export const useMoveZipCodes = ({
  sicId,
  onSuccess,
  onError,
  onSettled,
}: UseMoveZipCodesArgs) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (request: MoveZipCodesRequest) => {
      await http.put(apiUrls.moveZipCodes, request);

      return request;
    },
    onSuccess: (request) => {
      onSuccess?.(request);
    },
    onError: (_error, request) => {
      onError?.(request);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: useRoutesKeys.routes(sicId),
      });
      onSettled?.();
    },
  });
};
