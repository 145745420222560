import { Box, Breadcrumbs, capitalize, Typography } from "@mui/material";
import {
  Link,
  useLocation,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import pluralize from "pluralize";
import { ColumnDef } from "@tanstack/react-table";
import type {
  BaseEventPayload,
  ElementDragType,
} from "@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types";
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { useIsFeatureFlagEnabled } from "../../../../featureFlags/useIsFeatureFlagEnabled";
import { useIsSelectedDateTodayOrInTheFutureForCurrentSic } from "../../../../hooks/useIsSelectedDateTodayOrInTheFutureForCurrentSic";
import { useTableSelectionParam } from "../../../../hooks/useTableSelectionParam";
import Table, { TableRef } from "../../../../components/shared/table/Table";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import {
  SELECTED_DATE_PARAM_KEY,
  useDateSearchParamOrFallbackToToday,
} from "../../../../hooks/useDateSearchParamOrFallbackToToday";
import { dateToInt } from "../../../../utils/dateTimeHelper";
import {
  SummaryContext,
  useActionsMenuContext,
} from "../../shared/ActionsMenuContext";
import { useSearchTermParam } from "../../../../hooks/useSearchTermParam";
import { useDebouncedSearch } from "../../../../hooks/useDebouncedSearch";
import { useFilterSearchParams } from "../../../../hooks/useFilterSearchParams";
import { unifyShipmentContext } from "../../../../utils/unifyShipmentContext";
import { Shipment } from "../../../../services/prePlanningService.types";
import TableActionBar, {
  TableSection,
  tableSectionSchema,
} from "../../shared/TableActionBar";
import {
  filterShipmentData,
  getShipmentTableFilters,
} from "../../../../constants/filters/shipmentTableFilters";
import { TableShipment } from "../../../../components/shared/old-table/ShipmentsTableBase";
import { getDroppableId } from "../../../../utils/dragging";
import { PageNotFound } from "../../../PageNotFound";
import { keepSpecificParams } from "../../../../utils/searchParamHelper";
import { UNPLANNED_SELECTED_DATE } from "../../../../hooks/useUnplannedDatetimeSearchParam";
import SectionTitle from "../../prePlanning/tables/SectionTitle";
import { Totalizer } from "../../../../components/shared/totalizer";
import InboundActionBar from "../../shared/InboundActionBar";
import Filters from "../../shared/Filter/Filters";
import {
  plannedRoutesQueryKey,
  usePlannedRoutes,
} from "../../../../hooks/react-query/usePlannedRoutes";
import {
  filterShipmentsBySearchTerm,
  getFormattedPlanShipments,
} from "../utils";
import {
  planSummaryTypeSchema,
  PlanTableFormat,
} from "../../../../types/planning/plan.type";
import { useRoutesAndTraps } from "../../../../hooks/react-query/useRoutes";
import { InboundPlanningOutletContext } from "../InboundPlanningLayout";
import {
  actionsMap,
  ShipmentActionDialog,
  ShipmentActionTypeId,
} from "../ShipmentActionDialog";
import {
  AssignShipmentFn,
  UnassignShipmentFn,
} from "../../../../types/assignShipment.type";
import {
  ConfirmShipmentMoveDialog,
  type ShipmentToMove,
} from "../ConfirmShipmentMoveDialog";
import { toast } from "../../../../utils/snackbarHelper";
import { contextMenuActions } from "../../prePlanning/tables/contextMenuActions";
import { AssignToDialog } from "../../shared/AssignToDialog";
import { useShipmentsByRouteOrTrap } from "../../../../hooks/react-query/preplanning/useShipmentsByRouteOrTrap";
import { AutoSequenceButton } from "../AutoSequenceButton";
import {
  useDeliveryRoutesSequenceStatus,
  type DeliveryRouteSequenceStatus,
} from "../../../../hooks/react-query/useDeliveryRoutesSequenceStatus";
import { toastMessage } from "../../../../constants/strings";
import { queryClient } from "../../../../queryClientProvider";
import { useAutoSequenceRoutes } from "../../../../hooks/react-query/useAutoSequenceRoute";
import { FlexColumn, FlexRow } from "../../../../components/shared/layout/Flex";
import { planningContextMenuActions } from "../PlanningContextMenu/planningContextMenuActions";
import { InboundTrailerIcon } from "../../shared/InboundTrailerIcon";
import { MassageRoute } from "../MassageRoute";
import {
  getStopSequence,
  useInboundDragAndDrop,
} from "../useInboundDragAndDrop";
import { useReorderShipments } from "../../../../hooks/react-query/useReorderShipments";
import {
  useAssignShipments,
  useUnassignShipments,
} from "../../../../hooks/react-query/useAssignShipments";
import { ChangeableStatusTag } from "../ChangeableStatusTag";
import planShipmentColumns from "./planShipmentColumnsDef";
import { ShipmentTableIconActions } from "./ShipmentTableIconActions";
import { RouteOrTrapInfoBar } from "./RouteOrTrapInfoBar";

export const PlanShipmentsTable = () => {
  const isEditingEnabled = useIsSelectedDateTodayOrInTheFutureForCurrentSic();
  const isNewPlanningTableDesignEnabled = useIsFeatureFlagEnabled(
    "inbound-new-planning-table-design-client"
  );
  const isMassageRouteEnabled = useIsFeatureFlagEnabled(
    "inbound-planning-massage-route-client"
  );
  const isMapViewEnabled = useIsFeatureFlagEnabled(
    "inbound-planning-map-link-client"
  );

  const { sidebarRef, shipmentActionDialogRef, confirmDialogRef } =
    useOutletContext<InboundPlanningOutletContext>();
  const { openDialog, activeDialog } = useActionsMenuContext();
  const { selection, setSelection } = useTableSelectionParam();
  const { id = "" } = useParams();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tableRef = useRef<TableRef>(null);
  const [serviceCenter] = useSelectedServiceCenter();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const planDate = dateToInt(selectedDate);
  const [searchTerm, setSearchTerm] = useSearchTermParam();
  const debouncedSearch = useDebouncedSearch(searchTerm);
  const [filters] = useFilterSearchParams({
    presetRouteName: [],
    trailerNumber: [],
    serviceDueDate: [],
    appointmentDate: [],
    shipperName: [],
    consigneeName: [],
    consigneeAddress: [],
    consigneeCity: [],
    consigneeZip: [],
    destinationSicCode: [],
    status: [],
    tags: [],
    pieces: [],
    pallets: [],
    weight: [],
    eta: [],
  });

  const [autoSequenceTimestamp, setAutoSequenceTimestamp] = useState(
    new Date().getTime()
  );
  const [isRouteOrTrapInfoBarOpen, setIsRouteOrTrapInfoBarOpen] =
    useState(false);

  const { trailerTitle, type } = useMemo(
    function getSearchParams() {
      const search = new URLSearchParams(searchParams);
      const trailerTitle = search.get("trailerTitle");
      const type = planSummaryTypeSchema.parse(search.get("planType"));
      return { trailerTitle, type } as const;
    },
    [searchParams]
  );

  const { data: routes = [], isLoading: isRoutesLoading } = useRoutesAndTraps(
    serviceCenter.id
  );

  const {
    data: plannedRoutesOrTraps = [],
    isLoading: isPlannedRoutesLoading,
    refetch: refetchPlans,
  } = usePlannedRoutes(planDate, serviceCenter.id);

  const {
    data: shipments = [],
    isLoading: isShipmentsLoading,
    refetch: refetchShipments,
  } = useShipmentsByRouteOrTrap({
    trailerId: Number(id),
    type,
  });

  const formattedSearchTerm = debouncedSearch.toLowerCase();

  const filteredShipmentsBySearchTerm = useMemo(
    () =>
      shipments.filter((item) =>
        filterShipmentsBySearchTerm(
          item,
          formattedSearchTerm,
          serviceCenter.timeZone
        )
      ),
    [shipments, formattedSearchTerm, serviceCenter.timeZone]
  );

  const formattedPlannedRoutesAndTraps: PlanTableFormat[] = useMemo(
    () =>
      getFormattedPlanShipments(
        serviceCenter.id,
        planDate,
        plannedRoutesOrTraps,
        routes,
        filteredShipmentsBySearchTerm
      ),
    [
      serviceCenter.id,
      planDate,
      plannedRoutesOrTraps,
      routes,
      filteredShipmentsBySearchTerm,
    ]
  );

  const routeOrTrap = formattedPlannedRoutesAndTraps.find(
    (routeOrTrap) => routeOrTrap.id === Number(id)
  );

  const { mutate: reOrderShipment, isPending: isReordering } =
    useReorderShipments({
      date: planDate,
      sicId: serviceCenter.id,
      onSuccess: (_, count) => {
        shipmentActionDialogRef.current?.close();
        toast(toastMessage.inbound.reorder.success(count), {
          variant: "success",
        });
        tableRef.current?.clearSelection();
      },
      onSettled: () => {
        shipmentActionDialogRef.current?.close();
        confirmDialogRef.current?.close();
      },
    });

  const { mutateAsync: autoSequenceRoutes } = useAutoSequenceRoutes();

  const onAutoSequenceRoutesFinished = (
    sequenceResult: DeliveryRouteSequenceStatus[]
  ) => {
    queryClient.setQueryData<PlanTableFormat[]>(
      plannedRoutesQueryKey(planDate, serviceCenter.id, searchTerm),
      (previousStateOfPlans) => {
        if (!previousStateOfPlans) return [];

        // Map over the previous state to create a new array
        return previousStateOfPlans.map((plan) => {
          // Find the matching sequence status
          const sequence = sequenceResult.find((seq) => seq.id === plan.id);

          // If there's a matching sequence status, update the plan accordingly
          if (sequence) {
            if (sequence.sequenceStatus === "error") {
              return {
                ...plan,
                error: {
                  message: "Auto-Sequence Failed",
                  onRetry: () => autoSequenceRoutes([plan.id]),
                  isRetryLoading: false,
                },
              };
            }
            if (sequence.sequenceStatus === "processed") {
              return {
                ...plan,
                sequenceStatus: "processed",
              };
            }
          }
          // Return the plan as-is if no matching sequence status
          return plan;
        });
      }
    );
    if (sequenceResult.every((route) => route.sequenceStatus === "processed")) {
      toast(
        toastMessage.inbound.autoSequencing.success(sequenceResult.length),
        {
          variant: "success",
          preventDuplicate: true,
        }
      );
      refetchShipments();
    } else if (
      sequenceResult.every((route) => route.sequenceStatus === "error")
    ) {
      toast(toastMessage.inbound.autoSequencing.failed(sequenceResult.length), {
        variant: "error",
        preventDuplicate: true,
      });
    } else {
      const routesWithErrors = sequenceResult.filter(
        (promise) => promise.sequenceStatus === "error"
      );
      toast(
        toastMessage.inbound.autoSequencing.someFailed(
          sequenceResult.length - routesWithErrors.length,
          routesWithErrors.length
        ),
        {
          variant: "warning",
          preventDuplicate: true,
        }
      );
    }
    tableRef.current?.clearSelection();
  };

  useDeliveryRoutesSequenceStatus({
    routeIds: formattedPlannedRoutesAndTraps
      .filter(
        (route) =>
          route.type === "delivery_route" &&
          route.sequenceStatus === "processing"
      )
      .map((route) => route.id),
    timestamp: autoSequenceTimestamp,
    onAutoSequenceRoutesFinished,
  });

  const onSuccessfulAssignment = async (actionType: ShipmentActionTypeId) => {
    const { description } = actionsMap[actionType];

    shipmentActionDialogRef.current?.close();
    toast(description, {
      variant: "success",
    });

    sidebarRef.current?.clearSelection();
    await sidebarRef.current?.refresh();

    tableRef.current?.clearSelection();
  };

  const { mutate: assignShipment, isPending: isAssignPending } =
    useAssignShipments({
      onSuccess: async () => {
        await onSuccessfulAssignment("assign");
      },
      onSettled: () => {
        shipmentActionDialogRef.current?.close();
        confirmDialogRef.current?.close();
      },
    });

  const { mutate: unassignShipment, isPending: isUnassignPending } =
    useUnassignShipments({
      onSuccess: async () => {
        await onSuccessfulAssignment("unassign");
      },
      onSettled: () => {
        shipmentActionDialogRef.current?.close();
        confirmDialogRef.current?.close();
      },
    });

  const isLoading =
    isRoutesLoading ||
    isPlannedRoutesLoading ||
    isShipmentsLoading ||
    isReordering;

  const filteredShipments = filterShipmentData(
    filteredShipmentsBySearchTerm,
    filters,
    serviceCenter.timeZone
  );

  const shipmentFilters = useMemo(
    () =>
      getShipmentTableFilters(
        filteredShipments,
        serviceCenter.timeZone,
        searchParams
      ),
    [filteredShipments, searchParams, serviceCenter.timeZone]
  );

  const pathToMainTable = useMemo(
    // Create a path to navigate back to the main table.
    function createPathToMainTable() {
      // Split the pathname into an array of segments, filtering out any empty strings
      let pathSegments = pathname.split("/").filter(Boolean);
      // Check if the path has more than one segment
      if (pathSegments.length > 1) {
        // Remove the last two segments from the array (usually 'shipments' and an ID)
        pathSegments = pathSegments.slice(0, pathSegments.length - 2);
        // Reconstruct the path by joining the segments with '/'
        // and add a leading '/' to form a valid path
        return "/" + pathSegments.join("/");
      }
    },
    [pathname]
  );

  const mainTableSection: TableSection = useMemo(() => {
    const fragments = pathToMainTable?.split("/") || "";

    const sectionValidation = tableSectionSchema.safeParse(
      pluralize.singular(fragments[fragments.length - 1])
    );
    if (!sectionValidation.success) {
      throw new Error("Table section not defined");
    }
    return sectionValidation.data;
  }, [pathToMainTable]);

  const selectionToContext = useCallback(
    (shipment?: Shipment): SummaryContext[] => {
      if (shipment) {
        return [
          {
            id: id || "",
            shipmentIds: [shipment.id],
            type: "shipment",
          },
        ];
      }
      return unifyShipmentContext(
        selection.map((shipmentId) => {
          const shipment = filteredShipments.find(
            (shipment) => shipment.id.toString() === shipmentId
          );
          return {
            id,
            shipmentIds: shipment ? [shipment.id] : [],
            type: "shipment",
          };
        })
      );
    },
    [filteredShipments, id, selection]
  );

  const contextActions = useCallback(
    (shipment?: Shipment) =>
      planningContextMenuActions({
        section: "shipments",
        shipmentContext: selectionToContext(shipment),
        clearSelection: () => tableRef.current?.clearSelection(),
        routeOrTrap: routeOrTrap ? routeOrTrap : null,
      }),
    [routeOrTrap, selectionToContext]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const preColumns: ColumnDef<TableShipment, any>[] = [
    {
      id: "drag",
      header: "",
      enableSorting: false,
    },
  ];

  const columns = useMemo(
    () =>
      planShipmentColumns({
        isLoading,
        isEditable: isEditingEnabled,
        timeZone: serviceCenter.timeZone,
        routeOrTrap,
        parentType: type === "delivery_route" ? "route" : "trap",
      }),
    [isEditingEnabled, isLoading, routeOrTrap, serviceCenter.timeZone, type]
  );

  const commonProps = useMemo(
    () => ({
      ref: tableRef,
      caption: `${id} shipments`,
      isLoading,
      columns,
      preColumns,
      data: filteredShipments,
      defaultSort: [
        {
          id: type === "delivery_route" ? "stopSeq" : "consigneeName",
          desc: false,
        },
      ],
    }),
    [id, isLoading, columns, preColumns, filteredShipments, type]
  );

  const handleAssignShipments: AssignShipmentFn = useCallback(
    ({ destination, origin, shipmentIds, destinationIndex, location }) => {
      const { id: destinationId, parentId: destinationParentId } = destination;
      const { id: originId, parentId: originParentId } = origin;

      const sourcePlan = formattedPlannedRoutesAndTraps.find(
        (plan) => parseInt(originParentId || originId) === plan.id
      );

      const destinationPlan = formattedPlannedRoutesAndTraps.find(
        (plan) => parseInt(destinationParentId || destinationId) === plan.id
      );

      if (destinationPlan === undefined) {
        shipmentActionDialogRef.current?.close();
        throw new Error(
          `Droppable Destination not found with type: ${destination.type} and id: ${destinationId}`
        );
      }

      if (destinationParentId === originParentId) {
        if (origin.type === "trap" || destination.type === "trap") {
          toast(toastMessage.inbound.autoSequencing.notAllowedOnTraps, {
            variant: "error",
          });
          return;
        }

        if (destinationPlan.shipments.length > 0) {
          const afterShipmentId =
            getStopSequence({
              plan: destinationPlan,
              destinationIndex,
              location,
            }) || 0;
          shipmentActionDialogRef.current?.open("loading", "reorder");
          reOrderShipment({
            routeId: Number(destinationParentId),
            data: {
              shipmentIds,
              afterShipmentId,
            },
          });
        }
        shipmentActionDialogRef.current?.close();
        return;
      }

      const shipmentToMove: ShipmentToMove = {
        destination: {
          name: destinationPlan.name,
          status: destinationPlan.status,
          type: destination.type,
        },
        source: {
          name: sourcePlan?.name || "",
          status: sourcePlan?.status || "",
          type: origin.type,
        },
        shipmentIds,
        rollbackMessage: undefined,
        onConfirm: () => {
          assignShipment({
            origins: [
              {
                id: origin.parentId || origin.id,
                type: origin.type,
                shipmentIds,
              },
            ],
            target: {
              date: planDate,
              sicId: serviceCenter.id,
              id: destination.parentId || destination.id,
              type: destination.type,
            },
          });
        },
      };

      // On handleAssign, Destination is ALWAYS Route/Trap
      const onlyDestinationIsRouteOrTrap =
        sourcePlan?.type !== "delivery_route" &&
        sourcePlan?.type !== "delivery_trap";

      const bothAreRouteOrTrap =
        sourcePlan?.type === "delivery_route" ||
        sourcePlan?.type === "delivery_trap";

      // Shows rollback message saying that both going to return to CLDK
      if (
        bothAreRouteOrTrap &&
        !destinationPlan.isAvailableForMassage &&
        !sourcePlan.isAvailableForMassage
      ) {
        confirmDialogRef.current?.open({
          ...shipmentToMove,
          rollbackMessage: {
            toDestination: true,
            toSource: true,
          },
        });
      }
      // Shows rollback message only for the not available one
      else if (
        bothAreRouteOrTrap &&
        (!destinationPlan.isAvailableForMassage ||
          !sourcePlan.isAvailableForMassage)
      ) {
        confirmDialogRef.current?.open({
          ...shipmentToMove,
          rollbackMessage: {
            toDestination: !destinationPlan.isAvailableForMassage,
            toSource: !sourcePlan.isAvailableForMassage,
          },
        });
      }
      // Shows rollback message only to the destination
      else if (
        onlyDestinationIsRouteOrTrap &&
        !destinationPlan.isAvailableForMassage
      ) {
        confirmDialogRef.current?.open({
          ...shipmentToMove,
          rollbackMessage: {
            toDestination: true,
            toSource: false,
          },
        });
      }
      // DON'T SHOW MODAL
      else {
        shipmentActionDialogRef.current?.open("loading", "assign");
        assignShipment({
          origins: [
            {
              id: origin.parentId || origin.id,
              type: origin.type,
              shipmentIds,
            },
          ],
          target: {
            date: planDate,
            sicId: serviceCenter.id,
            id: destination.parentId || destination.id,
            type: destination.type,
          },
        });
      }
    },
    [
      assignShipment,
      confirmDialogRef,
      formattedPlannedRoutesAndTraps,
      planDate,
      reOrderShipment,
      serviceCenter.id,
      shipmentActionDialogRef,
    ]
  );

  const handleUnassignShipments: UnassignShipmentFn = useCallback(
    ({ shipmentIds, sourceId, sourceType }) => {
      const source = formattedPlannedRoutesAndTraps.find(
        (trap) => parseInt(sourceId) === trap.id
      );

      if (!source) {
        shipmentActionDialogRef.current?.close();
        throw new Error(
          `Draggable source not found with type: ${sourceType} and id: ${sourceId}`
        );
      }

      const unassignShipmentFn = () =>
        unassignShipment({
          origins: [
            {
              id: sourceId,
              type: sourceType,
              shipmentIds,
            },
          ],
          target: {
            date: planDate,
            sicId: serviceCenter.id,
            type: "unassign",
          },
        });

      // Shows rollback message only to the source
      if (!source.isAvailableForMassage) {
        confirmDialogRef.current?.open({
          destination: {
            name: "unassign",
            type: "shipment",
          },
          source: {
            name: source.name,
            status: source.status,
            type: sourceType,
          },
          shipmentIds,
          rollbackMessage: {
            toDestination: false,
            toSource: true,
          },
          onConfirm: unassignShipmentFn,
        });
      } else {
        shipmentActionDialogRef.current?.open("loading", "unassign");
        unassignShipmentFn();
      }
    },
    [
      confirmDialogRef,
      formattedPlannedRoutesAndTraps,
      planDate,
      serviceCenter.id,
      shipmentActionDialogRef,
      unassignShipment,
    ]
  );

  const { handleDrop } = useInboundDragAndDrop();

  const dropHandler = useCallback(
    ({ source, location }: BaseEventPayload<ElementDragType>) => {
      handleDrop({
        source,
        location,
        handleAssignShipments,
        handleUnassignShipments,
      });
    },
    [handleAssignShipments, handleDrop, handleUnassignShipments]
  );

  useEffect(
    () =>
      monitorForElements({
        onDrop: dropHandler,
      }),
    [dropHandler]
  );

  if (!isNewPlanningTableDesignEnabled) {
    return <PageNotFound />;
  }

  return (
    <>
      <FlexRow>
        <FlexColumn
          style={{ padding: 24, overflowY: "hidden", overflowX: "auto" }}
        >
          <Breadcrumbs
            sx={{ marginBottom: 2 }}
            aria-label="shipment-breadcrumbs"
          >
            <Link
              to={{
                pathname: pathToMainTable ?? "/",
                search: keepSpecificParams(new URLSearchParams(searchParams), [
                  SELECTED_DATE_PARAM_KEY,
                  UNPLANNED_SELECTED_DATE,
                ]).toString(),
              }}
            >
              Routes and Traps
            </Link>
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              color="text.primary"
            >
              <InboundTrailerIcon
                isLockedRoute={Boolean(routeOrTrap?.isCommitted)}
                icon={routeOrTrap?.type === "delivery_route" ? "route" : "trap"}
              />
              <Typography>{trailerTitle}</Typography>
            </Box>
          </Breadcrumbs>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1.5rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <SectionTitle
                page={capitalize(trailerTitle ?? "Not defined")}
                showToggles={false}
                containerStyles={{ margin: 0 }}
              />
              {routeOrTrap && (
                <ChangeableStatusTag
                  deliveryRouteId={routeOrTrap.id}
                  deliveryRouteName={routeOrTrap.routeName}
                  isAvailableForTransition={
                    !routeOrTrap.isBlockedFromTransition
                  }
                  status={routeOrTrap.status}
                  type={routeOrTrap.type}
                  statusLastChangedTime={routeOrTrap.statusLastChangedTime}
                />
              )}
              {isMassageRouteEnabled && routeOrTrap && (
                <MassageRoute
                  plan={routeOrTrap}
                  style={{ marginLeft: "8px" }}
                />
              )}
            </Box>
            {routeOrTrap && (
              <ShipmentTableIconActions
                isMapViewEnabled={isMapViewEnabled}
                routeOrTrap={routeOrTrap}
                clearSelection={() => tableRef.current?.clearSelection()}
                isInfoBarOpen={isRouteOrTrapInfoBarOpen}
                setIsInfoBarOpen={setIsRouteOrTrapInfoBarOpen}
              />
            )}
          </Box>
          <Totalizer.Root data={shipments}>
            <Totalizer.Content name="Bills" property="length" />
            <Totalizer.Content name="Plts" property="pallets" />
            <Totalizer.Content name="Pcs" property="pieces" />
            <Totalizer.Content name="lbs" property="weight" />
          </Totalizer.Root>
          <TableActionBar
            isShipmentTable
            section={mainTableSection}
            selectedRows={shipments.filter((shipment) =>
              selection.includes(shipment.id.toString())
            )}
            clearSelection={tableRef.current?.clearSelection}
            actions={
              isEditingEnabled && (
                <InboundActionBar
                  selectedRowsContext={selectionToContext()}
                  activeTab="shipments"
                  contextMenuActions={contextMenuActions({
                    section: "planning",
                    splitCreateActions: true,
                    shipmentContext: selectionToContext(),
                    openDialog,
                    clearSelection: () => tableRef.current?.clearSelection(),
                  })}
                />
              )
            }
          >
            <>
              <Filters
                filters={shipmentFilters}
                filterSectionId="Shipments"
                showEta
                placeholder="Search PRO Number, Shipper or Consignee Name"
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
              <AutoSequenceButton
                routesAndTraps={routeOrTrap ? [routeOrTrap] : []}
                selectedRoutesAndTraps={routeOrTrap ? [routeOrTrap] : []}
                clearSelection={() => tableRef.current?.clearSelection()}
                setAutoSequenceTimestamp={setAutoSequenceTimestamp}
              />
            </>
          </TableActionBar>
          {isEditingEnabled ? (
            <Table
              {...commonProps}
              getRowId={(row) => `${row.id}`}
              contextMenuActions={contextActions}
              rowSelectionMode="checkbox"
              onRowSelection={setSelection}
              isSortable
              droppableTableId={(row) =>
                getDroppableId("shipmentTable", type, row.original.id, id)
              }
              emptyDroppableTableId={getDroppableId("shipmentTable", type, id)}
            />
          ) : (
            <Table {...commonProps} />
          )}
        </FlexColumn>
        {routeOrTrap && (
          <RouteOrTrapInfoBar
            routeOrTrap={routeOrTrap}
            isOpen={isRouteOrTrapInfoBarOpen}
            setIsOpen={setIsRouteOrTrapInfoBarOpen}
          />
        )}
      </FlexRow>
      <ShipmentActionDialog ref={shipmentActionDialogRef} />
      <ConfirmShipmentMoveDialog
        ref={confirmDialogRef}
        isLoading={isAssignPending || isUnassignPending}
      />
      {activeDialog === "assign-to" && (
        <AssignToDialog
          source={routeOrTrap?.type === "delivery_route" ? "route" : "trap"}
          onSuccess={() => {
            refetchPlans();
            refetchShipments();
          }}
        />
      )}
    </>
  );
};
