import { MouseEvent } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useSelectionContext } from "../../../context/SelectionContext";
import { Shipment } from "../../../services/prePlanningService.types";
import { dashIfEmpty } from "../../../constants/utils";
import { SummaryContext } from "../shared/ActionsMenuContext";
import { useIsSelectedDateTodayOrInTheFutureForCurrentSic } from "../../../hooks/useIsSelectedDateTodayOrInTheFutureForCurrentSic";
import { SideBarRowDetails } from "./SideBarRowDetails";
import DraggableBox, { DraggableProps } from "./DraggableBox";

type UnassignedShipmentBoxProps = {
  shipment: Shipment;
  shipments: Shipment[];
  collapsed: boolean;
  setSelectedItems: (selectedItems: number[]) => void;
};

const textStyle = {
  fontSize: ".6rem ",
  fontWeight: "bold",
};

export const UnassignedShipmentBox = ({
  shipment,
  shipments,
  collapsed,
  setSelectedItems,
}: UnassignedShipmentBoxProps) => {
  const isEditingAllowed = useIsSelectedDateTodayOrInTheFutureForCurrentSic();
  const { selected, handleSelect } = useSelectionContext();

  const handleSelection = (event: MouseEvent<HTMLDivElement>) => {
    const shipmentContext: SummaryContext = {
      id: shipment.id,
      shipmentIds: [shipment.id],
      type: "shipment",
    };
    handleSelect(shipmentContext);

    const isShiftKeyPressed = event.shiftKey;
    const isCtrlKeyPressed = event.ctrlKey;

    const manageShipmentsSelectedUsingShiftKey = () => {
      const firstSelectedIndex = shipments.findIndex(
        (e) => e.id === Number(selected[0].id)
      );
      const secondSelectedIndex = shipments.findIndex(
        (e) => e.id === Number(shipment.id)
      );

      if (firstSelectedIndex !== -1 && secondSelectedIndex !== -1) {
        const startIndex = Math.min(firstSelectedIndex, secondSelectedIndex);
        const endIndex = Math.max(firstSelectedIndex, secondSelectedIndex);
        const ids = [
          ...new Set(
            shipments
              .slice(startIndex, endIndex + 1)
              .map((shipment) => shipment.id)
          ),
        ];
        setSelectedItems(ids);
      }
    };

    const manageShipmentsSelectedUsingCtrlKey = () => {
      const ids = [
        ...new Set(
          [...selected, shipmentContext].flatMap(
            (shipment) => shipment.shipmentIds
          )
        ),
      ];
      setSelectedItems(ids);
    };

    if (isShiftKeyPressed) {
      manageShipmentsSelectedUsingShiftKey();
    } else if (isCtrlKeyPressed) {
      manageShipmentsSelectedUsingCtrlKey();
    }
  };

  return collapsed ? (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "3px solid lightgray",
        padding: "8px 0 6px 6px",
        marginBottom: "0.2rem",
      }}
      onClick={handleSelection}
    >
      <Typography
        style={{
          ...textStyle,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {dashIfEmpty(shipment.consignee?.name)}
      </Typography>
      {isEditingAllowed && (
        <Tooltip title="More Actions">
          <IconButton size="small" aria-haspopup="true">
            <MoreHorizIcon sx={{ width: 22, height: 22 }} />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  ) : (
    <Box
      style={{
        margin: "5px 0",
        border: "1px solid #ccc",
      }}
      onClick={handleSelection}
    >
      <SideBarRowDetails
        selectedShipmentArr={[]}
        shipment={shipment}
        padding="10px"
      />
    </Box>
  );
};

type UnassignedShipmentDraggableBoxProps = DraggableProps &
  UnassignedShipmentBoxProps;

export const UnassignedShipmentDraggableBox = ({
  id,
  index,
  collapsed,
  shipment,
  shipments,
  shipmentId,
  setSelectedItems,
  selectedShipmentIds: selectedItems,
}: UnassignedShipmentDraggableBoxProps) => (
  <DraggableBox
    key={shipmentId}
    shipmentId={shipmentId}
    id={id}
    index={index}
    setSelectedItems={setSelectedItems}
    selectedShipmentIds={selectedItems}
  >
    <UnassignedShipmentBox
      collapsed={collapsed}
      shipment={shipment}
      shipments={shipments}
      setSelectedItems={setSelectedItems}
    />
  </DraggableBox>
);
