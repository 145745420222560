import { z } from "zod";
import { useMutation } from "@tanstack/react-query";
import { http } from "../../utils/httpCommon";
import { apiUrls } from "../../utils/apiUrls";
import { dateToInt } from "../../utils/dateTimeHelper";

const stateSchema = z.enum(["Unprocessed", "Processed"]);
const documentTypeSchema = z.enum([
  "LineHaulUnloadManifest",
  "CityLoadCardManifest",
  "Bulk",
  "TrapUnloadManifest",
]);

const printResultSchema = z.object({
  id: z.string(),
  state: stateSchema,
  type: documentTypeSchema,
  url: z.string(),
});

export type PrintResult = z.infer<typeof printResultSchema>;

type RouteIds = {
  routes: number[];
};

type TrapIds = {
  trapIds: number[];
};

type TripIds = {
  tripNumbers: number[];
};

type UsePrintBulkParams = {
  sicId: number;
  planDate: string;
  cityLoadCard?: RouteIds;
  trapLoadCard?: TrapIds;
  trapUnload?: TrapIds;
  lineHaulUnload?: TripIds;
  deliveryManifest?: RouteIds;
  deliveryReceipt?: RouteIds;
};

export const usePrintBulkReport = (
  onSuccess: (result: PrintResult) => void,
  onSettled?: (ids: number[]) => void
) =>
  useMutation({
    mutationFn: async ({
      sicId,
      cityLoadCard,
      trapUnload,
      trapLoadCard,
      lineHaulUnload,
      deliveryManifest,
      deliveryReceipt,
      planDate,
    }: UsePrintBulkParams): Promise<PrintResult> => {
      const payload = {
        sicId,
        planDate: dateToInt(planDate),

        cityLoadCard: cityLoadCard ? { ...cityLoadCard, sicId } : undefined,
        trapUnload: trapUnload ? { ...trapUnload, sicId } : undefined,
        trapLoadCard: trapLoadCard ? { ...trapLoadCard, sicId } : undefined,
        lineHaulUnload: lineHaulUnload
          ? { ...lineHaulUnload, sicId }
          : undefined,
        deliveryManifest: deliveryManifest
          ? { ...deliveryManifest, sicId }
          : undefined,
        deliveryReceipt: deliveryReceipt
          ? { ...deliveryReceipt, sicId }
          : undefined,
      };

      const { data } = await http.post(apiUrls.getBulkReport, payload);
      return printResultSchema.parse(data);
    },
    onSuccess,

    onSettled: (_, _error, variables: UsePrintBulkParams) => {
      const ids: number[] = [
        ...(variables.cityLoadCard?.routes ?? []),
        ...(variables.trapLoadCard?.trapIds ?? []),
        ...(variables.trapUnload?.trapIds ?? []),
        ...(variables.lineHaulUnload?.tripNumbers ?? []),
        ...(variables.deliveryManifest?.routes ?? []),
        ...(variables.deliveryReceipt?.routes ?? []),
      ];

      onSettled?.(ids);
    },
  });
