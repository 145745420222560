import { useState } from "react";
import { Divider, Stack, Typography, useTheme } from "@mui/material";
import { Label } from "@mui/icons-material";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { useShipmentDetailSearch } from "../../../../hooks/react-query/preplanning/useShipmentDetails";
import Loading from "../../../../components/shared/layout/Loading";
import { Tag } from "../../../../types/freight/tag.type";
import { AccordionWithContentAndActionBar } from "../AccordionWithContentAndActionBar";
import { useDateSearchParamOrFallbackToToday } from "../../../../hooks/useDateSearchParamOrFallbackToToday";
import { dateToInt } from "../../../../utils/dateTimeHelper";
import { useDeliveryRoutesSummary } from "../../../../hooks/react-query/useDeliveryRoutes";
import {
  RouteSummary,
  Shipment,
} from "../../../../services/prePlanningService.types";
import { ShipmentsSection } from "../old-sidebar/ShipmentsSection";
import { ShipmentTagsFilter } from "./ShipmentTagsFilter";

export type ShipmentWithRouteId = Shipment & { routeId: number };
export type TagWithShipmentCount = { tag: Tag; count: number };
type TagWithShipmentCountMap = { [tagName: string]: TagWithShipmentCount };

function getShipmentsWithRouteId(
  shipments: Shipment[],
  routes: RouteSummary[]
): ShipmentWithRouteId[] {
  // We need to add the parent route ID to shipments because it's needed in ShipmentsSection.
  // The routeId property of shipments is not always populated by the backend at the time of writing
  return shipments.map((shipment) => {
    const parentRoute = routes.find((route) =>
      route.shipmentIds.includes(shipment.id)
    );
    if (!parentRoute) {
      throw new Error(`Route not found for shipmentId ${shipment.id}`);
    }
    return { ...shipment, routeId: parentRoute.id };
  });
}

function getUniqueTagsWithCount(shipments: Shipment[]) {
  const tagCounter: TagWithShipmentCountMap = {};

  shipments.forEach((shipment) => {
    shipment.tags.forEach((tag) => {
      if (!(tag.name in tagCounter)) tagCounter[tag.name] = { tag, count: 0 };
      tagCounter[tag.name].count += 1;
    });
  });
  return Object.values(tagCounter);
}

export const SpecialShipments = () => {
  const theme = useTheme();
  const [serviceCenter] = useSelectedServiceCenter();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const [tagsApplied, setTagsApplied] = useState<string[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);

  const planDate = dateToInt(selectedDate);
  const { data: routes = [], isLoading: isLoadingRoutes } =
    useDeliveryRoutesSummary(planDate, serviceCenter.id);

  const routesShipmentsIds = routes.flatMap(
    (routeSummary) => routeSummary.shipmentIds
  );
  const { data: shipmentsDetails = [], isLoading: isLoadingShipments } =
    useShipmentDetailSearch({
      search: {
        sicId: serviceCenter.id,
        planDate,
        shipmentIds: routesShipmentsIds,
      },
    });

  const shipmentsWithTags = shipmentsDetails.filter(
    (shipment) => shipment.tags.length > 0
  );
  const shipments = getShipmentsWithRouteId(shipmentsWithTags, routes);
  const tags = getUniqueTagsWithCount(shipments);

  const handleTagFilterChange = (tagsApplied: TagWithShipmentCount[]) => {
    const tagNames = tagsApplied.map((tag) => tag.tag.name);
    setTagsApplied(tagNames);
  };

  const filteredShipments = shipments
    .filter((shipment) =>
      shipment.tags.some((tag) => tagsApplied.includes(tag.name))
    )
    .sort((a, b) => a.assignment?.localeCompare(b.assignment ?? "") ?? 0);

  if (isLoadingRoutes || isLoadingShipments) {
    return <Loading label="sidebar special shipments" />;
  }

  return (
    <AccordionWithContentAndActionBar
      onChange={(value: boolean) => setIsExpanded(value)}
      isExpanded={isExpanded}
      title={`Special Shipments (${filteredShipments.length} of ${shipments.length})`}
      icon={<Label />}
    >
      <ShipmentTagsFilter tags={tags} onChange={handleTagFilterChange} />

      {filteredShipments.length === 0 ? (
        <>
          <Divider />
          <Stack spacing={2} sx={{ px: 2, py: 3 }}>
            <Typography variant="body2" color={theme.palette.grey[600]}>
              No shipments to show. Select from the filter above.
            </Typography>
          </Stack>
        </>
      ) : (
        <ShipmentsSection
          planDate={selectedDate}
          shipments={filteredShipments}
        />
      )}
    </AccordionWithContentAndActionBar>
  );
};
